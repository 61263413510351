// import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserProvider";

// import { Link, useHistory } from "react-router-dom";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import RestauranteOrdenDomicilio from '../../../views/Restaurante/OrdenDomicilio';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
// import { DataContext } from "../../Context/Data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'
import '../../css/Venta.css'

toast.configure();
const modelo = {
  usuario: {
    id: "",
    cedula: "",
    nitempresa: "",
    nombre: "",
    nombreempresa: "",
    contraseña: "",
    llaveempresa: "",
    llaveusuario: "",
    cargo: "",
    departamento: "",
    Eliminado: "",
  },
  empresa: {
    empresa: {
      id: "",
      nit: "",
      nombre: "",
      descripcion: "",
      telefono: "",
      direccion: "",
      email: "",
      celular: "",
      llave: "",
      servidor: "",
      basededatos: "",
      activo: "",
      fechaultimopago: "",
      maximafechadeuso: "",
      Eliminado: "",
    },
    config: {
      carpeta_documentos: "",
      carpeta_bd: "",
      contribuyente_nit: "",
      contribuyente_razon_social: "",
      contribuyente_tipo: "",
      contribuyente_responsabilidad_fiscal: "",
      contribuyente_regimen_fiscal: "",
      contribuyente_matricula_mercantil: "",
      direccion_contribuyente_linea: "",
      direccion_contribuyente_municipio: "",
      direccion_contribuyente_departamento: "",
      direccion_contribuyente_pais: "",
      contribuyente_email: "",
      contribuyente_telefono: "",
      software_en_pruebas: "",
      usar_set_pruebas: "",
      identificador_set_pruebas: "",
      identificador_software: "",
      rango_numero_resolucion: "",
      rango_fecha_resolucion: "",
      rango_vigencia_desde: "",
      rango_vigencia_hasta: "",
      rango_clave_tecnica: "",
      rango_prefijo: "",
      rango_desde: "",
      rango_hasta: "",
      ruta_certificado: "",
      clave_certificado: "",
      encabezado_pdf: "",
      ruta_logo: "",
      smtp_host: "",
      smtp_puerto: "",
      smtp_ssl: "",
      smtp_usuario: "",
      smtp_clave: "",
      sucursal: "",
      pin_software: "",
      especialidad: "",
      Eliminado: "",
    },
    modulos: {
      FACTURA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      INVENTARIO: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      CARTERA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      NOMINA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      FACTURAELECTRONICA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      NOMINAELECTRONICA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      CONTABILIDAD: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      TESORERIA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      COMPRA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      RADIAN: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      MAESTROS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      ADMINISTRACION: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      },
      DATOSBASICOS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      }, ARTICULOS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
      }



    }
  }
}
const Orders = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [Movimientos, setMovimientos] = useState([]);
  const { user } = useContext(UserContext)
  const [dataUser, setDataUser] = useState(modelo)
  const [editarorden, seteditarorden] = useState(false);
  const [ordenselec, setordenselec] = useState({});

  useEffect(() => {
    let dt = JSON.parse(user)
    setDataUser(dt)
    buscartodoslosmovimientos('');

  }, [])

  const buscartodoslosmovimientos = (coincidencia) => {
    try {

      let dt = JSON.parse(user)
      var url = props.servidor + '/Movimientoapi/Restaurante/' + dt.usuario.cedula + '?llave=' + dt.usuario.llaveempresa + ''
      const api = fetch(props.servidor + '/Movimientoapi/Restaurante/' + dt.usuario.cedula + '?llave=' + dt.usuario.llaveempresa + '')
        .then((response) => {
          return response.ok ? response.json() : Promise.reject(response);
        })
        .then((dataJson) => {
          setMovimientos(dataJson)
          console.log(dataJson)
        }).catch((error) => {
          Swal.fire(
            'Opps!',
            'No pudieron cargar los movimientos',
            'error'
          )
          setMovimientos([])
        })
    } catch (error) {
      console.log(error)
    }
  }
  const anularorden = async (concepto, ndocumento) => {

    let dt = JSON.parse(user)

    let response = await fetch("" + props.servidor + "/Movimientoapi/anularrestaurante/" + concepto + "/" + ndocumento + "/" + dt.usuario.cedula + "?llave=" + dt.usuario.llaveempresa + "")
    if (response.ok) {
      let data = await response.json()
      Swal.fire(
        'Exito!',
        'Se anulo la Orden: ' + concepto + " - " + ndocumento,
        'success'
      )

    }



  }
  return (
    <Fragment>
      <div className="flexbetween">
        <h3 className="mb-1">Ordenes Mesero</h3>
        {/* <Button size="sm" color="danger" onClick={() => deleteAll()}>
          Delete all
        </Button> */}
      </div>
      <hr />
      <div className="mb-3">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fas fa-search"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Buscar"
            onChange={(e) => {
              // setSearchTerm(e.target.value);
            }}
          />
        </InputGroup>
        {/* <span className="ml-5 d-block align-middle">
          <small> *(Date format: mm/dd/yyyy)</small>
        </span> */}
        <Button onClick={() => { buscartodoslosmovimientos('') }}>Refrescar</Button>
      </div>
      {true === true ? (
        <Container>
          {/* {reversedOrders.length > 0 ? ( */}
          {Movimientos.length > 0 ? (
            <div className="flexevenly mt-4">
              {/* {reversedOrders */}
              {Movimientos
                .filter((order) => {
                  if (searchTerm === "") {
                    return order;
                  }
                  if (
                    order.faencmovi.estacion
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return order;
                  }
                  if (
                    order.faencmovi.fecha.toLowerCase().includes(searchTerm)
                  ) {
                    return order;
                  }
                })
                .map((order) => (
                  <Card
                    // key={reversedOrders._id}
                    className="ordercard mb-3 shadow bg-white rounded"
                  >
                    <CardHeader className=" fontsizefortables">
                      <div className="font-weight-bold flexbetween">
                        <big>Mesa :</big>
                        <big>{order.faencmovi.estacion}</big>
                      </div>
                      <br />
                      <div className="flexbetween">
                        <span>Fecha :</span>
                        <span>{order.faencmovi.fecha}</span>
                      </div>
                      <div className="flexbetween">
                        <span>Numero :</span>
                        <span>{order.faencmovi.ndocumento}</span>
                      </div>
                      <div className="flexbetween">
                        <span>Ordenado Por:</span>
                        <span>{order.faencmovi.nombreven}</span>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className="fontsizefortables"
                        size="sm"
                        responsive
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            {/* <th>Price</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {order.famovimiento.map((order) => (
                            <tr key={order.concepto + order.nrodocumento}>
                              <td>{order.descrip+" "+order.desadicional}</td>
                              <td>{order.cantidad}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      {/* <Link to="/sell/:tId/:tableName">
										<Button size="sm">Edit Order</Button>
									</Link> */}
                      <ButtonGroup>
                        {/* {true === true ? (
                          <Button
                            size="sm"
                            className="mr-2"
                            color="primary"
                            onClick={() => {
                              // getTable(order);
                              // getSingleOrder(order);
                              // toggle();
                            }}
                          >
                            Facturar
                          </Button>
                        ) : null} */}

                        <Button
                          size="sm"
                          color="warning"
                          className="mr-2"
                          onClick={() => {
                            // editOrders(order);
                            setordenselec(order)

                            seteditarorden(true)
                          }}
                        >
                          Editar
                        </Button>

                        <Button
                          size="sm"
                          color="danger"
                          onClick={async () => {
                            // getSingleOrder(order);
                            // toggle2(); 
                            await anularorden(order.faencmovi.concepto, order.faencmovi.ndocumento)
                          }}
                        >
                          Anular
                        </Button>
                      </ButtonGroup>
                    </CardFooter>

                  </Card>
                ))}
              <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden}>

                <ModalBody >

                  <RestauranteOrdenDomicilio vienedeafuera={true} mesa={ordenselec.faencmovi ? ordenselec.faencmovi.estacion : "sinmesa"} editarorden={editarorden} connection={props.connection}
                    correrSocket={props.correrSocket} permisosusuario={props.permisosusuario}
                    caja={props.caja} servidor={props.servidor} setrestauranteordendomicilio={(reportes) => {

                    }} />


                </ModalBody>
                <ModalFooter>

                  <Button size="lg" color="primary" onClick={() => {
                    seteditarorden(false)
                    buscartodoslosmovimientos('')
                    // buscarmesas()
                    // buscar()
                  }}>Cerrar</Button>
                </ModalFooter>
              </Modal>
            </div>
          ) : (
            <Fragment>No orders yet</Fragment>
          )}
        </Container>
      ) : (
        <Container>
          {true === false ? (
            <span className="text-center">
              <strong>Note :</strong> Please keep refreshing. After the order is
              confirmed, the order gets removed from here and is placed to
              Receipts page.
            </span>
          ) : null}

          {/* {filteredOrders.length > 0 ? ( */}
          {Movimientos.length > 0 ? (
            <div className="flexevenly mt-4">
              {/* {filteredOrders */}
              {Movimientos
                .filter((order) => {
                  if (searchTerm === "") {
                    return order;
                  }
                  if (
                    order.date
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return order;
                  }
                })
                .map((order) => (
                  <Card
                    // key={reversedOrders._id}
                    className="ordercard mb-3 shadow bg-white rounded"
                  >
                    <CardHeader className=" fontsizefortables">
                      <div className="font-weight-bold flexbetween">
                        <big>Type :</big>
                        <big>{order.tableName}</big>
                      </div>
                      <br />
                      <div className="flexbetween">
                        <span>Date :</span>
                        <span>{order.date}</span>
                      </div>
                      <div className="flexbetween">
                        <span>Id :</span>
                        <span>{order.oId}</span>
                      </div>
                      <div className="flexbetween">
                        <span>Ordered By :</span>
                        <span>{order.displayName}</span>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className="fontsizefortables"
                        size="sm"
                        responsive
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            {/* <th>Price</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {order.cart.map((order) => (
                            <tr key={order._id}>
                              <td>{order.itemName}</td>
                              <td>{order.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      {/* <Link to="/sell/:tId/:tableName">
                  <Button size="sm">Edit Order</Button>
                </Link> */}
                      <ButtonGroup>
                        {true === true ? (
                          <Button
                            size="sm"
                            className="mr-2"
                            color="primary"
                            onClick={() => {
                              // getTable(order);
                              // getSingleOrder(order);
                              // toggle();
                            }}
                          >
                            Checkout
                          </Button>
                        ) : null}

                        <Button
                          size="sm"
                          color="warning"
                          className="mr-2"
                          onClick={() => {
                            // editOrders(order);
                          }}
                        >
                          Edit Order
                        </Button>

                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            // getSingleOrder(order);
                            // toggle2();
                          }}
                        >
                          Cancel Order
                        </Button>
                      </ButtonGroup>
                    </CardFooter>
                    {/* <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} charCode="x">
                        Confirmation
                      </ModalHeader>
                      <ModalBody>
                        <Container>Are you sure?</Container>
                      </ModalBody>
                      <ModalFooter>
                        <Link to={`/orders/${getOrder.oId}`}>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => {
                              reservedCase(getOrder.tId);
                              addToReport();
                              toggle();
                              setTable(initialvaluefortable);
                              deleteOrdersForReport(getOrder.orderId);
                            }}
                          >
                            Yes
                          </Button>
                        </Link>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => toggle()}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal2} toggle={toggle2}>
                      <ModalHeader toggle={toggle2} charCode="x">
                        Confirmation
                      </ModalHeader>
                      <ModalBody>
                        <Container>Are you sure?</Container>
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => {
                            deleteOrders(getOrder.orderId, getOrder.tId);
                            toggle2();
                          }}
                        >
                          Yes
                        </Button>

                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => toggle2()}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal> */}
                  </Card>
                ))}
            </div>
          ) : (
            <Fragment>No orders yet</Fragment>
          )}
        </Container>
      )}
    </Fragment>
  );
};

export default Orders;
