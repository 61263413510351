import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
const modeloBcbanco = {
    codbanco: "",
    razonsocia: "",
    nitcedula: "",
    codsucursa: "",
    nomsucursa: "",
    contacto: "",
    ciudad: "",
    direccion: "",
    pais: "",
    telefono1: "",
    telefono2: "",
    fax: "",
    gerente: "",
    conseccheq: "",
    numcuentap: "",
    numcuentab: "",
    email: "",
    efsalant: "",
    efdebmes: "",
    efcremes: "",
    possalant: "",
    posdebmes: "",
    poscremes: "",
    conseccheq2: "",
    horafecha: "",
    periodo: "",
}
const Bcbanco = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Bcbanco, setBcbanco] = useState(modeloBcbanco);
    const [Bcbancos, setBcbancos] = useState([]);
    const [codbanco, setcodbanco] = useState([]);
    const [codbancos, setcodbancos] = useState([]);
    const [razonsocia, setrazonsocia] = useState([]);
    const [razonsocias, setrazonsocias] = useState([]);
    const [nitcedula, setnitcedula] = useState([]);
    const [nitcedulas, setnitcedulas] = useState([]);
    const [codsucursa, setcodsucursa] = useState([]);
    const [codsucursas, setcodsucursas] = useState([]);
    const [nomsucursa, setnomsucursa] = useState([]);
    const [nomsucursas, setnomsucursas] = useState([]);
    const [contacto, setcontacto] = useState([]);
    const [contactos, setcontactos] = useState([]);
    const [ciudad, setciudad] = useState([]);
    const [ciudads, setciudads] = useState([]);
    const [direccion, setdireccion] = useState([]);
    const [direccions, setdireccions] = useState([]);
    const [pais, setpais] = useState([]);
    const [paiss, setpaiss] = useState([]);
    const [telefono1, settelefono1] = useState([]);
    const [telefono1s, settelefono1s] = useState([]);
    const [telefono2, settelefono2] = useState([]);
    const [telefono2s, settelefono2s] = useState([]);
    const [fax, setfax] = useState([]);
    const [faxs, setfaxs] = useState([]);
    const [gerente, setgerente] = useState([]);
    const [gerentes, setgerentes] = useState([]);
    const [conseccheq, setconseccheq] = useState([]);
    const [conseccheqs, setconseccheqs] = useState([]);
    const [numcuentap, setnumcuentap] = useState([]);
    const [numcuentaps, setnumcuentaps] = useState([]);
    const [numcuentab, setnumcuentab] = useState([]);
    const [numcuentabs, setnumcuentabs] = useState([]);
    const [email, setemail] = useState([]);
    const [emails, setemails] = useState([]);
    const [efsalant, setefsalant] = useState([]);
    const [efsalants, setefsalants] = useState([]);
    const [efdebmes, setefdebmes] = useState([]);
    const [efdebmess, setefdebmess] = useState([]);
    const [efcremes, setefcremes] = useState([]);
    const [efcremess, setefcremess] = useState([]);
    const [possalant, setpossalant] = useState([]);
    const [possalants, setpossalants] = useState([]);
    const [posdebmes, setposdebmes] = useState([]);
    const [posdebmess, setposdebmess] = useState([]);
    const [poscremes, setposcremes] = useState([]);
    const [poscremess, setposcremess] = useState([]);
    const [conseccheq2, setconseccheq2] = useState([]);
    const [conseccheq2s, setconseccheq2s] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [periodo, setperiodo] = useState([]);
    const [periodos, setperiodos] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalBcbanco, setVerModalBcbanco] = useState(false);
    const [abriricuentas, setabriricuentas] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Bcbanco = { ...Bcbanco }
        _Bcbanco[e.target.name] = value
        setBcbanco(_Bcbanco)
        setRoles([])


    }
    const handleChange2 = (e) => {
        let value = e.codcuenta
        var _Bcbanco = { ...Bcbanco }
        _Bcbanco['numcuentap'] = value
        setBcbanco(_Bcbanco)
        setRoles([])


    }
    const obtenerBcbancos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Bcbancoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setBcbancos(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerBcbancos('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codbanco,
            sortable: true,
        },
        {
            name: 'Razon Social',
            selector: row => row.razonsocia,
            sortable: true,
        },
        {
            name: 'Nit',
            selector: row => row.nitcedula,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalBcbanco(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalBcbanco = (data) => {
        var enviodata = { ...data }
        var _Bcbanco = {
            codbanco: enviodata.codbanco,
            razonsocia: enviodata.razonsocia,
            nitcedula: enviodata.nitcedula,
            codsucursa: enviodata.codsucursa,
            nomsucursa: enviodata.nomsucursa,
            contacto: enviodata.contacto,
            ciudad: enviodata.ciudad,
            direccion: enviodata.direccion,
            pais: enviodata.pais,
            telefono1: enviodata.telefono1,
            telefono2: enviodata.telefono2,
            fax: enviodata.fax,
            gerente: enviodata.gerente,
            conseccheq: enviodata.conseccheq,
            numcuentap: enviodata.numcuentap,
            numcuentab: enviodata.numcuentab,
            email: enviodata.email,
            efsalant: enviodata.efsalant,
            efdebmes: enviodata.efdebmes,
            efcremes: enviodata.efcremes,
            possalant: enviodata.possalant,
            posdebmes: enviodata.posdebmes,
            poscremes: enviodata.poscremes,
            conseccheq2: enviodata.conseccheq2,
            horafecha: enviodata.horafecha,
            periodo: enviodata.periodo,
        }
        setBcbanco(_Bcbanco);
        setVerModalBcbanco(!verModalBcbanco);
        seteditar(true)
    }
    const cerrarModalBcbanco = () => {
        setBcbanco(modeloBcbanco)
        setVerModalBcbanco(!verModalBcbanco);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Bcbanco) => {

        let claves = Object.keys(_Bcbanco);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Bcbanco[clave] = Number(_Bcbanco[clave])
            }
        }
        return _Bcbanco
    }
    const verificar = (_Bcbanco) => {

        let claves = Object.keys(_Bcbanco);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codbanco' || clave == 'razonsocia') {
                if (_Bcbanco[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Bcbanco)
        var _Bcbanco = { ...Bcbanco }
        _Bcbanco = asignarceros(_Bcbanco)
        var _verificado = verificar(_Bcbanco)
        if (_verificado) {
            console.log(_Bcbanco)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Bcbancoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Bcbanco)
                })

            } else {
                response = await fetch("" + props.servidor + "/Bcbancoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Bcbanco)
                })
            }

            if (response.ok) {
                await obtenerBcbancos('');
                setBcbanco(modeloBcbanco)
                setVerModalBcbanco(!verModalBcbanco);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Bcbanco fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Bcbanco',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Bancos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataBcbancos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerBcbancos(document.getElementById('buscardataBcbancos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalBcbanco(!verModalBcbanco)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Bcbancos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalBcbanco}>
                <ModalHeader>
                    Detalle Bcbancos
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codbanco" value={Bcbanco.codbanco} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Razon Social*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="razonsocia" value={Bcbanco.razonsocia} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nit*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nitcedula" value={Bcbanco.nitcedula} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Sucursal*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codsucursa" value={Bcbanco.codsucursa} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Sucursal*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nomsucursa" value={Bcbanco.nomsucursa} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta Puc*</Label>
                                        <Input type="text" onKeyDown={()=>{
                                            setabriricuentas(true)
                                        }} bsSize="sm" onChange={handleChange} name="numcuentap" value={Bcbanco.numcuentap} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta Bancaria*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="numcuentab" value={Bcbanco.numcuentab} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="Datos Adicionales" title="Datos Adicionales">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Contacto*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="contacto" value={Bcbanco.contacto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Ciudad*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="ciudad" value={Bcbanco.ciudad} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Direccion*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="direccion" value={Bcbanco.direccion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Pais*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="pais" value={Bcbanco.pais} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Telefono 1*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono1" value={Bcbanco.telefono1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Telefono 2*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono2" value={Bcbanco.telefono2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fax*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="fax" value={Bcbanco.fax} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Gerente*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="gerente" value={Bcbanco.gerente} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cheque*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="conseccheq" value={Bcbanco.conseccheq} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Email*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="email" value={Bcbanco.email} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                    <BuscarCuentas tituloencabezado={"Buscar Cuentas"} tipocuenta={"banco"} abrir={abriricuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabriricuentas(ab) }} seleccionar={(value) => {
                        handleChange2(value)
                        setabriricuentas(false)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalBcbanco}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Bcbanco;
