import React, { useContext, useState } from "react"
import { UserContext } from "../context/UserProvider"
import Swal from 'sweetalert2'
import { Navigate } from "react-router-dom"
import Fondo from './images/en azul-01.svg'
import axios from 'axios';
// import Icono from './images/FactinIcono.ico'
import Icono from './images/logo.png'
import { PermisoContext } from '../context/PermisosProvider';

const modelomodulo = {
    factura: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    inventario: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    cartera: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    nomina: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    facturaelectronica: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    nominaelectronica: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    contabilidad: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    tesoreria: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    compra: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    radian: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    }


}
const Login = (props) => {

    const [_correo, set_Correo] = useState("")
    const [_clave, set_Clave] = useState("")
    const { user, iniciarSession } = useContext(UserContext)
    const { permisos, obtenerPermisos } = useContext(PermisoContext)
    const [autorizado, setautorizado] = React.useState(false);
    const [empresas, setempresas] = React.useState(false);
    const [empresa, setempresa] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    // const [url, seturl] = React.useState('http://factin.ddns.net:5001');
    // const [url, seturl] = React.useState('http://192.168.1.100:5001');
    // const [url, seturl] = React.useState('https://localhost:44351');
    const [url, seturl] = React.useState(props.servidorempresa);
    // const [url, seturl] = React.useState('http://192.168.1.100:5020');
    // const [url, seturl] = React.useState('http://factin.ddns.net:5020');
    // const [url, seturl] = React.useState('http://factin.ddns.net:5002');

    if (user != null && permisos!=null) {
        return <Navigate to="/" />
    }
    const buscarpermisos = async (codigo,nit) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidorempresa + '/Grupoapi/permisos/' + codigo+'/'+nit + '');

            if (response.ok) {
                let data = await response.json()
                var modulos = {

                }
                data.map((dato, index) => {
                    modulos[dato.codigomodulo] = dato
                })
                obtenerPermisos(modulos)
            }
        } catch (error) {
            console.log(error)
        }
    }
    var ingresar = () => {
        var usuario = document.getElementById('usuario').value
        var clave = document.getElementById('clave').value
        try {
            axios({
                method: 'get',
                url: '' + url + '/Usuarioapi/Buscar/' + usuario + '/' + clave,
                params: { nit: empresa }

            })
                .then(async(dataJson) => {
                    console.log(dataJson)
                    if (dataJson.data.length == 0) {
                        Swal.fire(
                            'Opps!',
                            'No se encontro el usuario',
                            'error'
                        )
                    } else {
                        console.log(dataJson.data)
                        // const modelo = {
                        //     cedula: dataJson.data[0].cedula,
                        //     nitempresa: dataJson.data[0].nitempresa,
                        //     nombre: dataJson.data[0].nombre,
                        //     nombreempresa: dataJson.data[0].nombreempresa,
                        //     llaveempresa: dataJson.data[0].llaveempresa,
                        //     llaveusuario: dataJson.data[0].llaveusuario,
                        //     cargo: dataJson.data[0].cargo,
                        //     departamento: dataJson.data[0].departamento,
                        //     grupo: dataJson.data[0].grupo,
                        //     nitempresa: dataJson.data[0].nitempresa
                        // }
                        var modulos = {

                        }
                        dataJson.data.empresa.modulos.map((dato, index) => {
                            modulos[dato.codigomodulo] = dato
                        })
                        dataJson.data.empresa.modulos = modulos
                        iniciarSession(dataJson.data)
                        await buscarpermisos(dataJson.data.usuario.cargo,dataJson.data.empresa.empresa.nit)
                        window.localStorage.setItem("mensajeslocal", JSON.stringify({
                            mensajes: []
                        }))
                    }

                }).catch(async (error) => {
                    
                    Swal.fire(
                        'Opps!',
                        'No se pudo iniciar sessión',
                        'error'
                    )
                })

        } catch (error) {
            console.log(error)
            alert(error)
        }
    }

    var buscarempresas = () => {
        var usuario = document.getElementById('usuario').value
        var clave = document.getElementById('clave').value
        console.log(url)
        try {
            axios({
                method: 'get',
                url: '' + url + '/Usuarioapi/empresa/' + usuario + '/' + clave,
            }).then((response) => {
                console.log(response)
                var empresas3 = response.data
                var empresas2 = []
                var modulos = empresas3.map((emp, index) => {
                    empresas2.push(<option value={emp.empresa}>{emp.nombreempresa}</option>)

                })
                setempresas(empresas2)
                if (empresas3.length != 0) {
                    setempresa(empresas3[0].empresa)

                } else {
                    setempresa("")

                }
                console.log(empresas)
            })
                .catch(function (error) {
                    console.log(error)
                    alert(error)

                })
                .finally(() => {

                });;
        } catch (error) {
            console.log(error)
            alert(error)
        }
    }



    const handleSubmit = (event) => {
        event.preventDefault();

        let request = {
            correo: _correo,
            clave: _clave
        }

        const api = fetch("api/session/Login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(request)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                if (dataJson.idUsuario == 0) {
                    Swal.fire(
                        'Opps!',
                        'No se encontro el usuario',
                        'error'
                    )
                } else {
                    iniciarSession(dataJson)
                }

            }).catch((error) => {
                Swal.fire(
                    'Opps!',
                    'No se pudo iniciar sessión',
                    'error'
                )
            })
    }

    return (
        <div className="container-xl">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <img src={Fondo} alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-6 contents">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="mb-4 input-group">
                                <div className="input-group-prepend">
                                    <img src={Icono} alt="Image" className="img-fluid" />
                                </div>

                            </div>
                            <div className="form-group first">
                                <input type="text" placeholder='Usuario' className="form-control border-0" id="usuario" />

                            </div>
                            <div className="form-group last mb-4">
                                <input onChange={(val) => {
                                    buscarempresas()

                                }} type="password" placeholder='Contraseña' className="form-control border-0" id="clave" />
                            </div>
                            <div className={"Empresas"} >
                                <select onChange={(val) => {
                                    setempresa(val.target.value)

                                }} className='form-control border-0'>
                                    {empresas}
                                </select>
                            </div>
                            <input type='button' value="Ingresar" readonly className="btn btn-block btn-primary2" onClick={() => { ingresar() }} />
                            {/* <input type='button' value="Ingresar" readonly className="btn btn-block btn-primary2" onClick={() => { }} /> */}

                        </div>
                    </div>

                </div>

            </div>
        </div>

    )
}

export default Login