import React, { useEffect, useState, useContext } from "react";
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { UserContext } from "../context/UserProvider";
import { MensajeContext } from "../context/MensajesProvider";
import { PermisoContext } from "../context/PermisosProvider";
import Buscar from '../componentes/Buscar'
import BuscarMovimiento from '../componentes/Buscarmovimiento'
import "./css/Venta.css"
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
// const servidorsocket="https://localhost:44352"
// const servidorsocket="https://localhost:44352"
const modelopermisos = {
    FACTURA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    INVENTARIO: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    CARTERA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    NOMINA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    FACTURAELECTRONICA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    NOMINAELECTRONICA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    CONTABILIDAD: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    TESORERIA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    COMPRA: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    RADIAN: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    MAESTROS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    ADMINISTRACION: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    },
    DATOSBASICOS: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    }, RINGCALL: {
        editar: false,
        buscar: false,
        imprimir: false,
        eliminar: false,
        adicionar: false,
        electronico: false,
    }



}
const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, RINGCALL: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modeloTerceros = {
    tercero: "",
    clase: "",
    codigo: "",
    cedula: "",
    digito: "",
    razsoc: "",
    nom1: "",
    nom2: "",
    ape1: "",
    ape2: "",
    codigomun: "",
    coddepar: "",
    codpais: "",
    email: "",
    contacto: "",
    beneficiario: "",
    repleg: "",
    reg_merc: "",
    direccion: "",
    tel1: "",
    tel2: "",
    tel3: "",
    barrio: "",
    fechnac: "",
    interes: "",
    cupocre: "",
    plazo: "",
    vendedor: "",
    ccosto: "",
    precsug: "",
    actecono: "",
    feching: "",
    fechulne: "",
    regtrib: "",
    resfis: "",
    desccom: "",
    desccom2: "",
    desccom3: "",
    desccom4: "",
    autoreteft: "",
    estado: "",
    salant1: "",
    saldeb1: "",
    salcre1: "",
    venretefuente: "",
    venbasefte: "",
    venporcfte: "",
    veniva: "",
    venbaseiva: "",
    venporciva: "",
    venreteiva: "",
    venbasereteiva: "",
    venporcreteiva: "",
    venreteica: "",
    venbaseica: "",
    venporica: "",
    salact1: "",
    ciudad: "",
    pais: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    reteica: "",
    grupot: "",
    auto: "",
    salant2: "",
    saldeb2: "",
    salcre2: "",
    salact2: "",
    brutop1: "",
    brutop2: "",
    ivap: "",
    desctop1: "",
    desctop2: "",
    reteftep: "",
    reteivap: "",
    reteica2p: "",
    imptoconsumop: "",
    brutoa1: "",
    brutoa2: "",
    ivaa: "",
    desctoa1: "",
    desctoa2: "",
    retftea: "",
    reteivaa: "",
    reteicaa: "",
    imptoconsa: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    municipio: "",
    cambioimp: "",
    flete: "",
    cumplemes: "",
    cumpledia: "",
    cumpleano: "",
    horafecha: "",
    sexo: "",
    zona: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    autorexpro: "",
    cod_postal: "",
    nompais: "",
    campo: "",
    comretefte: "",
    combasefte: "",
    comporcfte: "",
    comreteiva: "",
    combasereteiva: "",
    comporcreteiva: "",
    comiva: "",
    combaseiva: "",
    comporciva: "",
    comreteica: "",
    combaseica: "",
    comporica: "",
    vencree: "",
    venbacree: "",
    venporcree: "",
    comcree: "",
    combacree: "",
    comporcree: "",
    Eliminado: "",
}
const modeloUsuario = {
    id: 0,
    cedula: '',
    nit: "",
    nombreusuario: "",
    nombreempresa: "",
    contraseña: '',
    cargo: "",
    departamento: ""
}
const modelogrupos = [

]
const usuarioingresado = "soporte"
const modelomensaje =
{
    quien: '',
    mensaje: '',
    solucion: '',
    asesor: '',
    grupo: '',
    visto: "visto",
    tipo: 'soporte',
    tercero: '',
    nomtercero: '',
    ticket: 0,
    fecha: '',
    fechasolucion: '',
    contacto1: '',
    contacto2: '',
    telefono1: '',
    telefono2: '',
    estado: 'por atender',
}
const modelomensajes = {
    mensajes: [

    ]
}
const Chat = (props) => {
    const [grupos, setgrupos] = useState(modelogrupos)
    const [mensajes, setmensajes] = useState(modelomensajes)
    const [mensaje, setmensaje] = useState(modelomensaje)
    const [gruposeleccionada, setgruposeleccionadas] = useState('')
    const [mensajesseleccionado, setmensajesseleccionado] = useState([])
    const [tamañofinal, settamañofinal] = useState('80vh')
    const { user } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [permisousuario, setpermisousuario] = useState(modelo)
    const [tercero, settercero] = useState(modeloTerceros)
    const [busquedaterceros, setbusquedaterceros] = useState([]);
    const [verModalterceros, setVerModalterceros] = useState(false);
    const [terceros, setterceros] = useState([]);
    const [abriropciones, setabriropciones] = useState(false);
    const [fechabusqueda, setfechabusqueda] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2) + "T" + ("0" + (new Date().getHours())).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + (new Date().getSeconds())).slice(-2));
    const [nuevo, setnuevo] = useState(false);
    const { mensajelocal, obtenerMensajeslocal } = useContext(MensajeContext)
    const { permisos, obtenerPermisos } = useContext(PermisoContext)
    const [permisosusuario, setpermisosusuario] = useState(modelopermisos)
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2) + 'T' + (new Date().getHours() + 2) + ':' + ("0" + new Date().getMinutes()).slice(-2),)
    const [fechahoyhoy, setfechahoyhoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2) + 'T' + (new Date().getHours() + 2) + ':' + ("0" + new Date().getMinutes()).slice(-2),)

    const [ cambiomensajelocal2, setcambiomensajelocal2 ] = useState(false)
    const [ ultimoid, setultimoid ] = useState(0)
    


    useEffect(() => {
        var div = document.getElementById("divmensajeschat")
        div.scrollTop = div.scrollHeight + 100
    })
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscartodasterceros('');
        obtenerUsuarios('');
        obtenerMensajesCallslocal('')
        var ttt = JSON.parse(permisos);
        setpermisosusuario(ttt)
        // props.connection.on("recibirmensajes", (comm) => {

        //     document.getElementById('buscarmensajeschat').click()

        // });
        // props.connection.on("actualizarmensaje", (comm) => {

        //     var fdfdf={...mensajes}
        //     document.getElementById('buscarmensajeschat').click()

        //     obteneractualizacionmensaje(comm)

        // });
        props.correrSocket()
    }, [])

    useEffect(() => {
        
        props.connection.on("recibirmensajes", (comm) => {

            obtenerMensajesCalls('')

        });
        props.connection.on("actualizarmensaje", (comm) => {

            var fdfdf={...mensajes}
            obteneractualizacionmensaje(comm)

        });
    }, [mensajes])

    const enviarmensajes = async () => {
        try {

            try {
                await props.connection.invoke("recibirmensajes");
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            console.log(err);
            setTimeout(this.props.startsocket(), 2000);
        }
    }
    const actualizarmensaje = async (id) => {
        try {

            try {
                var ddfdf=mensajes
                await props.connection.invoke("actualizarmensaje", (id + ""));
            } catch (err) {
                console.error(err);
            }
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
            setTimeout(this.props.startsocket(), 2000);
        }
    }
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _mensaje = { ...mensaje }
        _mensaje[e.target.name] = value
        setmensaje(_mensaje)
    }
    const obtenerMensajesCallslocal = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/MensajesCallapi/' + dt.usuario.cedula + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var mensajess = {
                    mensajes: data
                }
                obtenerMensajeslocal(mensajess)
                var fecha = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
                fecha = fecha + "T" + ("0" + (new Date().getHours())).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + (new Date().getSeconds())).slice(-2)

                setfechabusqueda(fecha)
                var div = document.getElementById("divmensajeschat")
                div.scrollTop = div.scrollHeight + 100
            }
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerMensajesCalls = async (coincidencia) => {
        try {

            // var fecha = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
            // fecha = fecha + "T" + ("0" + (new Date().getHours())).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + (new Date().getSeconds())).slice(-2)
            // var numberOfMlSeconds = fecha.getTime();
            // var addMlSeconds = 10 * 60000;
            // fecha = new Date(numberOfMlSeconds - addMlSeconds);
            var dddd= mensajes
            let dt = JSON.parse(user)
            let mensalo = JSON.parse(window.localStorage.getItem("mensajeslocal"))
            var ultimo=0
          if (mensalo.mensajes.length>0) {
            ultimo=mensalo.mensajes[mensalo.mensajes.length-1].ticket
          }
            let response = await fetch(props.servidor + '/MensajesCallapi/id/' + ultimo + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var mensajess = {
                    mensajes: data
                }
                setmensajes(mensajess)

                setcambiomensajelocal2(!cambiomensajelocal2)
                var div = document.getElementById("divmensajeschat")
                div.scrollTop = div.scrollHeight + 100
            }
        } catch (error) {
            console.log(error)
        }
    }

    const obteneractualizacionmensaje = async (coincidencia) => {
        try {

            // var fecha = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
            // fecha = fecha + "T" + ("0" + (new Date().getHours())).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + (new Date().getSeconds())).slice(-2)
            // var numberOfMlSeconds = fecha.getTime();
            // var addMlSeconds = 10 * 60000;
            // fecha = new Date(numberOfMlSeconds - addMlSeconds);
            let dt = JSON.parse(user)
            var mensalocal344 = { ...mensajes }.mensajes
            let response = await fetch(props.servidor + '/MensajesCallapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var mensajess = {
                    mensajes: data
                }
                var menlocal = JSON.parse(window.localStorage.getItem("mensajeslocal"))
                var mensalocal = menlocal.mensajes
                var encontre = false
                for (let i = 0; i < mensalocal.length; i++) {
                    const dato = mensalocal[i];
                    if (dato.ticket == data.ticket) {
                        encontre = true
                        mensalocal[i]=data
                    }
                }
              
                if (encontre) {
                    menlocal.mensajes = mensalocal
                    obtenerMensajeslocal(menlocal)
                    setcambiomensajelocal2(!cambiomensajelocal2)
                } else {
                    var ff=mensajes
                    var ff2=mensajes
                    var menlocal2 = { ...mensajes }
                    var mensalocal = { ...mensajes }.mensajes
                    var encontre = false
                    for (let i = 0; i < mensalocal.length; i++) {
                        const dato = mensalocal[i];
                        if (dato.ticket == data.ticket) {
                            encontre = true
                            mensalocal[i]=data
                        }
                    }
                  
                    if (encontre) {
                        menlocal.mensajes = mensalocal
                        setmensajes(menlocal)
                    }
                }


            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerUsuarios = async (coincidencia) => {

        let dt = JSON.parse(user)
        let response = await fetch(props.servidorempresa + '/Usuarioapi/Buscargrupos/' + '?nit=' + dt.usuario.nitempresa + '');

        if (response.ok) {
            let data = await response.json()
            setgrupos(data)
        }
    }

    const filtrarterceros = async (coincidencia) => {
        var _codigomuns = terceros.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.razsoc.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaterceros(_codigomuns)
    }
    const cerrarModalterceros = () => {
        setVerModalterceros(!verModalterceros);
        setbusquedaterceros(terceros)
    }
    const buscartodasterceros = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setterceros(dataJson)
                    setbusquedaterceros(dataJson.filter(e => e.tercero == "cliente"))


                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarterceros = (e) => {
        let value = e
        var _Movimiento = { ...mensaje }
        _Movimiento['tercero'] = value.codigo
        _Movimiento['nomtercero'] = value.razsoc
        _Movimiento['contacto1'] = value.contacto
        _Movimiento['contacto2'] = value.beneficiario
        _Movimiento['telefono1'] = value.tel1
        _Movimiento['telefono2'] = value.tel2
        setmensaje(_Movimiento)
        cerrarModalterceros()
        settercero(value)

    }

    const guardarCambiosServicio = async () => {
        let response;
        if (mensaje.solucion.length < 50 && mensaje.estado=="solucionado") {
            Swal.fire(
                'Oops!',
                'Solucion muy corta',
                'error'
            )
            return
        }
        if (mensaje.asesor=='') {
            Swal.fire(
                'Oops!',
                'Debe seleccionar un Asesor',
                'error'
            )
            return
        }
        console.log(mensaje)
        let dt = JSON.parse(user)
        var _MensajesCall = { ...mensaje }
        _MensajesCall.grupo = "soporte"
        _MensajesCall.quien = _MensajesCall.asesor
        var fecha = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
        fecha = fecha + "T" + ("0" + (new Date().getHours())).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + (new Date().getSeconds())).slice(-2)
        if (nuevo) {
            _MensajesCall.fecha = fechahoy
            _MensajesCall.fechasolucion = ''
        } else {
            _MensajesCall.fechasolucion = fechahoyhoy
        }
        if (true) {
            console.log(_MensajesCall)
            if (nuevo) {
                setnuevo(false)
                response = await fetch("" + props.servidor + "/MensajesCallapi?llave=" + dt.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_MensajesCall)
                })

            } else {
                response = await fetch("" + props.servidor + "/MensajesCallapi?llave=" + dt.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_MensajesCall)
                })
            }

            if (response.ok) {
                if (nuevo) {
                    setabriropciones(false)
                    Swal.fire(
                        'Guardado Exitosamente!',
                        'El Servicio fue guardado exitosamente',
                        'success'
                    )
                    var ffff=ultimoid
                    enviarmensajes()
                    setabriropciones(false)
                    setmensaje(modelomensaje)
                } else {
                    setabriropciones(false)
                    Swal.fire(
                        'Guardado Exitosamente!',
                        'El Servicio fue guardado exitosamente',
                        'success'
                    )
                    actualizarmensaje(_MensajesCall.ticket)
                   
                }
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Servicio',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    const guardarCambios = async (_mensaje) => {
        let response;
        console.log(mensaje)
        let dt = JSON.parse(user)
        var _MensajesCall = _mensaje
        _MensajesCall.grupo = dt.usuario.cedula
        _MensajesCall.quien = gruposeleccionada
        var fecha = new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2)
        fecha = fecha + "T" + ("0" + (new Date().getHours())).slice(-2) + ":" + ("0" + (new Date().getMinutes())).slice(-2) + ":" + ("0" + (new Date().getSeconds())).slice(-2)
        _MensajesCall.quien = gruposeleccionada
        _MensajesCall.fecha = fecha
        if (true) {
            console.log(_MensajesCall)
            response = await fetch("" + props.servidor + "/MensajesCallapi?llave=" + dt.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_MensajesCall)
            })

            if (response.ok) {
                // await obtenerMensajesCalls('');
                setabriropciones(false)
                var ffdf=ultimoid
                enviarmensajes()
                var div = document.getElementById("divmensajeschat")
                div.scrollTop = div.scrollHeight + 100
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Mensaje',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }
    return (
        <>
            <Card >

                <CardBody>
                    <Row className="container-xl">
                        <Col sm={3} style={{ background: '#188BAD', height: tamañofinal }} className="accordion" >
                            <Col sm={12} ><Button onClick={() => {
                                setgruposeleccionadas("soporte")
                                var div = document.getElementById("divmensajeschat")
                                div.scrollTop = div.scrollHeight + 100
                            }} style={{ background: '#188BAD' }} className="border-0 mt-2"><i className="fas fa-pen-alt">Soporte</i></Button></Col>

                            {grupos.map((dato) => {
                                return <Col sm={12} ><Button onClick={() => {
                                    setgruposeleccionadas(dato.usuario)
                                    var div = document.getElementById("divmensajeschat")
                                    div.scrollTop = div.scrollHeight + 100
                                }} style={{ background: '#188BAD' }} className="border-0 mt-2"><i className="fas fa-pen-alt">{dato.nombreusuario}</i></Button></Col>
                            })}


                        </Col>
                        <Col sm={9}>
                            <div className="table-responsive container-xl" id="divmensajeschat" style={{ height: tamañofinal }}>

                                <Row>
                                    {(JSON.parse(mensajelocal)) && JSON.parse(mensajelocal).mensajes.map((dato) => {
                                        let dt = JSON.parse(user)
                                        if ((dato.grupo == dt.usuario.cedula && dato.quien == gruposeleccionada) || (dato.grupo == gruposeleccionada && dato.quien == dt.usuario.cedula)) {
                                            var colorfontmio = 'white'
                                            var colorfonttuyo = 'white'
                                            var colorbackmio = '#188BAD'
                                            var colorbacktuyo = '#07C2BA'
                                            if (dato.tipo != '' && dato.estado == 'por atender') {
                                                colorbacktuyo = '#F56B6B'
                                            }
                                            if (dato.tipo != '' && dato.estado != 'por atender') {
                                                colorbacktuyo = '#12E90F'
                                            }
                                            if (dato.quien != dt.usuario.cedula || dato.quien == "soporte") {
                                                if (dato.quien != "soporte") {
                                                    var colorbacktuyo = 'white'

                                                } else {
                                                    if (dato.quien == dt.usuario.cedula) {
                                                        var colorbacktuyo = 'white'


                                                    } else {
                                                        var colorbacktuyo = 'white'

                                                    }
                                                }


                                            } else {
                                                if (dato.grupo == dt.usuario.cedula) {
                                                    var colorbacktuyo = 'white'

                                                } else {
                                                    var colorbackmio = 'white'

                                                }

                                            }
                                            if (dato.tipo != "soporte" && dato.tipo != "capacitacion") {
                                                return <>
                                                    <Col sm={5} className='mt-2 ml-2 chattext' style={{ color: colorfonttuyo, background: colorbacktuyo, borderRadius: '20px' }}>
                                                        <div className="chattext" style={{ height: '40px' }} >
                                                            {dato.mensaje}
                                                        </div>

                                                    </Col>
                                                    <Col sm={5} className='mt-2 ml-2 chattext' style={{ color: colorfontmio, background: colorbackmio, borderRadius: '20px' }}>
                                                        <div className="chattext" style={{ height: '40px' }}   >
                                                            {dato.mensaje}
                                                        </div>
                                                    </Col>
                                                </>
                                            } else {
                                                return <>
                                                    <Col sm={5} className='mt-2 ml-2 ' style={{ color: colorfonttuyo, background: colorbacktuyo, borderRadius: '20px' }}>
                                                        <div className={(colorbacktuyo == 'white') ? "desabilitar chattext" : "chattext"} >
                                                            <Button onClick={() => {
                                                                setnuevo(false)
                                                                setabriropciones(true)
                                                                setmensaje(dato)
                                                                setfechahoy(dato.fecha.replace(' ','T'))
                                                            }} style={{ color: colorfonttuyo, background: colorbacktuyo, borderRadius: '20px', border: 'none', height: '40px' }}>{dato.mensaje}</Button>

                                                        </div>

                                                    </Col>
                                                    <Col sm={5} className='mt-2 ml-2' style={{ color: colorfontmio, background: colorbackmio, borderRadius: '20px' }}>
                                                        <div className={(colorbackmio == 'white') ? "desabilitar chattext" : "chattext"} >
                                                            <Button onClick={() => {
                                                                setnuevo(false)
                                                                setabriropciones(true)
                                                                setmensaje(dato)
                                                                setfechahoy(dato.fecha.replace(' ','T'))

                                                            }} style={{ color: colorfontmio, background: colorbackmio, borderRadius: 'none', border: 'none' }}>{dato.mensaje}</Button>

                                                        </div>

                                                    </Col>
                                                </>
                                            }
                                        }
                                    })}
                                    {mensajes.mensajes.map((dato) => {
                                        let dt = JSON.parse(user)
                                        if ((dato.grupo == dt.usuario.cedula && dato.quien == gruposeleccionada) || (dato.grupo == gruposeleccionada && dato.quien == dt.usuario.cedula)) {
                                            var colorfontmio = 'white'
                                            var colorfonttuyo = 'white'
                                            var colorbackmio = '#188BAD'
                                            var colorbacktuyo = '#07C2BA'
                                            if (dato.tipo != '' && dato.estado == 'por atender') {
                                                colorbacktuyo = '#F56B6B'
                                            }
                                            if (dato.tipo != '' && dato.estado != 'por atender') {
                                                colorbacktuyo = '#12E90F'
                                            }
                                            if (dato.quien != dt.usuario.cedula || dato.quien == "soporte") {
                                                if (dato.quien == "soporte") {
                                                    var colorbacktuyo = 'white'

                                                } else {
                                                    if (dato.quien == dt.usuario.cedula) {
                                                        var colorbacktuyo = 'white'


                                                    } else {
                                                        var colorbacktuyo = 'white'

                                                    }
                                                }
                                            } else {
                                                if (dato.grupo == dt.usuario.cedula) {
                                                    var colorbacktuyo = 'white'

                                                } else {
                                                    var colorbackmio = 'white'

                                                }

                                            }
                                            if (dato.tipo != "soporte" && dato.tipo != "capacitacion") {
                                                return <>
                                                    <Col sm={5} className='mt-2 ml-2 chattext' style={{ color: colorfonttuyo, background: colorbacktuyo, borderRadius: '20px' }}>
                                                        <div className="chattext" style={{ height: '40px' }} >
                                                            {dato.mensaje}
                                                        </div>

                                                    </Col>
                                                    <Col sm={5} className='mt-2 ml-2 chattext' style={{ color: colorfontmio, background: colorbackmio, borderRadius: '20px' }}>
                                                        <div className="chattext" style={{ height: '40px' }}   >
                                                            {dato.mensaje}
                                                        </div>
                                                    </Col>
                                                </>
                                            } else {
                                                return <>
                                                    <Col sm={5} className='mt-2 ml-2 ' style={{ color: colorfonttuyo, background: colorbacktuyo, borderRadius: '20px' }}>
                                                        <div className={(colorbacktuyo == 'white') ? "desabilitar chattext" : "chattext"} >
                                                            <Button onClick={() => {
                                                                setnuevo(false)
                                                                setabriropciones(true)
                                                                setmensaje(dato)
                                                                setfechahoy(dato.fecha.replace(' ','T'))
                                                            }} style={{ color: colorfonttuyo, background: colorbacktuyo, borderRadius: '20px', border: 'none', height: '40px' }}>{dato.mensaje}</Button>

                                                        </div>

                                                    </Col>
                                                    <Col sm={5} className='mt-2 ml-2' style={{ color: colorfontmio, background: colorbackmio, borderRadius: '20px' }}>
                                                        <div className={(colorbackmio == 'white') ? "desabilitar chattext" : "chattext"} >
                                                            <Button onClick={() => {
                                                                setnuevo(false)
                                                                setabriropciones(true)
                                                                setmensaje(dato)
                                                                setfechahoy(dato.fecha.replace(' ','T'))

                                                            }} style={{ color: colorfontmio, background: colorbackmio, borderRadius: 'none', border: 'none' }}>{dato.mensaje}</Button>

                                                        </div>

                                                    </Col>
                                                </>
                                            }
                                        }
                                    })}
                                </Row>

                            </div>

                            <InputGroup style={{ bottom: '0px' }}>
                                <Input onKeyDown={(e) => {
                                    if (e.key == 'Enter' && document.getElementById('inputchat').value != '') {
                                        var _mensaje = {
                                            quien: gruposeleccionada,
                                            mensaje: document.getElementById('inputchat').value,
                                            grupo: usuarioingresado,
                                            visto: "visto"
                                        }
                                        document.getElementById('inputchat').value = ''
                                        document.getElementById('inputchat').focus()
                                        guardarCambios(_mensaje)
                                        document.getElementById('inputchat').value = ''
                                    }

                                }} id='inputchat'></Input>
                                <Button color="primary" size="sm" className="mr-2"
                                    onClick={() => {
                                        if (document.getElementById('inputchat').value != '') {
                                            var _mensaje = {
                                                quien: gruposeleccionada,
                                                mensaje: document.getElementById('inputchat').value,
                                                grupo: usuarioingresado,
                                                visto: "visto",
                                                estado:''
                                            }
                                            document.getElementById('inputchat').value = ''
                                            document.getElementById('inputchat').focus()
                                            guardarCambios(_mensaje)
                                        }

                                    }}
                                >
                                    <i className="fas fa-plus"></i>
                                </Button>
                                {(permisosusuario.RINGCALL.adicionar) && <Button color="primary" size="sm" className="mr-2"
                                    onClick={() => {
                                        setfechahoy(fechahoyhoy)
                                        setnuevo(true)
                                        setabriropciones(true)

                                    }}
                                >
                                    <i className="fa fa-cogs"></i>
                                </Button>}
                                <Button id={"buscarmensajeschat"} color="primary" size="sm" className="mr-2"
                                    onClick={() => {
                                        obtenerMensajesCalls('')

                                    }}
                                >
                                    <i className="fa fa-cogs"></i>
                                </Button>
                            </InputGroup>

                        </Col>
                    </Row>

                </CardBody>
                <Modal size="lg" isOpen={abriropciones}>
                    <ModalHeader>
                        Servicio
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm={3}>
                                <FormGroup inline={true}>
                                    <Label>Tercero</Label>
                                    <InputGroup>
                                        <Input value={mensaje.tercero} bsSize="sm" onKeyDown={(e) => {

                                            if (e.key == "Enter" && e.target.value == "") {
                                                buscartodasterceros('');
                                                setbusquedaterceros(terceros)
                                                setVerModalterceros(true)

                                            } else {


                                            }
                                        }} name="cliente" />
                                        <Button color="primary" size="sm" className="mr-2"
                                            onClick={() => {

                                                buscartodasterceros('');
                                                setbusquedaterceros(terceros)
                                                setVerModalterceros(true)



                                            }}
                                        >
                                            <i className="fas fa-pen-alt"></ i >
                                        </Button>
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <Label style={{ fontSize: '13px' }} >Estado</Label>
                                <Input disabled={nuevo} type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="estado" value={mensaje.estado}  >
                                    <option value="por atender">Por Atender</option>
                                    <option value="solucionado">Solucionado</option>

                                </Input>
                            </Col>
                            <Col sm={3}>
                                <FormGroup inline={true}>
                                    <Label>Contacto 1</Label>
                                    <InputGroup>
                                        <Input onChange={handleChange} value={mensaje.contacto1} bsSize="sm"
                                            name="contacto1" />
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup inline={true}>
                                    <Label>Telefono 1</Label>
                                    <InputGroup>
                                        <Input onChange={handleChange} value={mensaje.telefono1} bsSize="sm"
                                            name="telefono1" />
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup inline={true}>
                                    <Label>Contacto 2</Label>
                                    <InputGroup>
                                        <Input onChange={handleChange} value={mensaje.contacto2} bsSize="sm"
                                            name="contacto2" />
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup inline={true}>
                                    <Label>Telefono 2</Label>
                                    <InputGroup>
                                        <Input onChange={handleChange} value={mensaje.telefono2} bsSize="sm"
                                            name="telefono2" />
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup inline={true}>
                                    <Label>Mensaje</Label>
                                    <InputGroup>
                                        <Input disabled={!permisosusuario.RINGCALL.adicionar} onChange={handleChange} type="textarea" value={mensaje.mensaje} bsSize="sm"
                                            name="mensaje" />
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup inline={true}>
                                    <Label>Solucion</Label>
                                    <InputGroup>
                                        <Input disabled={mensaje.estado == "por atender"} onChange={handleChange} type="textarea" value={mensaje.solucion} bsSize="sm"
                                            name="solucion" />
                                    </InputGroup>

                                </FormGroup>
                            </Col>
                            {(permisosusuario.RINGCALL.adicionar) && <Col sm={2}>
                                <Label style={{ fontSize: '13px' }} >Asesor</Label>
                                <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="asesor" value={mensaje.asesor}  >
                                    <option value="">Seleccione...</option>
                                    {grupos.map((dato) => {
                                        return <option value={dato.usuario}>{dato.nombreusuario.toUpperCase()}</option>
                                    })}
                                </Input>
                            </Col>}
                            {(permisosusuario.RINGCALL.adicionar) && <Col sm={2}>
                                <Label style={{ fontSize: '13px' }} >Tipo</Label>
                                <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tipo" value={mensaje.tipo}  >
                                    <option value={"soporte"}>{"Soporte"}</option>
                                    <option value={"capacitacion"}>{"Capacitacion"}</option>
                                </Input>
                            </Col>}
                                <Col sm={5}>
                                <FormGroup>
                                    <Label>Fecha</Label>
                                    <Input disabled={!(nuevo && mensaje.tipo=="capacitacion")} name="fechaentrega" value={fechahoy} onChange={(e)=>{setfechahoy(e.target.value)}} type="datetime-local" bsSize="sm" />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            guardarCambiosServicio()
                            setabriropciones(false)
                            setmensaje(modelomensaje)
                        }}>Guardar</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            // guardarCambiosServicio()
                            setabriropciones(false)
                            setmensaje(modelomensaje)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                <Buscar  tituloencabezado={"Buscar Tercero"} filtrar={(coincidencia) => { filtrarterceros(coincidencia) }} busqueda={busquedaterceros} encabezado={["Codigo", "Razon Social", "Tipo"]} codigo={"codigo"} nombre={"razsoc"} dato={"tercero"} dato2={""} verModal={verModalterceros} cerrarModal={() => { cerrarModalterceros() }} seleccionar={(seleccionado) => {
                    seleccionarterceros(seleccionado)
                }} />
            </Card>
        </>
    )
}
export default Chat;
