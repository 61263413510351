import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { Autocomplete, TextField } from '@mui/material';
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import Clasiarticulos from '../views/Clasiarticulos';

import { FormatMoney } from 'format-money-js'

import * as XLSX from "xlsx"
import Venta from "./Venta";

const modeloInarticulos = {
    codigo: "",
    codigo2: "",
    nombre: "",
    descripcion: "no",
    descadicional: "",
    referencia: "",
    plu: "",
    unimed: "",
    unxmed: "",
    preccost: "",
    preccost2: "",
    cantmin: "",
    cantmax: "",
    ultmvto: "",
    ultcosto: "",
    dscto: "",
    artserv: "venta",
    tipoitem: "",
    invfisico1: "",
    ulinvfisico1: "",
    saldact: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    facturable: "si",
    produccion: "no",
    fchaprom: "no",
    fchainiprom: "",
    fchafinprom: "",
    serial: "",
    mfvence: "no",
    fvence: "",
    vnr: "",
    costoniif: "",
    pos: "",
    lote: "",
    desc1: "",
    comision: "",
    horafecha: "",
    tasa_ultco: "",
    ult_fa_com: "",
    fe_ult_com: "",
    stockmin1: "",
    stockmax1: "",
    canesca1: "",
    canesca2: "",
    pvtesca1: "",
    pvtesca2: "",
    convunid: "",
    digitado: "",
    iva: "",
    imptocons: "",
    rete: "",
    ica: "",
    reteiva: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    blisterxcaja: "",
    unidadxblister: "",
    blisterxcajanombre: "",
    unidadxblisternombre: "",
    inarticulosbodega: [
    ],
    inarticuloslistaprecio: [
    ],
    inarticuloscompuesto: [],
    cuentadesc: "",
    cuentaiva: "",
    cuentaimptocons: "",
    cuentarete: "",
    cuentaica: "",
    cuentacomdesc: "",
    cuentacomiva: "",
    cuentacomimptocons: "",
    cuentacomrete: "",
    cuentacomica: "",
    cuentacomdeb: "",
    cuentacomcre: "",
    cuentadeb: "",
    cuentacre: "",
    cuentacosto: "",
    cuentainventario: "",
    impoconsumovalor:0
}
const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}
const Inarticulos = (props) => {
    const { user, cerrarSession } = useContext(UserContext);


    const [Inarticulos, setInarticulos] = useState(modeloInarticulos);
    const [Inarticuloss, setInarticuloss] = useState([]);
    const [Inarticuloss2, setInarticuloss2] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [descripcion, setdescripcion] = useState([]);
    const [descripcions, setdescripcions] = useState([]);
    const [descadicional, setdescadicional] = useState([]);
    const [descadicionals, setdescadicionals] = useState([]);
    const [referencia, setreferencia] = useState([]);
    const [referencias, setreferencias] = useState([]);
    const [plu, setplu] = useState([]);
    const [plus, setplus] = useState([]);
    const [unimed, setunimed] = useState([]);
    const [unimeds, setunimeds] = useState([]);
    const [unxmed, setunxmed] = useState([]);
    const [unxmeds, setunxmeds] = useState([]);
    const [preccost, setpreccost] = useState([]);
    const [preccosts, setpreccosts] = useState([]);
    const [preccost2, setpreccost2] = useState([]);
    const [preccost2s, setpreccost2s] = useState([]);
    const [cantmin, setcantmin] = useState([]);
    const [cantmins, setcantmins] = useState([]);
    const [cantmax, setcantmax] = useState([]);
    const [cantmaxs, setcantmaxs] = useState([]);
    const [ultmvto, setultmvto] = useState([]);
    const [ultmvtos, setultmvtos] = useState([]);
    const [ultcosto, setultcosto] = useState([]);
    const [ultcostos, setultcostos] = useState([]);
    const [dscto, setdscto] = useState([]);
    const [dsctos, setdsctos] = useState([]);
    const [artserv, setartserv] = useState([]);
    const [artservs, setartservs] = useState([]);
    const [tipoitem, settipoitem] = useState([]);
    const [tipoitems, settipoitems] = useState([]);
    const [invfisico1, setinvfisico1] = useState([]);
    const [invfisico1s, setinvfisico1s] = useState([]);
    const [ulinvfisico1, setulinvfisico1] = useState([]);
    const [ulinvfisico1s, setulinvfisico1s] = useState([]);
    const [saldact, setsaldact] = useState([]);
    const [saldacts, setsaldacts] = useState([]);
    const [creadopor, setcreadopor] = useState([]);
    const [creadopors, setcreadopors] = useState([]);
    const [borradopor, setborradopor] = useState([]);
    const [borradopors, setborradopors] = useState([]);
    const [modificpor, setmodificpor] = useState([]);
    const [modificpors, setmodificpors] = useState([]);
    const [facturable, setfacturable] = useState([]);
    const [facturables, setfacturables] = useState([]);
    const [produccion, setproduccion] = useState([]);
    const [produccions, setproduccions] = useState([]);
    const [fchaprom, setfchaprom] = useState([]);
    const [fchaproms, setfchaproms] = useState([]);
    const [fchainiprom, setfchainiprom] = useState([]);
    const [fchainiproms, setfchainiproms] = useState([]);
    const [fchafinprom, setfchafinprom] = useState([]);
    const [fchafinproms, setfchafinproms] = useState([]);
    const [serial, setserial] = useState([]);
    const [serials, setserials] = useState([]);
    const [mfvence, setmfvence] = useState([]);
    const [mfvences, setmfvences] = useState([]);
    const [fvence, setfvence] = useState([]);
    const [fvences, setfvences] = useState([]);
    const [vnr, setvnr] = useState([]);
    const [vnrs, setvnrs] = useState([]);
    const [costoniif, setcostoniif] = useState([]);
    const [costoniifs, setcostoniifs] = useState([]);
    const [pos, setpos] = useState([]);
    const [poss, setposs] = useState([]);
    const [lote, setlote] = useState([]);
    const [lotes, setlotes] = useState([]);
    const [desc1, setdesc1] = useState([]);
    const [desc1s, setdesc1s] = useState([]);
    const [comision, setcomision] = useState([]);
    const [comisions, setcomisions] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [tasa_ultco, settasa_ultco] = useState([]);
    const [tasa_ultcos, settasa_ultcos] = useState([]);
    const [ult_fa_com, setult_fa_com] = useState([]);
    const [ult_fa_coms, setult_fa_coms] = useState([]);
    const [fe_ult_com, setfe_ult_com] = useState([]);
    const [fe_ult_coms, setfe_ult_coms] = useState([]);
    const [stockmin1, setstockmin1] = useState([]);
    const [stockmin1s, setstockmin1s] = useState([]);
    const [stockmax1, setstockmax1] = useState([]);
    const [stockmax1s, setstockmax1s] = useState([]);
    const [canesca1, setcanesca1] = useState([]);
    const [canesca1s, setcanesca1s] = useState([]);
    const [canesca2, setcanesca2] = useState([]);
    const [canesca2s, setcanesca2s] = useState([]);
    const [pvtesca1, setpvtesca1] = useState([]);
    const [pvtesca1s, setpvtesca1s] = useState([]);
    const [pvtesca2, setpvtesca2] = useState([]);
    const [pvtesca2s, setpvtesca2s] = useState([]);
    const [convunid, setconvunid] = useState([]);
    const [convunids, setconvunids] = useState([]);
    const [digitado, setdigitado] = useState([]);
    const [digitados, setdigitados] = useState([]);
    const [iva, setiva] = useState([]);
    const [ivas, setivas] = useState([]);
    const [imptocons, setimptocons] = useState([]);
    const [imptoconss, setimptoconss] = useState([]);
    const [rete, setrete] = useState([]);
    const [retes, setretes] = useState([]);
    const [ica, setica] = useState([]);
    const [icas, seticas] = useState([]);
    const [reteiva, setreteiva] = useState([]);
    const [reteivas, setreteivas] = useState([]);
    const [clas1, setclas1] = useState([]);
    const [clas1s, setclas1s] = useState([]);
    const [clas2, setclas2] = useState([]);
    const [clas2s, setclas2s] = useState([]);
    const [clas3, setclas3] = useState([]);
    const [clas3s, setclas3s] = useState([]);
    const [clas4, setclas4] = useState([]);
    const [clas4s, setclas4s] = useState([]);
    const [clas5, setclas5] = useState([]);
    const [clas5s, setclas5s] = useState([]);
    const [clas6, setclas6] = useState([]);
    const [clas6s, setclas6s] = useState([]);
    const [clas7, setclas7] = useState([]);
    const [clas7s, setclas7s] = useState([]);
    const [clas8, setclas8] = useState([]);
    const [clas8s, setclas8s] = useState([]);
    const [clas9, setclas9] = useState([]);
    const [clas9s, setclas9s] = useState([]);
    const [clas10, setclas10] = useState([]);
    const [clas10s, setclas10s] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState(modelo);
    const [mostrarrentabilidad, setmostrarrentabilidad] = useState(true);

    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalInarticulos, setVerModalInarticulos] = useState(false);
    const [inarticulosbodegareferencia, setinarticulosbodegareferencia] = useState([]);
    const [inarticulosbodegareferencias, setinarticulosbodegareferencias] = useState([]);
    const [inarticulosbodegabodega, setinarticulosbodegabodega] = useState([]);
    const [inarticulosbodegabodegas, setinarticulosbodegabodegas] = useState([]);
    const [busquedainarticulosbodegabodegas, setbusquedainarticulosbodegabodegas] = useState([]);
    const [verModalinarticulosbodegabodega, setVerModalinarticulosbodegabodega] = useState(false);
    const [filainarticulosbodegabodega, setfilainarticulosbodegabodega] = useState(false);
    const [inarticuloslistaprecioreferencia, setinarticuloslistaprecioreferencia] = useState([]);
    const [inarticuloslistaprecioreferencias, setinarticuloslistaprecioreferencias] = useState([]);
    const [inarticuloslistapreciolistadeprecio, setinarticuloslistapreciolistadeprecio] = useState([]);
    const [inarticuloslistapreciolistadeprecios, setinarticuloslistapreciolistadeprecios] = useState([]);
    const [busquedainarticuloslistapreciolistadeprecios, setbusquedainarticuloslistapreciolistadeprecios] = useState([]);
    const [verModalinarticuloslistapreciolistadeprecio, setVerModalinarticuloslistapreciolistadeprecio] = useState(false);
    const [filainarticuloslistapreciolistadeprecio, setfilainarticuloslistapreciolistadeprecio] = useState(false);
    const [inarticuloslistaprecioprecio, setinarticuloslistaprecioprecio] = useState([]);
    const [inarticuloslistaprecioprecios, setinarticuloslistaprecioprecios] = useState([]);
    const [Direcciones, setDirecciones] = useState([]);
    const [clasificaciones, setclasificaciones] = useState([]);
    const [nombreclasificaciones, setnombreclasificaciones] = useState({});
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [consecutivocodigo, setconsecutivocodigo] = useState([]);
    const [costocompuesto, setcostocompuesto] = useState(0);
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [crearclasificacion, setcrearclasificacion] = useState(false);

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
     
        var ws = XLSX.utils.json_to_sheet(Inarticuloss);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx")
    }
    const exportarExcellistadeprecio = async () => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/inarticulosapi/listasdeprecio?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var wb = XLSX.utils.book_new();
                var ws = XLSX.utils.json_to_sheet(data);

                XLSX.utils.book_append_sheet(wb, ws, "Reporte");
                XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx")
            }
        } catch (error) {
            console.log(error)
        }

    }
    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerConclasiarticuloss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Clasiarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
            if (response.ok) {
                let data = await response.json()

                setclasificaciones(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerConsecutivoInarticulos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/ultimo?llave=' + dt.usuario.llaveempresa + '');
            if (response.ok) {
                let data = await response.json()
                var _Inarticulos = { ...Inarticulos }
                _Inarticulos["codigo"] = data[0]
                setInarticulos(_Inarticulos)
                setRoles([])

            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Inarticulos = { ...Inarticulos }
        if (e.target.name != "facturable") {

            _Inarticulos[e.target.name] = value
        } else {
            var nofacturaruvt = true
            props.permisosusuario.forEach(element => {
                if (element.tipo == "dejardesactivarreferencias" && element.acceso[0] == "1") {
                    nofacturaruvt = false
                }
            });
            if (nofacturaruvt) {

                _Inarticulos[e.target.name] = value
            }

        }
        setInarticulos(_Inarticulos)
        setRoles([])


    }
    const handleChange2 = (e, values) => {
        let value = e.target.value
        var ff = values
        console.log(e.target)
        var _Inarticulos = { ...Inarticulos }
        _Inarticulos['clas1'] = values.codigo
        setInarticulos(_Inarticulos)
        setclas1(values.nombre)
        setRoles([])


    }

    const handleChangecuentas = (e, values, name) => {
        try {
            if (values) {
                let value = e.target.value
                var ff = values
                console.log(e.target)
                var _Inarticulos = { ...Inarticulos }
                _Inarticulos[name] = values.codcuenta
                setInarticulos(_Inarticulos)
                setRoles([])


            } else {

                var _Inarticulos = { ...Inarticulos }
                _Inarticulos[name] = ''
                setInarticulos(_Inarticulos)
                setRoles([])
            }
        } catch (error) {
            var _Inarticulos = { ...Inarticulos }
            _Inarticulos[name] = ""
            setInarticulos(_Inarticulos)
        }



    }
    const handleChange3 = (e, values) => {
        let value = e.target.value
        var ff = values
        console.log(e.target)
        var _Inarticulos = { ...Inarticulos }
        _Inarticulos['clas2'] = values.codigo
        setInarticulos(_Inarticulos)
        setclas2(values.nombre)
        setRoles([])


    }
    const seleccionarinarticulosbodegabodega = (e) => {
        var _Inarticulos = { ...Inarticulos }
        var _datos = { ...Inarticulos }['inarticulosbodega']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == filainarticulosbodegabodega) {
                var dat = {
                    ...dato,
                    ['bodega']: e.codigo
                }

                return dat
            } else {
                return dato
            }
        })
        _Inarticulos['inarticulosbodega'] = _enviodatos
        // setVendedor(_Vendedor)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        cerrarModalSeleccioninarticulosbodegabodegas()
    }
    const seleccionarinarticuloslistapreciolistadeprecio = (e) => {
        var _Inarticulos = { ...Inarticulos }
        var _datos = { ...Inarticulos }['inarticuloslistaprecio']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == filainarticuloslistapreciolistadeprecio) {
                var dat = {
                    ...dato,
                    ['listadeprecio']: e.codigo
                }

                return dat
            } else {
                return dato
            }
        })
        _Inarticulos['inarticuloslistaprecio'] = _enviodatos
        // setVendedor(_Vendedor)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        cerrarModalSeleccioninarticuloslistapreciolistadeprecios()
    }
    const handleChangegrillainarticulosbodega = async (e) => {
        var _Inarticulos = { ...Inarticulos }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Inarticulos }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                if (e.target.type == "number") {
                    var dat = {
                        ...dato,
                        [e.target.name]: parseFloat(e.target.value)
                    }
                }
                else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                }
                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const handleChangegrillainarticuloslistaprecio = async (e) => {
        var _Inarticulos = { ...Inarticulos }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Inarticulos }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                if (e.target.type == "number") {
                    var dat = {
                        ...dato,
                        [e.target.name]: parseFloat(e.target.value)
                    }
                }
                else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                }
                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }

    const handleChange5 = (e, values) => {

        var _Inarticulos = { ...Inarticulos }
        var subtabla = "inarticuloscompuesto"
        var _datos = { ...Inarticulos }[subtabla]
        var listcom = []
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                listcom.push(values.codigo)

                var dat = {
                    ...dato,
                    ['referenciacompuesto']: values.codigo
                }

                return dat
            } else {
                listcom.push(dato.referenciacompuesto)
                return dato
            }
        })
        var todosarticulos = Inarticuloss.map((dat) => {
            return dat
        })
        todosarticulos = todosarticulos.filter(b => listcom.includes(b.codigo))
        var sumcosto = 0
        todosarticulos.forEach(element => {
            sumcosto = sumcosto + element.preccost
        });
        setcostocompuesto(sumcosto)
        if (sumcosto != 0) {
            _Inarticulos.preccost = sumcosto
        }
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const handleChangegrillainarticuloscompuesto = async (e) => {
        var _Inarticulos = { ...Inarticulos }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Inarticulos }[subtabla]
        var listcom = []
        var listcomcant = []
        let _enviodatos = _datos.map((dato, index) => {
            listcom.push(dato.referenciacompuesto)
            if (index == fila) {

                if (e.target.type == "number") {
                    var dat = {
                        ...dato,
                        [e.target.name]: parseFloat(e.target.value)
                    }

                }
                else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                }
                listcomcant.push(parseFloat(e.target.value))

                return dat
            } else {
                listcomcant.push(dato.cantidad)

                return dato
            }
        })
        var todosarticulos2 = Inarticuloss2.map((dat) => {
            return dat
        })
        var sumcosto = 0
        for (let i = 0; i < _enviodatos.length; i++) {
            const element = _enviodatos[i];
            var todosarticulos = todosarticulos2.filter(b => b.codigo == element.referenciacompuesto)[0]
            var costopres = todosarticulos.preccost * element.cantidad
            if (todosarticulos.blisterxcaja != 0) {
                costopres = costopres / todosarticulos.blisterxcaja
            }
            if (todosarticulos.unidadxblister != 0) {
                costopres = costopres / todosarticulos.unidadxblister
            }
            sumcosto = sumcosto + (costopres)
        }

        setcostocompuesto(sumcosto)
        if (sumcosto != 0) {
            _Inarticulos.preccost = sumcosto
        }
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const handleChangegrillainarticulospresentacion = async (e) => {
        var _Inarticulos = { ...Inarticulos }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Inarticulos }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {

                var dat = {
                    ...dato,
                    [e.target.name]: e.target.value
                }
                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        setInarticulos(_Inarticulos)
        setRoles({ codig: '' })

    }
    const buscartodasinarticulosbodegabodegas = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Bodegaapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setinarticulosbodegabodegas(dataJson)
                    setbusquedainarticulosbodegabodegas(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setinarticulosbodegabodegas([])
                    setbusquedainarticulosbodegabodegas([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodasinarticuloslistapreciolistadeprecios = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Listadeprecioapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setinarticuloslistapreciolistadeprecios(dataJson)
                    setbusquedainarticuloslistapreciolistadeprecios(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setinarticuloslistapreciolistadeprecios([])
                    setbusquedainarticuloslistapreciolistadeprecios([])
                })
        } catch (error) {
            console.log(error)
        }
    }


    const base64certificado = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const obtenerInarticuloss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            var coin = ""
            if (coincidencia != "") {
                coin = "coin/"
            }
            let response = await fetch(props.servidor + '/Inarticulosapi/' + coin + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
            var fddfd = props.servidor + '/Inarticulosapi/' + coin + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
            if (response.ok) {
                let data = await response.json()
                setInarticuloss(data)
                if (coincidencia == "") {
                    setInarticuloss2(data)
                }
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const borrartodaslasinarticulosbodegabodegas = () => {
        setbusquedainarticulosbodegabodegas([])
    }
    const borrartodaslasinarticuloslistapreciolistadeprecios = () => {
        setbusquedainarticuloslistapreciolistadeprecios([])
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerInarticuloss('');
        obtenerConclasiarticuloss('');
        obtenerPlandecuentass('');
        var rentabil = true
        props.permisosusuario.forEach(element => {
            if (element.tipo == "nodejarcambiarrentabilidad" && element.acceso[0] == "1") {
                rentabil = false
            }
        });
        setmostrarrentabilidad(rentabil)
        // buscartodasinarticulosbodegabodegas('');
        // buscartodasinarticuloslistapreciolistadeprecios('');
    }, [])
    const filtrarinarticulosbodegabodegas = async (coincidencia) => {
        var _inarticulosbodegabodegas = inarticulosbodegabodegas.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedainarticulosbodegabodegas(_inarticulosbodegabodegas)
    }
    const filtrarinarticuloslistapreciolistadeprecios = async (coincidencia) => {
        var _inarticuloslistapreciolistadeprecios = inarticuloslistapreciolistadeprecios.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedainarticuloslistapreciolistadeprecios(_inarticuloslistapreciolistadeprecios)
    }
    const seleccionarclasificaciones = (row) => {
        for (let i = 0; i < clasificaciones.length; i++) {
            const element = clasificaciones[i];
            if (element.codigo == row.clas1) {
                setclas1(element.nombre)

            }
            if (element.codigo == row.clas2) {
                setclas2(element.nombre)

            }
        }
    }
    const Customnombre = ({ row }) => (
        <div>
            { }
            <div>
                <div
                >
                    { }
                    {row.nombre}
                </div>
            </div>
        </div>
    );
    const columns = [

        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => {
                            buscartodasinarticulosbodegabodegas('');
                            buscartodasinarticuloslistapreciolistadeprecios('');

                            abrirEditarModalInarticulos(row)
                            seleccionarclasificaciones(row);

                        }}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            cell: row => <Customnombre row={row} />,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Referencia',
            selector: row => row.referencia,
            sortable: true,
        },
        {
            name: 'Plu',
            selector: row => row.plu,
            sortable: true,
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalInarticulos = async (data) => {
        var enviodata = { ...data }

        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/cuerpo/' + enviodata.codigo + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setInarticulos(data);
                try {
                    var listcom = []
                    var listcomcant = []
                    data.inarticuloscompuesto.forEach(element => {
                        listcom.push(element.referenciacompuesto)
                        listcomcant.push(element.cantidad)
                    });
                    var todosarticulos = Inarticuloss.map((dat) => {
                        return dat
                    })
                    var sumcosto = 0
                    for (let i = 0; i < data.inarticuloscompuesto.length; i++) {
                        const element = data.inarticuloscompuesto[i];
                        todosarticulos = todosarticulos.filter(b => b.codigo == element.codigo)[0]
                        var costopres = todosarticulos.preccost * element.cantidad
                        if (todosarticulos.blisterxcaja != 0) {
                            costopres = costopres / todosarticulos.blisterxcaja
                        }
                        if (todosarticulos.unidadxblister != 0) {
                            costopres = costopres / todosarticulos.unidadxblister
                        }
                        sumcosto = sumcosto + (costopres)
                    }

                    setcostocompuesto(sumcosto)
                } catch (error) {
                    console.log(error)
                }

                setVerModalInarticulos(!verModalInarticulos);
                seteditar(true)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const cerrarModalInarticulos = () => {
        setInarticulos(modeloInarticulos)
        setVerModalInarticulos(!verModalInarticulos);
        seteditar(false)
        setKey('Datos')
        setcostocompuesto(0)
    }
    const cerrarModalSeleccioninarticulosbodegabodegas = () => {
        setVerModalinarticulosbodegabodega(!verModalinarticulosbodegabodega);
        setbusquedainarticulosbodegabodegas(inarticulosbodegabodegas)
    }
    const cerrarModalSeleccioninarticuloslistapreciolistadeprecios = () => {
        setVerModalinarticuloslistapreciolistadeprecio(!verModalinarticuloslistapreciolistadeprecio);
        setbusquedainarticuloslistapreciolistadeprecios(inarticuloslistapreciolistadeprecios)
    }
    const agregarinarticulosbodega = async (e, index) => {
        var mod = modeloInarticulos
        var _Inarticulos = { ...Inarticulos }
        if (_Inarticulos.codigo != '') {
            var _inarticulosbodegas = { ...Inarticulos }.inarticulosbodega
            var _inarticulosbodegasfinales = _inarticulosbodegas.concat({
                referencia: "",
                bodega: "",
            })
            _Inarticulos.inarticulosbodega = _inarticulosbodegasfinales
            setInarticulos(_Inarticulos)
            setRoles([])
        } else {
            Swal.fire(
                'Opps',
                'No ha digitado un codigo',
                'error'
            )
        }
    }
    const agregarcondatosinarticulosbodega = async (__Inarticulos) => {

        var _Inarticulos = __Inarticulos
        var _inarticulosbodegas = _Inarticulos.inarticulosbodega
        var _verificar = _inarticulosbodegas.filter((dato) => {
            if (dato.bodega == "") {
                return dato
            }
        })
        if (_verificar.length == 0) {
            var _inarticulosbodegasfinales = _inarticulosbodegas.concat({
                referencia: "",
                bodega: "",
            })
            _Inarticulos.inarticulosbodega = _inarticulosbodegasfinales
            setInarticulos(_Inarticulos)
            setRoles([])
        }

    }
    const agregarinarticuloslistaprecio = async (e, index) => {
        var mod = modeloInarticulos
        var _Inarticulos = { ...Inarticulos }
        if (_Inarticulos.codigo != '') {
            var _inarticuloslistaprecios = { ...Inarticulos }.inarticuloslistaprecio
            var _inarticuloslistapreciosfinales = _inarticuloslistaprecios.concat({
                referencia: "",
                presentacion: "3",
                listadeprecio: "",
                precio: "",
            })
            _Inarticulos.inarticuloslistaprecio = _inarticuloslistapreciosfinales
            setInarticulos(_Inarticulos)
            setRoles([])
        } else {
            Swal.fire(
                'Opps',
                'No ha digitado un codigo',
                'error'
            )
        }
    }


    const agregarinarticuloscompuesto = async (e, index) => {
        var mod = modeloInarticulos
        var _Inarticulos = { ...Inarticulos }
        if (_Inarticulos.codigo != '') {
            var _inarticuloslistaprecios = { ...Inarticulos }.inarticuloscompuesto
            var _inarticuloslistapreciosfinales = _inarticuloslistaprecios.concat({
                referencia: "",
                referenciacompuesto: "",
                cantidad: "",
            })
            _Inarticulos.inarticuloscompuesto = _inarticuloslistapreciosfinales
            setInarticulos(_Inarticulos)
            setRoles([])
        } else {
            Swal.fire(
                'Opps',
                'No ha digitado un codigo',
                'error'
            )
        }
    }
    const agregarcondatosinarticuloslistaprecio = async (__Inarticulos) => {

        var _Inarticulos = __Inarticulos
        var _inarticuloslistaprecios = _Inarticulos.inarticuloslistaprecio
        var _verificar = _inarticuloslistaprecios.filter((dato) => {
            if (dato.precio == "") {
                return dato
            }
        })
        if (_verificar.length == 0) {
            var _inarticuloslistapreciosfinales = _inarticuloslistaprecios.concat({
                referencia: "",
                listadeprecio: "",
                presentacion: "3",
                precio: "",
            })
            _Inarticulos.inarticuloslistaprecio = _inarticuloslistapreciosfinales
            setInarticulos(_Inarticulos)
            setRoles([])
        }

    }

    const handleChangegrillainarticuloslistapreciorentabilidad = async (e, ind) => {

        var _Inarticulos = { ...Inarticulos }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Inarticulos }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            var fff = Inarticulos.preccost > 0 ? Inarticulos.preccost * (1 + (Number(e.target.value + "") / 100)) * (1 + (Number(Inarticulos.iva + "") / 100)) : 0

            if (index == fila) {
                var dat = {
                    ...dato,
                    ['precio']: fff <= 0 ? dato.precio : Number(fff.toFixed(2)),
                    [e.target.name]: e.target.value
                }

                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const handleChangegrillainarticuloslistaprecio2 = async (e, ind) => {


        var _Inarticulos = { ...Inarticulos }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Inarticulos }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            var fff = Inarticulos.preccost > 0 ? 100 * (-1 + (Number(e.target.value) / (Inarticulos.preccost * (1 + (Number(Inarticulos.iva + "") / 100))))) : 0

            if (index == fila) {
                var dat = {
                    ...dato,
                    ['rentabilidad']: Number(fff.toFixed(2)),
                    [e.target.name]: e.target.value
                }

                return dat
            } else {
                return dato
            }
        })
        _Inarticulos[subtabla] = _enviodatos
        // setInarticulos(_Inarticulos)

        setInarticulos(_Inarticulos)
        console.log(Inarticulos)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')


    }
    const agregarcondatosinarticuloscompuesto = async (__Inarticulos) => {

        var _Inarticulos = __Inarticulos
        var _inarticuloslistaprecios = _Inarticulos.inarticuloscompuesto
        var _verificar = _inarticuloslistaprecios.filter((dato) => {
            if (dato.referenciacompuesto == "") {
                return dato
            }
        })
        if (_verificar.length == 0) {
            var _inarticuloslistapreciosfinales = _inarticuloslistaprecios.concat({
                referencia: "",
                referenciacompuesto: "",
                cantidad: "",
            })
            _Inarticulos.inarticuloscompuesto = _inarticuloslistapreciosfinales
            setInarticulos(_Inarticulos)
            setRoles([])
        }

    }
    const asignarceros = (_Inarticulos) => {

        let claves = Object.keys(_Inarticulos);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'unxmed' || clave == 'preccost' || clave == 'preccost2' || clave == 'cantmin' || clave == 'cantmax' || clave == 'ultcosto' || clave == 'dscto' || clave == 'invfisico1' || clave == 'ulinvfisico1' || clave == 'saldact' || clave == 'serial' || clave == 'vnr' || clave == 'costoniif' || clave == 'comision' || clave == 'tasa_ultco' || clave == 'ult_fa_com' || clave == 'stockmin1' || clave == 'stockmax1' || clave == 'iva' || clave == 'imptocons' || clave == 'rete' || clave == 'ica' || clave == 'reteiva' || clave == 'impoconsumovalor') {
                _Inarticulos[clave] = Number(_Inarticulos[clave])
            }
        }
        return _Inarticulos
    }
    const asignarceroslistaprecio = (_Inarticulos) => {

        let claves = Object.keys(_Inarticulos);
        for (let i = 0; i < _Inarticulos['inarticuloslistaprecio'].length; i++) {
            _Inarticulos['inarticuloslistaprecio'][i]['precio'] = Number(_Inarticulos['inarticuloslistaprecio'][i]['precio'])
        }
        return _Inarticulos
    }
    const asignarreferencia = (_Inarticulos) => {

        let claves = Object.keys(_Inarticulos);
        for (let i = 0; i < _Inarticulos['inarticuloslistaprecio'].length; i++) {
            _Inarticulos['inarticuloslistaprecio'][i]['referencia'] = _Inarticulos.codigo
        }
        for (let i = 0; i < _Inarticulos['inarticulosbodega'].length; i++) {
            _Inarticulos['inarticulosbodega'][i]['referencia'] = _Inarticulos.codigo
        }
        for (let i = 0; i < _Inarticulos['inarticuloscompuesto'].length; i++) {
            _Inarticulos['inarticuloscompuesto'][i]['referencia'] = _Inarticulos.codigo
        }
        return _Inarticulos
    }
    const verificar = (_Inarticulos) => {

        let claves = Object.keys(_Inarticulos);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' || clave == 'nombre') {
                if (_Inarticulos[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const agregarvendedorcomisionesrecaudo = async (e, index) => {

        var _Vendedor = Direcciones
        _Vendedor.push({
            porcentaje: "",
            cantidad: "",
            fechainicial: "",
            fechafinal: "",
        })

        setDirecciones(_Vendedor)
        setRoles([])

    }
    const buscararticulounico = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            console.log(error)
        }
    }
    const guardarCambios = async () => {
        let response;
        console.log(Inarticulos)
        var aaa = await buscararticulounico(Inarticulos.codigo)

        var _Inarticulos = { ...Inarticulos }
        _Inarticulos = asignarceros(_Inarticulos)
        _Inarticulos = asignarceroslistaprecio(_Inarticulos)
        _Inarticulos = asignarreferencia(_Inarticulos)
        _Inarticulos['blisterxcaja'] = Number(_Inarticulos.blisterxcaja + "")
        _Inarticulos['unidadxblister'] = Number(_Inarticulos.unidadxblister + "")
        var _verificado = verificar(_Inarticulos)
        if (_verificado) {
            try {
                console.log(_Inarticulos)
                if (!editar) {
                    if (aaa.codigo != "") {
                        Swal.fire(
                            'Opps',
                            'Articulo ya existe',
                            'error'
                        )
                        return
                    }
                    response = await fetch("" + props.servidor + "/Inarticulosapi?llave=" + usuario.usuario.llaveempresa + "", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(_Inarticulos)
                    })

                } else {
                    response = await fetch("" + props.servidor + "/Inarticulosapi?llave=" + usuario.usuario.llaveempresa + "", {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(_Inarticulos)
                    })
                }

                if (response.ok) {
                    setInarticulos(modeloInarticulos)
                    setVerModalInarticulos(!verModalInarticulos);
                    setKey('Datos')
                    Swal.fire(
                        'Guardado Exitosamente!',
                        'El Producto fue guardado exitosamente',
                        'success'
                    )
                    await obtenerInarticuloss('');

                } else {
                    var textoerrores = await response.text()
                    var jsonerrores = JSON.parse(textoerrores)
                    var errores = jsonerrores.errors
                    let claves = Object.keys(errores);
                    var stringerrores = ""
                    for (let i = 0; i < claves.length; i++) {
                        let clave = claves[i];
                        if (errores[clave].length != 0) {
                            stringerrores = stringerrores + errores[clave][0] + " \n "
                        }

                    }
                    var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                    if (mensaje.includes('not be converted to System.Decimal')) {
                        mensaje = 'Hay campos numericos rellenados con letras'
                    }
                    console.log(stringerrores)
                    Swal.fire(
                        'Error al Guardar!',
                        mensaje,
                        'error'
                    )
                }
            } catch (error) {
                var mensaje = ""
                if (error.message.includes('Failed to fetch')) {
                    mensaje = "Error de Conexion"
                }
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }
    const options = [
        { label: 'The Godfather', id: 1 },
        { label: 'Pulp Fiction', id: 2 },
    ];
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Productos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataInarticuloss" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerInarticuloss(document.getElementById('buscardataInarticuloss').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                buscartodasinarticulosbodegabodegas('');
                                buscartodasinarticuloslistapreciolistadeprecios('');
                                obtenerPlandecuentass('');
                                setVerModalInarticulos(!verModalInarticulos)
                                seteditar(false)
                                obtenerConclasiarticuloss('')
                                obtenerConclasiarticuloss('')
                                obtenerConsecutivoInarticulos('')
                            }}>Nuevo</Button>
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setproducto(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcel}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcellistadeprecio}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar Precios
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Inarticuloss}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '85%' }} isOpen={verModalInarticulos}>
                <ModalHeader>
                    Producto
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Inarticulos.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={5}>
                                    <FormGroup>
                                        <Label>Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Inarticulos.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Descripcion Adicional</Label>
                                        <Input type="select" onChange={handleChange} bsSize="sm" name="descripcion" value={Inarticulos.descripcion}   >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {(Inarticulos.descripcion == 'si') && <Col sm={3}>
                                    <FormGroup>
                                        <Label>-</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descadicional" value={Inarticulos.descadicional} />
                                    </FormGroup>
                                </Col>}

                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Referencia</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="referencia" value={Inarticulos.referencia} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Plu</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="plu" value={Inarticulos.plu} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Codigo2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo2" value={Inarticulos.codigo2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Unidad de Medida</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="unimed" value={Inarticulos.unimed} />
                                    </FormGroup>
                                </Col>
                                {(usuario.empresa.config.especialidad != "drogueria") && <Col sm={3}>
                                    <FormGroup>
                                        <Label>Unidad por Medida</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="unxmed" value={Inarticulos.unxmed} />
                                    </FormGroup>
                                </Col>}
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Costo en Pesos</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="preccost" value={Inarticulos.preccost} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Costo en US</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="preccost2" value={Inarticulos.preccost2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        {Inarticulos.nombre.includes("GAS") ? <Label>Metros Subsidiados</Label> : <Label>Cantidad Min</Label>}

                                        <Input type="number" bsSize="sm" onChange={handleChange} name="cantmin" value={Inarticulos.cantmin} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cantidad max</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="cantmax" value={Inarticulos.cantmax} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>F Ultima Venta</Label>
                                        <Input disabled type="date" bsSize="sm" onChange={handleChange} name="ultmvto" value={Inarticulos.ultmvto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Ultimo Costo</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="ultcosto" value={Inarticulos.ultcosto} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Descuento</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="dscto" value={Inarticulos.dscto} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="Datos Adicionales" title="Datos Adicionales">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo de Producto</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="artserv" value={Inarticulos.artserv}  >

                                            <option value="venta">VENTA</option>
                                            <option value="servicio">SERVICIO</option>
                                            <option value="consumointerno">CONSUMO INTERNO</option>
                                            <option value="consumoventa">CONSUMO VENTA</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Garantia</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="tipoitem" value={Inarticulos.tipoitem} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Activo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="facturable" value={Inarticulos.facturable}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Produccion</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="produccion" value={Inarticulos.produccion}>
                                            <option value="no">No</option>
                                            <option value="restaurante">Restaurante</option>
                                            <option value="domicilio">Domicilio</option>
                                            <option value="gas">Gas</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Promocion</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="fchaprom" value={Inarticulos.fchaprom}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Inicio Promocion</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fchainiprom" value={Inarticulos.fchainiprom} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Fin Promocion</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fchafinprom" value={Inarticulos.fchafinprom} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        {Inarticulos.nombre.includes("GAS") ? <Label>Desc Estrato 1</Label> : <Label>Serial</Label>}
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="serial" value={Inarticulos.serial} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Articulo Vence</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mfvence" value={Inarticulos.mfvence}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {(Inarticulos.mfvence == 'si') && <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Vencimiento</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fvence" value={Inarticulos.fvence} />
                                    </FormGroup>
                                </Col>}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>V.N.R.</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="vnr" value={Inarticulos.vnr} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Costo Niif</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="costoniif" value={Inarticulos.costoniif} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Serial Alfanumerico</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="pos" value={Inarticulos.pos} />
                                    </FormGroup>
                                </Col>
                                {(false) && <Col sm={3}>
                                    <FormGroup>
                                        <Label>Lote</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="lote" value={Inarticulos.lote} />
                                    </FormGroup>
                                </Col>}
                                <Col sm={3}>
                                    <FormGroup>
                                        {Inarticulos.nombre.includes("GAS") ? <Label>Incremento Comercial</Label> : <Label>Desc1</Label>}
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="desc1" value={Inarticulos.desc1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        {Inarticulos.nombre.includes("GAS") ? <Label>Desc Estrato 2</Label> : <Label>Comision</Label>}

                                        <Input type="number" bsSize="sm" onChange={handleChange} name="comision" value={Inarticulos.comision} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Ultima Compra</Label>
                                        <Input disabled type="number" bsSize="sm" onChange={handleChange} name="ult_fa_com" value={Inarticulos.ult_fa_com} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Ultima Compra</Label>
                                        <Input disabled type="date" bsSize="sm" onChange={handleChange} name="fe_ult_com" value={Inarticulos.fe_ult_com} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Imagen</Label>
                                        <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                            var archivoxml = e.target.files[0]
                                            var cerbase64 = await base64certificado(archivoxml)

                                            var _Inarticulos = { ...Inarticulos }
                                            _Inarticulos['clas10'] = cerbase64
                                            setInarticulos(_Inarticulos)

                                        }} >


                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="Especiales" title="Especiales">
                            <p>Impuestos</p>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Iva</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="iva" value={Inarticulos.iva} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Impo Consumo %</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="imptocons" value={Inarticulos.imptocons} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Impo Consumo Por Valor</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="impoconsumovalor" value={Inarticulos.impoconsumovalor} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Rete Fuente</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="rete" value={Inarticulos.rete} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Rete Ica</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="ica" value={Inarticulos.ica} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Rete Iva</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="reteiva" value={Inarticulos.reteiva} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {(usuario.empresa.config.especialidad == "drogueria") && <>
                                <p>Presentaciones</p>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Presentacion Mayor</Label>
                                            <Input type="text" bsSize="sm" onChange={handleChange} name="blisterxcajanombre" value={Inarticulos.blisterxcajanombre} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Presentacion Intermedia</Label>
                                            <Input type="text" bsSize="sm" onChange={handleChange} name="unidadxblisternombre" value={Inarticulos.unidadxblisternombre} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>{Inarticulos.unidadxblisternombre + "X" + Inarticulos.blisterxcajanombre}</Label>
                                            <Input disabled={(Inarticulos.blisterxcajanombre == "" || Inarticulos.unidadxblisternombre == "")} type="number" bsSize="sm" onChange={handleChange} name="blisterxcaja" value={Inarticulos.blisterxcaja} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>{Inarticulos.unimed != "" ? Inarticulos.unimed + "X" + Inarticulos.unidadxblisternombre : "Unidad" + "X" + Inarticulos.unidadxblisternombre}</Label>
                                            <Input type="number" bsSize="sm" onChange={handleChange} name="unidadxblister" value={Inarticulos.unidadxblister} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>}
                        </Tab>
                        {(true) && <Tab eventKey="Clasificaciones" title="Clasificaciones">
                            <Row className="mt-3">
                                <Col sm={6}>
                                    <Autocomplete
                                        onChange={handleChange2}
                                        name="clas1"
                                        disablePortal
                                        options={clasificaciones.filter(p => p.conclasificacion == 'Clasificación1')}
                                        getOptionLabel={(clasi) => clasi.nombre}
                                        sx={{ width: 300 }}
                                        value={clasificaciones.filter(p => p.codigo == Inarticulos.clas1 && p.conclasificacion == 'Clasificación1')[0]}
                                        renderInput={(params) => <TextField {...params} label="Clasificacion 1" />}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Autocomplete
                                        onChange={handleChange3}
                                        name="clas2"
                                        disablePortal
                                        options={clasificaciones.filter(p => p.conclasificacion == 'Clasificación2')}
                                        getOptionLabel={(clasi) => clasi.nombre}
                                        sx={{ width: 300 }}
                                        value={clasificaciones.filter(p => p.codigo == Inarticulos.clas2 && p.conclasificacion == 'Clasificación2')[0]}
                                        renderInput={(params) => <TextField {...params} label="Clasificacion 2" />}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Autocomplete
                                        onChange={handleChange3}
                                        name="clas3"
                                        disablePortal
                                        options={clasificaciones.filter(p => p.conclasificacion == 'Clasificación3')}
                                        getOptionLabel={(clasi) => clasi.nombre}
                                        sx={{ width: 300 }}
                                        value={clasificaciones.filter(p => p.codigo == Inarticulos.clas2 && p.conclasificacion == 'Clasificación3')[0]}
                                        renderInput={(params) => <TextField {...params} label="Clasificacion 3" />}
                                    />
                                </Col>
                            </Row>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => {
                                setcrearclasificacion(true)
                            }}>Crear Clasificacion</Button>
                            <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={crearclasificacion}>

                                <ModalBody >

                                    <Clasiarticulos servidor={props.servidor} setclasiarticulos={(reportes) => {
                                        setcrearclasificacion(false)
                                        obtenerConclasiarticuloss('');
                                    }} />

                                </ModalBody>
                                <ModalFooter>

                                    <Button size="lg" color="primary" onClick={() => {
                                        setcrearclasificacion(false)
                                        obtenerConclasiarticuloss('');
                                    }}>Cerrar</Button>
                                </ModalFooter>
                            </Modal>

                            <Row>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clas5</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas5" value={Inarticulos.clas5} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clas6</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas6" value={Inarticulos.clas6} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clas7</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas7" value={Inarticulos.clas7} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clas8</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas8" value={Inarticulos.clas8} />
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <Row>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clas9</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas9" value={Inarticulos.clas9} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clas10</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clas10" value={Inarticulos.clas10} />
                                    </FormGroup>
                                </Col> */}
                            </Row>

                        </Tab>}
                        {(false) && <Tab eventKey="Bodega" title="Bodega">
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>Referencia</th>
                                                <th>Bodega</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Inarticulos.inarticulosbodega.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">{<a href="#" onClick={(e) => { agregarinarticulosbodega(e) }} className={"badge badge-info p-2"}>{"Agregar Bodega"}</a>}</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Inarticulos.inarticulosbodega.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" disabled type='text' ultimo={false} onClick={() => { setfila(index) }} onChange={handleChangegrillainarticulosbodega} tabla={'inarticulosbodega'} name="referencia" value={Inarticulos.codigo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup inline={true}>
                                                                        <InputGroup>
                                                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                                                if (e.key == "Enter" && e.target.value == "") {
                                                                                    setbusquedainarticulosbodegabodegas(inarticulosbodegabodegas)
                                                                                    setfilainarticulosbodegabodega(index)
                                                                                    setVerModalinarticulosbodegabodega(true)

                                                                                }
                                                                            }} name="bodega" index={index} value={Inarticulos.inarticulosbodega[index]['bodega']} />
                                                                            <Button color="primary" size="sm" className="mr-2"
                                                                                onClick={() => {
                                                                                    setbusquedainarticulosbodegabodegas(inarticulosbodegabodegas)
                                                                                    setfilainarticulosbodegabodega(index)
                                                                                    setVerModalinarticulosbodegabodega(true)
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-pen-alt"></ i >
                                                                            </Button>
                                                                        </InputGroup>

                                                                    </FormGroup>
                                                                </td>
                                                                <tr>
                                                                    <td colSpan="1">{<a href="#" onClick={(e) => { agregarcondatosinarticulosbodega(Inarticulos) }} className={"badge badge-info p-2"}>{"Agregar Bodega"}</a>}</td>
                                                                </tr>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>}
                        <Tab eventKey="Cuentas" title="Cuentas">
                            <p>Ventas</p>
                            <Row style={{ marginTop: '10px' }}>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentadesc') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentadesc)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Descuento" />}
                                            maxHeight={200}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentaiva') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentaiva)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Iva" />}
                                            maxHeight={200}
                                        />


                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentaimptocons') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentaimptocons)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Impo Consumo" />}
                                            maxHeight={200}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentarete') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentarete)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Rete Fuente" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentaica') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentaica)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Rete Ica" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentadeb') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentadeb)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Cuenta Neto" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacre') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacre)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Cuenta Bruto" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacosto') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacosto)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Cuenta Costo" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentainventario') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentainventario)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Cuenta Inventario" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <p>Compras</p>
                            <Row style={{ marginTop: '10px' }}>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomdesc') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomdesc)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Descuento" />}
                                            maxHeight={200}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomiva') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomiva)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Iva" />}
                                            maxHeight={200}
                                        />


                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomimptocons') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomimptocons)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Impo Consumo" />}
                                            maxHeight={200}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomrete') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomrete)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Rete Fuente" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomica') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomica)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Rete Ica" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomdeb') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomdeb)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Cuenta Neto" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangecuentas(e, value, 'cuentacomcre') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Inarticulos.cuentacomcre)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ borderRadius: '4px' }} label="Cuenta Bruto" />}
                                            maxHeight={200}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Lista de Precio" title="Lista de Precio">
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>Referencia</th>
                                                <th>Lista de Precio</th>
                                                {(usuario.empresa.config.especialidad == "drogueria") && <th>Presentacion</th>}
                                                <th>Precio</th>
                                                {mostrarrentabilidad && <th>Rentabilidad</th>}
                                                {Inarticulos.nombre.includes("GAS") &&
                                                    <>
                                                        <th>Gm</th>
                                                        <th>Tm</th>
                                                        <th>fpcm</th>
                                                        <th>p</th>
                                                        <th>Cfm</th>
                                                        <th>Dm</th>
                                                        <th>Cuvm</th>

                                                    </>

                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Inarticulos.inarticuloslistaprecio.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">{<a href="#" onClick={(e) => { agregarinarticuloslistaprecio(e) }} className={"badge badge-info p-2"}>{"Agregar Lista de Precio"}</a>}</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Inarticulos.inarticuloslistaprecio.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' ultimo={false} onClick={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="referencia" value={Inarticulos.codigo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup inline={true}>
                                                                        <InputGroup>
                                                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                                                if (e.key == "Enter" && e.target.value == "") {
                                                                                    setbusquedainarticuloslistapreciolistadeprecios(inarticuloslistapreciolistadeprecios)
                                                                                    setfilainarticuloslistapreciolistadeprecio(index)
                                                                                    setVerModalinarticuloslistapreciolistadeprecio(true)

                                                                                }
                                                                            }} name="listadeprecio" index={index} value={Inarticulos.inarticuloslistaprecio[index]['listadeprecio']} />
                                                                            <Button color="primary" size="sm" className="mr-2"
                                                                                onClick={() => {
                                                                                    setbusquedainarticuloslistapreciolistadeprecios(inarticuloslistapreciolistadeprecios)
                                                                                    setfilainarticuloslistapreciolistadeprecio(index)
                                                                                    setVerModalinarticuloslistapreciolistadeprecio(true)
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-pen-alt"></ i >
                                                                            </Button>
                                                                        </InputGroup>

                                                                    </FormGroup>
                                                                </td>
                                                                {(usuario.empresa.config.especialidad == "drogueria") && <td >
                                                                    <FormGroup>
                                                                        <Input type="select" ultimo={true} onChange={handleChangegrillainarticulospresentacion} bsSize="sm" name="presentacion" value={Inarticulos.inarticuloslistaprecio[index].presentacion} onFocus={() => { setfila(index) }} tabla={'inarticuloslistaprecio'}  >
                                                                            {(Inarticulos.blisterxcajanombre != "") && <option value="1">{Inarticulos.blisterxcajanombre}</option>}
                                                                            {(Inarticulos.unidadxblisternombre != "") && <option value="2">{Inarticulos.unidadxblisternombre}</option>}
                                                                            <option value="3">Unidad</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                </td>}
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio2} tabla={'inarticuloslistaprecio'} name="precio" value={Inarticulos.inarticuloslistaprecio[index].precio} />
                                                                    </FormGroup>
                                                                </td>
                                                                {
                                                                    mostrarrentabilidad &&
                                                                    <td >
                                                                        <FormGroup>
                                                                            <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={(evv) => {
                                                                                var primerotercero = true
                                                                                props.permisosusuario.forEach(element => {
                                                                                    if (element.tipo == "nodejarcambiarrentabilidad" && element.acceso[0] == "2") {
                                                                                        primerotercero = false
                                                                                    }
                                                                                });
                                                                                if (primerotercero) {
                                                                                    handleChangegrillainarticuloslistapreciorentabilidad(evv)
                                                                                }


                                                                            }} tabla={'inarticuloslistaprecio'} name="rentabilidad" value={Inarticulos.inarticuloslistaprecio[index].rentabilidad} />
                                                                        </FormGroup>
                                                                    </td>
                                                                }

                                                                {Inarticulos.nombre.includes("GAS") &&
                                                                    <>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo1" value={Inarticulos.inarticuloslistaprecio[index].campo1} />
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo2" value={Inarticulos.inarticuloslistaprecio[index].campo2} />
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo3" value={Inarticulos.inarticuloslistaprecio[index].campo3} />
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo4" value={Inarticulos.inarticuloslistaprecio[index].campo4} />
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo5" value={Inarticulos.inarticuloslistaprecio[index].campo5} />
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo6" value={Inarticulos.inarticuloslistaprecio[index].campo6} />
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td >
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloslistaprecio(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloslistaprecio} tabla={'inarticuloslistaprecio'} name="campo7" value={Inarticulos.inarticuloslistaprecio[index].campo7} />
                                                                            </FormGroup>
                                                                        </td>

                                                                    </>

                                                                }
                                                                <tr>
                                                                    <td colSpan="1">{<a href="#" onClick={(e) => { agregarcondatosinarticuloslistaprecio(Inarticulos) }} className={"badge badge-info p-2"}>{"Agregar Lista de Precio"}</a>}</td>
                                                                </tr>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>
                        <Tab eventKey="Compuestos" title="Compuestos">
                            <Row>
                                <div>
                                    <Label>Costo Compuesto</Label>
                                    <Input bsSize="sm" disabled type='text' value={fm3.from(costocompuesto)} />


                                </div>
                            </Row>
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>Referencia</th>
                                                <th>Referencia Compuesto</th>
                                                <th>Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Inarticulos.inarticuloscompuesto.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">{<a href="#" onClick={(e) => { agregarinarticuloscompuesto(e) }} className={"badge badge-info p-2"}>{"Agregar Compuesto"}</a>}</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Inarticulos.inarticuloscompuesto.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' ultimo={false} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloscompuesto} tabla={'inarticuloscompuesto'} name="referencia" value={Inarticulos.codigo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    {
                                                                        Inarticuloss2.length != 0 &&
                                                                        <Col sm={6}>
                                                                            <Autocomplete
                                                                                onChange={handleChange5}
                                                                                name="referenciacompuesto"
                                                                                disablePortal
                                                                                onFocus={() => { setfila(index) }}
                                                                                options={Inarticuloss2}
                                                                                getOptionLabel={(clasi) => clasi.nombre}
                                                                                sx={{ width: 300 }}
                                                                                value={Inarticuloss2.filter(p => p.codigo == item.referenciacompuesto)[0]}
                                                                                renderInput={(params) => <TextField {...params} label="" />}
                                                                            />
                                                                        </Col>
                                                                    }

                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosinarticuloscompuesto(Inarticulos) } }} onFocus={() => { setfila(index) }} onChange={handleChangegrillainarticuloscompuesto} tabla={'inarticuloscompuesto'} name="cantidad" value={Inarticulos.inarticuloscompuesto[index].cantidad} />
                                                                    </FormGroup>
                                                                </td>

                                                                <tr>
                                                                    <td colSpan="1">{<a href="#" onClick={(e) => { agregarcondatosinarticuloscompuesto(Inarticulos) }} className={"badge badge-info p-2"}>{"Agregar Compuesto"}</a>}</td>
                                                                </tr>
                                                                <td >
                                                                    <FormGroup>

                                                                        <Button color="danger" size="sm" className="mr-2"
                                                                            onClick={() => {
                                                                                var _Inarticulos = { ...Inarticulos }
                                                                                var _datos = { ...Inarticulos }['inarticuloscompuesto']
                                                                                let _enviodatos = _datos.map((dato, index2) => {
                                                                                    if (index2 == index) {
                                                                                    } else {
                                                                                        return dato
                                                                                    }
                                                                                })
                                                                                _Inarticulos['inarticuloscompuesto'] = _enviodatos.filter(p => p != undefined)
                                                                                // setMovimientos(_Movimientos)

                                                                                setInarticuloss([])
                                                                                setInarticuloss2([])
                                                                                setRoles({ codig: '' })
                                                                                setInarticulos(_Inarticulos)
                                                                                obtenerInarticuloss('')

                                                                            }}
                                                                        >
                                                                            <i className="fas fa-trash-alt"></i>
                                                                        </Button>
                                                                    </FormGroup>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>
                    </ Tabs >
                    <Buscar filtrar={(coincidencia) => { filtrarinarticulosbodegabodegas(coincidencia) }} busqueda={busquedainarticulosbodegabodegas} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalinarticulosbodegabodega} cerrarModal={() => { cerrarModalSeleccioninarticulosbodegabodegas() }} seleccionar={(seleccionado) => {
                        seleccionarinarticulosbodegabodega(seleccionado)
                    }} />
                    <Buscar filtrar={(coincidencia) => { filtrarinarticuloslistapreciolistadeprecios(coincidencia) }} busqueda={busquedainarticuloslistapreciolistadeprecios} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalinarticuloslistapreciolistadeprecio} cerrarModal={() => { cerrarModalSeleccioninarticuloslistapreciolistadeprecios() }} seleccionar={(seleccionado) => {
                        seleccionarinarticuloslistapreciolistadeprecio(seleccionado)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalInarticulos}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Inarticulos;
