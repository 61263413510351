import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import { UserContext } from "../context/UserProvider";
const modeloUsuario = {
    id: 0,
    cedula: '',
    nit: "",
    nombre: "",
    nombreempresa: "",
    contraseña: '',
    cargo: "",
    departamento: "",
    empresas: [
        // {
        //     usuario: "",
        //     empresa: "",
        //     nombreusuario: "",
        //     nombreempresa: "",
        //     grupo: ""
        // }
    ]
}

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}
const Usuario = (props) => {

    const { user } = useContext(UserContext)
    const [usuario, setUsuario] = useState(modeloUsuario);
    const [pendiente, setPendiente] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [roles, setRoles] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [verModal2, setVerModal2] = useState(false);
    const [verModalGrupo, setverModalGrupo] = useState(false);
    const [key, setKey] = useState('Datos');
    const [empresas, setempresas] = useState([]);
    const [editarempresa, seteditarempresa] = useState(false);
    const [grupos, setgrupos] = useState([]);
    const [busquedaempresas, setbusquedaempresas] = useState([]);
    const [busquedagrupos, setbusquedagrupos] = useState([]);
    const [fila, setfila] = useState(0);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [dataUser, setDataUser] = useState(modelo)

    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _usuario = { ...usuario }
        _usuario[e.target.name] = value
        setUsuario(_usuario)
        setRoles([])


    }


    const buscartodasempresas = (value) => {

        const api = fetch(props.servidor + '/Empresaapi/nombres/' + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                if (props.usuariounico) {

                } else {
                    setempresas(dataJson)
                    setbusquedaempresas(dataJson)
                }

            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    const buscartodoslosgrupos = (value) => {

        const api = fetch(props.servidor + '/Grupoapi/' + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                if (props.usuariounico) {

                } else {
                    setgrupos(dataJson)
                    setbusquedagrupos(dataJson)
                }

                console.log(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    const borrartodaslasempresas = () => {
        setempresas([])
    }
    const valoressugeridos = (sugerencia) => {

        return sugerencia.nit + " - " + sugerencia.nombre
    }
    const rendervaloressugeridos = (sugerencia) => (
        <span onClick={(e) => {

        }}>
            {sugerencia.nit + " - " + sugerencia.nombre}
        </span>
    )

    const rendervaloressugeridos2 = ({ containerProps, children, query }) => {
        <span onClick={(e) => {
            console.log(this)
        }}>
            {children.nit + " - " + children.nombre}
        </span>
    }

    const obtenerEmpresas = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Empresaapi/nombres/' + coincidencia);
        let dt = JSON.parse(user)
        if (response.ok) {
            let data = await response.json()
            if (props.usuariounico) {
                var data2 = data.map((dat) => {
                    if (dat.nit == dt.empresa.empresa.nit) {
                        return dat
                    }

                })
                data2 = data2.filter(p => p != undefined)
                setempresas(data2)
            } else {
                setempresas(data)
            }

        }
    }
    const filtrarempresas = async (coincidencia) => {
        var _empresas = empresas.filter((emp) => {
            if (emp.nit.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedaempresas(_empresas)
    }
    const filtrargrupos = async (coincidencia) => {
        var _empresas = grupos.filter((emp) => {
            if (emp.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedaempresas(_empresas)
    }
    const obtenerUsuarios = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Usuarioapi/completo/' + coincidencia);
        let dt = JSON.parse(user)
        if (response.ok) {
            let data = await response.json()
            if (props.usuariounico) {
                var data2 = data.map((dat) => {
                    var datt = undefined
                    if (dat.usuario.cedula != '1140900262' && dat.usuario.cedula != '1045699499' && dat.usuario.cedula != '1048224128' && dat.usuario.cedula != '1007845566') {


                        for (let i = 0; i < dat.empresas.length; i++) {
                            const element = dat.empresas[i];
                            if (element.empresa == dt.empresa.empresa.nit) {

                                datt = dat
                                i = dat.empresas.length + 123
                            }
                        }
                    }
                    return datt


                })
                data2 = data2.filter(p => p != undefined)
                setUsuarios(data2)
                setPendiente(false)
            } else {
                setUsuarios(data)
                setPendiente(false)
            }

        }
    }

    useEffect(() => {
        // obtenerRoles();
        obtenerUsuarios('');
        obtenerEmpresas('');
        buscartodoslosgrupos('');
    }, [])

    useEffect(() => {
        console.log('')
        //    agregarcondatos()

    }, [editarempresa])

    const columns = [
        {
            name: 'Cedula',
            selector: row => row.usuario.cedula,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.usuario.nombre,
            sortable: true,
        },
        {
            name: 'Contraseña',
            selector: row => props.usuariounico ? "********" : row.usuario.contraseña,
            sortable: true,
        },
        {
            name: 'Estado',
            // selector: row => row.esActivo,
            selector: row => true,
            sortable: true,
            cell: row => {
                let clase;
                // clase = row.esActivo ? "badge badge-info p-2" : "badge badge-danger p-2"
                clase = true ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    // <span className={clase}>{row.esActivo ? "Activo" : "No Activo"}</span>
                    <span className={clase}>{true ? "Activo" : "No Activo"}</span>
                )
            }
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModal = (data) => {
        var enviodata = { ...data }
        var _usuario = {
            id: enviodata.usuario.id,
            cedula: enviodata.usuario.cedula,
            nit: "",
            nombre: enviodata.usuario.nombre,
            nombreempresa: "",
            contraseña: enviodata.usuario.contraseña,
            cargo: enviodata.usuario.cargo,
            departamento: enviodata.usuario.departamento,
            empresas: enviodata.empresas
        }
        setUsuario(_usuario);
        setVerModal(!verModal);
        seteditar(true)
    }

    const cerrarModal = () => {
        setUsuario(modeloUsuario)
        setVerModal(!verModal);
        seteditar(false)
        setKey('Datos')
    }

    const seleccionarempresa = (empresa) => {
        console.log()
        setVerModal2(false);
        console.log(empresa)
        var _empresaseleccionada = empresas.filter((emp) => {
            if (emp.nit == empresa) { return emp }

        })
        var _usuario = { ...usuario }
        var _empresas = _usuario.empresas
        var _yaexiste = _empresas.filter((emp) => {
            if (emp.empresa == empresa) { return emp }

        })
        if (_empresaseleccionada.length != 0 && _yaexiste.length == 0) {
            _empresas[fila]['empresa'] = _empresaseleccionada[0].nit
            _empresas[fila]['nombreempresa'] = _empresaseleccionada[0].nombre
            _empresas[fila]['grupo'] = ''
        }
        _usuario.empresas = _empresas

        setUsuario(_usuario)
        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const seleccionargrupo2 = (grupo) => {
        console.log('')
        setfila2('')
        console.log(fila2)

    }
    const seleccionargrupo = async (grupo) => {
        console.log(grupo)
        setverModalGrupo(false);
        var usuuu = usuarios
        console.log(grupo)
        var _gruposelecionado = grupos.filter((gru) => {
            if (gru.codigogrupo == grupo) { return gru }

        })
        var _usuario = { ...usuario }
        var _empresas = { ...usuario }.empresas
        if (_gruposelecionado.length != 0) {
            let _envioempresas = _empresas.map((emp, index) => {
                if (index == fila) {
                    return {
                        usuario: emp.usuario,
                        empresa: emp.empresa,
                        nombreusuario: emp.nombreusuario,
                        nombreempresa: emp.nombreempresa,
                        grupo: _gruposelecionado[0].codigogrupo

                    }
                } else {
                    return emp
                }
            })
            _usuario.empresas = _envioempresas
            // setUsuario(_usuario)
        }
        setUsuario(_usuario)
        console.log(usuario)
        setRoles({ codig: '' })
        console.log(roles)
        seteditarempresa(!editarempresa)
        agregarcondatos(_usuario)

        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const cerrarModalSeleccionEmpresas = () => {
        setVerModal2(!verModal2);
        setbusquedaempresas(empresas)
    }
    const cerrarModalSeleccionGrupos = () => {
        setverModalGrupo(!verModalGrupo);
        setbusquedagrupos(grupos)
    }
    const agregar = async (e, index) => {
        var mod = modeloUsuario
        var _usuario = { ...usuario }
        var _empresas = { ...usuario }.empresas
        var _empresasfinales = _empresas.concat({
            usuario: _usuario.cedula,
            empresa: "",
            nombreusuario: _usuario.nombre,
            nombreempresa: "",
            grupo: ""
        })
        _usuario.empresas = _empresasfinales
        setUsuario(_usuario)
        setRoles([])
    }
    const agregarcondatos = async (__usuario) => {

        var _usuario = __usuario
        var _empresas = _usuario.empresas
        var _verificar = _empresas.filter((emp) => {
            if (emp.empresa == "") {
                return emp
            }
        })
        if (_verificar.length == 0) {
            var _empresasfinales = _empresas.concat({
                usuario: _usuario.cedula,
                empresa: "",
                nombreusuario: _usuario.nombre,
                nombreempresa: "",
                grupo: ""
            })
            _usuario.empresas = _empresasfinales
            setUsuario(_usuario)
            setRoles([])
        }

    }

    const guardarCambios = async () => {
        if (usuario.empresas.length == 0) {
            Swal.fire(
                'Error al Guardar!',
                'No tiene Permisos',
                'error'
            )
            return
        }
        // delete usuario.idRolNavigation;
        var _usuario = { ...usuario }
        var _empresas = _usuario.empresas
        var _enviousuario = {
            usuario: {
                id: _usuario.id,
                cedula: _usuario.cedula,
                nit: _usuario.nit,
                nombre: _usuario.nombre,
                nombreempresa: _usuario.nombreempresa,
                contraseña: _usuario.contraseña,
                cargo: _usuario.cargo,
                departamento: _usuario.departamento,
            },
            empresas: []
        }
        for (let i = 0; i < _empresas.length; i++) {
            const element = _empresas[i]
            if (element.empresa != "") {
                _enviousuario.empresas.push({
                    usuario: _usuario.cedula,
                    empresa: element.empresa,
                    nombreusuario: _usuario.nombre,
                    nombreempresa: element.nombreempresa,
                    grupo: element.grupo
                })
            }

        }
        let response;
        if (!editar) {
            response = await fetch(props.servidor + "/Usuarioapi/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_enviousuario)
            })

        } else {
            response = await fetch(props.servidor + "/Usuarioapi/", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_enviousuario)
            })
        }

        if (response.ok) {
            await obtenerUsuarios('');
            setUsuario(modeloUsuario)
            setVerModal(!verModal);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'El Usuario fue guardado exitosamente',
                'success'
            )
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Sucedio un error al guardar el Usuario',
                'error'
            )
        }

    }

    const eliminarUsuario = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el usuario",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/usuario/Eliminar/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            // obtenerUsuarios();

                            Swal.fire(
                                'Eliminado!',
                                'El usuario fue eliminado.',
                                'success'
                            )
                        }
                    })
            }
        })
    }


    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Usuarios
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataUsuarios" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerUsuarios(document.getElementById('buscardataUsuarios').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModal(!verModal)
                                seteditar(false)
                                buscartodasempresas('')
                                buscartodoslosgrupos('')
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={usuarios}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={verModal}>
                <ModalHeader>
                    Detalle Usuario
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        {(true) &&
                            <Tab eventKey="Datos" title="Datos">
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Cedula</Label>
                                            <Input disabled={editar} bsSize="sm" onChange={handleChange} name="cedula" value={usuario.cedula} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Nombre</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="nombre" value={usuario.nombre} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Contraseña</Label>
                                            <Input bsSize="sm" type="password" onChange={handleChange} name="contraseña" value={usuario.contraseña} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Cargo</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="cargo" value={usuario.cargo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Departamento</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="departamento" value={usuario.departamento} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </Tab>}
                        {(!props.usuariounico) &&
                            <Tab eventKey="Empresa" title="Empresa">
                                <Row>
                                    <Col sm={12}>

                                        <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                            <thead>
                                                <tr>
                                                    <th>Nit</th>
                                                    <th>Nombre</th>
                                                    <th>Grupo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (usuario.empresas.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="5">{<a href="#" onClick={(e) => { agregar(e) }} className={"badge badge-info p-2"}>{"Agregar Empresa"}</a>}</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            usuario.empresas.map((item, index) => (
                                                                <tr key={(index + 1)}>

                                                                    <td >
                                                                        <FormGroup inline={true}>
                                                                            <InputGroup>
                                                                                <Input bsSize="sm" onKeyDown={(e) => {
                                                                                    if (e.key == "Enter" && e.target.value == "") {
                                                                                        setbusquedaempresas(empresas)
                                                                                        setfila(index)
                                                                                        setVerModal2(true)

                                                                                    }
                                                                                }} placeholder="Empresa" name="empresa" index={index} value={usuario.empresas[index]['empresa']} />
                                                                                <Button color="primary" size="sm" className="mr-2"
                                                                                    onClick={() => {
                                                                                        setbusquedaempresas(empresas)
                                                                                        setfila(index)
                                                                                        setVerModal2(true)
                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-pen-alt"></i>
                                                                                </Button>
                                                                            </InputGroup>

                                                                        </FormGroup>
                                                                    </td>
                                                                    <td >
                                                                        <FormGroup>
                                                                            <Input disabled bsSize="sm" placeholder="Nombre Empresa" name="nombreempresa" index={index} value={usuario.empresas[index]['nombreempresa']} />
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td >
                                                                        <FormGroup>

                                                                            <InputGroup>
                                                                                <Input bsSize="sm" onKeyDown={(e) => {
                                                                                    if (e.key == "Enter" && e.target.value == "") {
                                                                                        var _gupos = grupos.filter((gru) => {
                                                                                            if (gru.nitempresa == usuario.empresas[index]['empresa']) { return gru }

                                                                                        })
                                                                                        setbusquedagrupos(_gupos)
                                                                                        setfila(index)
                                                                                        setverModalGrupo(true)

                                                                                    }
                                                                                }} placeholder="Grupo" name="grupo" index={index} value={usuario.empresas[index]['grupo']} />
                                                                                <Button color="primary" size="sm" className="mr-2"
                                                                                    onClick={() => {
                                                                                        var _gupos = grupos.filter((gru) => {
                                                                                            if (gru.nitempresa == usuario.empresas[index]['empresa']) { return gru }

                                                                                        })
                                                                                        setbusquedagrupos(_gupos)
                                                                                        setfila(index)
                                                                                        setverModalGrupo(true)

                                                                                    }}
                                                                                >
                                                                                    <i className="fas fa-pen-alt"></i>
                                                                                </Button>
                                                                            </InputGroup>

                                                                        </FormGroup>
                                                                    </td>
                                                                    <td >
                                                                        <FormGroup>

                                                                            <Button color="danger" size="sm" className="mr-2"
                                                                                onClick={() => {
                                                                                    var _usuario = usuario
                                                                                    var _empresas = usuario.empresas
                                                                                    var _empresasvivas = _empresas.filter((emp) => {
                                                                                        if (emp.empresa != usuario.empresas[index]['empresa']) { return emp }

                                                                                    })
                                                                                    _usuario.empresas = _empresasvivas
                                                                                    setUsuario(_usuario)
                                                                                    setRoles([])
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-trash-alt"></i>
                                                                            </Button>
                                                                        </FormGroup>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>
                                    </Col>

                                </Row>

                            </Tab>}
                    </Tabs>

                    <Buscar filtrar={(coincidencia) => { filtrarempresas(coincidencia) }} busqueda={busquedaempresas} encabezado={["Nit", "Nombre"]} codigo={"nit"} nombre={"nombre"} dato={""} dato2={""} verModal={verModal2} cerrarModal={() => { cerrarModalSeleccionEmpresas() }} seleccionar={(seleccionado) => {
                        seleccionarempresa(seleccionado.nit)
                    }} />
                    <Buscar filtrar={(coincidencia) => { filtrargrupos(coincidencia) }} busqueda={busquedagrupos} encabezado={["Codigo", "Nombre"]} codigo={"codigogrupo"} nombre={"nombregrupo"} dato={""} dato2={""} verModal={verModalGrupo} cerrarModal={() => { cerrarModalSeleccionGrupos() }} seleccionar={(seleccionado) => {
                        seleccionargrupo(seleccionado.codigogrupo)
                    }} />


                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default Usuario;