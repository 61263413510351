import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from '../../../context/UserProvider';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import Filtro from '../../../componentes/filtro';
import { Try } from "@mui/icons-material";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    ivaporc: "",
    costopromedio: "",
    costoultimo: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [previsualizar2, setprevisualizar2] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [fm2, setfm2] = useState(new FormatMoney({ decimals: 0 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [filtros, setfiltros] = useState(false);
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [fm4, setfm4] = useState(new FormatMoney({ decimals: 2 }));
    const [tipotercero, settipotercero] = useState("-");
    const [consaldocero, setconsaldocero] = useState("no");
    const [clasificaciontercero1, setclasificaciontercero1] = useState("-");
    const dataTableRef = useRef(null);
    useEffect(() => {
        console.log(new Date())
        console.log(new Date('20230-01-01'))
        console.log((new Date() - new Date('20230-01-01')) / 86400000)
    }, [])
    const buscarpdf = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var fdff = props.servidor + '/CtabonoApi/estadodecuentainmobiliariapdf/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + "/" + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + ''
        const api = fetch(props.servidor + '/CtabonoApi/estadodecuentainmobiliariapdf/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + "/" + dt.empresa.empresa.nit + "/" + consaldocero + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'estado_de_cuenta.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                if (consaldocero == "si") {
                    setVentas(data.datos);

                } else {
                    var datasincero = buscarsaldosencero(data.datos)
                    var datasincerofacturas = buscarsaldosencerofacturas(datasincero)

                    setVentas(datasincerofacturas);

                }
                console.log(data.datos)
            }).catch(async (error) => {
                console.log(error)
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var fdff = props.servidor + '/CtabonoApi/estadodecuentainmobiliaria/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + "/" + clasificaciontercero1 + '?llave=' + dt.usuario.llaveempresa + ''
        const api = fetch(props.servidor + '/CtabonoApi/estadodecuentainmobiliaria/' + fechaInicio + '/' + fechaFin + '/' + tipodocumentoinicioseleccionado + '/' + tipodocumentofinseleccionado + '/' + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + tipotercero + "/" + clasificaciontercero1 +  '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                if (consaldocero == "si") {
                    setVentas(data);

                } else {
                    var datasincero = buscarsaldosencero(data)
                    var datasincerofacturas = buscarsaldosencerofacturas(datasincero)

                    setVentas(datasincerofacturas);

                }
                console.log(data)
            }).catch(async (error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const totaltotalsaldo = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.vlrfactur + element.debito - element.credito)
        }
        return tot
    }
    const totaltotaldb = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.debito)
        }
        return tot
    }
    const totaltotalcr = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.credito)
        }
        return tot
    }
    const totaltotaldbcr = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + Math.abs((element.debito - element.credito))
        }
        return tot
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.vlrfactur)
        }
        return tot
    }
    const totaltotalbrcrvivo = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            var saldo = (element.vlrfactur + element.debito - element.credito)
            if (saldo != 0) {
                tot = tot + Math.abs((element.debito - element.credito))
            }
        }
        return tot
    }

    const saldofacturas = (data, row) => {
        var vent = data
        var fila = row
        var tot = fila.movimientos.vlrfactur
        var pagos = fila.pagos
        pagos.forEach(element => {
            if (element.docctabono != 0) {
                if (element.ctabonoafecartera == "debito") {
                    if (element.ctabonotipodoc == "recibo de caja") {
                        tot = tot + element.bruto
                    } else {
                        tot = tot + element.neto

                    }
                } else {
                    if (element.ctabonotipodoc == "recibo de caja") {
                        tot = tot - element.bruto
                    } else {
                        tot = tot - element.neto

                    }
                }
            }

        });
        return tot
    }
    const buscarsaldosencerofacturas = (data) => {
        for (let i = 0; i < data.length; i++) {
            const element2 = data[i];
            var data2facturas = []
            element2.facturas.forEach(element => {
                var saldo = saldofacturas(data, element)
                if (Math.round(saldo) != 0) {
                    data2facturas.push(element)
                }
            });
            data[i].facturas = data2facturas

        }
        return data
    }

    const saldofacturastercero = (data, row) => {
        var vent = data
        var fila = row.facturas
        var tot = 0
        try {
            fila.forEach(element => {
                if (true) {
                    tot = tot + element.movimientos.vlrfactur

                } else {
                    tot = tot - element.movimientos.vlrfactur

                }
            });
            fila.forEach(element2 => {
                element2.pagos.forEach(element => {
                    if (element.docctabono != 0) {
                        if (element.ctabonoafecartera == "debito") {
                            if (element.ctabonotipodoc == "recibo de caja") {
                                tot = tot + element.bruto
                            } else {
                                tot = tot + element.neto

                            }
                        } else {
                            if (element.ctabonotipodoc == "recibo de caja") {
                                tot = tot - element.bruto
                            } else {
                                tot = tot - element.neto

                            }
                        }
                    }
                });
            });

        } catch (error) {

        }
        return tot
    }

    const buscarsaldosencero = (data) => {
        var data2 = []
        data.forEach(element => {
            var saldo = saldofacturastercero(data, element)
            if (Math.round(saldo)  != 0) {
                data2.push(element)
            }
        });
        return data2
    }
    const rowPreExpanded2 = ({ data }) => {
        return <>
            <DataTable
                progressPending={pendiente}
                columns={columns2}
                data={data.facturas}
                // noTableHead={true}
                conditionalRowStyles={conditionalRowStyles3}
                expandableRows
                expandableRowsComponent={rowPreExpandedsub}
                expandableRowExpanded={rowPreExpanded3}
            />
        </>
    }
    const CustomNoDataComponent = () => (
        <div>
            {/* Puedes personalizar este componente según tus necesidades */}
            {/* No hay pagos para mostrar. */}
        </div>
    );
    const rowPreExpandedsub = ({ data }) => {
        return <>
            <DataTable
                progressPending={pendiente}
                columns={columns3}
                data={data.pagos.filter(p => p.docctabono != 0)}
                noTableHead={true}
                conditionalRowStyles={conditionalRowStyles4}
                noDataComponent={<CustomNoDataComponent />}
            />
        </>
    }


    const conditionalRowStyles4 = [

        {
            when: row => row.ctabonoafecartera != "debito",
            style: {
                fontSize: '10px',
                color: '#65893D'

            }
        },
        {
            when: row => row.ctabonoafecartera == "debito",
            style: {
                fontSize: '10px',
                color: '#BF3314'

            }
        }
    ];
    const conditionalRowStyles3 = [

        {
            when: row => true,
            style: {
                fontSize: '10px',
                color: '#188BAD'

            }
        }
    ];

    const columns3 = [
        {
            name: '',
            selector: row => "",
            grow: 2
        },
        {
            name: 'Concepto',
            selector: row => row.conceptoafecta,
        },
        {
            name: 'Documento',
            selector: row => row.docctabono,
            grow: 1
        }, {
            name: 'fecha',
            selector: row => row.fechaabono,
            grow: 3
        }, {
            name: 'valor',
            selector: row => row.ctabonotipodoc == "recibo de caja" ? fm4.from(row.bruto) : fm4.from(row.neto),
            grow: 4
        }, {
            name: 'Saldo',
            selector: row => "",
            grow: 1
        }
    ];
    const columns2 = [
        {
            name: '',
            selector: row => "",
        },
        {
            name: 'Concepto',
            selector: row => row.movimientos.conceptofactura,
        },
        {
            name: 'Documento',
            selector: row => row.movimientos.docmovimiento,
            grow: 2
        }, {
            name: 'Fecha',
            selector: row => row.movimientos.fecha,
            grow: 4
        }, {
            name: 'Valor',
            selector: row => fm4.from(row.movimientos.vlrfactur),
            grow: 4
        }, {
            name: 'Saldo',
            selector: row => fm4.from(saldofacturas(ventas, row)),
            grow: 4
        }
    ];
    const rowPreExpanded3 = () => {
        return <>
            <>

            </>
        </>
    }
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.terceros ? row.terceros.cedula : "",
            sortable: true,
            grow: 4,
        },
        {
            name: 'Tercero',
            selector: row => row.terceros ? row.terceros.nombrecli : "",
            sortable: true,
            grow: 4,
        }, {
            name: 'Saldo',
            selector: row => fm4.from(saldofacturastercero(ventas, row)),
            grow: 4
        }
    ];

    const conditionalRowStyles2 = [

        {
            when: row => true,
            style: {
                fontSize: '12px',

            }
        }, {
            when: row => row.conceptofactura == "encabezado",
            style: {
                fontSize: '14px',
                fontWeight: 'bold'


            }
        }, {
            when: row => row.conceptofactura == "Totales",
            style: {
                fontSize: '14px',
                fontWeight: 'bold'


            }
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '10px',
                fontWeight: 800,

            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
                fontSize: '8px'
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx")
    }
    const imprimirpdf = () => {

        if (true) {
            const doc = new jsPDF('p', 'pt', 'letter');
            doc.page = 1;
            dataTableRef.current = document.getElementById('imprimirestadodecuenta')
            // Calcula la altura del contenido a imprimir
            let contentHeight = dataTableRef.current.clientHeight;

            // Convierte el contenido del DataTable a una imagen usando html2canvas
            html2canvas(dataTableRef.current)
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/png');

                    // Calcula el ancho de la página
                    const pageWidth = doc.internal.pageSize.width;
                    const pageHeight = doc.internal.pageSize.height;

                    // Calcula el ratio de escala
                    const ratio = pageWidth / canvas.width;

                    // Calcula la altura de la imagen después de la escala
                    const imgHeight = canvas.height * ratio;

                    // Añade la imagen al documento PDF
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 0, position, pageWidth, imgHeight);
                    position -= pageHeight;

                    // Añade nuevas páginas si el contenido es demasiado grande
                    while (contentHeight + position > 0) {
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, pageWidth, imgHeight);
                        position -= pageHeight;
                        const frrr = contentHeight + position
                    }
                    // doc.output('dataurlnewwindow', { filename: 'estado_de_cuenta.pdf' });
                    // Guarda el PDF
                    doc.save('estado_de_cuenta.pdf');
                });
        }


        // const doc = new jsPDF('p', 'pt', 'a4');
        // doc.page = 1; // use this as a counter.
        // console.log(doc.getFontList())
        // var canttt = 0;
        // try {
        //     var contmovv = 0
        //     ventas.forEach(element => {
        //         contmovv = contmovv + element.movimientos.length
        //     });
        //     canttt = 200 + (((ventas.length + contmovv + 10) * 300) / 100)
        // } catch (error) {

        // }
        // var escala = (doc.internal.pageSize.width - canttt) / document.getElementById('imprimirestadodecuenta').clientWidth
        // doc.html(document.querySelector("#imprimirestadodecuenta"), {
        //     callback: function (pdf) {
        //         doc.autoPrint();

        //         doc.output('dataurlnewwindow', { filename: 'cierre_caja.pdf' });
        //     },
        //     html2canvas: {
        //         scale: escala
        //     }
        // })
    }
    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                            Estado de Cuenta
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                            selected={fechaFin}
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                                            setfiltros(true)
                                        }}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Saldos en Cero</Label>
                                        <Input value={consaldocero} onChange={(e) => { setconsaldocero(e.target.value) }} type="select" style={{ fontSize: '13px' }} name="coniva"  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>

                                        </Input>


                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>

                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col> */}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={() => { imprimirpdf() }}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={() => { buscarpdf() }}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF 2
                                        </Button>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Movimientos Totales
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total Saldo
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotalsaldo())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total Credito
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotalcr())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total Debito
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotaldb())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total DBCR
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotaldbcr())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={3}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Total DBCR con saldo
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotalbrcrvivo())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <hr></hr>
                            <Row>

                                <Col sm="12" id={"imprimirestadodecuenta"} ref={ref => dataTableRef.current = ref}>
                                    <DataTable

                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        conditionalRowStyles={conditionalRowStyles2}
                                        expandableRows
                                        expandableRowsComponent={rowPreExpanded2}
                                        expandableRowExpanded={rowPreExpanded3}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    settipotercero={(e) => { settipotercero(e) }}
                    setclasificaciontercero1={(e) => { setclasificaciontercero1(e) }}

                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    tipotercero={tipotercero}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    clasificaciontercero1={clasificaciontercero1}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />

            </Row>

            <Modal size="lg" isOpen={previsualizar2}>
                <ModalHeader>
                    Opciones
                </ModalHeader>
                <ModalBody id={"imprimirestadodecuenta"} >
                    <Row >
                        <Col sm={12}>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        conditionalRowStyles={conditionalRowStyles2}
                                        expandableRows
                                        expandableRowsComponent={rowPreExpanded2}
                                        expandableRowExpanded={rowPreExpanded3}
                                    />



                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        setprevisualizar2(false)
                        imprimirpdf()
                    }}>PDF</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        setprevisualizar2(false)
                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ReporteVenta;