import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
const modeloPlantillaEncModel = {
    codigo: "",
    tipoinforme: "estadoderesultados",
    nombre: "",
    titulo: "",
    comentario: "",
    piepagina1: "",
    piepagina2: "",
    piepagina3: "",
    piepagina4: "",
    piepagina5: "",
    piepagina6: "",
    piepagina7: "",
    piepagina8: "",
    piepagina9: "",
    piepagina10: "",
    piepagina11: "",
    piepagina12: "",
}
const ModeloDetalle = []
const ModeloDetalledetalle = []
const PlantillaEncModel = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [PlantillaEncModel, setPlantillaEncModel] = useState(modeloPlantillaEncModel);
    const [Detalle, setDetalle] = useState(ModeloDetalle);
    const [Detalledetalle, setDetalledetalle] = useState(ModeloDetalledetalle);
    const [PlantillaEncModels, setPlantillaEncModels] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [tipoinforme, settipoinforme] = useState([]);
    const [tipoinformes, settipoinformes] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [titulo, settitulo] = useState([]);
    const [titulos, settitulos] = useState([]);
    const [comentario, setcomentario] = useState([]);
    const [comentarios, setcomentarios] = useState([]);
    const [piepagina1, setpiepagina1] = useState([]);
    const [piepagina1s, setpiepagina1s] = useState([]);
    const [piepagina2, setpiepagina2] = useState([]);
    const [piepagina2s, setpiepagina2s] = useState([]);
    const [piepagina3, setpiepagina3] = useState([]);
    const [piepagina3s, setpiepagina3s] = useState([]);
    const [piepagina4, setpiepagina4] = useState([]);
    const [piepagina4s, setpiepagina4s] = useState([]);
    const [piepagina5, setpiepagina5] = useState([]);
    const [piepagina5s, setpiepagina5s] = useState([]);
    const [piepagina6, setpiepagina6] = useState([]);
    const [piepagina6s, setpiepagina6s] = useState([]);
    const [piepagina7, setpiepagina7] = useState([]);
    const [piepagina7s, setpiepagina7s] = useState([]);
    const [piepagina8, setpiepagina8] = useState([]);
    const [piepagina8s, setpiepagina8s] = useState([]);
    const [piepagina9, setpiepagina9] = useState([]);
    const [piepagina9s, setpiepagina9s] = useState([]);
    const [piepagina10, setpiepagina10] = useState([]);
    const [piepagina10s, setpiepagina10s] = useState([]);
    const [piepagina11, setpiepagina11] = useState([]);
    const [piepagina11s, setpiepagina11s] = useState([]);
    const [piepagina12, setpiepagina12] = useState([]);
    const [piepagina12s, setpiepagina12s] = useState([]);
    const [fila, setfila] = useState(0);
    const [filadetalle, setfiladetalle] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalPlantillaEncModel, setVerModalPlantillaEncModel] = useState(false);
    const [Conplantillainformes, setConplantillainformes] = useState([]);
    const [abriricuentas, setabriricuentas] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _PlantillaEncModel = { ...PlantillaEncModel }
        _PlantillaEncModel[e.target.name] = value
        setPlantillaEncModel(_PlantillaEncModel)
        setRoles([])


    }
    const obtenerPlantillaEncModels = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/PlantillaEncModelapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlantillaEncModels(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerConplantillainformes = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Conplantillainformeapi?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setConplantillainformes(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerPlantillaEncModels('');
        obtenerConplantillainformes('')
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.encabezado.codigo,
            sortable: true,
        },
        {
            name: 'Tipo Informe Fila',
            selector: row => row.encabezado.tipoinforme,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.encabezado.nombre,
            sortable: true,
        },
        {
            name: 'Titulo',
            selector: row => row.encabezado.titulo,
            sortable: true,
        },
        {
            name: 'Comentario',
            selector: row => row.encabezado.comentario,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalPlantillaEncModel(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalPlantillaEncModel = (data) => {
        obtenerConplantillainformes('')
        var enviodata = { ...data }
        var _PlantillaEncModel = {
            codigo: enviodata.encabezado.codigo,
            tipoinforme: enviodata.encabezado.tipoinforme,
            nombre: enviodata.encabezado.nombre,
            titulo: enviodata.encabezado.titulo,
            comentario: enviodata.encabezado.comentario,
            piepagina1: enviodata.encabezado.piepagina1,
            piepagina2: enviodata.encabezado.piepagina2,
            piepagina3: enviodata.encabezado.piepagina3,
            piepagina4: enviodata.encabezado.piepagina4,
            piepagina5: enviodata.encabezado.piepagina5,
            piepagina6: enviodata.encabezado.piepagina6,
            piepagina7: enviodata.encabezado.piepagina7,
            piepagina8: enviodata.encabezado.piepagina8,
            piepagina9: enviodata.encabezado.piepagina9,
            piepagina10: enviodata.encabezado.piepagina10,
            piepagina11: enviodata.encabezado.piepagina11,
            piepagina12: enviodata.encabezado.piepagina12,
        }
        var detalle = []
        var detalledetalle = []
        enviodata.detalle.forEach(element => {
            detalle.push(element.detalle)
            var detdetun = element.detalledetalle.filter(p => p.codigocon == element.detalle.codigocon)
            detalledetalle.push(detdetun)
        });
        setPlantillaEncModel(_PlantillaEncModel);
        setDetalle(detalle);
        setDetalledetalle(detalledetalle);
        setVerModalPlantillaEncModel(!verModalPlantillaEncModel);
        seteditar(true)
    }
    const cerrarModalPlantillaEncModel = () => {
        setPlantillaEncModel(modeloPlantillaEncModel)
        setDetalle([])
        setDetalledetalle([])
        setVerModalPlantillaEncModel(!verModalPlantillaEncModel);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_PlantillaEncModel) => {

        let claves = Object.keys(_PlantillaEncModel);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _PlantillaEncModel[clave] = Number(_PlantillaEncModel[clave])
            }
        }
        return _PlantillaEncModel
    }
    const verificar = (_PlantillaEncModel) => {

        let claves = Object.keys(_PlantillaEncModel);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' || clave == 'tipoinforme' || clave == 'nombre' || clave == 'titulo') {
                if (_PlantillaEncModel[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let dt = JSON.parse(user)
        let response;
        console.log(PlantillaEncModel)
        var _PlantillaEncModel = { ...PlantillaEncModel }
        var _detalle = Detalle.map((dato) => { return dato })
        var _detalledetalle = Detalledetalle.map((dato) => { return dato })

        _PlantillaEncModel = asignarceros(_PlantillaEncModel)

        var listadetalle = []
        _detalle.forEach(dat => {
            let arraysFiltrados = _detalledetalle.filter(array =>
                array.some(objeto => objeto.codigocon === dat.codigocon)
            );
            var arff = []
            if (arraysFiltrados.length != 0) {

                arff = arraysFiltrados[0]
            }
            var enviadodetalle = {
                detalle: dat,
                detalledetalle: arff
            }
            listadetalle.push(enviadodetalle)

        });

        var enviado = {
            encabezado: _PlantillaEncModel,
            detalle: listadetalle

        }


        // var _verificado = verificar(_PlantillaEncModel)
        if (true) {
            console.log(_PlantillaEncModel)
            if (true) {
                response = await fetch("" + props.servidor + "/PlantillaEncModelapi?llave=" + dt.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(enviado)
                })

            } else {
                response = await fetch("" + props.servidor + "/PlantillaEncModelapi?llave=" + dt.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_PlantillaEncModel)
                })
            }

            if (response.ok) {
                await obtenerPlantillaEncModels('');
                setPlantillaEncModel(modeloPlantillaEncModel)
                setVerModalPlantillaEncModel(!verModalPlantillaEncModel);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El PlantillaEncModel fue guardado exitosamente',
                    'success'
                )
            } else {
                var errorResponse = await response.json();
                console.error('Error de la aplicación:', errorResponse);
                console.error(enviado);
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el PlantillaEncModel',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }
    const handlerchangedetallep = (e, index) => {
        var codigo = e.target.value
        var _Vendedor = Detalle.map((dato) => { return dato })
        _Vendedor = _Vendedor.map((dato, index2) => {
            if (index == index2) {
                var dat = {
                    ...dato,
                    [e.target.name]: codigo,
                }
                return dat
            } else {
                return dato
            }
        })
        setDetalle(_Vendedor)
        setRoles([])

    }

    const handlerchangedetalledetallep = (e, index, cont) => {
        var codigo = e.target.value
        var _detalledetalle = Detalledetalle.map((dato) => { return dato })
        _detalledetalle = _detalledetalle.map((dato, index2) => {

            for (let i = 0; i < dato.length; i++) {
                const element = dato[i];
                if (Detalle[fila].codigocon == element.codigocon && i == cont) {
                    dato[i][e.target.name] = codigo
                }
            }
            return dato
        })
        setDetalledetalle(_detalledetalle)


    }

    const handlerchangedetalleplantilla = (e, index) => {
        var indiceSeleccionado = e.target.selectedIndex;

        // Obtener el atributo 'data-atributo' de la opción seleccionada
        var detallefila = e.target.options[indiceSeleccionado].getAttribute("detallefila");
        var detalletotal = e.target.options[indiceSeleccionado].getAttribute("detalletotal");

        var codigo = e.target.value
        var _Vendedor = Detalle.map((dato) => { return dato })
        _Vendedor = _Vendedor.map((dato, index2) => {
            if (index == index2) {
                var dat = {
                    ...dato,
                    [e.target.name]: codigo,
                    descripcionfila: detallefila,
                    descripciontotales: detalletotal,
                }
                return dat
            } else {
                return dato
            }
        })
        var _detalledetalle = Detalledetalle.map((dato) => { return dato })
        _detalledetalle = _detalledetalle.map((dato, index2) => {

            for (let i = 0; i < dato.length; i++) {
                const element = dato[i];
                if (Detalle[index].codigocon == element.codigocon) {
                    dato[i].codigocon = codigo
                }
            }
            return dato
        })
        setDetalle(_Vendedor)
        setDetalledetalle(_detalledetalle)
        setRoles([])

    }

    const agregardetalle = () => {
        var listadet = Detalle.map((dat) => {
            return dat
        })
        var det = {
            codigo: '',
            tipoinforme: '',
            codigocon: '',
            descripcionfila: '',
            descripciontotales: '',
            codigostotales: '',
            opcion: '',
            indicador: (listadet.length + 1) + ""
        }
        if (listadet.length == 0) {
            listadet.push(det)
        } else {
            var hayenblanco = listadet.filter(e => e.codigocon == '')
            if (hayenblanco.length == 0) {
                listadet.push(det)

            }
        }
        setDetalle(listadet)
    }

    const eliminarfiladetalledetalle = (indd, index5) => {
        if (Detalle.length != 0) {
            var listadet = Detalledetalle.map((dat) => {
                return dat
            })
            var _detalledetalle = Detalledetalle.map((dato) => { return dato })
            _detalledetalle = _detalledetalle.map((dato, index2) => {
                if (index2 == indd) {
                    for (let i = 0; i < dato.length; i++) {
                        const element = dato[i];
                        if (i == index5) {
                            dato.splice(i, 1);
                            break;
                        }
                    }
                }

                return dato
            })
            setDetalledetalle(_detalledetalle)

        }
    }

    const agregardetalledetalle = () => {
        if (Detalle.length != 0) {
            var listadet = Detalledetalle.map((dat) => {
                return dat
            })
            var det = {
                codigo: '',
                tipoinforme: '',
                codigocon: Detalle[fila].codigocon,
                cuentainicial: '',
                cuentafinal: '',
                descripcion: '',
                cambianaturaleza: 'si',
                tiporango: (listadet.length + 1) + "",
                ccosto: ''
            }
            var encontre = false
            var indexencontre = 0
            var listadet2 = listadet.map((dat, index4) => {

                for (let i = 0; i < dat.length; i++) {
                    const element = dat[i];
                    if (element.codigocon == Detalle[fila].codigocon) {
                        encontre = true
                        indexencontre = index4
                        i = dat.length + 123
                    }
                }
                return dat


            })
            if (listadet.length == 0) {
                listadet.push([])
                listadet[0].push(det)
            } else {
                if (encontre) {
                    var hayenblanco = listadet[indexencontre].filter(e => e.codigocon == '')
                    if (hayenblanco.length == 0) {
                        listadet[indexencontre].push(det)

                    }
                } else {
                    var blanc = []
                    blanc.push(det)
                    listadet.push(blanc)

                }

            }
            setDetalledetalle(listadet)
        }

    }
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de PlantillaEncModels
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataPlantillaEncModels" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerPlantillaEncModels(document.getElementById('buscardataPlantillaEncModels').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalPlantillaEncModel(!verModalPlantillaEncModel)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={PlantillaEncModels}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '100%' }} isOpen={verModalPlantillaEncModel}>
                <ModalHeader>
                    Plantilla PYG
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={PlantillaEncModel.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Tipo Informe*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipoinforme" value={PlantillaEncModel.tipoinforme}>
                                            <option value="estadoderesultados">Estado de Resultados</option>
                                            <option value="flujodeefectivo">Flujo de Efectivo</option>
                                            <option value="cambioenlasituacionfinanciera">Cambio en la Situacion Financiera</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={PlantillaEncModel.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Titulo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="titulo" value={PlantillaEncModel.titulo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Comentario*</Label>
                                        <Input type="textarea" bsSize="sm" onChange={handleChange} name="comentario" value={PlantillaEncModel.comentario} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6}>
                                    <Table striped  >
                                        <thead>
                                            <tr>
                                                <th >Orden</th>
                                                <th>Codigo</th>
                                                <th >Descripcion</th>
                                                <th >Descripcion totales</th>
                                                <th >Totales</th>
                                                {/* <th >Opcion</th>
                                                <th >Indicador</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Detalle.length < 1) ? (
                                                    <tr>
                                                    </tr>
                                                ) :
                                                    (
                                                        Detalle.map((item, index) => (
                                                            <tr key={(index + 1)}>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' onChange={(e) => {
                                                                            handlerchangedetallep(e, index)
                                                                        }} ultimo={false}
                                                                            value={Detalle[index].indicador} style={{ fontSize: '12px', width: '30px' }} onClick={() => { setfila(index) }} tabla={'vendedorcomisionesrecaudo'} name="indicador" />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input onClick={() => { setfila(index) }} type="select" onChange={(e) => {
                                                                            handlerchangedetalleplantilla(e, index)
                                                                        }} style={{ fontSize: '12px', width: '100px' }} name="codigocon" value={Detalle[index].codigocon}  >
                                                                            <option value=''></option>
                                                                            {

                                                                                Conplantillainformes.map((item3, index3) => (
                                                                                    <option value={item3.codigo} detallefila={item3.descripcionfila} detalletotal={item3.descripciontotales}>{item3.codigo + "-" + item3.descripcionfila}</option>

                                                                                ))
                                                                            }
                                                                        </Input>

                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' onChange={(e) => {
                                                                            // handlerchangedetalleplantilla(e, index)
                                                                        }} ultimo={false}
                                                                            value={Detalle[index].descripcionfila} style={{ fontSize: '12px', width: '200px' }} onClick={() => { setfila(index) }} tabla={'vendedorcomisionesrecaudo'} name="descripcionfila" />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' onChange={(e) => {
                                                                            // handlerchangedetalleplantilla(e, index)
                                                                        }} ultimo={false} style={{ fontSize: '12px', width: '200px' }} value={Detalle[index].descripciontotales} onClick={() => { setfila(index) }} tabla={'vendedorcomisionesrecaudo'} name="descripciontotales" />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='text' onChange={(e) => {
                                                                            if (Detalle[index].descripcionfila.trim() == "" && Detalle[index].codigocon != "") {
                                                                                handlerchangedetallep(e, index)
                                                                            }
                                                                        }} ultimo={false} style={{ fontSize: '12px', width: '150px' }} value={Detalle[index].codigostotales} onClick={() => { setfila(index) }} tabla={'vendedorcomisionesrecaudo'} name="codigostotales" />
                                                                    </FormGroup>
                                                                </td>

                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>

                                </Col>
                                <Col sm={6}>
                                    <Table striped style={{ marginLeft: '10px' }} >
                                        <thead>
                                            <tr>
                                                <th>C.Inicial</th>
                                                <th >C.Final</th>
                                                <th >Descripcion</th>
                                                <th >C. Nat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Detalledetalle.length < 1) ? (
                                                    <tr>
                                                    </tr>
                                                ) :
                                                    (
                                                        Detalledetalle.map((item, index) => {
                                                            var devuelto = []
                                                            item.map((element, index3) => {
                                                                try {
                                                                    if (element.codigocon == Detalle[fila].codigocon) {
                                                                        var unosolo = <tr key={(index + 1)}>
                                                                            <td >
                                                                                <FormGroup>
                                                                                    <Input bsSize="sm" type='text'
                                                                                        onChange={(e) => {
                                                                                            handlerchangedetalledetallep(e, index, index3)
                                                                                        }} ultimo={false}
                                                                                        value={element.cuentainicial} style={{ fontSize: '12px' }} onClick={() => { setfiladetalle(index) }} tabla={'vendedorcomisionesrecaudo'} name="cuentainicial" />
                                                                                </FormGroup>
                                                                            </td>
                                                                            <td >
                                                                                <FormGroup>
                                                                                    <Input bsSize="sm" type='text' onChange={(e) => {
                                                                                        handlerchangedetalledetallep(e, index, index3)
                                                                                    }} ultimo={false}
                                                                                        value={element.cuentafinal} style={{ fontSize: '12px' }} onClick={() => { setfiladetalle(index) }} tabla={'vendedorcomisionesrecaudo'} name="cuentafinal" />

                                                                                </FormGroup>
                                                                            </td>
                                                                            <td >
                                                                                <FormGroup>
                                                                                    <Input bsSize="sm" type='text' onChange={(e) => {
                                                                                        handlerchangedetalledetallep(e, index, index3)
                                                                                    }} ultimo={false}
                                                                                        value={element.descripcion} style={{ fontSize: '12px' }} onClick={() => { setfiladetalle(index) }} tabla={'vendedorcomisionesrecaudo'} name="descripcion" />
                                                                                </FormGroup>
                                                                            </td>
                                                                            <td >
                                                                                <FormGroup>
                                                                                    <Input bsSize="sm" type='text' onChange={(e) => {
                                                                                        handlerchangedetalledetallep(e, index, index3)
                                                                                    }} ultimo={false}
                                                                                        value={element.cambianaturaleza} style={{ fontSize: '12px' }} onClick={() => { setfiladetalle(index) }} tabla={'vendedorcomisionesrecaudo'} name="cambianaturaleza" />
                                                                                </FormGroup>
                                                                            </td>
                                                                            <td >
                                                                                <Col sm={3}>
                                                                                    <a href="#" onClick={(e) => { eliminarfiladetalledetalle(index, index3) }} className={"badge badge-info p-2"}>{"Eliminar"}</a>
                                                                                </Col>
                                                                            </td>
                                                                        </tr>
                                                                        devuelto.push(unosolo)
                                                                    }
                                                                } catch (error) {

                                                                }
                                                            })

                                                            return devuelto

                                                        })
                                                    )


                                            }
                                        </tbody>
                                    </Table>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <a href="#" onClick={(e) => { agregardetalle() }} className={"badge badge-info p-2"}>{"Agregar Detalle"}</a>
                                </Col>
                                <Col sm={4}>
                                </Col>
                                <Col sm={3}>
                                    <a href="#" onClick={(e) => { agregardetalledetalle() }} className={"badge badge-info p-2"}>{"Agregar Cuentas"}</a>
                                </Col>
                            </Row>
                            <Row>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina1*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina1" value={PlantillaEncModel.piepagina1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina2*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina2" value={PlantillaEncModel.piepagina2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina3*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina3" value={PlantillaEncModel.piepagina3} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina4*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina4" value={PlantillaEncModel.piepagina4} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina5*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina5" value={PlantillaEncModel.piepagina5} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina6*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina6" value={PlantillaEncModel.piepagina6} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina7*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina7" value={PlantillaEncModel.piepagina7} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina8*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina8" value={PlantillaEncModel.piepagina8} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina9*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina9" value={PlantillaEncModel.piepagina9} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina10*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina10" value={PlantillaEncModel.piepagina10} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina11*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina11" value={PlantillaEncModel.piepagina11} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>piepagina12*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="piepagina12" value={PlantillaEncModel.piepagina12} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalPlantillaEncModel}>Cerrar</Button>
                </ModalFooter>
                <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abriricuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabriricuentas(ab) }} seleccionar={() => {

                }} />
            </Modal>

        </>
    )
}
export default PlantillaEncModel;
