import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import Tree from "../componentes/Treecuentas";
import * as XLSX from "xlsx"

const modeloPlandecuentas = {
    codcuenta: "",
    descripcion: "",
    subcodcuenta: "",
    subdescripcion: "",
    idanexo: "n",
    porcbase: "",
    idcentrocosto: "n",
    tipocuenta: "o",
    nivel: "",
    descripcioniif: "",
    codcuentaniif: "",
    horafecha: "",
    anticipo: ""
}
const Plandecuentas = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Plandecuentas, setPlandecuentas] = useState(modeloPlandecuentas);
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [codcuenta, setcodcuenta] = useState([]);
    const [codcuentas, setcodcuentas] = useState([]);
    const [descripcion, setdescripcion] = useState([]);
    const [descripcions, setdescripcions] = useState([]);
    const [subcodcuenta, setsubcodcuenta] = useState([]);
    const [subcodcuentas, setsubcodcuentas] = useState([]);
    const [subdescripcion, setsubdescripcion] = useState([]);
    const [subdescripcions, setsubdescripcions] = useState([]);
    const [idanexo, setidanexo] = useState([]);
    const [idanexos, setidanexos] = useState([]);
    const [porcbase, setporcbase] = useState([]);
    const [porcbases, setporcbases] = useState([]);
    const [idcentrocosto, setidcentrocosto] = useState([]);
    const [idcentrocostos, setidcentrocostos] = useState([]);
    const [tipocuenta, settipocuenta] = useState([]);
    const [tipocuentas, settipocuentas] = useState([]);
    const [nivel, setnivel] = useState([]);
    const [nivels, setnivels] = useState([]);
    const [descripcioniif, setdescripcioniif] = useState([]);
    const [descripcioniifs, setdescripcioniifs] = useState([]);
    const [codcuentaniif, setcodcuentaniif] = useState([]);
    const [codcuentaniifs, setcodcuentaniifs] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalPlandecuentas, setVerModalPlandecuentas] = useState(false);
    const [treee, settreee] = useState([]);
    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(Plandecuentass);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte.xlsx")
    }
    const seleccionardatoarbol = (e) => {
        seteditar(true)
        let value = e
        console.log(e)
        var _Ctcentrocosto = { ...Plandecuentas }
        _Ctcentrocosto['codcuenta'] = value.key
        _Ctcentrocosto['descripcion'] = value.label
        _Ctcentrocosto['codcuentaniif'] = value.codcuentaniif
        _Ctcentrocosto['descripcioniif'] = value.descripcioniif
        _Ctcentrocosto['horafecha'] = value.horafecha
        _Ctcentrocosto['idanexo'] = value.idanexo
        _Ctcentrocosto['idcentrocosto'] = value.idcentrocosto
        _Ctcentrocosto['anticipo'] = value.anticipo

        _Ctcentrocosto['nivel'] = value.nivel
        _Ctcentrocosto['porcbase'] = value.porcbase
        _Ctcentrocosto['tipocuenta'] = value.tipocuenta

        _Ctcentrocosto['subcodcuenta'] = value.padre
        _Ctcentrocosto['subdescripcion'] = value.nompadre

        setPlandecuentas(_Ctcentrocosto)
        setRoles([])


    }
    const crerarbol = (tabla, subcodcuenta) => {
        var devuelto = []
        var padres = tabla.filter((dato) => {
            if (dato.subcodcuenta == subcodcuenta) { return dato }

        })
        if (padres.length == 0) {

            return []
        } else {
            padres.forEach(dato => {
                devuelto.push({
                    key: dato.codcuenta,
                    padre: dato.subcodcuenta,
                    nompadre: dato.subdescripcion,
                    label: dato.descripcion,
                    icon: "fa fa-plus",
                    title: dato.descripcion,
                    children: crerarbol(tabla, dato.codcuenta),
                    codcuentaniif: dato.codcuentaniif,
                    descripcioniif: dato.descripcioniif,
                    anticipo: dato.anticipo,

                    horafecha: dato.horafecha,
                    idanexo: dato.idanexo,
                    idcentrocosto: dato.idcentrocosto,
                    nivel: dato.nivel,
                    porcbase: dato.porcbase,
                    tipocuenta: dato.tipocuenta
                })
            });
        }
        return devuelto
    }
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Plandecuentas = { ...Plandecuentas }
        _Plandecuentas[e.target.name] = value
        if (e.target.name == "codcuenta") {
            _Plandecuentas["codcuentaniif"] = value

        }
        if (e.target.name == "descripcion") {
            _Plandecuentas["descripcioniif"] = value

        }
        setPlandecuentas(_Plandecuentas)
        setRoles([])


    }
    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerPlandecuentass('');
    }, [])
    const columns = [
        {
            name: 'Cuenta',
            selector: row => row.codcuenta,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: 'Cuenta a la que pertenece',
            selector: row => row.subcodcuenta,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.subdescripcion,
            sortable: true,
        },
        {
            name: 'Tipo de Cuenta',
            selector: row => row.tipocuenta == "o" ? "Otros" : row.tipocuenta == "b" ? "Banco" : row.tipocuenta == "a" ? "Acreedor" : "",
            sortable: true,
        },
        {
            name: 'Tercero',
            selector: row => row.idanexo == "n" ? "No Maneja Tercero" : row.idanexo == "s" ? "Si Maneja Tercero" : row.idanexo == "b" ? "Base y Tercero" : "",
            sortable: true,
        },
        {
            name: 'Base',
            selector: row => row.porcbase,
            sortable: true,
        },
        {
            name: 'Centro de Costo',
            selector: row => row.idcentrocosto == "s" ? "Si Maneja Centro de Costo" : "No Maneja Centro de Costo",
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => {
                            abrirEditarModalPlandecuentas(row)
                            var treecreado = crerarbol(Plandecuentass, "")
                            settreee(treecreado)
                        }}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalPlandecuentas = (data) => {
        var enviodata = { ...data }
        var _Plandecuentas = {
            codcuenta: enviodata.codcuenta,
            descripcion: enviodata.descripcion,
            subcodcuenta: enviodata.subcodcuenta,
            subdescripcion: enviodata.subdescripcion,
            idanexo: enviodata.idanexo,
            porcbase: enviodata.porcbase,
            idcentrocosto: enviodata.idcentrocosto,
            tipocuenta: enviodata.tipocuenta,
            nivel: enviodata.nivel,
            descripcioniif: enviodata.descripcioniif,
            anticipo: enviodata.anticipo,

            codcuentaniif: enviodata.codcuentaniif,
            horafecha: enviodata.horafecha,
        }
        setPlandecuentas(_Plandecuentas);
        setVerModalPlandecuentas(true);
        seteditar(true)
    }

    const abrirEditarModalPlandecuentasconcuenta = (data) => {
        var enviodata = { ...data }
        var _Plandecuentas = {
            codcuenta: enviodata.codcuenta,
            descripcion: modeloPlandecuentas.descripcion,
            subcodcuenta: enviodata.codcuenta,
            subdescripcion: enviodata.descripcion,
            idanexo: modeloPlandecuentas.idanexo,
            porcbase: modeloPlandecuentas.porcbase,
            idcentrocosto: modeloPlandecuentas.idcentrocosto,
            tipocuenta: modeloPlandecuentas.tipocuenta,
            nivel: modeloPlandecuentas.nivel,
            descripcioniif: modeloPlandecuentas.descripcioniif,
            anticipo: modeloPlandecuentas.anticipo,

            codcuentaniif: modeloPlandecuentas.codcuentaniif,
            horafecha: modeloPlandecuentas.horafecha,
        }
        setPlandecuentas(_Plandecuentas);
        seteditar(false)
    }
    const cerrarModalPlandecuentas = () => {
        setPlandecuentas(modeloPlandecuentas)
        setVerModalPlandecuentas(!verModalPlandecuentas);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Plandecuentas) => {

        let claves = Object.keys(_Plandecuentas);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Plandecuentas[clave] = Number(_Plandecuentas[clave])
            }
        }
        return _Plandecuentas
    }
    const verificar = (_Plandecuentas) => {

        let claves = Object.keys(_Plandecuentas);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codcuenta' || clave == 'descripcion' || clave == 'subcodcuenta' || clave == 'subdescripcion' || clave == 'idanexo' || clave == 'idcentrocosto' || clave == 'tipocuenta') {
                if (_Plandecuentas[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const cuentacontableverificada = (cuenta, regla) => {
        var niveldesc = false
        var reglalongitud = regla.split('.');
        var longitudes = []
        reglalongitud.forEach(element => {
            longitudes.push(element.length)
        });
        var longitud = 0;
        longitudes.forEach(element => {
            longitud = longitud + element
            if (cuenta.length == longitud) {
                niveldesc = true
            }
        });
        return niveldesc;
    }
    const verificarpapa = (cuenta, papa, regla) => {
        var niveldesc = false
        var reglalongitud = regla.split('.');
        var longitudes = []
        reglalongitud.forEach(element => {
            longitudes.push(element.length)
        });
        var longitud = 0;
        var nivelpapa = 0;
        for (let i = 0; i < longitudes.length; i++) {
            const element = longitudes[i];
            longitud = longitud + element
            if (papa.length == longitud) {
                nivelpapa = i;
            }
        }
        if (nivelpapa >= longitudes.length) {
            return false
        } else {
            longitud = 0;
            for (let i = 0; i <= nivelpapa + 1; i++) {
                const element = longitudes[i];
                longitud = longitud + element

            }
            if (cuenta.length == longitud) {
                return true
            } else {
                return false
            }
        }


        return niveldesc;
    }
    const guardarCambios = async () => {
        let response;
        console.log(Plandecuentas)
        var _Plandecuentas = { ...Plandecuentas }
        _Plandecuentas['porcbase'] = Number(_Plandecuentas['porcbase'])



        var niveldescuadrado = false
        var papanoes = false
        var emp = usuario.empresa;
        var rule = emp.empresa.identificador;
        niveldescuadrado = cuentacontableverificada(_Plandecuentas.codcuenta, rule)
        papanoes = verificarpapa(_Plandecuentas.codcuenta, _Plandecuentas.subcodcuenta, rule)
        if (!niveldescuadrado) {
            Swal.fire(
                'Oops!',
                'Esta cuenta no cumple con los niveles especificados en la creacion de la compañia',
                'error'
            )
            return
        }
        if (!papanoes) {
            Swal.fire(
                'Oops!',
                'Esta cuenta no pertenece a este Sub Nivel',
                'error'
            )
            return
        }
        _Plandecuentas = asignarceros(_Plandecuentas)
        var _verificado = verificar(_Plandecuentas)
        if (_verificado) {
            console.log(_Plandecuentas)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Plandecuentasapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Plandecuentas)
                })

            } else {
                response = await fetch("" + props.servidor + "/Plandecuentasapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Plandecuentas)
                })
            }

            if (response.ok) {
                await obtenerPlandecuentass('');
                setPlandecuentas(modeloPlandecuentas)
                setVerModalPlandecuentas(!verModalPlandecuentas);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Plandecuentas fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Plandecuentas',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Plandecuentass
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataPlandecuentass" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerPlandecuentass(document.getElementById('buscardataPlandecuentass').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalPlandecuentas(!verModalPlandecuentas)
                                seteditar(false)
                                var treecreado = crerarbol(Plandecuentass, "")
                                settreee(treecreado)
                            }}>Nuevo</Button>
                            <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcel}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Plandecuentass}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalPlandecuentas} style={{ maxWidth: '1600px', width: '90%' }} >
                <ModalHeader>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" style={{ backgroundColor: 'maroon' }} onClick={() => {
                        cerrarModalPlandecuentas()
                        setVerModalPlandecuentas(true)
                        seteditar(false)
                        var treecreado = crerarbol(Plandecuentass, "")
                        settreee(treecreado)
                    }}>Nuevo</Button>
                    <Button size="sm" style={{ backgroundColor: 'slateblue' }} onClick={() => {
                        abrirEditarModalPlandecuentasconcuenta(Plandecuentas)
                        var treecreado = crerarbol(Plandecuentass, "")
                        settreee(treecreado)
                    }}>Agregar Subcuenta</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalPlandecuentas}>Cerrar</Button>

                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Cuenta*</Label>
                                        <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="codcuenta" value={Plandecuentas.codcuenta} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descripcion" value={Plandecuentas.descripcion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Cuenta a la que pertenece*</Label>
                                        <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="subcodcuenta" value={Plandecuentas.subcodcuenta} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="subdescripcion" value={Plandecuentas.subdescripcion} />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Tercero*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="idanexo" value={Plandecuentas.idanexo} >
                                            <option value="n">No maneja Tercero</option>
                                            <option value="s">Si Maneja Tercero</option>
                                            <option value="b">Maneja Tercero y Base</option>
                                        </Input>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Base*</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="porcbase" value={Plandecuentas.porcbase} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Centro de Costo*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="idcentrocosto" value={Plandecuentas.idcentrocosto} >
                                            <option value="n">No maneja Centro de Costo</option>
                                            <option value="s">Si maneja Centro de Costo</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Tipo de Cuenta*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipocuenta" value={Plandecuentas.tipocuenta} >
                                            <option value="b">Banco</option>
                                            <option value="a">Acreedor</option>
                                            <option value="o">Otro</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Descripcion Niff*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descripcioniif" value={Plandecuentas.descripcioniif} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Anticipo</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="anticipo" value={Plandecuentas.anticipo} >
                                            <option value=""></option>
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col sm={12} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                <p>Plan de Cuentas</p>

                            </Col>
                            <Row className="table-responsive" style={{ height: '400px' }} >
                                <Tree cambio={(dato) => { seleccionardatoarbol(dato) }} data={treee} />
                            </Row>
                        </Tab>
                    </ Tabs >
                </ModalBody>
            </Modal>

        </>
    )
}
export default Plandecuentas;
