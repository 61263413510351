import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import { Autocomplete, TextField } from '@mui/material';
const modelogrilla = {
    codigo: "",
    descripcion: "",
    comprob: "",
    tipodoc: "",
    nombre: "",
    cuenta: "",
    resdet: "",
    dbcr: "",
    transfer: "",
    ccosto: "",
    iva: "",
    tipoitem: "",
    transfer2: "",
    codter: "",
    porc: "",
    tipomod: "1",
    tipodocd: "",
    horafecha: "",
    retefte: "",
    ica: "",
    reteiva: "",
    ccostop: "",
}
const modeloModeloContable = {
    codigo: "",
    descripcion: "",
    contobli: "no",
    comprob: "",
    tipodoc: "",
    nombre: "",
    cuenta: "",
    resdet: "",
    dbcr: "",
    transfer: "",
    ccosto: "",
    iva: "",
    tipoitem: "",
    transfer2: "",
    codter: "",
    porc: "",
    tipomod: "1",
    tipodocd: "",
    horafecha: "",
    retefte: "",
    ica: "",
    reteiva: "",
    ccostop: "",
    modeloModeloContable: [{
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "NETO",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "BRUTO",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "IVA",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "DESCUENTO",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "CONSUMO",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "RETEFTE",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "RETEICA",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "RETEIVA",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "DCTO PIE FACT",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "FLETE",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "AUTORRENTA-DB",// ANTES CREE DB
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "AUTORRENTA-CR",// ANTES CREE DB
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "COSTO",// ANTES CREE DB
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "INVENTARIO",// ANTES CREE DB
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }, {
        codigo: "",
        descripcion: "",
        comprob: "",
        tipodoc: "",
        nombre: "PROPINA",
        cuenta: "",
        resdet: "",
        dbcr: "debito",
        transfer: "nomanejatercero",
        ccosto: "",
        iva: "",
        tipoitem: "",
        transfer2: "no",
        modcont: "no",
        codter: "",
        porc: "",
        tipomod: "1",
        tipodocd: "",
        horafecha: "",
        retefte: "",
        ica: "",
        reteiva: "",
        ccostop: "",
        transfercont: "no",
        detalle: "nombretercero",
    }]
}
const ModeloContable = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [ModeloContable, setModeloContable] = useState(modeloModeloContable);
    const [ModeloContables, setModeloContables] = useState([]);
    const [ModeloContablesDetalle, setModeloContablesDetalle] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [descripcion, setdescripcion] = useState([]);
    const [descripcions, setdescripcions] = useState([]);
    const [comprob, setcomprob] = useState([]);
    const [comprobs, setcomprobs] = useState([]);
    const [tipodoc, settipodoc] = useState([]);
    const [tipodocs, settipodocs] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [cuenta, setcuenta] = useState([]);
    const [cuentas, setcuentas] = useState([]);
    const [resdet, setresdet] = useState([]);
    const [resdets, setresdets] = useState([]);
    const [dbcr, setdbcr] = useState([]);
    const [dbcrs, setdbcrs] = useState([]);
    const [transfer, settransfer] = useState([]);
    const [transfers, settransfers] = useState([]);
    const [ccosto, setccosto] = useState([]);
    const [ccostos, setccostos] = useState([]);
    const [iva, setiva] = useState([]);
    const [ivas, setivas] = useState([]);
    const [tipoitem, settipoitem] = useState([]);
    const [tipoitems, settipoitems] = useState([]);
    const [transfer2, settransfer2] = useState([]);
    const [transfer2s, settransfer2s] = useState([]);
    const [codter, setcodter] = useState([]);
    const [codters, setcodters] = useState([]);
    const [porc, setporc] = useState([]);
    const [porcs, setporcs] = useState([]);
    const [tipomod, settipomod] = useState([]);
    const [tipomods, settipomods] = useState([]);
    const [tipodocd, settipodocd] = useState([]);
    const [tipodocds, settipodocds] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [retefte, setretefte] = useState([]);
    const [reteftes, setreteftes] = useState([]);
    const [ica, setica] = useState([]);
    const [icas, seticas] = useState([]);
    const [reteiva, setreteiva] = useState([]);
    const [reteivas, setreteivas] = useState([]);
    const [ccostop, setccostop] = useState([]);
    const [ccostops, setccostops] = useState([]);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalModeloContable, setVerModalModeloContable] = useState(false);
    const [busquedacaclientes, setbusquedacaclientes] = useState([]);
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [Ctcentrocostos, setCtcentrocostos] = useState([]);
    const [Ctcomprobantess, setCtcomprobantess] = useState([]);

    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _ModeloContable = { ...ModeloContable }
        _ModeloContable[e.target.name] = value
        setModeloContable(_ModeloContable)
        setRoles([])


    }

    const obtenerModeloContables = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setModeloContables(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerModeloContablesDetalado = async (codigo) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ModeloContableapi/unico/' + codigo + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerCtcentrocostos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcentrocostos(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerCtcomprobantess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcomprobantesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcomprobantess(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerModeloContables('');
        obtenerPlandecuentass('');
        obtenerCtcentrocostos('');
        obtenerCtcomprobantess('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalModeloContable(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalModeloContable = async (data) => {
        var enviodata = { ...data }
        var modelo = { ...modeloModeloContable }

        var grillamodelocontable = modeloModeloContable.modeloModeloContable
        delete modelo.modeloModeloContable;

        modelo = {
            codigo: enviodata.codigo,
            descripcion: enviodata.descripcion,
            comprob: enviodata.comprob,
            tipodoc: enviodata.tipodoc,
            nombre: "",
            cuenta: "",
            resdet: "",
            dbcr: "",
            transfer: enviodata.transfer ? enviodata.transfer : "no",//transfiere a contabilidad en tipo de documento, en la tabla de modelo contable es tranfercont
            ccosto: "",
            iva: "",
            tipoitem: "",
            transfer2: enviodata.transfer2 ? enviodata.transfer2 : "no",//transfiere a archivo plano
            codter: "",
            porc: "",
            tipomod: "1",
            tipodocd: "",
            horafecha: "",
            retefte: "",
            ica: "",
            reteiva: "",
            ccostop: "",
            modcont: enviodata.modcont ? enviodata.modcont : "no",//permite modificar
            contobli: enviodata.contobli ? enviodata.contobli : "no",//permite modificar
        }

        var detallemodelocontable = await obtenerModeloContablesDetalado(enviodata.codigo)

        grillamodelocontable = grillamodelocontable.map((item, index) => {
            var encontre = false
            for (let i = 0; i < detallemodelocontable.length; i++) {
                const element = detallemodelocontable[i];
                if (item.nombre == element.nombre) {
                    encontre = true
                    i = detallemodelocontable.length + 123
                    return element
                }
            }
            if (!encontre) {
                return item
            }

        })

        modelo.modeloModeloContable = grillamodelocontable

        setModeloContable(modelo);
        setVerModalModeloContable(!verModalModeloContable);
        seteditar(true)
    }
    const cerrarModalModeloContable = () => {
        setModeloContable(modeloModeloContable)
        setVerModalModeloContable(!verModalModeloContable);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_ModeloContable) => {

        let claves = Object.keys(_ModeloContable);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _ModeloContable[clave] = Number(_ModeloContable[clave])
            }
        }
        return _ModeloContable
    }
    const verificar = (_ModeloContable) => {

        let claves = Object.keys(_ModeloContable);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            // if (clave == 'codigo' || clave == 'descripcion' || clave == 'comprob' || clave == 'tipodoc' || clave == 'nombre' || clave == 'cuenta' || clave == 'resdet' || clave == 'dbcr' || clave == 'transfer' || clave == 'ccosto' || clave == 'iva' || clave == 'tipoitem' || clave == 'transfer2' || clave == 'codter' || clave == 'porc' || clave == 'tipomod' || clave == 'tipodocd' || clave == 'horafecha' || clave == 'retefte' || clave == 'ica' || clave == 'reteiva' || clave == 'ccostop') {
            if (false) {
                if (_ModeloContable[clave] == '') {
                    return false
                }
            }
        }
        return true
    }

    const handleChangegrillafaencmovi = async (event, value, name, codi, fila) => {

        var _Movimiento = { ...ModeloContable }
        var subtabla = "modeloModeloContable"
        if (event.target.name != undefined) {
            name = event.target.name
        }
        var vll = ''
        if (value) {
            vll = value[codi]
        }
        var _datos = { ...ModeloContable }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [name]: vll
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento[subtabla] = _enviodatos

        setModeloContable(_Movimiento)

    }

    const handleChangegrillafaencmovi2 = async (e, fila) => {
        if (e.target.name == 'porc') {
            var fdf = Number(e.target.value)
            if (isNaN(Number(e.target.value))) {
                Swal.fire(
                    'Opps!',
                    'Solo se aceptan Numeros',
                    'error'
                )
                return

            }
        }
        var _Movimiento = { ...ModeloContable }
        var subtabla = "modeloModeloContable"

        var _datos = { ...ModeloContable }[subtabla]
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [e.target.name]: e.target.value
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento[subtabla] = _enviodatos

        setModeloContable(_Movimiento)
        //transfer es para transferir tercero no para contabilidad, hay q crear una columna para contabilidad en modelo contable
    }

    const handleOpen = async (e) => {
        if (e) {
            var value = e.target.value
            if (value) {
                if (value.length >= 3) {
                    var clientes = await buscarcoincidenciasterceros(value)
                    setbusquedacaclientes(clientes);
                }
            }
        }
    };

    const buscarcoincidenciasterceros = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }

    const utilizarplantilla = async () => {

        var _ModeloContable = { ...ModeloContable }
        
    }

    const guardarCambios = async () => {
        let response;
        console.log(ModeloContable)
        var enviomodelocontable = {

        }
        var _ModeloContable = { ...ModeloContable }
        enviomodelocontable.modeloscontables = ModeloContable.modeloModeloContable
        delete _ModeloContable.modeloModeloContable;
        enviomodelocontable.fatipodoc = _ModeloContable
        _ModeloContable = asignarceros(_ModeloContable)
        var _verificado = verificar(_ModeloContable)
        enviomodelocontable.modeloscontables = enviomodelocontable.modeloscontables.map((dato) => {
            var dat = {
                ...dato,
                ['codigo']: enviomodelocontable.fatipodoc.codigo,
                ['descripcion']: enviomodelocontable.fatipodoc.descripcion,
                ['comprob']: enviomodelocontable.fatipodoc.comprob,
                ['transfercont']: enviomodelocontable.fatipodoc.transfer,
                ['transfer2']: enviomodelocontable.fatipodoc.transfer2,
                ['modcont']: enviomodelocontable.fatipodoc.modcont,
                ['porc']: Number(dato.porc + "")
            }
            return dat
        })
        if (_verificado) {
            console.log(_ModeloContable)
            response = await fetch("" + props.servidor + "/ModeloContableapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(enviomodelocontable)
            })

            if (response.ok) {
                await obtenerModeloContables('');
                setModeloContable(modeloModeloContable)
                setVerModalModeloContable(!verModalModeloContable);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El ModeloContable fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el ModeloContable',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de ModeloContables
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataModeloContables" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerModeloContables(document.getElementById('buscardataModeloContables').value)}>Buscar</Button>
                            {/* <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalModeloContable(!verModalModeloContable)
                                seteditar(false)
                            }}>Nuevo</Button> */}
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={ModeloContables}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '90%' }} isOpen={verModalModeloContable}>

                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >

                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >codigo*</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="codigo" value={ModeloContable.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >descripcion*</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="descripcion" value={ModeloContable.descripcion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Ti. Documento</Label>
                                        <Input disabled type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tipodoc" value={ModeloContable.tipodoc}  >
                                            <option value="factura">Factura</option>
                                            <option value="compra">Compra</option>
                                            <option value="recibo de caja">Recibo de Caja</option>
                                            <option value="otros">Otros</option>
                                            <option value="remision">Remision</option>
                                            <option value="translado">Translado</option>
                                            <option value="cotizacion">Cotizacion</option>
                                            <option value="bancos">Bancos</option>
                                            <option value="causaciones">Causaciones</option>
                                            <option value="devoluciones">Devoluciones</option>
                                            <option value="contabilidad">Contabilidad</option>
                                            <option value="transladodebancos">Translado de Bancos</option>
                                            <option value="produccion">Produccion</option>
                                            <option value="pedidos">Pedidos</option>
                                            <option value="ordendecompra">Orden de Compra</option>
                                            <option value="bonos">Bonos</option>
                                            <option value="activosfijos">Activos Fijos</option>
                                            <option value="anticipoaproveedores">Anticipo a Proveedores</option>
                                            <option value="radian">Radian</option>
                                        </Input> </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Comprobante</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="comprob" value={ModeloContable.comprob}  >
                                            <option value="">--</option>
                                            {Ctcomprobantess.map((dato) => {
                                                return <option value={dato.codigo}>{dato.nombre}</option>
                                            })}

                                        </Input> </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Transfiere a Contabilidad</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="transfer" value={ModeloContable.transfer}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Permite Modiicar</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="modcont" value={ModeloContable.modcont}  >
                                            <option value="no">No</option>
                                            <option value="contabilidad">Contabilidad</option>
                                            <option value="tesoreria">Tesoreria</option>
                                            <option value="contabilidad-tesoreria">Contabilidad-Tesoreria</option>
                                        </Input> </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Contabilizacion Cuadrada</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="contobli" value={ModeloContable.contobli}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Archivo Plano</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="transfer2" value={ModeloContable.transfer2}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '400px', zoom: '70%' }}>
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Cuenta</th>
                                                <th>DB-CR</th>
                                                <th>Transfiere</th>
                                                <th>Detalle</th>
                                                <th>C.C</th>
                                                <th>Porcentaje</th>
                                                <th>Tercero</th>
                                                <th>Tipo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (ModeloContable.modeloModeloContable.length < 1) ? (
                                                    <tr>

                                                    </tr>
                                                ) :
                                                    (
                                                        ModeloContable.modeloModeloContable.map((item, index) => (
                                                            <tr key={(index + 1)} >


                                                                <td  >

                                                                    <Input style={{ width: '200px' }} value={item.nombre} disabled type='text' ultimo={false} name="nombre" />

                                                                </td>
                                                                <td >

                                                                    <Autocomplete
                                                                        onChange={(e, value) => { handleChangegrillafaencmovi(e, value, 'cuenta', 'codcuenta', index) }}

                                                                        disablePortal
                                                                        options={Plandecuentass}
                                                                        getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                                                        value={Plandecuentass.filter(p => p.codcuenta == item.cuenta)[0]}
                                                                        renderInput={(params) => <TextField {...params} size="small" style={{ width: '200px', borderRadius: '4px' }} label="Cuenta" />}
                                                                        maxHeight={200}
                                                                    />

                                                                </td>
                                                                <td >
                                                                    <Input style={{ width: '200px' }} value={item.dbcr} type='select' onChange={(e) => {
                                                                        handleChangegrillafaencmovi2(e, index);
                                                                    }} name="dbcr">
                                                                        <option value="debito">Debito</option>
                                                                        <option value="credito">Credito</option>
                                                                    </Input>
                                                                </td>
                                                                <td >
                                                                    <Input style={{ width: '200px' }} value={item.transfer} type='select' onChange={(e) => {
                                                                        handleChangegrillafaencmovi2(e, index);
                                                                    }} name="transfer">
                                                                        <option value="nomanejatercero">No Maneja Tercero</option>
                                                                        <option value="simanejatercero">Si Maneja Tercero</option>
                                                                        <option value="manejaterceroybase">Maneja Tercero y Base</option>
                                                                    </Input>
                                                                </td>
                                                                <td >
                                                                    <Input style={{ width: '200px' }} value={item.detalle} type='select' onChange={(e) => {
                                                                        handleChangegrillafaencmovi2(e, index);
                                                                    }} name="detalle">
                                                                        <option value="nombretercero">Nombre Tercero</option>
                                                                        <option value="tipodedocumento">Tipo de Documento</option>
                                                                        <option value="detalle">Detalle</option>
                                                                        <option value="nombredecuenta">Nombre de Cuenta</option>
                                                                    </Input>
                                                                </td>
                                                                <td >
                                                                    <Autocomplete
                                                                        onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'ccosto', 'codigo', index) }}
                                                                        disablePortal
                                                                        options={Ctcentrocostos.filter(p => p.subcodigo != '')}
                                                                        getOptionLabel={(clasi) => clasi.nombre}
                                                                        value={Ctcentrocostos.filter(p => p.codigo == item.ccosto)[0]}
                                                                        renderInput={(params) => <TextField {...params} size="small" name="ccosto" label="Centro de Costo" />}
                                                                    />
                                                                </td>
                                                                <td >
                                                                    <Input style={{ width: '200px' }} type='text' value={item.porc} ultimo={false} name="porc" onChange={(e) => {
                                                                        handleChangegrillafaencmovi2(e, index);
                                                                    }} />
                                                                </td>
                                                                <td >
                                                                    <Autocomplete
                                                                        onChange={(e, values) => { handleChangegrillafaencmovi(e, values, 'codter', 'codigo', index) }}
                                                                        name="codter"
                                                                        disablePortal
                                                                        options={busquedacaclientes}
                                                                        getOptionLabel={(clasi) => clasi.codigo + ' - ' + clasi.razsoc}
                                                                        sx={{ width: 300, height: 50, borderRadius: 4 }}
                                                                        value={busquedacaclientes.filter(p => p.codigo == item.codter)[0]}
                                                                        style={{ fontSize: 14 }}
                                                                        renderInput={(params) => <TextField {...params} size="small" name="codter" label="Tercero" />}
                                                                        onInputChange={handleOpen}
                                                                    />
                                                                </td>
                                                                <td >
                                                                    <Input style={{ width: '200px' }} type='select' value={item.tipomod} onChange={(e) => {
                                                                        handleChangegrillafaencmovi2(e, index);
                                                                    }} name="tipomod">
                                                                        <option value="1">Colgaap</option>
                                                                        <option value="7">NIIF</option>
                                                                    </Input>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )


                                            }

                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Utilizar Plantilla</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalModeloContable}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default ModeloContable;
