import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
// import ReporteVenta from './views/ReporteVenta';
import ReporteExistenciaArticulo from '../../Reportes/Inventario/ReporteExistenciaArticulos';
import ReporteVenta from '../../ReporteVenta';
import ReportesInventario from '../../Reportes/Inventario/inicio';
import ReportesMovimiento from '../../Reportes/Movimiento/inicio';
import ReportesRingCall from '../../Reportes/RingCall/inicio';
import ReportesCartera from '../../Reportes/Cartera/inicio';
import ReportesContabilidad from '../../Reportes/Contabilidad/inicio';
import ReportesInventarioDrogueria from '../../Reportes/Inventario/Drogueria/inicio';
import HistorialVenta from '../../HistorialVenta';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import '../../css/Venta.css'
import Icono from '../../images/informes/informeventas.png'
import Icono2 from '../../images/informes/informeventas_auto_x2.jpg'
import IconoCartera from '../../images/informes/cartera.jpg'
import IconoInventario from '../../images/informes/inventario.jpg'
import IconoTesoreria from '../../images/informes/tesoreria.jpg'
import IconoContabilidad from '../../images/informes/contabilidad.jpg'
import IconoNomina from '../../images/informes/nomina.jpg'
import IconoSalir from '../../images/informes/salir.jpg'
import IconoOtros from '../../images/informes/otros.jpg'

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}


const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [key, setKey] = React.useState('home');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [historialventa, sethistorialventa] = React.useState(false);
    const [reporteVenta, setreporteVenta] = React.useState(false);
    const [reporteInventario, setreporteInventario] = React.useState(false);
    const [reporteInventarioDrogueria, setreporteInventarioDrogueria] = React.useState(false);
    const [reporteringcall, setreporteringcall] = React.useState(false);

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])
    const handleClick = (reportName) => {
        // Lógica para generar el informe
        console.log(`Generando informe: ${reportName}`);
    };

    return (
        <>
            <div className="button-container">
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setreporteExistenciaArticulos(true)}}
                    style={{ backgroundColor: '#8B008B',fontSize:'12px'}}
                >
                    Existencia Articulos
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setreporteExistenciaArticulosespecial(true)}}
                    style={{ backgroundColor: '#8B008B',fontSize:'12px'}}
                >
                    Existencia Articulos Precio
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setreporteCatalogoArticulos(true)}}
                    style={{ backgroundColor: '#008000',fontSize:'12px'}}
                >
                    <span className="movie-button-text">Catalogo de Articulos</span>
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setreporteListadePrecio(true)}}
                    style={{ backgroundColor: '#008080',fontSize:'12px'}}
                >
                    <span className="movie-button-text">Lista de Precios</span>
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() =>{props.setreporteCostoMercanciaVendida(true)}}
                    style={{ backgroundColor: '#4682B4',fontSize:'12px'}}
                >
                    <span className="movie-button-text">Costo de Mercancia Vendida</span>
                </Button>
                
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setreporteLibroAuxiliar(true)}}
                    style={{ backgroundColor: '#00CED1',fontSize:'12px'}}
                >
                    <span className="movie-button-text">Libro Auxiliar</span>
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setreporteExistenciaArticulosDrogueria(true)}}
                    style={{ backgroundColor: '#00CED1',fontSize:'12px'}}
                >
                    <span className="movie-button-text">Existencia Presentacion</span>
                </Button>
                
                <Button
                    className="report-button movie-button"
                    onClick={() => {props.setinicio(false)}}
                    style={{ backgroundColor: '#000000',fontSize:'12px'}}
                >
                    <span className="movie-button-text">Salir</span>
                </Button>
            </div>
            <div className="button-container">
               
              
            </div>
        </>
    );
}

export default Reportes