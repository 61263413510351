import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import { FormatMoney } from 'format-money-js'
import { jsPDF } from "jspdf";

import Buscar from '../componentes/Buscar'
const modeloPais = {
    codigo: "",
    nombre: "",
}

const ConciliacionBancaria = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [cuentaseleccionada, setcuentaseleccionada] = useState("")
    const [abrirconciliacion, setabrirconciliacion] = useState(false)
    const [abrirconciliacionimpresion, setabrirconciliacionimpresion] = useState(false)
    const [usuario, setDataUser] = useState([]);
    const [Plandecuentass, setPlandecuentass] = useState([]);
    const [movimientosaconciliar, setmovimientosaconciliar] = useState([]);
    const [extracto, setextracto] = useState([]);
    const [pendiente, setpendiente] = useState(true);
    const [pendiente2, setpendiente2] = useState(true);
    const [pendiente3, setpendiente3] = useState(true);
    const [saldoextracto, setsaldoextracto] = useState(0);
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerPlandecuentass('')
    }, [])
    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data.filter(p => p.tipocuenta == 'b'))
                setpendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarexcell = () => {

        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliarexcel2/" + fechaInicio + "/" + fechaFin + "/-/-/-/" + cuentaseleccionada + "/-/" + rule + '/-/-/' + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Conciliacion_Bancaria.xlsx');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch((error) => {
               
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const cerrarconciliacion = async () => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ContabilidadApi/cerrarconciliacion/' + fechaFin + '?llave=' + dt.usuario.llaveempresa + '',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(extracto),
                }
            );

            if (response.ok) {
                Swal.fire(
                    'Exito!',
                    'Conciliacion Cerrada',
                    'succes'
                )
                setabrirconciliacion(false)
                setabrirconciliacionimpresion(false)
                setextracto([])
                setcuentaseleccionada("")

            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerdatosaconciliar = async (cuenta) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ContabilidadApi/buscarmovimientossinconciliar/' + cuenta + '/' + fechaFin + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setmovimientosaconciliar(data)
                setpendiente2(false)
                setcuentaseleccionada(cuenta)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const CustomOrden = ({ row }) => (
        <div>
            { }
            <div>
                <div style={{ color: 'blue', cursor: 'pointer' }}

                    onClick={async () => {
                        try {
                            setabrirconciliacion(true)
                            obtenerdatosaconciliar(row.codcuenta)

                        } catch (error) {
                            console.log(error)
                        }
                    }}
                >
                    {row.codcuenta}
                </div>
            </div>
        </div>
    );
    const CustomCheck = ({ row }) => (

        <div style={{ alignItems: 'center', marginLeft: '30px' }}>
            <div>
                <Input type="checkbox" checked={extracto.some(objeto => JSON.stringify(objeto) === JSON.stringify(row))} onChange={(e) => {
                    let value = e.target.checked
                    if (value) {
                        var _extracto = extracto.map((dat) => { return dat })
                        var yaesta = _extracto.some(objeto => JSON.stringify(objeto) === JSON.stringify(row));
                        if (!yaesta) {
                            _extracto.push(row)
                            setextracto(_extracto)
                        }
                    } else {
                        var _extracto = extracto.map((dat) => { return dat })
                        var nuevalista = _extracto.filter(objeto => JSON.stringify(objeto) !== JSON.stringify(row));
                        _extracto = nuevalista
                        setextracto(_extracto)
                    }
                }} />
            </div>
        </div>
    );
    const columns = [
        {
            name: 'Cuenta',
            selector: row => row.codcuenta,
            sortable: true,
            cell: row => <CustomOrden row={row} />,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
    ];
    const columnsmovimientos = [
        {
            name: 'Check',
            selector: row => row.tipodoc,
            sortable: true,
            cell: row => <CustomCheck row={row} />,
        },
        {
            name: 'Concepto',
            selector: row => row.tipodoc,
            sortable: true,
            cell: row => <CustomOrden row={row} />,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
        },
        {
            name: '# Documento',
            selector: row => row.ndocumento,
            sortable: true,
        },
        {
            name: 'Doc Ref',
            selector: row => row.documentoref,
            sortable: true,
        },
        {
            name: 'Valor',
            selector: row => row.valor,
            sortable: true,
        }
    ];

    const columnsextracto = [

        {
            name: 'Concepto',
            selector: row => row.tipodoc,
            sortable: true,
            cell: row => <CustomOrden row={row} />,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
        },
        {
            name: '# Documento',
            selector: row => row.ndocumento,
            sortable: true,
        },
        {
            name: 'Doc Ref',
            selector: row => row.documentoref,
            sortable: true,
        },
        {
            name: 'Valor',
            selector: row => row.valor,
            sortable: true,
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const mapearconciliacion = (tipo) => {
        var devvv = movimientosaconciliar.filter(p => p.comprobanteconciliacion === tipo)
            .filter(a => !extracto
                .some(b => b.tipodoc === a.tipodoc && b.item === a.item && b.ndocumento === a.ndocumento)).map((dato, index) => {
                    return <Row>
                        <Col sm={3} >
                            <div>
                                {dato.comprobanteconciliacion}
                            </div>

                        </Col>
                        <Col sm={3} style={{ textAlign: 'right' }}>
                            <div>
                                {dato.fecha}
                            </div>

                        </Col>
                        <Col sm={3} style={{ textAlign: 'right' }}>
                            <div>
                                {dato.ndocumento}
                            </div>

                        </Col>
                        <Col sm={3} style={{ textAlign: 'right' }}>
                            <div>
                                {fm3.from(Number(dato.valor + ""))}
                            </div>

                        </Col>
                    </Row>
                })
        if (devvv.length > 0) {
            return devvv
        } else {
            return [1].map(() => {
                return <Row>
                    <Col sm={3} >
                        <div>
                        </div>

                    </Col>
                    <Col sm={3} style={{ textAlign: 'right' }}>
                        <div>
                        </div>

                    </Col>
                    <Col sm={3} style={{ textAlign: 'right' }}>
                        <div>
                        </div>

                    </Col>
                    <Col sm={3} style={{ textAlign: 'right' }}>
                        <div>
                        </div>

                    </Col>

                </Row>
            })
        }
    }
    const sumarconci = (tipo) => {
        return movimientosaconciliar.filter(p => p.comprobanteconciliacion === tipo)
            .filter(a => !extracto
                .some(b => b.tipodoc === a.tipodoc && b.item === a.item && b.ndocumento === a.ndocumento))
            .reduce((acumulador, objeto) => acumulador + objeto.valor, 0)
    }
    const sumarsaldoextractomasconciliado = (tipo) => {
        var cont = movimientosaconciliar
            .filter(a => !extracto
                .some(b => b.tipodoc === a.tipodoc && b.item === a.item && b.ndocumento === a.ndocumento))
            .reduce((acumulador, objeto) => acumulador + objeto.valor, 0)
        var saldoextra = Number(saldoextracto + "")
        var total = saldoextra + cont
        return total
    }

    const sumartotalcontabilizado = (tipo) => {
        var cont = movimientosaconciliar
            .reduce((acumulador, objeto) => acumulador + objeto.valor, 0)
        return cont
    }
    const previsualizarpdfelectronica = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('conciliacionprueba').clientWidth
        doc.html(document.querySelector("#conciliacionprueba"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'Conciliacion.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Conciliacion
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                                <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                    selected={fechaInicio}
                                    value={fechaInicio}
                                    onChange={(e) => setFechaInicio(e.target.value)}></Input>


                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>Fecha Conciliacion:</Label>
                                <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                    selected={fechaFin}
                                    value={fechaFin}
                                    onChange={(e) => setFechaFin(e.target.value)}></Input>


                            </FormGroup>
                        </Col>
                    </Row>
                    <DataTable
                        columns={columns}
                        data={Plandecuentass}
                        progressPending={pendiente}
                        progressComponent={
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <img src="https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif" alt="Cargando..." />
                            </div>
                        }
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}

                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={abrirconciliacion}>
                <ModalHeader>
                    Movimiento
                </ModalHeader>
                <ModalBody>
                    <DataTable
                        columns={columnsmovimientos}
                        data={movimientosaconciliar}
                        progressPending={pendiente2}
                        progressComponent={
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <img src="https://c.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif" alt="Cargando..." />
                            </div>
                        }
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}

                    />
                    <DataTable
                        columns={columnsextracto}
                        data={extracto}

                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}

                    />
                </ModalBody>
                <ModalFooter>
                    <Label style={{ fontSize: '13px' }}>Saldo Extracto</Label>

                    <FormGroup>
                        <Input bsSize="sm" type="text" className="form-control form-control-sm"
                            value={saldoextracto}
                            onChange={(e) => setsaldoextracto(e.target.value)}></Input>


                    </FormGroup>
                    <Button size="sm" color="primary" onClick={() => { setabrirconciliacionimpresion(true) }}>Imprimir</Button>
                    <Button size="sm" color="primary" onClick={() => { buscarexcell() }}>Excel General</Button>
                    
                    <Button size="sm" color="primary" onClick={() => {
                        setabrirconciliacion(false)
                        setextracto([])
                        setcuentaseleccionada("")

                    }}>Salir</Button>
                </ModalFooter>

            </Modal>
            <Modal size="lg" isOpen={abrirconciliacionimpresion}>

                <ModalBody id="conciliacionprueba">
                    <Row>
                        <Col sm={12} style={{ alignItems: 'center', textAlign: 'center' }}>
                            <div>
                                {JSON.parse(user).empresa.empresa.nombre}<br />
                                {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                {"Conciliacion Bancaria"}<br />
                                {"Cuenta:" + cuentaseleccionada}<br />
                            </div>

                        </Col>
                        <Col sm={12} >
                            <Row>
                                <Col sm={6} >
                                    <div>
                                        {"SALDO EXTRACTO"}
                                    </div>

                                </Col>
                                <Col sm={6} style={{ textAlign: 'right' }}>
                                    <div>
                                        {fm3.from(Number(saldoextracto + ""))}
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>
                            {abrirconciliacionimpresion && mapearconciliacion("Pago")}
                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Menos Pagos Contabilizadas que NO aparecen en el Extracto"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumarconci("Pago"))
                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>
                            {abrirconciliacionimpresion && mapearconciliacion("Notas Credito")}
                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Menos Notas Credito Contabilizadas que NO aparecen en el Extracto"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumarconci("Notas Credito"))

                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>
                            {abrirconciliacionimpresion && mapearconciliacion("Recaudo")}
                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Mas Recaudos Contabilizadas que NO aparecen en el Extracto"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumarconci("Recaudo"))

                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>
                            {abrirconciliacionimpresion && mapearconciliacion("Notas Debito")}
                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Mas Notas Debito Contabilizadas que NO aparecen en el Extracto"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumarconci("Notas Debito"))

                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>

                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Saldo Extracto Conciliado"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumarsaldoextractomasconciliado())

                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>

                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Saldo Contabilizado"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumartotalcontabilizado())

                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12}>

                            <Row>
                                <Col sm={9} >
                                    <div>
                                        {"Diferencia"}
                                    </div>

                                </Col>

                                <Col sm={3} style={{ textAlign: 'right' }}>
                                    <div>
                                        {abrirconciliacionimpresion &&
                                            fm3.from(sumarsaldoextractomasconciliado() - sumartotalcontabilizado())


                                        }
                                    </div>

                                </Col>
                            </Row>

                        </Col>

                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        previsualizarpdfelectronica()
                    }}>PDF</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        previsualizarpdfelectronica()
                        cerrarconciliacion()

                    }}>Cerrar Conciliacion</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        setabrirconciliacionimpresion(false)
                        setabrirconciliacionimpresion(false)
                    }}>Salir</Button>
                </ModalFooter>

            </Modal>
        </>
    )
}
export default ConciliacionBancaria;
