import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../Buscar'
const modeloBcbanco = {
    codbanco: "",
    razonsocia: "",
    nitcedula: "",
    codsucursa: "",
    nomsucursa: "",
    contacto: "",
    ciudad: "",
    direccion: "",
    pais: "",
    telefono1: "",
    telefono2: "",
    fax: "",
    gerente: "",
    conseccheq: "",
    numcuentap: "",
    numcuentab: "",
    email: "",
    efsalant: "",
    efdebmes: "",
    efcremes: "",
    possalant: "",
    posdebmes: "",
    poscremes: "",
    conseccheq2: "",
    horafecha: "",
    periodo: "",
}
const Bcbanco = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Bcbanco, setBcbanco] = useState(modeloBcbanco);
    const [Bcbancos, setBcbancos] = useState([]);
    const [Bcbancos2, setBcbancos2] = useState([]);
    const [codbanco, setcodbanco] = useState([]);
    const [codbancos, setcodbancos] = useState([]);
    const [razonsocia, setrazonsocia] = useState([]);
    const [razonsocias, setrazonsocias] = useState([]);
    const [nitcedula, setnitcedula] = useState([]);
    const [nitcedulas, setnitcedulas] = useState([]);
    const [codsucursa, setcodsucursa] = useState([]);
    const [codsucursas, setcodsucursas] = useState([]);
    const [nomsucursa, setnomsucursa] = useState([]);
    const [nomsucursas, setnomsucursas] = useState([]);
    const [contacto, setcontacto] = useState([]);
    const [contactos, setcontactos] = useState([]);
    const [ciudad, setciudad] = useState([]);
    const [ciudads, setciudads] = useState([]);
    const [direccion, setdireccion] = useState([]);
    const [direccions, setdireccions] = useState([]);
    const [pais, setpais] = useState([]);
    const [paiss, setpaiss] = useState([]);
    const [telefono1, settelefono1] = useState([]);
    const [telefono1s, settelefono1s] = useState([]);
    const [telefono2, settelefono2] = useState([]);
    const [telefono2s, settelefono2s] = useState([]);
    const [fax, setfax] = useState([]);
    const [faxs, setfaxs] = useState([]);
    const [gerente, setgerente] = useState([]);
    const [gerentes, setgerentes] = useState([]);
    const [conseccheq, setconseccheq] = useState([]);
    const [conseccheqs, setconseccheqs] = useState([]);
    const [numcuentap, setnumcuentap] = useState([]);
    const [numcuentaps, setnumcuentaps] = useState([]);
    const [numcuentab, setnumcuentab] = useState([]);
    const [numcuentabs, setnumcuentabs] = useState([]);
    const [email, setemail] = useState([]);
    const [emails, setemails] = useState([]);
    const [efsalant, setefsalant] = useState([]);
    const [efsalants, setefsalants] = useState([]);
    const [efdebmes, setefdebmes] = useState([]);
    const [efdebmess, setefdebmess] = useState([]);
    const [efcremes, setefcremes] = useState([]);
    const [efcremess, setefcremess] = useState([]);
    const [possalant, setpossalant] = useState([]);
    const [possalants, setpossalants] = useState([]);
    const [posdebmes, setposdebmes] = useState([]);
    const [posdebmess, setposdebmess] = useState([]);
    const [poscremes, setposcremes] = useState([]);
    const [poscremess, setposcremess] = useState([]);
    const [conseccheq2, setconseccheq2] = useState([]);
    const [conseccheq2s, setconseccheq2s] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [periodo, setperiodo] = useState([]);
    const [periodos, setperiodos] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalBcbanco, setVerModalBcbanco] = useState(props.abrir);

    const obtenerBcbancos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Municipioapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setBcbancos(data)
                setBcbancos2(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerBcbancos('');
        setVerModalBcbanco(props.abrir)
    }, [])
    const cerrarModalBcbanco = () => {

        // setBcbanco(modeloBcbanco)
        // setVerModalBcbanco(!verModalBcbanco);
        // seteditar(false)
        // setKey('Datos')
        props.setabrirbanco(false)
    }
    const seleccionarbcbancos = async (e) => {
        props.seleccionar(e)
        // let value = e
        // var _Movimiento = { ...movimiento }
        // _Movimiento['banco'] = value.codbanco

        // setmovimiento(_Movimiento)
        // setRoles([])
        // cerrarModalBcbanco()
        // setcuentabanco(value.numcuentap)
    }

    const filtrarBancos = async (coincidencia) => {
        var _codigomuns = Bcbancos2.filter((dato) => {
            if (dato.nombremun.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.nombremun + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setBcbancos(_codigomuns)
    }
    return (
        <>
            <Buscar tituloencabezado={"Municipio"} filtrar={(coincidencia) => { filtrarBancos(coincidencia) }} busqueda={Bcbancos} encabezado={["Municipio", "Departamento"]} codigo={"nombremun"} nombre={"coddepar"} dato={""} dato2={""} verModal={props.abrir} cerrarModal={() => { cerrarModalBcbanco() }} seleccionar={(seleccionado) => {
                seleccionarbcbancos(seleccionado)
            }} />
        </>
    )
}
export default Bcbanco;
