import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'
import BuscarProductos from '../componentes/Buscar2/Productos'
import BuscarCentrodeCosto from '../componentes/Buscar2/CentrodeCosto'

const modeloBcbanco = {
    articulo: "",
    ccosto: "",
    tipo: "",
    cuenta: ""
}
const Bcbanco = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Bcbanco, setBcbanco] = useState(modeloBcbanco);
    const [Bcbancos, setBcbancos] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalBcbanco, setVerModalBcbanco] = useState(false);
    const [abriricuentas, setabriricuentas] = useState(false);
    const [abrirproducto, setabrirproducto] = useState(false);
    const [abrircentrodecosto, setabrircentrodecosto] = useState(false);


    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Bcbanco = { ...Bcbanco }
        _Bcbanco[e.target.name] = value
        setBcbanco(_Bcbanco)
        setRoles([])


    }
    const handleChange2 = (e) => {
        let value = e.codcuenta
        var _Bcbanco = { ...Bcbanco }
        _Bcbanco['articulo'] = value
        setBcbanco(_Bcbanco)
        setRoles([])


    }
    const obtenerBcbancos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ModeloContableapi/modeloarticuloccosto/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setBcbancos(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerBcbancos('');
    }, [])
    const columns = [
        {
            name: 'Articulo',
            selector: row => row.articulo,
            sortable: true,
        },
        {
            name: 'C. Costo',
            selector: row => row.ccosto,
            sortable: true,
        },
        {
            name: 'Cuenta',
            selector: row => row.cuenta,
            sortable: true,
        },
        {
            name: 'Tipo',
            selector: row => row.tipo,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalBcbanco(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalBcbanco = (data) => {
        var enviodata = { ...data }
        var _Bcbanco = {
            articulo: enviodata.articulo,
            ccosto: enviodata.ccosto,
            tipo: enviodata.tipo,
            cuenta: enviodata.cuenta
        }
        setBcbanco(_Bcbanco);
        setVerModalBcbanco(!verModalBcbanco);
        seteditar(true)
    }
    const cerrarModalBcbanco = () => {
        setBcbanco(modeloBcbanco)
        setVerModalBcbanco(!verModalBcbanco);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Bcbanco) => {

        let claves = Object.keys(_Bcbanco);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Bcbanco[clave] = Number(_Bcbanco[clave])
            }
        }
        return _Bcbanco
    }
    const verificar = (_Bcbanco) => {

        let claves = Object.keys(_Bcbanco);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'articulo' || clave == 'cuenta' || clave == 'ccosto') {
                if (_Bcbanco[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Bcbanco)
        var _Bcbanco = { ...Bcbanco }
        var _verificado = verificar(_Bcbanco)
        if (_verificado) {
            console.log(_Bcbanco)
            response = await fetch("" + props.servidor + "/ModeloContableapi/guardarmodeloarticuloccosto?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_Bcbanco)
            })



            if (response.ok) {
                await obtenerBcbancos('');
                setBcbanco(modeloBcbanco)
                setVerModalBcbanco(!verModalBcbanco);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Bcbanco fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Bcbanco',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Bancos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            {/* <div class="input-group-prepend" >

                                <input type="text" id="buscardataBcbancos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerBcbancos(document.getElementById('buscardataBcbancos').value)}>Buscar</Button>
                           */}
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalBcbanco(!verModalBcbanco)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Bcbancos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalBcbanco}>
                <ModalHeader>
                    Contable Articulo Ccosto
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Articulo*</Label>
                                        <Input type="text" onKeyDown={(e) => {
                                            if (e.key == "Enter") {
                                                setabrirproducto(true)
                                            }
                                        }} placeholder="Presione Enter Para Buscar" name="acreedor" value={Bcbanco.articulo} />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Centro de Costo*</Label>
                                        <Input type="text" onKeyDown={(e) => {
                                            if (e.key == "Enter") {
                                                setabrircentrodecosto(true)
                                            }
                                        }} placeholder="Presione Enter Para Buscar" name="acreedor" value={Bcbanco.ccosto} />

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipo" value={Bcbanco.tipo} >
                                            <option value=""></option>
                                            <option value="BRUTO">BRUTO</option>
                                            <option value="NETO">NETO</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta*</Label>
                                        <Input type="text" onKeyDown={(e) => {
                                            if (e.key == "Enter") {
                                                setabriricuentas(true)
                                            }
                                        }} placeholder="Presione Enter Para Buscar" name="acreedor" value={Bcbanco.cuenta} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                    <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abriricuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabriricuentas(ab) }} seleccionar={(e) => {
                        let value = e.codcuenta
                        var _Bcbanco = { ...Bcbanco }
                        _Bcbanco['cuenta'] = value
                        setBcbanco(_Bcbanco)
                        setRoles([])

                        setabriricuentas(false)
                    }} />
                    <BuscarProductos tituloencabezado={"Buscar Cuentas"} abrir={abrirproducto} servidor={props.servidor} setabrirbanco={(ab) => { setabrirproducto(ab) }} seleccionar={(e) => {
                        let value = e.codigo
                        var _Bcbanco = { ...Bcbanco }
                        _Bcbanco['articulo'] = value
                        setBcbanco(_Bcbanco)
                        setabrirproducto(false)
                    }} />
                    <BuscarCentrodeCosto tituloencabezado={"Buscar Cuentas"} abrir={abrircentrodecosto} servidor={props.servidor} setabrirbanco={(ab) => { setabrircentrodecosto(ab) }} seleccionar={(e) => {
                        let value = e.codigo
                        var _Bcbanco = { ...Bcbanco }
                        _Bcbanco['ccosto'] = value
                        setBcbanco(_Bcbanco)
                        setabrircentrodecosto(false)
                    }} />

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalBcbanco}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Bcbanco;
