import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import Esperando from '../componentes/Esperando'
import { Try } from "@mui/icons-material";
const modeloPais = {
    codigo: "",
    nombre: "",
}
const ImportarExportar = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [usuario, setDataUser] = useState([]);
    const [fileD, setfileD] = useState([]);
    const [esperando, setesperando] = useState(false);

    const obtenerPaiss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Paisapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        // obtenerPaiss('');
    }, [])
    const convertBlobToBase64 = async (blob) => { // blob data
        return await blobToBase64(blob);
    }
    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const recibirelemento = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const fileData = e.target.result;
            const blob = new Blob([fileData], { type: file.type });
            var base64data = ""
            base64data = await convertBlobToBase64(blob);
            setfileD(base64data)
        };

        reader.readAsArrayBuffer(file);
        // var blob = doc.output('blob')
        // var reader = new FileReader();
        // var base64data = ""
        // base64data = await convertBlobToBase64(blob);
        // console.log(base64data)
        // var pdf = {
        //     pdfbase64: base64data

        // }

    }


    const guardarCambios = async () => {
        try {

            setesperando(true)

            let response;
            response = await fetch("" + props.servidor + "/Backup/" + usuario.empresa.empresa.nit + "?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob' // Indica que esperas recibir un Blob como respuesta
            });


            if (response.ok) {
                try {
                    let data = await response.json()

                    // Decodificar la cadena Base64 a bytes
                    const zipBytes = atob(data.back).split('').map(char => char.charCodeAt(0));

                    // Crear un Blob a partir de los bytes decodificados
                    const blob = new Blob([new Uint8Array(zipBytes)], { type: 'application/zip' });

                    // Crear una URL para el Blob
                    const blobUrl = URL.createObjectURL(blob);

                    // Crear un enlace de descarga
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = 'archivo.zip'; // Nombre del archivo ZIP
                    link.click();
                    setesperando(false)

                    Swal.fire(
                        'Guardado Exitosamente!',
                        'El Pais fue guardado exitosamente',
                        'success'
                    )
                } catch (error) {
                    try {
                        var eerr = await error.json()
                        Swal.fire(
                            'Error al Guardar!',
                            error,
                            'error'
                        )
                        setesperando(false)
                    } catch (error2) {
                        Swal.fire(
                            'Error al Guardar!',
                            error2,
                            'error'
                        )
                    }

                }
            } else {
                var textoerrores = await response.text()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores,
                    'error'
                )
                setesperando(false)

            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
            setesperando(false)

        }

    }

    const guardarCambiosinventariofisico = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/EnvioApi/importarinventariofisico?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Pais fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }

    const pruebaapi = async () => {
        try {
            const modelo2 = {
                faencmovi: {
                    compañia: "",
                    concepto: "FE1",
                    ndocumento: 55,
                    fecha: "2024-02-07",
                    tercero: "cliente",
                    cliente: "1140900262",
                    nombrecli: "Miguel Julio",
                    observacion: "",
                    bruto: 1000,
                    iva: 0,
                    descuento: 0,
                    despiefac: 500,
                    retefuente: 0,
                    reteiva: 0,
                    ica: 0,
                    retefte: 0,
                    impconsumo: 0,
                    subtotal: 500,
                    total: 500,
                    fechpost: "2024-02-07",
                    fpago: "contado",
                    mpago: "transferencia",
                    sucursal: "",
                    cufe: "",
                    emitido: "no",
                    qr: "",
                },
                famovimiento: [
                    {
                        item: 1,
                        referencia: "1001",
                        descrip: ":",
                        bodega: "1",
                        cantidad: 1,
                        precio: 1000,
                        pordescuento: 0,
                        poriva: 0,
                        porimptoconsumo: 0,
                        subtotal: 1000,
                        compañia: "",
                        concepto: "FE1",
                        nrodocumento: 55,
                        costo: 0,
                        desadicional: "",

                        tercero: "cliente",
                        cliente: "1140900262",

                    }
                ],
                cacliente: {
                    tercero: "cliente",
                    clase: "13",
                    codigo: "1140900262",
                    cedula: "1140900262",
                    digito: "",
                    razsoc: "miguel julio",
                    codigomun: "001",
                    coddepar: "08",
                    codpais: "CO",
                    email: "miguelantoiojuliodiaz@gmail.com",
                    direccion: "cra 1 # 2-3",
                    regtrib: "R-99-PN",
                    resfis: "R-99",
                }

            }

            var response = await fetch(props.servidor + "/EnvioApi/72175003/TxTuXgXVAp/ecaf49b6-b270-4cf2-94ec-7b126bda5c81?llave=MSMFCECCPCLEDPFVTPFKKMFQ72175003APHIGHAMOIXQXPJOEHSHHONHCAHUKZYSFO", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(modelo2)
            })

            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Pais fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }
    return (
        <>
            <Card>

                <CardBody>
                    <Row>
                        <Col sm={5}>
                            <Card>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    Importar
                                </CardHeader>
                                <CardBody>
                                    <Button style={{ backgroundColor: '#188BAD', color: "white", marginTop: '10px' }} onClick={() => { guardarCambios() }}>Importar</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </CardBody>
            </Card>

            <Esperando verModal={esperando} />
        </>
    )
}
export default ImportarExportar;
