import React, { Component, useEffect, useState } from 'react';

import axios from 'axios';


// import Icono from './images/logo2.jpg'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Producto from './Producto';
import Usuario from './Usuario';
function Inicio(props) {
    const [autorizado, setautorizado] = React.useState(false);
    const [key, setKey] = React.useState('home');
    const [horariosxconvenios, sethorariosxconvenios] = React.useState(false);
    const [programacion, setprogramacion] = React.useState(false);
    const [crearconvenio, setcrearconvenio] = React.useState(false);
    const [horariosconvenios, sethorariosconvenios] = React.useState(false);
    const [informes, setinformes] = React.useState(false);
    const [preinformes, setpreinformes] = React.useState(false);
    const [crearvendedor, setcrearvendedor] = React.useState(false);
    const [crearmunicipios, setcrearmunicipios] = React.useState(false);
    const [creardepartamentos, setcreardepartamentos] = React.useState(false);
    const [crearterceros, setcrearterceros] = React.useState(false);
    const [acuserecibofacturaelectronica, setacuserecibofacturaelectronica] = React.useState(true);
    const [recibodelbienoservicio, setrecibodelbienoservicio] = React.useState(true);
    const [aceptacionexpresafacturaelectronica, setaceptacionexpresafacturaelectronica] = React.useState(false);
    const [aceptaciontacitafacturaelectronica, setaceptaciontacitafacturaelectronica] = React.useState(false);
    const [rechazofacturaelectronica, setrechazofacturaelectronica] = React.useState(false);
    const [configuraciondian, setconfiguraciondian] = React.useState(false);
    // const [url, seturl] = React.useState('http://factin.ddns.net:5002');
    // const [url, seturl] = React.useState('http://192.168.1.100:5002');
    const [url, seturl] = React.useState('https://localhost:44352');

    useEffect(() => {
    }, []);

    return (
        <div className="container-xxl" >
          

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className=" pestañas"
            >
                {/* {configuraciondian ?
                    <Tab eventKey="Configuraciones Dian" title="Configuraciones Dian">
                        <Configfacturas nitempresa={props.nitempresa}   cedula={props.cedula} usuario={props.usuario} llaveempresa={props.llaveempresa} nombreempresa={props.nombreempresa}nombreusuario={props.nombreusuario} llaveusuario={props.llaveusuario} url={url} />
                        
                    </Tab> : false} */}
                {acuserecibofacturaelectronica ?
                    <Tab eventKey="Acuse de Factura Electronica" title="Acuse de Factura Electronica">
                        <Producto />
                    </Tab> : false}
                {recibodelbienoservicio ?
                    <Tab eventKey="Recibo del bien y/o prestación del servicio" title="Recibo del bien y/o prestación del servicio">
                        <Usuario />
                    </Tab> : false}
            

            </Tabs>

        </div>
    );
}

export default Inicio;
