import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import * as XLSX from "xlsx"
const modeloContperiodo = {
    ano: "",
    periodo: "",
    nombre: "",
    fechaini: "",
    fechafin: "",
    comentarios: "",
    e_cont: "",
    e_tesor: "",
    e_movi: "",
    e_cart: "",
}
const Contperiodo = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Contperiodo, setContperiodo] = useState(modeloContperiodo);
    const [Contperiodos, setContperiodos] = useState([]);
    const [ano, setano] = useState([]);
    const [anos, setanos] = useState([]);
    const [periodo, setperiodo] = useState([]);
    const [periodos, setperiodos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fechaini, setfechaini] = useState([]);
    const [fechainis, setfechainis] = useState([]);
    const [fechafin, setfechafin] = useState([]);
    const [fechafins, setfechafins] = useState([]);
    const [comentarios, setcomentarios] = useState([]);
    const [comentarioss, setcomentarioss] = useState([]);
    const [e_cont, sete_cont] = useState([]);
    const [e_conts, sete_conts] = useState([]);
    const [e_tesor, sete_tesor] = useState([]);
    const [e_tesors, sete_tesors] = useState([]);
    const [e_movi, sete_movi] = useState([]);
    const [e_movis, sete_movis] = useState([]);
    const [e_cart, sete_cart] = useState([]);
    const [e_carts, sete_carts] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalContperiodo, setVerModalContperiodo] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Contperiodo = { ...Contperiodo }
        _Contperiodo[e.target.name] = value
        setContperiodo(_Contperiodo)
        setRoles([])


    }
    const obtenerContperiodos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Contperiodoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setContperiodos(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerContperiodos('');
    }, [])
    const columns = [
        {
            name: 'Año',
            selector: row => row.ano,
            sortable: true,
        },
        {
            name: 'Periodo',
            selector: row => row.periodo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Fecha Inicial',
            selector: row => row.fechaini,
            sortable: true,
        },
        {
            name: 'Fecha Final',
            selector: row => row.fechafin,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalContperiodo(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(Contperiodos);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte.xlsx")
    }
    const abrirEditarModalContperiodo = (data) => {
        var enviodata = { ...data }
        var _Contperiodo = {
            ano: enviodata.ano,
            periodo: enviodata.periodo,
            nombre: enviodata.nombre,
            fechaini: enviodata.fechaini,
            fechafin: enviodata.fechafin,
            comentarios: enviodata.comentarios,
            e_cont: enviodata.e_cont,
            e_tesor: enviodata.e_tesor,
            e_movi: enviodata.e_movi,
            e_cart: enviodata.e_cart,
        }
        setContperiodo(_Contperiodo);
        setVerModalContperiodo(!verModalContperiodo);
        seteditar(true)
    }
    const cerrarModalContperiodo = () => {
        setContperiodo(modeloContperiodo)
        setVerModalContperiodo(!verModalContperiodo);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Contperiodo) => {

        let claves = Object.keys(_Contperiodo);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Contperiodo[clave] = Number(_Contperiodo[clave])
            }
        }
        return _Contperiodo
    }
    const verificar = (_Contperiodo) => {

        let claves = Object.keys(_Contperiodo);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'ano' || clave == 'periodo' || clave == 'nombre' || clave == 'fechaini' || clave == 'fechafin') {
                if (_Contperiodo[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Contperiodo)
        var _Contperiodo = { ...Contperiodo }
        _Contperiodo = asignarceros(_Contperiodo)
        var _verificado = verificar(_Contperiodo)
        if (_verificado) {
            console.log(_Contperiodo)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Contperiodoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Contperiodo)
                })

            } else {
                response = await fetch("" + props.servidor + "/Contperiodoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Contperiodo)
                })
            }

            if (response.ok) {
                await obtenerContperiodos('');
                setContperiodo(modeloContperiodo)
                setVerModalContperiodo(!verModalContperiodo);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Contperiodo fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Contperiodo',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Periodos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataContperiodos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerContperiodos(document.getElementById('buscardataContperiodos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalContperiodo(!verModalContperiodo)
                                seteditar(false)
                            }}>Nuevo</Button>
                              <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcel}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Contperiodos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalContperiodo}>
                <ModalHeader>
                    Detalle Periodos
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Año*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="ano" value={Contperiodo.ano} />
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <FormGroup> 
                                        <Label style={{ fontSize: '13px' }} >Periodo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="periodo" value={Contperiodo.periodo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Contperiodo.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Fecha Inicial*</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechaini" value={Contperiodo.fechaini} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Fecha Final*</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechafin" value={Contperiodo.fechafin} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Estado</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="fechafin" value={Contperiodo.fechafin} >
                                            <option value={"abierto"}>Abierto</option>
                                            <option value={"cerrado"}>Cerrado</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalContperiodo}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Contperiodo;
