import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloConCorreo = {
    correo: "",
    clave: "",
    host: "",
    puerto: "",
}
const ConCorreo = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [ConCorreo, setConCorreo] = useState(modeloConCorreo);
    const [ConCorreos, setConCorreos] = useState([]);
    const [correo, setcorreo] = useState([]);
    const [correos, setcorreos] = useState([]);
    const [clave, setclave] = useState([]);
    const [claves, setclaves] = useState([]);
    const [host, sethost] = useState([]);
    const [hosts, sethosts] = useState([]);
    const [puerto, setpuerto] = useState([]);
    const [puertos, setpuertos] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalConCorreo, setVerModalConCorreo] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _ConCorreo = { ...ConCorreo }
        _ConCorreo[e.target.name] = value
        setConCorreo(_ConCorreo)
        setRoles([])


    }
    const obtenerConCorreos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ConCorreoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setConCorreos(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerConCorreos('');
    }, [])
    const columns = [
        {
            name: 'Correo',
            selector: row => row.correo,
            sortable: true,
        },
        {
            name: 'Clave',
            selector: row => row.clave,
            sortable: true,
        },
        {
            name: 'Host',
            selector: row => row.host,
            sortable: true,
        },
        {
            name: 'Puerto',
            selector: row => row.puerto,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalConCorreo(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalConCorreo = (data) => {
        var enviodata = { ...data }
        var _ConCorreo = {
            correo: enviodata.correo,
            clave: enviodata.clave,
            host: enviodata.host,
            puerto: enviodata.puerto,
        }
        setConCorreo(_ConCorreo);
        setVerModalConCorreo(!verModalConCorreo);
        seteditar(true)
    }
    const cerrarModalConCorreo = () => {
        setConCorreo(modeloConCorreo)
        setVerModalConCorreo(!verModalConCorreo);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_ConCorreo) => {

        let claves = Object.keys(_ConCorreo);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _ConCorreo[clave] = Number(_ConCorreo[clave])
            }
        }
        return _ConCorreo
    }
    const verificar = (_ConCorreo) => {

        let claves = Object.keys(_ConCorreo);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'correo' || clave == 'clave' || clave == 'host' || clave == 'puerto') {
                if (_ConCorreo[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(ConCorreo)
        var _ConCorreo = { ...ConCorreo }
        _ConCorreo = asignarceros(_ConCorreo)
        var _verificado = verificar(_ConCorreo)
        if (_verificado) {
            console.log(_ConCorreo)
            if (!editar) {
                response = await fetch("" + props.servidor + "/ConCorreoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_ConCorreo)
                })

            } else {
                response = await fetch("" + props.servidor + "/ConCorreoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_ConCorreo)
                })
            }

            if (response.ok) {
                await obtenerConCorreos('');
                setConCorreo(modeloConCorreo)
                setVerModalConCorreo(!verModalConCorreo);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El ConCorreo fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el ConCorreo',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de ConCorreos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataConCorreos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerConCorreos(document.getElementById('buscardataConCorreos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalConCorreo(!verModalConCorreo)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={ConCorreos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalConCorreo}>
                <ModalHeader>
                    Detalle ConCorreos
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Correo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="correo" value={ConCorreo.correo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Clave*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clave" value={ConCorreo.clave} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Host*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="host" value={ConCorreo.host} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Puerto*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="puerto" value={ConCorreo.puerto} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalConCorreo}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default ConCorreo;
