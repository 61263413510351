import React, { useEffect, useState, useContext, useRef } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import Venta from "./CierreCaja";
import BuscarTipoDocumento from '../componentes/Buscar2/TipodeDocumento'
import Buscarinventariofisico from '../componentes/Buscar2/inventariofisico'
import BuscarBodega from '../componentes/Buscar2/Bodega'
import Esperando from '../componentes/Esperando'
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Try } from "@mui/icons-material";

const modeloListadeprecio = {
    codigo: "",
    nombre: "",
}
const modeloFatipodoc = {
    codigo: "",
    descripcion: "",
    tipodoc: "factura",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "",
    formapago: "",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "0",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "",
    descporc: "",
    ivasn: "",
    ivaporc: "",
    rete: "",
    ica: "",
    reteiva: "",
    imptosn: "",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "pos",
    mvendedor: "",
    captionvend: "",
    docu1: "",
    captiondoc1: "",
    val_doc1: "",
    docu2: "",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",
    num_res: "",
    rango_res_i: "",
    rango_res_f: "",
    inicresolu: "",
    vencresolu: "",
    vencresoludias: "",
    prefijo: "",
    clave_tec: "",
    ambiente: "2",
    identificadorsw: "",
    pin: "",
    setpruebas: "",
    transfer: "si"
}
const Listadeprecio = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Listadeprecio, setListadeprecio] = useState(modeloListadeprecio);
    const [Listadeprecios, setListadeprecios] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalListadeprecio, setVerModalListadeprecio] = useState(false);
    const [abrirtipodocumento, setabrirtipodocumento] = useState(false);
    const [tipodocumento, settipodocumentoo] = useState(modeloFatipodoc);
    const [esperando, setesperando] = useState(false);
    const [mostrarexistencia, setmostrarexistencia] = useState(true);
    const dataTableRef = useRef(null);

    const [abrirtipodocumento2, setabrirtipodocumento2] = useState(false);
    const [existencias, setexistencias] = useState([]);
    const [tipodocumento2, settipodocumentoo2] = useState(modeloFatipodoc);

    const [abrirbuscarinventariofisico, setabrirbuscarinventariofisico] = useState(false);
    const [abrirbodega, setabrirbodega] = useState(false);
    const [bodega, setbodega] = useState(modeloFatipodoc);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Listadeprecio = { ...Listadeprecio }
        if (e.target.type == "number") {
            _Listadeprecio[e.target.name] = parseFloat(value)
        }
        else {
            _Listadeprecio[e.target.name] = value
        }
        setListadeprecio(_Listadeprecio)
        setRoles([])


    }


    const ordenar = async (coincidencia) => {
        var _invv = Listadeprecios.map((dat) => {
            return dat
        })
        var palabra = document.getElementById('buscardataListadeprecios').value
        _invv.sort((a, b) => {
            if (a.codigo === palabra) {
                return -1; // a va primero
            } else if (b.codigo === palabra) {
                return 1; // b va primero
            } else {
                return a.codigo.localeCompare(b.codigo); // ordenar alfabéticamente en caso de que no sea la palabra elegida
            }
        });

        setListadeprecios(_invv)
    }
    const ordenarnombre = async (coincidencia) => {
        var _invv = Listadeprecios.map((dat) => {
            return dat
        })
        var palabra = document.getElementById('buscardataListadeprecios2').value.toLowerCase()
        _invv.sort((a, b) => {
            if (a.nombre.toLowerCase().includes(palabra)) {
                return -1; // a va primero
            } else if (b.nombre.toLowerCase().includes(palabra)) {
                return 1; // b va primero
            } else {
                return a.nombre.localeCompare(b.nombre); // ordenar alfabéticamente en caso de que no sea la palabra elegida
            }
        });
        setListadeprecios([])

        setListadeprecios(_invv)
    }
    const obtenerListadeprecios = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Inarticulosapi/inventariofisico/?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setListadeprecios(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const ultimoinventario = async () => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/movimientoapi/inventariofisicobuscartodoultimo/' + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.length > 0) {
                    obtenerinvfisbuscado(data[0].consecutivo, data[0].fecha)
                    // setBcbancos(data)
                    // setBcbancos2(data)
                } else {
                    obtenerListadeprecios('');
                    // setBcbancos(data)
                    // setBcbancos2(data)
                }


            }
        } catch (error) {
            console.log(error)
        }

    }
    const obtenerinvfisbuscado = async (consecutivo, fecha) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/movimientoapi/inventariofisicobuscar/' + consecutivo + '/' + fecha + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setListadeprecios(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const imprimirpdf = () => {

        if (true) {
            const doc = new jsPDF('p', 'pt', 'letter');
            doc.page = 1;
            dataTableRef.current = document.getElementById('imprimirinventariofisico')
            // Calcula la altura del contenido a imprimir
            let contentHeight = dataTableRef.current.clientHeight;

            // Convierte el contenido del DataTable a una imagen usando html2canvas
            html2canvas(dataTableRef.current)
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/png');

                    // Calcula el ancho de la página
                    const pageWidth = doc.internal.pageSize.width;
                    const pageHeight = doc.internal.pageSize.height;

                    // Calcula el ratio de escala
                    const ratio = pageWidth / canvas.width;

                    // Calcula la altura de la imagen después de la escala
                    const imgHeight = canvas.height * ratio;

                    // Añade la imagen al documento PDF
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 0, position, pageWidth, imgHeight);
                    position -= pageHeight;

                    // Añade nuevas páginas si el contenido es demasiado grande
                    while (contentHeight + position > 0) {
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, pageWidth, imgHeight);
                        position -= pageHeight;
                        const frrr = contentHeight + position
                    }
                    // doc.output('dataurlnewwindow', { filename: 'estado_de_cuenta.pdf' });
                    // Guarda el PDF
                    doc.save('Inventario_Fisico.pdf');
                });
        }


    }
    const buscarexistenciasactuales = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        // var dsdsd = props.servidor + '/Inarticulosapi/reporteexistencia/existencia/' + "-" + "/" + "-" + "/" + "-" + dt.empresa.config.identificador_software + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + "/" + clasificacionarticulo1 + "/" + coincidencianombre + '?llave=' + dt.usuario.llaveempresa + ''
        const api = fetch(props.servidor + '/Inarticulosapi/reporteexistencia/existencia/' + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + "/" + "-" + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setexistencias(data);
            }).catch((error) => {
                setexistencias([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        // obtenerListadeprecios('');
        ultimoinventario()
        buscarexistenciasactuales()
        props.permisosusuario.forEach(element => {
            if (element.tipo == "desactivarexistenciaeninventariofisico" && element.acceso[0] == "1") {
                setmostrarexistencia(false)
            }
        });
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre ',
            sortable: true,
            grow: 7,
            cell: row => <div>
                { }
                <div>
                    <div style={{ width: '150px' }}
                    >
                        { }
                        {row.nombre}
                    </div>
                </div>
            </div>,
        },
        {
            name: 'Plu',
            selector: row => row.plu,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Clasificaciones',
            selector: row => row.clas1,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Fisico',
            selector: row => row.existencia,
            sortable: true,
            grow: 4,
            cell: row => <div>
                { }
                <div>
                    <div style={{ width: '150px' }}
                    >
                        <Input value={row.existencia} onChange={(e) => {
                            // Encuentra el índice del elemento que deseas modificar
                            const index = Listadeprecios.findIndex((dat) => dat === row);

                            // Solo si el elemento existe, procede a actualizarlo
                            if (index !== -1) {
                                // Crea una nueva copia superficial del array original
                                const _invv = [...Listadeprecios];

                                // Modifica el elemento específico en la nueva copia
                                _invv[index] = {
                                    ..._invv[index],
                                    existencia: e.target.value,
                                };

                                // Actualiza el estado con la nueva copia
                                setListadeprecios(_invv);
                            }
                        }} />

                    </div>
                </div>
            </div>,
        },
        mostrarexistencia && {
            name: 'Actual Sistema',
            selector: row => existenciasactuales(row.codigo),
            sortable: true,
            grow: 4,
        }
    ];
    const existenciasactuales = (referencia) => {
        if (mostrarexistencia) {
            var linea = existencias.filter(p => p.bodega == bodega.codigo && p.referencia == referencia)
            if (linea.length == 0) {
                return 0
            } else {
                return linea[0].existencia
            }
            return "hola"
        }



    }
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
                width: '150px'
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalListadeprecio = (data) => {
        var enviodata = { ...data }
        var _Listadeprecio = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        }
        setListadeprecio(_Listadeprecio);
        setVerModalListadeprecio(!verModalListadeprecio);
        seteditar(true)
    }
    const cerrarModalListadeprecio = () => {
        setListadeprecio(modeloListadeprecio)
        setVerModalListadeprecio(!verModalListadeprecio);
        seteditar(false)
        setKey('Datos')
    }
    const guardarCambios = async () => {
        try {
            if (Listadeprecios[0].generado == "si") {
                Swal.fire(
                    'Ajuste Ya Generado!',
                    'Este Ajuste ya se Genero Anteriormente',
                    'error'
                )
                return
            }
            if (Listadeprecios[0].consecutivo == 0) {
                Swal.fire(
                    'Debe Guardar Primero!',
                    'Primero guarde el ajuste y despues generelo',
                    'error'
                )
                return
            }
            setesperando(true)
            let response;
            console.log(Listadeprecio)
            response = await fetch("" + props.servidor + "/movimientoapi/inventariofisico/" + tipodocumento.codigo + "/" + tipodocumento2.codigo + "/" + bodega.codigo + "?llave=" + usuario.usuario.llaveempresa + "&usuario=" + usuario.usuario.cedula + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Listadeprecios)
            })



            if (response.ok) {
                await obtenerListadeprecios('');
                setListadeprecio(modeloListadeprecio)
                setVerModalListadeprecio(!verModalListadeprecio);
                setKey('Datos')
                Swal.fire(
                    'Generado Exitosamente!',
                    'Se genero exitosamente el inventario fisico',
                    'success'
                )
                setesperando(false)

            } else {
                try {
                    var textoerrores = await response.text()
                    Swal.fire(
                        'Error al Guardar!',
                        textoerrores,
                        'error'
                    )
                    setesperando(false)

                } catch (error) {
                    Swal.fire(
                        'Error al Guardar!',
                        error,
                        'error'
                    )
                    setesperando(false)

                }
            }

        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
            setesperando(false)

        }
    }

    const guardarCambios2 = async () => {
        try {
            if (Listadeprecios[0].generado == "si") {
                Swal.fire(
                    'Ajuste Ya Generado!',
                    'Este Ajuste ya se Genero Anteriormente',
                    'error'
                )
                return
            }
            if (Listadeprecios[0].generado == "no") {

                Swal.fire({
                    title: 'Ya hay un inventario generado',
                    text: "Desea sobreescribirlo?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'No, volver'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setesperando(true)

                        let response;
                        console.log(Listadeprecio)
                        response = await fetch("" + props.servidor + "/movimientoapi/inventariofisicoguardar?llave=" + usuario.usuario.llaveempresa + "&usuario=" + usuario.usuario.cedula + "", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify(Listadeprecios)
                        })



                        if (response.ok) {
                            await ultimoinventario();
                            setListadeprecio(modeloListadeprecio)
                            setVerModalListadeprecio(!verModalListadeprecio);
                            setKey('Datos')
                            Swal.fire(
                                'Guardado Exitosamente!',
                                'Se Guardo exitosamente el inventario fisico',
                                'success'
                            )
                            setesperando(false)

                        } else {
                            var textoerrores = await response.text()
                            var jsonerrores = JSON.parse(textoerrores)
                            var errores = jsonerrores.errors
                            let claves = Object.keys(errores);
                            var stringerrores = ""
                            for (let i = 0; i < claves.length; i++) {
                                let clave = claves[i];
                                if (errores[clave].length != 0) {
                                    stringerrores = stringerrores + errores[clave][0] + " \n "
                                }

                            }
                            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                            if (mensaje.includes('not be converted to System.Decimal')) {
                                mensaje = 'Hay campos uscarmericos rellenados con letras'
                            }
                            console.log(stringerrores)
                            Swal.fire(
                                'Error al Guardar!',
                                mensaje,
                                'error'
                            )
                            setesperando(false)
                        }
                    } else {
                        return
                    }
                })

            } else {
                setesperando(true)

                let response;
                console.log(Listadeprecio)
                response = await fetch("" + props.servidor + "/movimientoapi/inventariofisicoguardar?llave=" + usuario.usuario.llaveempresa + "&usuario=" + usuario.usuario.cedula + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(Listadeprecios)
                })



                if (response.ok) {
                    await obtenerListadeprecios('');
                    setListadeprecio(modeloListadeprecio)
                    setVerModalListadeprecio(!verModalListadeprecio);
                    setKey('Datos')
                    Swal.fire(
                        'Guardado Exitosamente!',
                        'Se Guardo exitosamente el inventario fisico',
                        'success'
                    )
                    setesperando(false)

                } else {
                    var textoerrores = await response.text()
                    var jsonerrores = JSON.parse(textoerrores)
                    var errores = jsonerrores.errors
                    let claves = Object.keys(errores);
                    var stringerrores = ""
                    for (let i = 0; i < claves.length; i++) {
                        let clave = claves[i];
                        if (errores[clave].length != 0) {
                            stringerrores = stringerrores + errores[clave][0] + " \n "
                        }

                    }
                    var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                    if (mensaje.includes('not be converted to System.Decimal')) {
                        mensaje = 'Hay campos uscarmericos rellenados con letras'
                    }
                    console.log(stringerrores)
                    Swal.fire(
                        'Error al Guardar!',
                        mensaje,
                        'error'
                    )
                    setesperando(false)
                }
            }


        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
            setesperando(false)

        }
    }


    return (
        <>
            <Card >
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Inventario Fisico
                </CardHeader>
                <CardBody>
                    <div className="col-sm-12" >
                        <div className="input-group input-group-sm mb-3" >
                            <Col sm={2}>
                                <FormGroup>
                                    <Label style={{ fontSize: '13px' }}>Buscar Codigo</Label>
                                    <Input onChange={(e) => {

                                        ordenar()
                                    }} type="text" id="buscardataListadeprecios" placeholder='Buscar' />
                                </FormGroup>

                            </Col>

                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>Buscar Nombre</Label>
                                <Input onChange={() => {
                                    ordenarnombre()
                                }} type="text" id="buscardataListadeprecios2" placeholder='Buscar por Nombre' />
                            </FormGroup>


                            <Col sm={3}>
                                <FormGroup>
                                    <Label style={{ fontSize: '13px' }}>Tipo Documento Entrada</Label>
                                    <Input type="text" onKeyDown={(e) => {
                                        if (e.key == "Enter") {
                                            setabrirtipodocumento(true)
                                        }
                                    }} value={tipodocumento.codigo} placeholder="Presione Enter Para Buscar" />
                                </FormGroup>

                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label style={{ fontSize: '13px' }}>Tipo Documento Salida</Label>
                                    <Input type="text" onKeyDown={(e) => {
                                        if (e.key == "Enter") {
                                            setabrirtipodocumento2(true)
                                        }
                                    }} value={tipodocumento2.codigo} placeholder="Presione Enter Para Buscar" />
                                </FormGroup>

                            </Col>
                            <Col sm={1}>
                                <FormGroup>
                                    <Label style={{ fontSize: '13px' }}>Bodega</Label>
                                    <Input type="text" onKeyDown={(e) => {
                                        if (e.key == "Enter") {
                                            setabrirbodega(true)
                                        }
                                    }} value={bodega.codigo} placeholder="Presione Enter Para Buscar" />
                                </FormGroup>


                            </Col>

                            <Button style={{ backgroundColor: 'coral', color: 'black' }} className="ml-2" size="sm" onClick={() => guardarCambios()}>Generar Ajuste Inventario Fisico</Button>
                            <Button style={{ backgroundColor: '#ffa1eb', color: 'black' }} color="primary" className="ml-2" size="sm" onClick={() => { guardarCambios2() }}>Guardar Inventario</Button>
                            <Button style={{ backgroundColor: '#188BAD', color: 'black' }} color="primary" className="ml-2" size="sm" onClick={() => { setabrirbuscarinventariofisico(true) }}>Buscar Inventario</Button>
                            <Button style={{ backgroundColor: '#188BAD', color: 'black' }} color="primary" className="ml-2" size="sm" onClick={() => {
                                if (Listadeprecios[0].generado == "si") {
                                    obtenerListadeprecios('')

                                }

                            }}>Limpiar Inventario</Button>
                            <Button style={{ backgroundColor: '#188BAD', color: 'black' }} color="primary" className="ml-2" size="sm" onClick={() => {
                                imprimirpdf()

                            }}>PDF</Button>

                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearlistadeprecio(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>

                        </div>
                    </div>


                    <hr></hr>
                    <Col sm="12" id={"imprimirinventariofisico"} ref={ref => dataTableRef.current = ref}>
                        <DataTable
                            columns={columns}
                            data={Listadeprecios}
                            progressPending={pendiente}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            customStyles={customStyles}
                        />
                    </Col>
                </CardBody>
            </Card>
            <BuscarTipoDocumento permisosusuario={props.permisosusuario} tipo={"inventariofisico"} tituloencabezado={"Buscar Tipo Documento"} abrir={abrirtipodocumento} servidor={props.servidor} setabrirbanco={(ab) => { setabrirtipodocumento(ab) }} seleccionar={(value) => {
                settipodocumentoo(value)
                setabrirtipodocumento(false)
            }} />
            <BuscarTipoDocumento permisosusuario={props.permisosusuario} tipo={"inventariofisico"} tituloencabezado={"Buscar Tipo Documento"} abrir={abrirtipodocumento2} servidor={props.servidor} setabrirbanco={(ab) => { setabrirtipodocumento2(ab) }} seleccionar={(value) => {
                settipodocumentoo2(value)
                setabrirtipodocumento2(false)
            }} />
            <Buscarinventariofisico tituloencabezado={"Buscar Inventario Fisico"} abrir={abrirbuscarinventariofisico} servidor={props.servidor} setabrirbanco={(ab) => { setabrirbuscarinventariofisico(ab) }} seleccionar={(value) => {
                setListadeprecios([])
                obtenerinvfisbuscado(value.consecutivo, value.fecha)
                setabrirbuscarinventariofisico(false)
            }} />
            <Esperando verModal={esperando} />
            <BuscarBodega tituloencabezado={"Buscar Bodega"} abrir={abrirbodega} servidor={props.servidor} setabrirbanco={(ab) => { setabrirbodega(ab) }} seleccionar={(value) => {
                setbodega(value)
                setabrirbodega(false)
            }} />
        </>
    )
}
export default Listadeprecio;
