import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
// import ReporteVenta from './views/ReporteVenta';
import ReporteExistenciaArticulo from './Reportes/Inventario/ReporteExistenciaArticulos';
import ReporteVenta from './ReporteVenta';
import ReportesInventario from '../views/Reportes/Inventario/inicio';
import ReportesMovimiento from '../views/Reportes/Movimiento/inicio';
import ReportesRingCall from '../views/Reportes/RingCall/inicio';
import ReportesCartera from '../views/Reportes/Cartera/inicio';
import ReportesContabilidad from '../views/Reportes/Contabilidad/inicio';
import ReportesTesoreria from '../views/Reportes/Tesoreria/inicio';
import ReportesInventarioDrogueria from '../views/Reportes/Inventario/Drogueria/inicio';
import ReportesInicio from './Reportes2';
import HistorialVenta from './HistorialVenta';

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}


const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [key, setKey] = React.useState('Inicio');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [historialventa, sethistorialventa] = React.useState(false);
    const [reporteVenta, setreporteVenta] = React.useState(false);
    const [reporteInventario, setreporteInventario] = React.useState(false);
    const [reporteInventarioDrogueria, setreporteInventarioDrogueria] = React.useState(false);
    const [reporteringcall, setreporteringcall] = React.useState(false);
    const [reporteinicio, setreporteinicio] = React.useState(true);
    const [reportecartera, setreportecartera] = React.useState(false);
    const [tesoreria, settesoreria] = React.useState(false);
    const [contabilidad, setcontabilidad] = React.useState(false);
    const [nomina, setnomina] = React.useState(false);

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])

    if (user == null) {
        return <Navigate to="/Login" />
    }

    return (
        <>
            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >
                            <Tab eventKey="Inicio" title="Inicio">
                                <ReportesInicio servidor={props.servidor}
                                    setreporteinicio={(reporteInventario) => {
                                        props.setreportes(reporteInventario)
                                    }}

                                    setreporteVenta={(reporteInventario) => {
                                        setreporteVenta(reporteInventario)
                                        setKey("Inf Ventas")
                                    }}
                                    setreportecartera={(reporteInventario) => {
                                        setreportecartera(reporteInventario)
                                        setKey("Inf Cartera")
                                    }}
                                    setreporteInventario={(reporteInventario) => {
                                        setreporteInventario(reporteInventario)
                                        setKey("Inf Inventario")
                                    }}
                                    setreporteInventarioDrogueria={(reporteInventario) => {
                                        setreporteInventarioDrogueria(reporteInventario)
                                        setKey("Inf Inventario D")
                                    }}
                                    setcontabilidad={(reporteInventario) => {
                                        setcontabilidad(reporteInventario)
                                        setKey("Inf Contabilidad")
                                    }}
                                    settesoreria={(reporteInventario) => {
                                        settesoreria(reporteInventario)
                                        setKey("Inf Tesoreria")
                                    }}
                                    setnomina={(reporteInventario) => {
                                        setnomina(reporteInventario)
                                        setKey("Inf Nomina")
                                    }}
                                />
                            </Tab>
                            {reporteVenta && <Tab eventKey="Inf Ventas" title="Inf Ventas">
                                <ReportesMovimiento caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setreporteVenta={(reporteInventario) => {
                                    setreporteVenta(reporteInventario)
                                    setKey("Inicio")
                                }} />
                            </Tab>}
                            {reportecartera && <Tab eventKey="Inf Cartera" title="Inf Cartera">
                                <ReportesCartera caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setreportecartera={(reporteInventario) => {
                                    setreportecartera(reporteInventario)
                                    setKey("Inicio")
                                }} />
                            </Tab>}

                            {reporteInventario && <Tab eventKey="Inf Inventario" title="Inf Inventario">
                                <ReportesInventario caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setreporteInventario={(reporteInventario) => {
                                    setreporteInventario(reporteInventario)
                                    setKey("Inicio")
                                }} />
                            </Tab>

                            }
                            {reporteInventarioDrogueria && <Tab eventKey="Inf Inventario D" title="Inf Inventario D">
                                <ReportesInventarioDrogueria caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setreporteInventario={(reporteInventario) => {
                                    setreporteInventarioDrogueria(reporteInventario)
                                    setKey("Inicio")
                                }} />
                            </Tab>

                            }
                            {tesoreria && <Tab eventKey="Inf Tesoreria" title="Inf Tesoreria">
                                <ReportesTesoreria  caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setreporteInventario={(reporteInventario) => {
                                    setreporteInventarioDrogueria(reporteInventario)
                                    setKey("Inicio")
                                }} />
                            </Tab>}
                            {contabilidad && <Tab eventKey="Inf Contabilidad" title="Inf Contabilidad">
                                <ReportesContabilidad caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setreporteInventario={(reporteInventario) => {
                                    setreporteInventarioDrogueria(reporteInventario)
                                    setKey("Inicio")
                                }} />
                            </Tab>}
                            {nomina && <Tab eventKey="Inf Nomina" title="Inf Nomina">
                                {/* <ReportesInventarioDrogueria servidor={props.servidor} setreporteInventario={(reporteInventario) => {
                                    setreporteInventarioDrogueria(reporteInventario)
                                }} /> */}
                            </Tab>}

                        </Tabs>

                        {/* <Outlet /> */}

                    </div>
                </div>

            </div>


        </>
    )
}

export default Reportes