import React, { Fragment, useContext, useEffect, useState } from "react";
// import { DataContext } from "../../Context/Data";
import TableCards from "./TableCards";
import { Button, Container } from "reactstrap";
import AddTables from "./AñadirTablas";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, FormGroup, Label } from "reactstrap";
import classnames from "classnames";
import { UserContext } from "../../../context/UserProvider";
import { jsPDF } from "jspdf";
import { QRCode } from 'react-qrcode-logo';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";

import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestauranteOrdenDomicilio from '../../../views/Restaurante/OrdenDomicilio';
import Swal from "sweetalert2";
import { FormatMoney } from 'format-money-js'

toast.configure();
const tabespacio = '\u00A0';

const modelomovimiento = {
  compañia: "",
  concepto: "",
  ndocumento: "",
  fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
  tercero: "",
  cliente: "",
  nombrecli: "",
  vendedor: "",
  direccion: "",
  nombreven: "",
  documento1: "",
  documento2: "",
  periodo: "",
  horafecha: "",
  estacion: "",
  nomusuario: "",
  observacion: "",
  anuladopor: "",
  borradopor: "",
  bruto: "",
  iva: "",
  descuento: "",
  despiefac: "",
  retefuente: "",
  reteiva: "",
  ica: "",
  retefte: "",
  impconsumo: "",
  subtotal: "",
  total: "",
  cuotai: "",
  ncuotasi: "",
  diferidasi: "",
  apartirdei: "",
  tipocre: "",
  ncuotasf: "",
  tasa: "",
  diferidas: "",
  apartirde: "",
  fechpost: "",
  banco: "",
  efectivo: "",
  cheque: "",
  debito: "",
  credito: "",
  descuentoc: "",
  beneficiario: "",
  impreso: "",
  anulado: "",
  estado: "",
  modificadopor: "",
  convertcon: "",
  convertdoc: "",
  devolcon: "",
  devoldoc: "",
  concprod: "",
  ndocprod: "",
  conctrasl: "",
  ndoctrasl: "",
  concodcped: "",
  ndocodcped: "",
  moneda: "",
  pendiente: "",
  cree: "",
  fletes: "",
  tcambio: "",
  dsctopp1: "",
  dsctopp2: "",
  dsctopp3: "",
  dsctovapp1: "",
  dsctovapp2: "",
  dsctovapp3: "",
  bancocr: "",
  convdescon: "",
  convdesdoc: "",
  convoricon: "",
  convoridoc: "",
  poretefte: "",
  poreteiva: "",
  porica: "",
  porimptoconsumo: "",
  vlr_dolar: "",
  autorizado: "",
  fpago: "contado",
  mpago: "",
  sucursal: "",
  conceptodevo: "",
  ndocumentodevo: 0,
  cufe: "",
  emitido: "no",
  qr: "",
  famovimiento: [
    // {
    //     item: "",
    //     referencia: "",
    //     descrip: "",
    //     bodega: "",
    //     cantidad: "",
    //     precio: "",
    //     pordescuento: "",
    //     poriva: "",
    //     porimptoconsumo: "",
    //     subtotal: "",
    //     compañia: "",
    //     concepto: "",
    //     nrodocumento: "",
    //     costo: "",
    //     desadicional: "",
    //     unimed: "",
    //     ccosto: "",
    //     fecha: "",
    //     preivainc: "",
    //     codprod: "",
    //     canprod: "",
    //     horafecha: "",
    //     tipoitem: "",
    //     tercero: "",
    //     cliente: "",
    //     vlr_desc: "",
    //     vlr_iva: "",
    //     vlr_base_iva: "",
    //     costodolar: "",
    //     tasa: "",
    //     vlrimptoconsumo: "",
    //     poretefte: "",
    //     vlretefte: "",
    //     porica: "",
    //     vlrica: "",
    //     poreteiva: "",
    //     vlreteiva: "",
    //     cantdig: "",
    // } 
  ]
}

const modelotipodocumento = {
  codigo: "",
  descripcion: "",
  tipodoc: "",
  tdpro: "",
  trasauto: "",
  tipoprod: "",
  actuacu: "",
  comactacu: "",
  rentab: "",
  afecinv: "",
  serial: "",
  descuentoc: "",
  actcosto: "",
  numitem: "",
  despiece: "",
  exportacion: "",
  afecar: "",
  rcauto: "",
  causadoc: "",
  afecaj: "nada",
  formapago: "si",
  afeban: "",
  docban: "",
  muesdescbanc: "",
  asicont: "",
  consuni: "",
  conscom: "",
  precsug: "",
  descsug: "",
  cupocred: "",
  permexisne: "",
  conpunreor: "",
  facdebcos: "",
  camprecven: "",
  camdesc: "",
  cambioiva: "",
  cambioimp: "",
  confacven: "",
  consecfac: "",
  camdescenc: "",
  camdescpp: "",
  pass1: "",
  pass2: "",
  pass3: "",
  pass4: "",
  pass5: "",
  pass6: "",
  pass7: "",
  pass8: "",
  pass9: "",
  pass10: "",
  ivaincluido: "",
  busplu: "",
  ccosto: "",
  centrocosto: "",
  tcostofijo: "",
  comprob: "",
  impto: "",
  tbodegasn: "",
  tbodega: "",
  tbodegafija: "",
  descsn: "si",
  descporc: "",
  ivasn: "si",
  ivaporc: "",
  rete: "si",
  ica: "si",
  reteiva: "si",
  imptosn: "si",
  imptoporc: "",
  captionimpto: "",
  enviacorreo: "",
  ctrlmov: "",
  docauto: "",
  tdpro1: "",
  tdpro2: "",
  filtroart: "",
  facturapap: "",
  mvendedor: "si",
  captionvend: "",
  docu1: "si",
  captiondoc1: "",
  val_doc1: "",
  docu2: "si",
  captiondoc2: "",
  observa: "",
  obslinea: "",
  mdevolucio: "",
  mediospago: "",
  puntoventa: "",
  modidoc: "",
  modidocenc: "",
  noimpdupl: "",
  rentabilid: "",
  redondeofac: "",

}
const Sell = (props) => {
  const { user } = useContext(UserContext)

  const [searchTerm, setSearchTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [error, setError] = useState();
  const [mesas, setmesas] = useState([]);
  const [mesa, setmesa] = useState({});
  const [usuario, setDataUser] = useState({});
  const [editarorden, seteditarorden] = useState(false);
  const [editarorden2, seteditarorden2] = useState(false);
  const [movimientoelectronico, setmovimientoelectronico] = useState(modelomovimiento);
  const [previsualizar, setprevisualizar] = useState(false);
  const [tipodocumento, settipodocumento] = useState(modelotipodocumento);
  const [vercrearmesas, setvercrearmesas] = useState(false);
  const [codigomesanueva, setcodigomesanueva] = useState("");
  const [nombremesanueva, setnombremesanueva] = useState("");
  const [key, setKey] = useState("");

  const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
  const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
  const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
  const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));


  useEffect(() => {
    let dt = JSON.parse(user)
    setDataUser(dt)
    buscarmesas();
  }, [])

  const buscarmesas = async () => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Movimientoapi/mesas' + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()

        setmesas(data)
      }
    } catch (error) {
      console.log(error)
    }

  }
  const crearmesas = async () => {
    try {
      let dt = JSON.parse(user);
      const url = props.servidor + '/Movimientoapi/crearmesa/' + codigomesanueva + '/' + nombremesanueva + '/?llave=' + dt.usuario.llaveempresa;

      // const requestData = {
      //   // Coloca aquí los datos que deseas enviar en el cuerpo de la solicitud
      // };

      // const requestOptions = {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json;charset=utf-8',
      //   },
      //   body: JSON.stringify(e),
      // };

      const response = await fetch(url);

      if (response.ok) {
        const dataJson = await response.json();
        setcodigomesanueva("")
        setnombremesanueva("")
        setvercrearmesas(false)
        await buscarmesas()
      } else {

      }
    } catch (error) {
      console.log(error);
    }

  }
  const liberarmesa = async (mesa) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Movimientoapi/liberarmesa/' + mesa + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        await buscarmesas()
        Swal.fire(
          'Exito!',
          'Mesa Liberada',
          'success'
        )
      } else {
        Swal.fire(
          'Error!',
          'No ha pagado',
          'error'
        )
      }
    } catch (error) {
      console.log(error)
    }

  }
  const buscarcoincidenciastipodocs = async (coincidencia) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (error) {
      Swal.fire(
        'Opps!',
        'No se pudo buscar los terceros',
        'error'
      )
      return []
    }
  }
  const convertiraelectronica = async (mesa) => {
    try {


      let dt = JSON.parse(user)
      let response = await fetch(props.servidor + '/Movimientoapi/convertirrestauranteelectronica/' + mesa + '?llave=' + dt.usuario.llaveempresa + '&usuario=' + dt.usuario.cedula + '&grupo=' + dt.usuario.cargo + '&nit=' + dt.empresa.empresa.nit + '&clave=' + dt.empresa.config.clave_certificado + '');

      if (response.ok) {
        let data = await response.json()
        var _movv = data.movimiento.faencmovi
        _movv.famovimiento = data.movimiento.famovimiento
        setmovimientoelectronico(_movv)
        var tipodocc = await buscarcoincidenciastipodocs(_movv.concepto)
        settipodocumento(tipodocc)
        await buscarmesas()
        Swal.fire(
          'Exito!',
          'Factura Electronica creada y Enviada',
          'success'
        )
        setprevisualizar(true)
      } else {
        let data = await response.json()
        await buscarmesas()
        Swal.fire(
          'Exito!',
          data.mensaje,
          'error'
        )
      }
    } catch (error) {
      console.log(error)
    }

  }

  const previsualizarpdf = () => {
    // a0 - a10
    // b0 - b10
    // c0 - c10
    // dl
    // letter
    // government-letter
    // legal
    // junior-legal
    // ledger
    // tabloid
    // credit-card
    const doc = new jsPDF('portrait', 'pt', 'tabloid');
    // const doc = new jsPDF('p', 'pt', 'letter');
    doc.page = 1; // use this as a counter.

    console.log(doc.getFontList())
    doc.html(document.querySelector("#pruebapdfventaelectronica"), {
      callback: function (pdf) {
        doc.autoPrint();
        doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
      }
    })
  }
  return (
    <Fragment>
      <div className="flexbetween">

      </div>
      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Add a mesa
        </ModalHeader>
        <ModalBody>
          <Container>
            <br />
            <AddTables
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              mesa={mesa}
              error={error}
              setError={setError}
            />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="primary" onClick={fnCollection}>
            Add
          </Button>{" "}
          <Button
            size="sm"
            color="danger"
            onClick={() => {
              setError(undefined);
              toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}

      <hr />
      <Row>
        <Col sm="12">
          {true === true ? (
            <div className="mt-3 text-right">
              <Button color="primary" onClick={() => {
                setvercrearmesas(true)
              }}>
                Añadir Mesa
              </Button>

            </div>
          ) : null}
          <div className="mt-3 mb-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Buscar"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </InputGroup>
          </div>
          {mesas ? (
            <div>
              {true === true ? (
                <div className="flexevenly mt-4">
                  {mesas
                    .filter((mesa) => {
                      if (searchTerm === "") {
                        return mesa;
                      } else if (
                        mesa.nombre
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return mesa;
                      }
                    })
                    .map((mesa) => (
                      <div key={mesa.codigo}>
                        <TableCards
                          nombre={mesa.nombre}
                          mesas={mesas}
                          setmesas={(me) => {
                            setmesas(me)
                          }}
                          //   toggleTab={toggleTab}
                          mesa={mesa}
                          setmesa={(me) => {
                            setmesa(me)
                          }}
                          fetchTables={(me) => { liberarmesa(me.codigo) }}
                          notify={() => { seteditarorden(true) }}
                          notify2={() => { seteditarorden2(true) }}
                          notify3={(me) => { convertiraelectronica(me.codigo) }}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="text-center">
                  <Button
                    className="mt-3 "
                    onClick={() => {
                      //   toggleTab("2");
                    }}
                  >
                    See Menus
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <h3 className="mt-5 text-center">
              Tables has not been added by the admin
            </h3>
          )}
        </Col>
      </Row>
      <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden}>

        <ModalBody >

          <RestauranteOrdenDomicilio vienedeafuera={false} mesa={mesa.codigo} editarorden={editarorden} connection={props.connection}
            correrSocket={props.correrSocket} permisosusuario={props.permisosusuario}
            caja={props.caja} servidor={props.servidor} setrestauranteordendomicilio={(reportes) => {

            }} />

        </ModalBody>
        <ModalFooter>

          <Button size="lg" color="primary" onClick={() => {
            seteditarorden(false)
            buscarmesas()
            // buscar()
          }}>Cerrar</Button>
        </ModalFooter>
      </Modal>
      <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden2}>

        <ModalBody >

          <RestauranteOrdenDomicilio vienedeafuera={true} mesa={mesa.codigo} editarorden={editarorden2} connection={props.connection}
            correrSocket={props.correrSocket} permisosusuario={props.permisosusuario}
            caja={props.caja} servidor={props.servidor} setrestauranteordendomicilio={(reportes) => {

            }} />

        </ModalBody>
        <ModalFooter>

          <Button size="lg" color="primary" onClick={() => {
            seteditarorden2(false)
            buscarmesas()
            // buscar()
          }}>Cerrar</Button>
        </ModalFooter>
      </Modal>
      <Modal size="lg" isOpen={previsualizar}>
        <ModalHeader>
          Opciones
        </ModalHeader>
        <ModalBody id={"pruebapdfventaelectronica"} >
          <Row >
            <Col sm={3}>
              <Row>
                <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                <QRCode value={movimientoelectronico.qr ? movimientoelectronico.qr : ""} />


                  {JSON.parse(user).empresa.empresa.nombre}<br />
                  {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                  {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                  {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                  {".Valido hasta " + tipodocumento.vencresolu}<br />
                  {"Factura de Venta No: " + movimientoelectronico.ndocumento}<br />
                  {"Fecha: " + movimientoelectronico.fecha}<br />
                  {"-------------------------------------"}<br />
                  {"Cliente: " + movimientoelectronico.nombrecli}<br />
                  {"Nit: " + movimientoelectronico.cliente}<br />
                  {"-------------------------------------"}<br />
                  {"Cajero: " + JSON.parse(user).usuario.nombre}<br />
                  {"Cod. Vendedor: " + movimientoelectronico.vendedor}<br />
                  {"Vendedor: " + movimientoelectronico.nombreven}<br />
                  {"Cufe: "}<br />
                  {movimientoelectronico.cufe && movimientoelectronico.cufe.substring(0, movimientoelectronico.cufe.length / 2)}<br />
                  {movimientoelectronico.cufe && movimientoelectronico.cufe.substring(movimientoelectronico.cufe.length / 2, movimientoelectronico.cufe.length)}<br />
                </Col>

              </Row>
              <Row>
                <Col sm={12}>
                  <Table size="sm">
                    <thead>
                      <tr style={{ fontSize: '10px', color: 'black' }}>
                        <th style={{ width: '10%', color: 'black' }}>Cant</th>
                        <th style={{ width: '20%', color: 'black' }}>Descr</th>
                        <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                        <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                        (movimientoelectronico.famovimiento.length < 1) ? (
                          <tr>
                            <td colSpan="15">Sin Datos</td>
                          </tr>
                        ) :
                          (
                            movimientoelectronico.famovimiento.map((item, index) => (
                              <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                              </tr>
                            ))
                          )


                      }
                    </tbody>
                  </Table>

                </Col>

              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                  {"Items:" + movimientoelectronico.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}

                </Col>

              </Row>
              <Row>
                <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                  <Table size="sm">
                    <thead>

                    </thead>
                    <tbody >
                      <tr  >
                        <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(movimientoelectronico.bruto)}</th>
                      </tr>
                      <tr>
                        <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(movimientoelectronico.descuento)}</th>
                      </tr>
                      <tr>
                        <th style={{ textAlign: 'right', color: 'black' }}>{"Consumo:"}</th>
                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(movimientoelectronico.impconsumo)}</th>
                      </tr>
                      <tr>
                        <th style={{ textAlign: 'right', color: 'black' }}>{"Propina:"}</th>
                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(movimientoelectronico.convoridoc)}</th>
                      </tr>
                      <tr>
                        <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(movimientoelectronico.total)}</th>
                      </tr>
                    </tbody>
                  </Table>


                </Col>

              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                  {"Tel:3187653564 - 3187653564 - 3218561024"}<br />
                  {"Gracias Por Su Compra"}
                  {"Generado por Solifactin S.A.S"}


                </Col>

              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>

          <Button size="sm" color="primary" onClick={() => {

            setprevisualizar(false)
            previsualizarpdf()
          }}>PDF</Button>
          <Button size="sm" color="primary" onClick={() => {
            setprevisualizar(false)
          }}>Cerrar</Button>
        </ModalFooter>
      </Modal>
      <Modal size="lg" isOpen={vercrearmesas}>
        <ModalHeader>
          Bodega
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}>
              <FormGroup>
                <Label>Numero</Label>
                <Input type="text" bsSize="sm" onChange={(e) => {
                  setcodigomesanueva(e.target.value)

                }} name="codigo" value={codigomesanueva} />
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label>Nombre</Label>
                <Input type="text" bsSize="sm" onChange={(e) => {
                  setnombremesanueva(e.target.value)
                }} name="nombre" value={nombremesanueva} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="primary" onClick={crearmesas}>Guardar</Button>
          <Button size="sm" color="danger" onClick={() => {
            setvercrearmesas(false)
            setcodigomesanueva("")
            setnombremesanueva("")
          }}>Cerrar</Button>
        </ModalFooter>
      </Modal>

    </Fragment>
  );
};

export default Sell;
