import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Auxiliardebancos from './auxiliardebancos'
import Cuentasporpagar from './cuentasporpagar'
import Informedecausaciones from './informedecausaciones'
import Informedeegresos from './informedeegresos'
import Librodiario from './librodiario'
import Movimienodebanco from './movimienodebanco'
import Estadobancos from './estadobancos'

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [key, setKey] = React.useState('home');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [reporteExistenciaArticulos, setreporteExistenciaArticulos] = React.useState(false);
    const [reporteCatalogoArticulos, setreporteCatalogoArticulos] = React.useState(false);
    const [reporteListadePrecio, setreporteListadePrecio] = React.useState(false);
    const [reporteCostoMercanciaVendida, setreporteCostoMercanciaVendida] = React.useState(false);
    const [reporteLibroAuxiliar, setreporteLibroAuxiliar] = React.useState(false);

    // const [reporteVenta, setreporteVenta] = React.useState(false);

    if (user == null) {
        return <Navigate to="/Login" />
    }



    return (
        <>
            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >
                            <Tab eventKey="Auxiliar de bancos" title="Auxiliar de bancos">
                                <Auxiliardebancos servidor={props.servidor} />

                            </Tab>
                            <Tab eventKey="Cuentas por pagar" title="Cuentas por pagar">
                                <Cuentasporpagar servidor={props.servidor} />

                            </Tab>
                            <Tab eventKey="Informe de causaciones" title="Informe de causaciones">
                                <Informedecausaciones servidor={props.servidor} />

                            </Tab>
                            <Tab eventKey="Informe de egresos" title="Informe de egresos">
                                <Informedeegresos servidor={props.servidor} />

                            </Tab>
                            <Tab eventKey="Libro diario" title="Libro diario">
                                <Librodiario servidor={props.servidor} />

                            </Tab>


                            <Tab eventKey="Movimieno de banco" title="Movimieno de banco">
                                <Movimienodebanco servidor={props.servidor} />

                            </Tab>
                            <Tab eventKey="Estado de bancos" title="Estado de bancos">
                                <Estadobancos servidor={props.servidor} />

                            </Tab>
                        </Tabs>

                        {/* <Outlet /> */}

                    </div>
                </div>

            </div>


        </>
    )
}

export default Reportes