import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../Buscar'
const modeloBcbanco = {
    codigo: "",
    nombre: "",
    nitcedula: "",
    codsucursa: "",
    nomsucursa: "",
    contacto: "",
    ciudad: "",
    direccion: "",
    pais: "",
    telefono1: "",
    telefono2: "",
    fax: "",
    gerente: "",
    conseccheq: "",
    numcuentap: "",
    numcuentab: "",
    email: "",
    efsalant: "",
    efdebmes: "",
    efcremes: "",
    possalant: "",
    posdebmes: "",
    poscremes: "",
    conseccheq2: "",
    horafecha: "",
    periodo: "",
}
const Bcbanco = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Bcbanco, setBcbanco] = useState(modeloBcbanco);
    const [Bcbancos, setBcbancos] = useState([]);
    const [Bcbancos2, setBcbancos2] = useState([]);
    const [usuario, setDataUser] = useState([]);

    const obtenerBcbancos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
           var ndo=props.ndocumento==''?0:props.ndocumento
            let response = await fetch(props.servidor + '/bonoapi/saldo/' + props.concepto + '/' + ndo + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setBcbancos(data)
                setBcbancos2(data)
            }
        } catch (error) {
            console.log(error)
        }


    }

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        if (props.abrir) {
            obtenerBcbancos('');
        }
        // setVerModalBcbanco(props.abrir)
    }, [props.abrir])
    const cerrarModalBcbanco = () => {

        // setBcbanco(modeloBcbanco)
        // setVerModalBcbanco(!verModalBcbanco);
        // seteditar(false)
        // setKey('Datos')
        props.setabrirbanco(false)
    }
    const seleccionarbcbancos = async (e) => {
        props.seleccionar(e)
        // let value = e
        // var _Movimiento = { ...movimiento }
        // _Movimiento['banco'] = value.codigo

        // setmovimiento(_Movimiento)
        // setRoles([])
        // cerrarModalBcbanco()
        // setcuentabanco(value.numcuentap)
    }

    const filtrarBancos = async (coincidencia) => {
        var _codigomuns = Bcbancos2.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || (dato.nombre + "").toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setBcbancos(_codigomuns)
    }
    return (
        <>
            <Buscar tituloencabezado={"Buscar Bono"} filtrar={(coincidencia) => { filtrarBancos(coincidencia) }} busqueda={Bcbancos} encabezado={["Codigo", "Nombre", "Valor", "Cliente"]} codigo={"codigo"} nombre={"nombre"} dato={"valor"} dato2={"nomcliente"} verModal={props.abrir} cerrarModal={() => { cerrarModalBcbanco() }} seleccionar={(seleccionado) => {
                seleccionarbcbancos(seleccionado)
            }} />
        </>
    )
}
export default Bcbanco;
