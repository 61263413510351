
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import { FormatMoney } from 'format-money-js'
import Autosuggest from 'react-autosuggest';
import { useContext, useState, useEffect } from "react";
import "./css/Venta.css"
import React, { UserContext } from "../context/UserProvider";
import Buscar from '../componentes/Buscar'
import { Event, event } from "jquery";

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modelotipodocumento = {
    codigo: "",
    descripcion: "",
    tipodoc: "",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "",
    formapago: "si",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "si",
    descporc: "",
    ivasn: "si",
    ivaporc: "",
    rete: "si",
    ica: "si",
    reteiva: "si",
    imptosn: "si",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "",
    mvendedor: "si",
    captionvend: "",
    docu1: "si",
    captiondoc1: "",
    val_doc1: "",
    docu2: "si",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",

}
const modeloVendedor = {
    codigo: "",
    nombre: ""
}
const modeloInbodegas = {
    codigo: "",
    nombre: ""
}
const modelomediopago = {
    codigo: "",
    nombre: ""
}
const modeloTerceros = {
    tercero: "",
    clase: "",
    codigo: "",
    cedula: "",
    digito: "",
    razsoc: "",
    nom1: "",
    nom2: "",
    ape1: "",
    ape2: "",
    codigomun: "",
    coddepar: "",
    codpais: "",
    email: "",
    contacto: "",
    beneficiario: "",
    repleg: "",
    reg_merc: "",
    direccion: "",
    tel1: "",
    tel2: "",
    tel3: "",
    barrio: "",
    fechnac: "",
    interes: "",
    cupocre: "",
    plazo: "",
    vendedor: "",
    ccosto: "",
    precsug: "",
    actecono: "",
    feching: "",
    fechulne: "",
    regtrib: "",
    resfis: "",
    desccom: "",
    desccom2: "",
    desccom3: "",
    desccom4: "",
    autoreteft: "",
    estado: "",
    salant1: "",
    saldeb1: "",
    salcre1: "",
    venretefuente: "",
    venbasefte: "",
    venporcfte: "",
    veniva: "",
    venbaseiva: "",
    venporciva: "",
    venreteiva: "",
    venbasereteiva: "",
    venporcreteiva: "",
    venreteica: "",
    venbaseica: "",
    venporica: "",
    salact1: "",
    ciudad: "",
    pais: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    reteica: "",
    personat: "",
    auto: "",
    salant2: "",
    saldeb2: "",
    salcre2: "",
    salact2: "",
    brutop1: "",
    brutop2: "",
    ivap: "",
    desctop1: "",
    desctop2: "",
    reteftep: "",
    reteivap: "",
    reteica2p: "",
    imptoconsumop: "",
    brutoa1: "",
    brutoa2: "",
    ivaa: "",
    desctoa1: "",
    desctoa2: "",
    retftea: "",
    reteivaa: "",
    reteicaa: "",
    imptoconsa: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    municipio: "",
    cambioimp: "",
    flete: "",
    cumplemes: "",
    cumpledia: "",
    cumpleano: "",
    horafecha: "",
    sexo: "",
    zona: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    autorexpro: "",
    cod_postal: "",
    nompais: "",
    campo: "",
    comretefte: "",
    combasefte: "",
    comporcfte: "",
    comreteiva: "",
    combasereteiva: "",
    comporcreteiva: "",
    comiva: "",
    combaseiva: "",
    comporciva: "",
    comreteica: "",
    combaseica: "",
    comporica: "",
    vencree: "",
    venbacree: "",
    venporcree: "",
    comcree: "",
    combacree: "",
    comporcree: "",
    Eliminado: "",
}
const modelomovimiento = {
    compañia: "",
    concepto: "",
    ndocumento: "",
    fecha: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + ("0" + new Date().getDate()).slice(-2),
    tercero: "",
    cliente: "",
    nombrecli: "",
    vendedor: "",
    nombreven: "",
    documento1: "",
    documento2: "",
    periodo: "",
    horafecha: "",
    estacion: "",
    nomusuario: "",
    observacion: "",
    anuladopor: "",
    borradopor: "",
    bruto: "",
    iva: "",
    descuento: "",
    despiefac: "",
    retefuente: "",
    reteiva: "",
    ica: "",
    retefte: "",
    impconsumo: "",
    subtotal: "",
    total: "",
    cuotai: "",
    ncuotasi: "",
    diferidasi: "",
    apartirdei: "",
    tipocre: "",
    ncuotasf: "",
    tasa: "",
    diferidas: "",
    apartirde: "",
    fechpost: "",
    banco: "",
    efectivo: "",
    cheque: "",
    debito: "",
    credito: "",
    descuentoc: "",
    beneficiario: "",
    impreso: "",
    anulado: "",
    estado: "",
    modificadopor: "",
    convertcon: "",
    convertdoc: "",
    devolcon: "",
    devoldoc: "",
    concprod: "",
    ndocprod: "",
    conctrasl: "",
    ndoctrasl: "",
    concodcped: "",
    ndocodcped: "",
    moneda: "",
    pendiente: "",
    cree: "",
    fletes: "",
    tcambio: "",
    dsctopp1: "",
    dsctopp2: "",
    dsctopp3: "",
    dsctovapp1: "",
    dsctovapp2: "",
    dsctovapp3: "",
    bancocr: "",
    convdescon: "",
    convdesdoc: "",
    convoricon: "",
    convoridoc: "",
    poretefte: "",
    poreteiva: "",
    porica: "",
    porimptoconsumo: "",
    vlr_dolar: "",
    autorizado: "",
    fpago: "contado",
    mpago: "",
    sucursal: "",
    famovimiento: [
        // {
        //     item: "",
        //     referencia: "",
        //     descrip: "",
        //     bodega: "",
        //     cantidad: "",
        //     precio: "",
        //     pordescuento: "",
        //     poriva: "",
        //     porimptoconsumo: "",
        //     subtotal: "",
        //     compañia: "",
        //     concepto: "",
        //     nrodocumento: "",
        //     costo: "",
        //     desadicional: "",
        //     unimed: "",
        //     ccosto: "",
        //     fecha: "",
        //     preivainc: "",
        //     codprod: "",
        //     canprod: "",
        //     horafecha: "",
        //     tipoitem: "",
        //     tercero: "",
        //     cliente: "",
        //     vlr_desc: "",
        //     vlr_iva: "",
        //     vlr_base_iva: "",
        //     costodolar: "",
        //     tasa: "",
        //     vlrimptoconsumo: "",
        //     poretefte: "",
        //     vlretefte: "",
        //     porica: "",
        //     vlrica: "",
        //     poreteiva: "",
        //     vlreteiva: "",
        //     cantdig: "",
        // } 
    ]
}
const modeloproductos = [{
    idProducto: "1001",
    descripcion: "articulo 1",
    bodega: "5",
    cantidad: 25.3654,
    descuento: 5,
    iva: 19,
    consumo: 8,
    precio: 10000000,
    total: 500000 * 25.3654
}, {
    idProducto: "1002",
    descripcion: "articulo 2",
    bodega: "4",
    cantidad: 3,
    descuento: 2,
    iva: 19,
    consumo: 8,
    precio: 2000,
    total: 2000 * 3
}, {
    idProducto: "1003",
    descripcion: "articulo 3",
    bodega: "3",
    cantidad: 24,
    descuento: 7,
    iva: 0,
    consumo: 8,
    precio: 8000,
    total: 8000 * 24
}, {
    idProducto: "1004",
    descripcion: "articulo 4",
    bodega: "7",
    cantidad: 1,
    descuento: 19,
    iva: 19,
    consumo: 0,
    precio: 500000,
    total: 500000 * 1
}, {
    idProducto: "1005",
    descripcion: "articulo 5",
    bodega: "1",
    cantidad: 100,
    descuento: 20,
    iva: 19,
    consumo: 8,
    precio: 24.256,
    total: 24.256 * 100
}, {
    idProducto: "1006",
    descripcion: "articulo 6",
    bodega: "9",
    cantidad: 1000,
    descuento: 0,
    iva: 0,
    consumo: 8,
    precio: 200,
    total: 200 * 10000
}]
const modeloInarticulos = {
    codigo: "",
    nombre: "",
    descripcion: "",
    descadicional: "",
    referencia: "",
    plu: "",
    unimed: "",
    unxmed: "",
    preccost: "",
    preccost2: "",
    cantmin: "",
    cantmax: "",
    ultmvto: "",
    ultcosto: "",
    dscto: "",
    artserv: "",
    tipoitem: "",
    invfisico1: "",
    ulinvfisico1: "",
    saldact: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    facturable: "",
    produccion: "",
    fchaprom: "",
    fchainiprom: "",
    fchafinprom: "",
    serial: "",
    mfvence: "",
    fvence: "",
    vnr: "",
    costoniif: "",
    pos: "",
    lote: "",
    desc1: "",
    comision: "",
    horafecha: "",
    tasa_ultco: "",
    ult_fa_com: "",
    fe_ult_com: "",
    stockmin1: "",
    stockmax1: "",
    canesca1: "",
    canesca2: "",
    pvtesca1: "",
    pvtesca2: "",
    convunid: "",
    digitado: "",
    iva: "",
    imptocons: "",
    rete: "",
    ica: "",
    reteiva: "",
    clas1: "",
    clas2: "",
    clas3: "",
    clas4: "",
    clas5: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    inarticulosbodega: [
    ],
    inarticuloslistaprecio: [
    ],
}
const modeloInccostos = {
    codigo: "",
    nombre: "",
    subcodigo: "",
    subnombre: "",
    codigoa: "",
    horafecha: "",
}
const Venta = (props) => {
    const { user } = useContext(UserContext)
    const [a_Productos, setA_Productos] = useState([])
    const [a_Busqueda, setA_Busqueda] = useState("")
    const [documentoCliente, setDocumentoCliente] = useState("")
    const [nombreCliente, setNombreCliente] = useState("")
    const [tipoDocumento, setTipoDocumento] = useState("Boleta")
    const [productos, setProductos] = useState(modeloproductos)
    const [bruto, setbruto] = useState(0)
    const [descuento, setdescuento] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [iva, setiva] = useState(0)
    const [consumo, setconsumo] = useState(0)
    const [reteiva, setreteiva] = useState(0)
    const [retefuente, setretefuente] = useState(0)
    const [reteica, setreteica] = useState(0)
    const [total, setTotal] = useState(0)
    const [igv, setIgv] = useState(0)
    const [tipodocumento, settipodocumento] = useState(modelotipodocumento)
    const [busquedatipodocumentos, setbusquedatipodocumentos] = useState([]);
    const [verModaltipodocumentos, setVerModaltipodocumentos] = useState(false);
    const [tipodocumentos, settipodocumentos] = useState([]);
    const [Movimiento, setMovimiento] = useState(modelomovimiento);
    const [Movimientos, setMovimientos] = useState([]);
    const [Roles, setRoles] = useState([]);
    const [fila, setfila] = useState([]);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [tercero, settercero] = useState(modeloTerceros)
    const [busquedaterceros, setbusquedaterceros] = useState([]);
    const [verModalterceros, setVerModalterceros] = useState(false);
    const [terceros, setterceros] = useState([]);

    const [vendedor, setvendedor] = useState(modeloVendedor)
    const [busquedavendedors, setbusquedavendedors] = useState([]);
    const [verModalvendedors, setVerModalvendedors] = useState(false);
    const [vendedors, setvendedors] = useState([]);

    const [mediopago, setmediopago] = useState(modelomediopago)
    const [busquedamediopagos, setbusquedamediopagos] = useState([]);
    const [verModalmediopagos, setVerModalmediopagos] = useState(false);
    const [mediopagos, setmediopagos] = useState([]);

    const [articulo, setarticulo] = useState(modeloInarticulos)
    const [listaarticulo, setlistaarticulo] = useState([])
    const [busquedaarticulos, setbusquedaarticulos] = useState([]);
    const [verModalarticulos, setVerModalarticulos] = useState(false);
    const [articulos, setarticulos] = useState([]);

    const [bodega, setbodega] = useState(modeloInbodegas)
    const [busquedabodegas, setbusquedabodegas] = useState([]);
    const [verModalbodegas, setVerModalbodegas] = useState(false);
    const [bodegas, setbodegas] = useState([]);
    const [abrirdescripcionadicional, setabrirdescripcionadicional] = useState(false);
    const [abrirotrosimpuestos, setabrirotrosimpuestos] = useState(false);
    const [abriropcionespuntoventa, setabriropcionespuntoventa] = useState(false);
    const [porcentajereteiva, setporcentajereteiva] = useState(0);
    const [porcentajeica, setporcentajeica] = useState(0);
    const [acabodeseleccionararticulo, setacabodeseleccionararticulo] = useState(false);

    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));

    const [ccosto, setccosto] = useState(modeloInccostos)
    const [busquedaccostos, setbusquedaccostos] = useState([]);
    const [verModalccostos, setVerModalccostos] = useState(false);
    const [ccostos, setccostos] = useState([]);

    const [puntodeventacambiarcantidad, setpuntodeventacambiarcantidad] = useState(false);
    const [puntodeventacambiarprecio, setpuntodeventacambiarprecio] = useState(false);

    const [nuevo, setnuevo] = useState(false);
    const [editar, seteditar] = useState(false);
    // const fm = new FormatMoney({ symbol: '' });
    // const fm2 = new FormatMoney({ symbol: '', decimals: 4 });
    // const fm3 = new FormatMoney({ symbol: '$', decimals: 4 });
    const reestablecer = () => {
        setDocumentoCliente("");
        setNombreCliente("")
        setTipoDocumento("Boleta")
        setProductos([])
        setTotal(0)
        setSubTotal(0)
        setIgv(0)
    }
    //busquedas
    useEffect(() => {
        buscartodastipodocumentos('');
        buscartodasterceros('');
        buscartodasvendedors('');
        buscartodasmediopagos('');
        buscartodasarticulos('');
        buscartodasbodegas('');
        buscartodasccostos('');
    }, [])
    useEffect(() => {
        if (tipodocumento.puntoventa == 'si') {

            try {
                if (Movimiento.famovimiento[fila + 1]) {
                    if (!acabodeseleccionararticulo) {
                        if (puntodeventacambiarcantidad) {
                            document.getElementById('movimientocantidad' + (fila)).focus()

                        } else {
                            if (puntodeventacambiarprecio) {
                                document.getElementById('movimientoprecio' + (fila)).focus()

                            } else {
                                document.getElementById('movimientoreferencia' + (fila + 1)).focus()

                            }
                        }
                    } else {
                        if (puntodeventacambiarcantidad) {
                            if (tipodocumento.tbodegafija == "si") {
                                document.getElementById('movimientodescrip' + (fila)).focus()
                            } else {
                                document.getElementById('movimientobodega' + (fila)).focus()
                            }

                        } else {
                            if (puntodeventacambiarprecio) {
                                document.getElementById('movimientocantidad' + (fila)).focus()

                            } else {
                                document.getElementById('movimientoitem' + (fila + 1)).focus()

                            }
                        }

                    }
                }
            } catch (error) {

            }
        }
    }, [Movimiento.famovimiento.length])
    const filtrartipodocumentos = async (coincidencia) => {
        var _codigomuns = tipodocumentos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.descripcion.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedatipodocumentos(_codigomuns)
    }
    const cerrarModaltipodocumentos = () => {
        setVerModaltipodocumentos(!verModaltipodocumentos);
        setbusquedatipodocumentos(tipodocumentos)
    }
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    settipodocumentos(dataJson)
                    setbusquedatipodocumentos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                    setbusquedatipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (e) => {
        if (tipodocumento.codigo != "") {
            if (e.target.name == 'fecha' && tipodocumento.ctrlmov == 'si') {
                Swal.fire(
                    'Restringido!',
                    'No puede cambiar la fecha',
                    'error'
                )
                var _Movimiento = { ...Movimiento }
                _Movimiento[e.target.name] = fechahoy
                setMovimiento(_Movimiento)
                setRoles([])

                return
            }
            let value = e.target.value
            console.log(e.target)
            var _Movimiento = { ...Movimiento }
            _Movimiento[e.target.name] = value
            setMovimiento(_Movimiento)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }
    const calculartotalesmovimiento = (_enviodatos2) => {
        var totales = {
            bruto: 0,
            descuento: 0,
            subtotal: 0,
            iva: 0,
            consumo: 0,
            reteiva: 0,
            retefuente: 0,
            reteica: 0,
            total: 0,
        }
        _enviodatos2.map((dato, index) => {
            var bruto = 0
            var descuento = 0
            var subtotal = 0
            var iva = 0
            var consumo = 0
            var reteiva = 0
            var retefuente = 0
            var reteica = 0
            var total = 0
            var cantidadcaja = Number(dato.cantidadcaja)
            var cantidadblister = Number(dato.cantidadblister)
            var cantidadunidad = Number(dato.cantidadunidad)
            var preciocaja = Number(dato.preciocaja)
            var precioblister = Number(dato.precioblister)
            var preciounidad = Number(dato.preciounidad)
            var pordescuento = Number(dato.pordescuento)
            var poriva = Number(dato.poriva)
            var porimptoconsumo = Number(dato.porimptoconsumo)
            var poretefte = Number(dato.poretefte)
            var porica = Number(dato.porica)
            var poreteiva = Number(dato.poreteiva)
            bruto = (cantidadcaja * preciocaja)+(cantidadblister * precioblister)+(cantidadunidad * preciounidad)
            descuento = bruto * (pordescuento / 100)
            subtotal = bruto - descuento
            iva = subtotal * (poriva / 100)
            consumo = subtotal * (porimptoconsumo / 100)
            reteiva = iva * (poreteiva / 100)
            retefuente = subtotal * (poretefte / 100)
            reteica = subtotal * (porica / 100)
            total = subtotal + iva + consumo - reteiva - retefuente - reteica - reteiva
            totales.bruto = totales.bruto + bruto
            totales.descuento = totales.descuento + descuento
            totales.subtotal = totales.subtotal + subtotal
            totales.iva = totales.iva + iva
            totales.consumo = totales.consumo + consumo
            totales.reteiva = totales.reteiva + reteiva
            totales.retefuente = totales.retefuente + retefuente
            totales.reteica = totales.reteica + reteica
            totales.total = totales.total + total
        })
        setbruto(totales.bruto)
        setdescuento(totales.descuento)
        setSubTotal(totales.subtotal)
        setiva(totales.iva)
        setconsumo(totales.consumo)
        setreteiva(totales.reteiva)
        setretefuente(totales.retefuente)
        setreteica(totales.reteica)
        setTotal(totales.total)
    }
    const handleChangegrillafaencmovi = async (e) => {
        if (e.target.name != 'descrip' && e.target.name != 'desadicional') {
            if (isNaN(Number(e.target.value))) {
                Swal.fire(
                    'Opps!',
                    'Solo se aceptan Numeros',
                    'error'
                )
                return
            }
        }
        e.target.setAttribute('cambio', 'si')
        var _Movimiento = { ...Movimiento }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Movimiento }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [e.target.name]: e.target.value
                }
                return dat
            } else {
                return dato
            }
        })
        var total = calcularsubtotalitem(_enviodatos[fila])
        let _enviodatos2 = _enviodatos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['subtotal']: total
                }
                return dat
            } else {
                return dato
            }
        })
        var totales = calculartotalesmovimiento(_enviodatos2)

        _Movimiento[subtabla] = _enviodatos2
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }

    const ivaincluido = async (e) => {
        if (e.target.name != 'descrip') {
            if (isNaN(Number(e.target.value))) {
                Swal.fire(
                    'Opps!',
                    'Solo se aceptan Numeros',
                    'error'
                )
                return
            }
        }

        var _Movimiento = { ...Movimiento }
        var subtabla = 'famovimiento'
        var _datos = { ...Movimiento }[subtabla]
        var porcentajeivaincluido = 0
        if (tipodocumento.ivasn == "si") {
            porcentajeivaincluido = Number(tipodocumento.ivaporc)
        } else {
            porcentajeivaincluido = Number(listaarticulo[fila].iva)
        }


        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [e.target.name]: (Number(dato[e.target.name]) / ((100 + porcentajeivaincluido) / 100)),
                    ['poriva']: porcentajeivaincluido
                }
                return dat
            } else {
                return dato
            }
        })

        var total = calcularsubtotalitem(_enviodatos[fila])
        let _enviodatos2 = _enviodatos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['subtotal']: total
                }
                return dat
            } else {
                return dato
            }
        })
        var totales = calculartotalesmovimiento(_enviodatos2)

        _Movimiento[subtabla] = _enviodatos2
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }


    const cambiardatosconimpuesto = async () => {
        var _Movimiento = { ...Movimiento }
        var subtabla = 'famovimiento'
        var _datos = { ...Movimiento }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            var dat = {
                ...dato,
                ['porica']: porcentajeica,
                ['poreteiva']: porcentajereteiva
            }
            return dat

        })

        var totales = calculartotalesmovimiento(_enviodatos)

        _Movimiento[subtabla] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)

    }


    const handleChangeimpuestosglobales = async (e) => {
        var _Movimiento = { ...Movimiento }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Movimiento }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            var dat = {
                ...dato,
                [e.target.name]: e.target.value
            }
            return dat

        })
        if (e.target.name == "porica") {
            setporcentajeica(e.target.value)
            var r = fm1.from(e.target.value) + " %"
        }
        if (e.target.name == "poreteiva") {
            setporcentajereteiva(e.target.value)
            var r = fm1.from(e.target.value) + " %"
        }
        var totales = calculartotalesmovimiento(_enviodatos)

        _Movimiento[subtabla] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }


    const seleccionartipodocumentos = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['concepto'] = value.codigo
        if (value.ctrlmov == 'si') {

            _Movimiento['fecha'] = fechahoy
        }
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModaltipodocumentos()
        settipodocumento(value)
    }

    const filtrarterceros = async (coincidencia) => {
        var _codigomuns = terceros.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.razsoc.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaterceros(_codigomuns)
    }
    const cerrarModalterceros = () => {
        setVerModalterceros(!verModalterceros);
        setbusquedaterceros(terceros)
    }
    const buscartodasterceros = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Caclientesapi/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setterceros(dataJson)
                    setbusquedaterceros(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setterceros([])
                    setbusquedaterceros([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarterceros = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['cliente'] = value.codigo
        _Movimiento['nombrecli'] = value.razsoc
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModalterceros()
        settercero(value)
        if (value.tercero == "cliente") {
            setporcentajereteiva(value.venporcreteiva)
            setporcentajeica(value.venporica)
        }
        if (value.tercero == "proveedor") {
            setporcentajereteiva(value.comporcreteiva)
            setporcentajeica(value.comporica)
        }
    }

    const filtrarvendedors = async (coincidencia) => {
        var _codigomuns = vendedors.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedavendedors(_codigomuns)
    }
    const cerrarModalvendedors = () => {
        setVerModalvendedors(!verModalvendedors);
        setbusquedavendedors(vendedors)
    }
    const buscartodasvendedors = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Vendedorapi/nombres/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setvendedors(dataJson)
                    setbusquedavendedors(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setvendedors([])
                    setbusquedavendedors([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarvendedors = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['vendedor'] = value.codigo
        _Movimiento['nombreven'] = value.nombre
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModalvendedors()
    }


    const filtrarmediopagos = async (coincidencia) => {
        var _codigomuns = mediopagos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedamediopagos(_codigomuns)
    }
    const cerrarModalmediopagos = () => {
        setVerModalmediopagos(!verModalmediopagos);
        setbusquedamediopagos(mediopagos)
    }
    const buscartodasmediopagos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Mediodepagoapi/nombres/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setmediopagos(dataJson)
                    setbusquedamediopagos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setmediopagos([])
                    setbusquedamediopagos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarmediopagos = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['mpago'] = value.codigo
        setMovimiento(_Movimiento)
        setRoles([])
        cerrarModalmediopagos()
    }


    const filtrararticulos = async (coincidencia) => {
        var _codigomuns = articulos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaarticulos(_codigomuns)
    }
    const cerrarModalarticulos = () => {
        setVerModalarticulos(!verModalarticulos);
        setbusquedaarticulos(articulos)


    }
    const cerrarModalarticulosselec = (arti) => {
        setVerModalarticulos(!verModalarticulos);
        setbusquedaarticulos(articulos)
        if (arti.descripcion == "si") {
            setabrirdescripcionadicional(true)
        }

    }
    const buscartodasarticulos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Inarticulosapi/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setarticulos(dataJson)
                    setbusquedaarticulos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setarticulos([])
                    setbusquedaarticulos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionararticulos = (e) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        var preciousado = 0
        var ivausado = 0
        if (tipodocumento.precsug != "") {
            articulo.inarticuloslistaprecio.map((precio) => {
                if (precio.listadeprecio == tipodocumento.precsug) {
                    preciousado = precio.precio
                }
            })
        } else {
            if (tercero.precsug != "") {
                e.inarticuloslistaprecio.map((precio) => {
                    if (precio.listadeprecio == tercero.precsug) {
                        preciousado = precio.precio
                    }
                })
            }
        }
        if (tipodocumento.ivaincluido == "si") {
            if (tipodocumento.ivasn == "si") {
                ivausado = Number(tipodocumento.ivaporc)
            } else {
                ivausado = Number(e.iva)
            }

        }
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['referencia']: e.codigo,
                    ['descrip']: e.nombre,
                    ['desadicional']: e.descadicional,
                    ['precio']: preciousado,
                    ['cantidad']: 1
                }
                return dat
            } else {
                return dato
            }
        })
        if (tipodocumento.puntoventa == "si") {
            _enviodatos = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['cantidad']: 1
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        var totalll = calcularsubtotalitem(_enviodatos[fila])
        _enviodatos = _enviodatos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['referencia']: e.codigo,
                    ['descrip']: e.nombre,
                    ['desadicional']: e.descadicional,
                    ['precio']: preciousado,
                    ['subtotal']: totalll
                }
                return dat
            } else {
                return dato
            }
        })
        var _enviodatos2 = []
        if (tipodocumento.ivaincluido == "si" && preciousado != 0) {
            _enviodatos2 = _enviodatos.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['precio']: (Number(preciousado) / ((100 + ivausado) / 100)),
                        ['poriva']: ivausado
                    }
                    return dat
                } else {
                    return dato
                }
            })
            var totall = calcularsubtotalitem(_enviodatos2[fila])
            _enviodatos2 = _enviodatos2.map((dato, index) => {
                if (index == fila) {
                    var dat = {
                        ...dato,
                        ['subtotal']: totall
                    }
                    return dat
                } else {
                    return dato
                }
            })
        }
        if (_enviodatos2.length == 0) {
            if (tipodocumento.puntoventa == "si") {
                var bodega = ""
                var ccosto = ""
                if (tipodocumento.tbodegasn == "si") {
                    bodega = tipodocumento.tbodega
                }
                if (tipodocumento.ccosto == "si") {
                    ccosto = tipodocumento.centrocosto
                }
                var totales = calculartotalesmovimiento(_enviodatos)
                _enviodatos = _enviodatos.concat(
                    {
                        item: "",
                        referencia: "",
                        descrip: "",
                        bodega: bodega,
                        cantidad: "",
                        precio: "",
                        pordescuento: "",
                        poriva: "",
                        porimptoconsumo: "",
                        subtotal: "",
                        compañia: "",
                        concepto: "",
                        nrodocumento: "",
                        costo: "",
                        desadicional: "",
                        unimed: "",
                        ccosto: ccosto,
                        fecha: "",
                        preivainc: "",
                        codprod: "",
                        canprod: "",
                        horafecha: "",
                        tipoitem: "",
                        tercero: "",
                        cliente: "",
                        vlr_desc: "",
                        vlr_iva: "",
                        vlr_base_iva: "",
                        costodolar: "",
                        tasa: "",
                        vlrimptoconsumo: "",
                        poretefte: "",
                        vlretefte: "",
                        porica: "",
                        vlrica: "",
                        poreteiva: "",
                        vlreteiva: "",
                        cantdig: "",
                    }
                )
            }

            _Movimiento['famovimiento'] = _enviodatos


        } else {
            if (tipodocumento.puntoventa == "si") {

                var totales = calculartotalesmovimiento(_enviodatos)
                _enviodatos2 = _enviodatos2.concat(
                    {
                        item: "",
                        referencia: "",
                        descrip: "",
                        bodega: "",
                        cantidad: "",
                        precio: "",
                        pordescuento: "",
                        poriva: "",
                        porimptoconsumo: "",
                        subtotal: "",
                        compañia: "",
                        concepto: "",
                        nrodocumento: "",
                        costo: "",
                        desadicional: "",
                        unimed: "",
                        ccosto: "",
                        fecha: "",
                        preivainc: "",
                        codprod: "",
                        canprod: "",
                        horafecha: "",
                        tipoitem: "",
                        tercero: "",
                        cliente: "",
                        vlr_desc: "",
                        vlr_iva: "",
                        vlr_base_iva: "",
                        costodolar: "",
                        tasa: "",
                        vlrimptoconsumo: "",
                        poretefte: "",
                        vlretefte: "",
                        porica: "",
                        vlrica: "",
                        poreteiva: "",
                        vlreteiva: "",
                        cantdig: "",
                    })



            }
            _Movimiento['famovimiento'] = _enviodatos2

        }

        // setMovimientos(_Movimientos)
        var artt = listaarticulo
        artt[fila] = e
        setlistaarticulo(artt)
        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        cerrarModalarticulosselec(e)
        // setacabodeseleccionararticulo(true)
    }



    const filtrarbodegas = async (coincidencia) => {
        var _codigomuns = bodegas.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedabodegas(_codigomuns)
    }
    const cerrarModalbodegas = () => {
        setVerModalbodegas(!verModalbodegas);
        setbusquedabodegas(bodegas)
    }
    const buscartodasbodegas = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Bodegaapi/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setbodegas(dataJson)
                    setbusquedabodegas(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setbodegas([])
                    setbusquedabodegas([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarbodegas = (e) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['bodega']: e.codigo,
                    ['nombrebodega']: e.nombre,
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        cerrarModalbodegas()
    }




    const filtrarccostos = async (coincidencia) => {
        var _codigomuns = ccostos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaccostos(_codigomuns)
    }
    const cerrarModalccostos = () => {
        setVerModalccostos(!verModalccostos);
        setbusquedaccostos(ccostos)
    }
    const buscartodasccostos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setccostos(dataJson)
                    setbusquedaccostos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setccostos([])
                    setbusquedaccostos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarccostos = (e) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['ccosto']: e.codigo
                }
                return dat
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos
        // setMovimientos(_Movimientos)

        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        cerrarModalccostos()
    }
    //para obtener la lista de sugerencias
    const onSuggestionsFetchRequested = ({ value }) => {

        const api = fetch("api/venta/Productos/" + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setA_Productos(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }

    //funcion que nos permite borrar las sugerencias
    const onSuggestionsClearRequested = () => {
        setA_Productos([])
    }

    //devuelve el texto que se mostrara en la caja de texto del autocomplete cuando seleccionas una sugerencia (item)
    const getSuggestionValue = (sugerencia) => {

        return sugerencia.codigo + " - " + sugerencia.marca + " - " + sugerencia.descripcion
    }

    //como se debe mostrar las sugerencias - codigo htmlf
    const renderSuggestion = (sugerencia) => (
        <span>
            {sugerencia.codigo + " - " + sugerencia.marca + " - " + sugerencia.descripcion}
        </span>
    )

    //evento cuando cambie el valor del texto de busqueda
    const onChange = (e, { newValue }) => {
        setA_Busqueda(newValue)
    }

    const inputProps = {
        placeholder: "Buscar producto",
        value: a_Busqueda,
        onChange
    }

    const calcularsubtotalitem = (item) => {
        var base = (Number(item.cantidadcaja + "") * Number(item.preciocaja + ""))+(Number(item.cantidadblister + "") * Number(item.precioblister + ""))+(Number(item.cantidadunidad + "") * Number(item.preciounidad + ""))
        var descuento = base * (Number(item.pordescuento + "") / 100)
        var basecondescuento = base - descuento
        var iva = basecondescuento * (Number(item.poriva + "") / 100)
        var impoconsumo = basecondescuento * (Number(item.porimptoconsumo + "") / 100)
        var retefuente = basecondescuento * (Number(item.poretefte + "") / 100)
        var total = basecondescuento + iva + impoconsumo - retefuente
        return total
    }
    const sugerenciaSeleccionada = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        Swal.fire({
            title: suggestion.marca + " - " + suggestion.descripcion,
            text: "Ingrese la cantidad",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Volver',
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {


                if (isNaN(parseFloat(inputValue))) {
                    setA_Busqueda("")
                    Swal.showValidationMessage(
                        "Debe ingresar un valor númerico"
                    )
                } else {

                    let producto = {
                        idProducto: suggestion.idProducto,
                        descripcion: suggestion.descripcion,
                        cantidad: parseInt(inputValue),
                        precio: suggestion.precio,
                        total: suggestion.precio * parseFloat(inputValue)
                    }
                    let arrayProductos = []
                    arrayProductos.push(...productos)
                    arrayProductos.push(producto)

                    setProductos((anterior) => [...anterior, producto])
                    calcularTotal(arrayProductos)
                }


            },
            allowOutsideClick: () => !Swal.isLoading()

        }).then((result) => {
            if (result.isConfirmed) {
                setA_Busqueda("")
            } else {
                setA_Busqueda("")
            }
        })
    }

    const eliminarProducto = (id) => {
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        let _enviodatos = _datos.map((dato, index) => {
            if (index == id) {
            } else {
                return dato
            }
        })
        _Movimiento['famovimiento'] = _enviodatos.filter(p => p != undefined)
        // setMovimientos(_Movimientos)

        setMovimiento([])
        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)

        // let listaproductos = productos.filter(p => p.idProducto != id)

        // setProductos(listaproductos)

        // calcularTotal(listaproductos)
    }

    const calcularTotal = (arrayProductos) => {
        let t = 0;
        let st = 0;
        let imp = 0;

        if (arrayProductos.length > 0) {

            arrayProductos.forEach((p) => {
                t = p.total + t
            })

            st = t / (1.18)
            imp = t - st
        }

        //Monto Base = (Monto con IGV) / (1.18)

        //IGV = (Monto con IGV) – (Monto Base)

        setSubTotal(st.toFixed(2))
        setIgv(imp.toFixed(2))
        setTotal(t.toFixed(2))
    }
    const ingresecantidadmensaje = () => {
        Swal.fire(
            'Opps!',
            'Seleccione un Articulo Primero',
            'error'
        )
    }

    const handlerchangegrillaarticulo = (eventt) => {
        var er = articulos.filter((dato) => {
            if (dato.codigo == eventt.target.value) { return dato }

        })
        if (er.length == 1) {
            er = er[0]
        } else {
            if (er.length == 0) {
                Swal.fire(
                    'Opps!',
                    'Articulo seleccionado no existe',
                    'error'
                )
                return
            }
            if (er.length > 1) {
                Swal.fire(
                    'Opps!',
                    'Existe mas de un articulo con este codigo',
                    'error'
                )
                return
            }
        }
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
       if (false) {
        // var preciousadocaja = 0
        // var preciousadoblister = 0
        // var preciousadounidad = 0
        // var ivausado = 0
        // if (tipodocumento.precsug != "") {
        //     articulo.inarticuloslistaprecio.map((precio) => {
        //         if (precio.listadeprecio == tipodocumento.precsug) {
        //             preciousado = precio.precio
        //         }
        //     })
        // } else {
        //     if (tercero.precsug != "") {
        //         er.inarticuloslistaprecio.map((precio) => {
        //             if (precio.listadeprecio == tercero.precsug) {
        //                 preciousado = precio.precio
        //             }
        //         })
        //     }
        // }
        // if (tipodocumento.ivaincluido == "si") {
        //     if (tipodocumento.ivasn == "si") {
        //         ivausado = Number(tipodocumento.ivaporc)
        //     } else {
        //         ivausado = Number(er.iva)
        //     }

        // }
       }
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['referencia']: er.codigo,
                    ['descrip']: er.nombre,
                    ['desadicional']: er.descadicional
                    // ['precio']: preciousado,
                    // ['cantidad']: 1
                }
                return dat
            } else {
                return dato
            }
        })
       if (false) {
         // if (tipodocumento.puntoventa == "si") {
        //     _enviodatos = _enviodatos.map((dato, index) => {
        //         if (index == fila) {
        //             var dat = {
        //                 ...dato,
        //                 ['cantidad']: 1
        //             }
        //             return dat
        //         } else {
        //             return dato
        //         }
        //     })
        // }
        // var totalll = calcularsubtotalitem(_enviodatos[fila])
        // _enviodatos = _enviodatos.map((dato, index) => {
        //     if (index == fila) {
        //         var dat = {
        //             ...dato,
        //             ['referencia']: er.codigo,
        //             ['descrip']: er.nombre,
        //             ['desadicional']: er.descadicional,
        //             ['precio']: preciousado,
        //             ['subtotal']: totalll
        //         }
        //         return dat
        //     } else {
        //         return dato
        //     }
        // })
        // var _enviodatos2 = []
        // if (tipodocumento.ivaincluido == "si" && preciousado != 0) {
        //     _enviodatos2 = _enviodatos.map((dato, index) => {
        //         if (index == fila) {
        //             var dat = {
        //                 ...dato,
        //                 ['precio']: (Number(preciousado) / ((100 + ivausado) / 100)),
        //                 ['poriva']: ivausado
        //             }
        //             return dat
        //         } else {
        //             return dato
        //         }
        //     })
        //     var totall = calcularsubtotalitem(_enviodatos2[fila])
        //     _enviodatos2 = _enviodatos2.map((dato, index) => {
        //         if (index == fila) {
        //             var dat = {
        //                 ...dato,
        //                 ['subtotal']: totall
        //             }
        //             return dat
        //         } else {
        //             return dato
        //         }
        //     })
        // }
        // if (_enviodatos2.length == 0) {

        //     if (tipodocumento.puntoventa == "si") {
        //         var totales = calculartotalesmovimiento(_enviodatos)
        //         var bodega = ""
        //         var ccosto = ""
        //         if (tipodocumento.tbodegasn == "si") {
        //             bodega = tipodocumento.tbodega
        //         }
        //         if (tipodocumento.ccosto == "si") {
        //             ccosto = tipodocumento.centrocosto
        //         }
        //         _enviodatos = _enviodatos.concat(
        //             {
        //                 item: "",
        //                 referencia: "",
        //                 descrip: "",
        //                 bodega: bodega,
        //                 cantidad: "",
        //                 precio: "",
        //                 pordescuento: "",
        //                 poriva: "",
        //                 porimptoconsumo: "",
        //                 subtotal: "",
        //                 compañia: "",
        //                 concepto: "",
        //                 nrodocumento: "",
        //                 costo: "",
        //                 desadicional: "",
        //                 unimed: "",
        //                 ccosto: ccosto,
        //                 fecha: "",
        //                 preivainc: "",
        //                 codprod: "",
        //                 canprod: "",
        //                 horafecha: "",
        //                 tipoitem: "",
        //                 tercero: "",
        //                 cliente: "",
        //                 vlr_desc: "",
        //                 vlr_iva: "",
        //                 vlr_base_iva: "",
        //                 costodolar: "",
        //                 tasa: "",
        //                 vlrimptoconsumo: "",
        //                 poretefte: "",
        //                 vlretefte: "",
        //                 porica: "",
        //                 vlrica: "",
        //                 poreteiva: "",
        //                 vlreteiva: "",
        //                 cantdig: "",
        //             })

        //     }
        //     _Movimiento['famovimiento'] = _enviodatos


        // } else {

        //     if (tipodocumento.puntoventa == "si") {
        //         var totales = calculartotalesmovimiento(_enviodatos)
        //         var bodega = ""
        //         var ccosto = ""
        //         if (tipodocumento.tbodegasn == "si") {
        //             bodega = tipodocumento.tbodega
        //         }
        //         if (tipodocumento.ccosto == "si") {
        //             ccosto = tipodocumento.centrocosto
        //         }
        //         _enviodatos2 = _enviodatos2.concat(
        //             {
        //                 item: "",
        //                 referencia: "",
        //                 descrip: "",
        //                 bodega: bodega,
        //                 cantidad: "",
        //                 precio: "",
        //                 pordescuento: "",
        //                 poriva: "",
        //                 porimptoconsumo: "",
        //                 subtotal: "",
        //                 compañia: "",
        //                 concepto: "",
        //                 nrodocumento: "",
        //                 costo: "",
        //                 desadicional: "",
        //                 unimed: "",
        //                 ccosto: ccosto,
        //                 fecha: "",
        //                 preivainc: "",
        //                 codprod: "",
        //                 canprod: "",
        //                 horafecha: "",
        //                 tipoitem: "",
        //                 tercero: "",
        //                 cliente: "",
        //                 vlr_desc: "",
        //                 vlr_iva: "",
        //                 vlr_base_iva: "",
        //                 costodolar: "",
        //                 tasa: "",
        //                 vlrimptoconsumo: "",
        //                 poretefte: "",
        //                 vlretefte: "",
        //                 porica: "",
        //                 vlrica: "",
        //                 poreteiva: "",
        //                 vlreteiva: "",
        //                 cantdig: "",
        //             })

        //     }
        //     _Movimiento['famovimiento'] = _enviodatos2

        // }
       }
        _Movimiento['famovimiento'] = _enviodatos
        var artt = listaarticulo
        artt[fila] = er
        setlistaarticulo(artt)
        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        setacabodeseleccionararticulo(true)
    }

    
    const handlerchangegrillacantidad = (eventt) => {
      
        var _Movimiento = { ...Movimiento }
        var _datos = { ...Movimiento }['famovimiento']
        var er = articulos.filter((dato) => {
            if (dato.codigo == _datos[fila].referencia) { return dato }

        })
        if (er.length == 1) {
            er = er[0]
        } else {
            if (er.length == 0) {
                Swal.fire(
                    'Opps!',
                    'Articulo seleccionado no existe',
                    'error'
                )
                return
            }
            if (er.length > 1) {
                Swal.fire(
                    'Opps!',
                    'Existe mas de un articulo con este codigo',
                    'error'
                )
                return
            }
        }
        var preciousadocaja = 0
        var preciousadoblister = 0
        var preciousadounidad = 0
        var preciousado = 0
        var nombreprecio = ""
        var nombrecantidad = ""
        er.inarticuloslistaprecio.map((precio) => {
            if (precio.presentacion=='1') {
                preciousadocaja = precio.precio
            }
            if (precio.presentacion=='2') {
                preciousadoblister = precio.precio
            }
            if (precio.presentacion=='3') {
                preciousadounidad = precio.precio
            }
        })
       if (eventt.target.name=="cantidadcaja") {
        preciousado=preciousadocaja
        nombreprecio="preciocaja"
        nombrecantidad="cantidadcaja"
       }
       if (eventt.target.name=="cantidadblister") {
        preciousado=preciousadocaja
        nombreprecio="preciocaja"
        nombrecantidad="cantidadcaja"
       }
       if (eventt.target.name=="cantidadunidad") {
        preciousado=preciousadocaja
        nombreprecio="preciocaja"
        nombrecantidad="cantidadcaja"
       }
        var _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    [nombreprecio]: preciousado,
                    [nombrecantidad]: eventt.target.value
                }
                return dat
            } else {
                return dato
            }
        })
        var totales = calculartotalesmovimiento(_enviodatos)
        var totales2 = calcularsubtotalitem(_enviodatos[fila])
        
        _enviodatos = _enviodatos.map((dato, index) => {
            if (index == fila) {
                var dat = {
                    ...dato,
                    ['subtotal']: totales2
                }
                return dat
            } else {
                return dato
            }
        })
       if (false) {
         // if (tipodocumento.puntoventa == "si") {
        //     _enviodatos = _enviodatos.map((dato, index) => {
        //         if (index == fila) {
        //             var dat = {
        //                 ...dato,
        //                 ['cantidad']: 1
        //             }
        //             return dat
        //         } else {
        //             return dato
        //         }
        //     })
        // }
        // var totalll = calcularsubtotalitem(_enviodatos[fila])
        // _enviodatos = _enviodatos.map((dato, index) => {
        //     if (index == fila) {
        //         var dat = {
        //             ...dato,
        //             ['referencia']: er.codigo,
        //             ['descrip']: er.nombre,
        //             ['desadicional']: er.descadicional,
        //             ['precio']: preciousado,
        //             ['subtotal']: totalll
        //         }
        //         return dat
        //     } else {
        //         return dato
        //     }
        // })
        // var _enviodatos2 = []
        // if (tipodocumento.ivaincluido == "si" && preciousado != 0) {
        //     _enviodatos2 = _enviodatos.map((dato, index) => {
        //         if (index == fila) {
        //             var dat = {
        //                 ...dato,
        //                 ['precio']: (Number(preciousado) / ((100 + ivausado) / 100)),
        //                 ['poriva']: ivausado
        //             }
        //             return dat
        //         } else {
        //             return dato
        //         }
        //     })
        //     var totall = calcularsubtotalitem(_enviodatos2[fila])
        //     _enviodatos2 = _enviodatos2.map((dato, index) => {
        //         if (index == fila) {
        //             var dat = {
        //                 ...dato,
        //                 ['subtotal']: totall
        //             }
        //             return dat
        //         } else {
        //             return dato
        //         }
        //     })
        // }
        // if (_enviodatos2.length == 0) {

        //     if (tipodocumento.puntoventa == "si") {
        //         var totales = calculartotalesmovimiento(_enviodatos)
        //         var bodega = ""
        //         var ccosto = ""
        //         if (tipodocumento.tbodegasn == "si") {
        //             bodega = tipodocumento.tbodega
        //         }
        //         if (tipodocumento.ccosto == "si") {
        //             ccosto = tipodocumento.centrocosto
        //         }
        //         _enviodatos = _enviodatos.concat(
        //             {
        //                 item: "",
        //                 referencia: "",
        //                 descrip: "",
        //                 bodega: bodega,
        //                 cantidad: "",
        //                 precio: "",
        //                 pordescuento: "",
        //                 poriva: "",
        //                 porimptoconsumo: "",
        //                 subtotal: "",
        //                 compañia: "",
        //                 concepto: "",
        //                 nrodocumento: "",
        //                 costo: "",
        //                 desadicional: "",
        //                 unimed: "",
        //                 ccosto: ccosto,
        //                 fecha: "",
        //                 preivainc: "",
        //                 codprod: "",
        //                 canprod: "",
        //                 horafecha: "",
        //                 tipoitem: "",
        //                 tercero: "",
        //                 cliente: "",
        //                 vlr_desc: "",
        //                 vlr_iva: "",
        //                 vlr_base_iva: "",
        //                 costodolar: "",
        //                 tasa: "",
        //                 vlrimptoconsumo: "",
        //                 poretefte: "",
        //                 vlretefte: "",
        //                 porica: "",
        //                 vlrica: "",
        //                 poreteiva: "",
        //                 vlreteiva: "",
        //                 cantdig: "",
        //             })

        //     }
        //     _Movimiento['famovimiento'] = _enviodatos


        // } else {

        //     if (tipodocumento.puntoventa == "si") {
        //         var totales = calculartotalesmovimiento(_enviodatos)
        //         var bodega = ""
        //         var ccosto = ""
        //         if (tipodocumento.tbodegasn == "si") {
        //             bodega = tipodocumento.tbodega
        //         }
        //         if (tipodocumento.ccosto == "si") {
        //             ccosto = tipodocumento.centrocosto
        //         }
        //         _enviodatos2 = _enviodatos2.concat(
        //             {
        //                 item: "",
        //                 referencia: "",
        //                 descrip: "",
        //                 bodega: bodega,
        //                 cantidad: "",
        //                 precio: "",
        //                 pordescuento: "",
        //                 poriva: "",
        //                 porimptoconsumo: "",
        //                 subtotal: "",
        //                 compañia: "",
        //                 concepto: "",
        //                 nrodocumento: "",
        //                 costo: "",
        //                 desadicional: "",
        //                 unimed: "",
        //                 ccosto: ccosto,
        //                 fecha: "",
        //                 preivainc: "",
        //                 codprod: "",
        //                 canprod: "",
        //                 horafecha: "",
        //                 tipoitem: "",
        //                 tercero: "",
        //                 cliente: "",
        //                 vlr_desc: "",
        //                 vlr_iva: "",
        //                 vlr_base_iva: "",
        //                 costodolar: "",
        //                 tasa: "",
        //                 vlrimptoconsumo: "",
        //                 poretefte: "",
        //                 vlretefte: "",
        //                 porica: "",
        //                 vlrica: "",
        //                 poreteiva: "",
        //                 vlreteiva: "",
        //                 cantdig: "",
        //             })

        //     }
        //     _Movimiento['famovimiento'] = _enviodatos2

        // }
       }
        _Movimiento['famovimiento'] = _enviodatos
        var artt = listaarticulo
        artt[fila] = er
        setlistaarticulo(artt)
        setMovimiento(_Movimiento)
        console.log(Movimiento)
        setRoles({ codig: '' })
        console.log(Roles)
        setacabodeseleccionararticulo(true)
    }
    const guardarmovimiento = () => {
        if ((tercero.venbaseica < subTotal || tercero.venbasereteiva < subTotal) && (tercero.venporica > 0 || tercero.venporcreteiva > 0)) {

            setabrirotrosimpuestos(true)
        } else {
            terminarVenta()
        }
    }
    const verificar = (_movimiento) => {
        var faencmovi = _movimiento.faencmovi
        var famovimiento = _movimiento.famovimiento
        var verificado = true
        if (faencmovi.concepto == "" || faencmovi.fecha == "" || faencmovi.cliente == "") {
            return false
        }

        famovimiento.map((dato, index) => {
            if (dato.referencia == "" || dato.bodega == "" || (dato.cantidadcaja == 0 && dato.cantidadblister == 0 && dato.cantidadunidad == 0) || (dato.preciocaja == 0  && dato.precioblister == 0  && dato.preciounidad == 0) || dato.concepto == "") {
                verificado = false
               
            }

        })
        return verificado
    }
    const terminarVenta = () => {
        if (Movimiento.famovimiento.length < 1) {
            Swal.fire(
                'Opps!',
                'No existen productos',
                'error'
            )
            return
        }

        var movimientoguardado = {
            faencmovi: {},
            famovimiento: []
        }
        movimientoguardado.faencmovi = { ...Movimiento }
        movimientoguardado.faencmovi['ndocumento'] = 0
        movimientoguardado.faencmovi['bruto'] = bruto
        movimientoguardado.faencmovi['iva'] = Number(iva)
        movimientoguardado.faencmovi['descuento'] = Number(descuento)
        movimientoguardado.faencmovi['despiefac'] = Number(movimientoguardado.faencmovi.despiefac)
        movimientoguardado.faencmovi['retefuente'] = Number(retefuente)
        movimientoguardado.faencmovi['reteiva'] = Number(reteiva)
        movimientoguardado.faencmovi['ica'] = Number(reteica)
        movimientoguardado.faencmovi['retefte'] = Number(retefuente)
        movimientoguardado.faencmovi['impconsumo'] = Number(consumo)
        movimientoguardado.faencmovi['subtotal'] = Number(subTotal)
        movimientoguardado.faencmovi['total'] = Number(total)
        movimientoguardado.faencmovi['cuotai'] = Number(movimientoguardado.faencmovi.cuotai)
        movimientoguardado.faencmovi['ncuotasi'] = Number(movimientoguardado.faencmovi.ncuotasi)
        movimientoguardado.faencmovi['diferidasi'] = Number(movimientoguardado.faencmovi.diferidasi)
        movimientoguardado.faencmovi['tipocre'] = Number(movimientoguardado.faencmovi.tipocre)
        movimientoguardado.faencmovi['ncuotasf'] = Number(movimientoguardado.faencmovi.ncuotasf)
        movimientoguardado.faencmovi['tasa'] = Number(movimientoguardado.faencmovi.tasa)
        movimientoguardado.faencmovi['diferidas'] = Number(movimientoguardado.faencmovi.diferidas)
        movimientoguardado.faencmovi['efectivo'] = Number(movimientoguardado.faencmovi.efectivo)
        movimientoguardado.faencmovi['cheque'] = Number(movimientoguardado.faencmovi.cheque)
        movimientoguardado.faencmovi['debito'] = Number(movimientoguardado.faencmovi.debito)
        movimientoguardado.faencmovi['credito'] = Number(movimientoguardado.faencmovi.credito)
        movimientoguardado.faencmovi['descuentoc'] = Number(movimientoguardado.faencmovi.descuentoc)
        movimientoguardado.faencmovi['cree'] = Number(movimientoguardado.faencmovi.cree)
        movimientoguardado.faencmovi['fletes'] = Number(movimientoguardado.faencmovi.fletes)
        movimientoguardado.faencmovi['tcambio'] = Number(movimientoguardado.faencmovi.tcambio)
        movimientoguardado.faencmovi['dsctopp1'] = Number(movimientoguardado.faencmovi.dsctopp1)
        movimientoguardado.faencmovi['dsctopp2'] = Number(movimientoguardado.faencmovi.dsctopp2)
        movimientoguardado.faencmovi['dsctopp3'] = Number(movimientoguardado.faencmovi.dsctopp3)
        movimientoguardado.faencmovi['dsctovapp1'] = Number(movimientoguardado.faencmovi.dsctovapp1)
        movimientoguardado.faencmovi['dsctovapp2'] = Number(movimientoguardado.faencmovi.dsctovapp2)
        movimientoguardado.faencmovi['dsctovapp3'] = Number(movimientoguardado.faencmovi.dsctovapp3)
        movimientoguardado.faencmovi['convdesdoc'] = Number(movimientoguardado.faencmovi.convdesdoc)
        movimientoguardado.faencmovi['convoridoc'] = Number(movimientoguardado.faencmovi.convoridoc)
        movimientoguardado.faencmovi['poretefte'] = Number(movimientoguardado.faencmovi.poretefte)
        movimientoguardado.faencmovi['poreteiva'] = Number(movimientoguardado.faencmovi.poreteiva)
        movimientoguardado.faencmovi['porica'] = Number(movimientoguardado.faencmovi.porica)
        movimientoguardado.faencmovi['porimptoconsumo'] = Number(movimientoguardado.faencmovi.porimptoconsumo)
        movimientoguardado.faencmovi['vlr_dolar'] = Number(movimientoguardado.faencmovi.vlr_dolar)
        movimientoguardado.faencmovi['ndocprod'] = Number(movimientoguardado.faencmovi.ndocprod)
        movimientoguardado.faencmovi['ndoctrasl'] = Number(movimientoguardado.faencmovi.ndoctrasl)
        movimientoguardado.faencmovi['ndocodcped'] = Number(movimientoguardado.faencmovi.ndocodcped)

        movimientoguardado.famovimiento = { ...Movimiento }['famovimiento']
        movimientoguardado.famovimiento = movimientoguardado.famovimiento.map((dato, index) => {

            if (dato.referencia != "") {
                var dat = {
                    ...dato,
                    ['nrodocumento']: 0,
                    ['concepto']: movimientoguardado.faencmovi.concepto,
                    ['item']: (index + 1),
                    ['cantidad']: Number(dato.cantidad),
                    ['cantidadcaja']: Number(dato.cantidadcaja),
                    ['cantidadblister']: Number(dato.cantidadblister),
                    ['cantidadunidad']: Number(dato.cantidadunidad),
                    ['precio']: Number(dato.precio),
                    ['preciocaja']: Number(dato.preciocaja),
                    ['precioblister']: Number(dato.precioblister),
                    ['preciounidad']: Number(dato.preciounidad),
                    ['pordescuento']: Number(dato.pordescuento),
                    ['poriva']: Number(dato.poriva),
                    ['porimptoconsumo']: Number(dato.porimptoconsumo),
                    ['subtotal']: Number(dato.subtotal),
                    ['costo']: Number(dato.costo),
                    ['preivainc']: Number(dato.preivainc),
                    ['canprod']: Number(dato.canprod),
                    ['vlr_desc']: Number(dato.vlr_desc),
                    ['vlr_iva']: Number(dato.vlr_iva),
                    ['vlr_base_iva']: Number(dato.vlr_base_iva),
                    ['costodolar']: Number(dato.costodolar),
                    ['tasa']: Number(dato.tasa),
                    ['vlrimptoconsumo']: Number(dato.vlrimptoconsumo),
                    ['poretefte']: Number(dato.poretefte),
                    ['vlretefte']: Number(dato.vlretefte),
                    ['porica']: Number(dato.porica),
                    ['vlrica']: Number(dato.vlrica),
                    ['poreteiva']: Number(dato.poreteiva),
                    ['vlreteiva']: Number(dato.vlreteiva),
                    ['cantdig']: Number(dato.cantdig),
                    ['fecha']: movimientoguardado.faencmovi.fecha,
                    ['horafecha']: movimientoguardado.faencmovi.horafecha,
                }
                return dat

            }
        })
        movimientoguardado.famovimiento = movimientoguardado.famovimiento.filter(p => p != undefined)
        console.log(Movimiento)
        console.log(modelomovimiento)
        delete movimientoguardado.faencmovi.famovimiento;

        // let venta = {
        //     documentoCliente: documentoCliente,
        //     nombreCliente: nombreCliente,
        //     tipoDocumento: tipoDocumento,
        //     idUsuario: JSON.parse(user).idUsuario,
        //     subTotal: parseFloat(subTotal),
        //     igv: parseFloat(igv),
        //     total: parseFloat(total),
        //     listaProductos: productos
        // }
        let dt = JSON.parse(user)
        var verificado = verificar(movimientoguardado)
        if (!verificado) {
            Swal.fire(
                'Opps!',
                'Datos sin llenar',
                'error'
            )
            return
        }
        const api = fetch("" + props.servidor + "/Movimientoapi?llave=" + dt.llaveempresa + "", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(movimientoguardado)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                // reestablecer();
                var data = dataJson;
                Swal.fire(
                    '' + data.concepto + ' Creado!',
                    'Numero de ' + data.concepto + ' : ' + data.ndocumento,
                    'success'
                )

            }).catch((error) => {
                Swal.fire(
                    'Opps!',
                    'No se pudo crear la venta',
                    'error'
                )
                console.log("No se pudo enviar la venta ", error)
            })

    }

    return (
        <Row >
            <Col sm={12} >
                <Card style={{ borderColor: '#fff' }}>
                    <CardBody>
                        <Row>
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', color: 'black' }} color="success" block onClick={() => { setnuevo(true) }} >
                                    <i className="fa fa-plus"></i> Nuevo Movimiento</Button>
                            </Col>
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px' }} color="primary" block onClick={() => { seteditar(true) }} >
                                    <i className="fa fa-search-plus"></i> Buscar Movimiento</Button>
                            </Col>}
                            {(true) && <Col sm={3}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block onClick={guardarmovimiento} >
                                    <i className="fa fa-cloud"></i> Guardar Movimiento</Button>
                            </Col>}
                            {(true) && <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black' }} block onClick={() => { }} >
                                    <i className="fa fa-paper-plane"></i> Enviar Correo</Button>
                            </Col>}
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => { }} >
                                    <i className="fa fa-clone"></i> Utilizar Plantilla</Button>
                            </Col>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setventa(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={8} className={(nuevo || editar) ? "" : "desabilitar"} >

                <Row className="mb-2">
                    <Col sm={12}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                Detalles


                            </CardHeader>
                            <CardBody className="mb-2 table-responsive" style={{ height: '210px' }}>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tipo Doc</Label>
                                            <InputGroup >
                                                <Input name="concepto" value={Movimiento.concepto} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "" && Movimiento.famovimiento.length < 1) {
                                                        buscartodastipodocumentos('');
                                                        setbusquedatipodocumentos(tipodocumentos)
                                                        setVerModaltipodocumentos(true)


                                                    } else {
                                                        if (Movimiento.famovimiento.length > 1) {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Al tener Articulos agregados no puede cambiar el tipo de documento',
                                                                'error'
                                                            )
                                                        }
                                                    }
                                                }} />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        if (Movimiento.famovimiento.length < 1) {
                                                            buscartodastipodocumentos('');

                                                            setbusquedatipodocumentos(tipodocumentos)
                                                            setVerModaltipodocumentos(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Al tener Articulos agregados no puede cambiar el tipo de documento',
                                                                'error'
                                                            )

                                                        }

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Fecha</Label>
                                            <Input name="fecha" value={Movimiento.fecha} onChange={handleChange} type="date" bsSize="sm" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Consecutivo</Label>
                                            <Input disabled onChange={handleChange} value={Movimiento.ndocumento} type="text" bsSize="sm" name="ndocumento" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Button style={{ fontSize: '12px', color: 'black' }} color="success" block
                                                onClick={() => {
                                                    if (Movimiento.concepto != "") {
                                                        var _Movimiento = { ...Movimiento }
                                                        var _datos = { ...Movimiento }['famovimiento']
                                                        var bodega = ""
                                                        var ccosto = ""
                                                        if (tipodocumento.tbodegasn == "si") {
                                                            bodega = tipodocumento.tbodega
                                                        }
                                                        if (tipodocumento.ccosto == "si") {
                                                            ccosto = tipodocumento.centrocosto
                                                        }
                                                        var _nuevosdatos = _datos.concat(
                                                            {
                                                                item: "",
                                                                referencia: "",
                                                                descrip: "",
                                                                bodega: bodega,
                                                                cantidad: "",
                                                                cantidadcaja: "",
                                                                cantidadblister: "",
                                                                cantidadunidad: "",
                                                                precio: "",
                                                                preciocaja: "",
                                                                precioblister: "",
                                                                preciounidad: "",
                                                                pordescuento: "",
                                                                poriva: "",
                                                                porimptoconsumo: "",
                                                                subtotal: "",
                                                                compañia: "",
                                                                concepto: "",
                                                                nrodocumento: "",
                                                                costo: "",
                                                                desadicional: "",
                                                                unimed: "",
                                                                ccosto: ccosto,
                                                                fecha: "",
                                                                preivainc: "",
                                                                codprod: "",
                                                                canprod: "",
                                                                horafecha: "",
                                                                tipoitem: "",
                                                                tercero: "",
                                                                cliente: "",
                                                                vlr_desc: "",
                                                                vlr_iva: "",
                                                                vlr_base_iva: "",
                                                                costodolar: "",
                                                                tasa: "",
                                                                vlrimptoconsumo: "",
                                                                poretefte: "",
                                                                vlretefte: "",
                                                                porica: "",
                                                                vlrica: "",
                                                                poreteiva: "",
                                                                vlreteiva: "",
                                                                cantdig: "",
                                                            }

                                                        )
                                                        _Movimiento['famovimiento'] = _nuevosdatos
                                                        setMovimiento(_Movimiento)

                                                    } else {
                                                        Swal.fire(
                                                            'Opps!',
                                                            'No ha seleccionado un Tipo de Documento',
                                                            'error'
                                                        )
                                                    }
                                                }} >
                                                <i className="fa fa-plus"></i> Agregar Item</Button>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Tercero</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.cliente} bsSize="sm" onKeyDown={(e) => {
                                                    var tieneimpuestositems = false
                                                    Movimiento.famovimiento.map((dato, index) => {
                                                        if (Number(dato.poretefte) != 0) {
                                                            tieneimpuestositems = true
                                                        }
                                                    })
                                                    if (e.key == "Enter" && e.target.value == "" && !tieneimpuestositems) {
                                                        buscartodasterceros('');
                                                        setbusquedaterceros(terceros)
                                                        setVerModalterceros(true)

                                                    } else {
                                                        if (tieneimpuestositems) {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )
                                                        }

                                                    }
                                                }} name="cliente" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        var tieneimpuestositems = false
                                                        Movimiento.famovimiento.map((dato, index) => {
                                                            if (Number(dato.poretefte) != 0) {
                                                                tieneimpuestositems = true
                                                            }
                                                        })
                                                        if (!tieneimpuestositems) {
                                                            buscartodasterceros('');
                                                            setbusquedaterceros(terceros)
                                                            setVerModalterceros(true)
                                                        } else {
                                                            Swal.fire(
                                                                'Opps!',
                                                                'Debe dejar los impuestos en 0',
                                                                'error'
                                                            )
                                                        }


                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Input onChange={handleChange} value={Movimiento.nombrecli} type="text" bsSize="sm" name="nombrecli" />
                                        </FormGroup>
                                    </Col>
                                    {(tipodocumento.docu1 == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>{(tipodocumento.captionvend == "") ? 'Documento 1' : tipodocumento.captiondoc1}</Label>
                                            <Input onChange={handleChange} value={Movimiento.documento1} type="text" bsSize="sm" name="documento1" />
                                        </FormGroup>
                                    </Col>}

                                    {(tipodocumento.docu2 == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>{(tipodocumento.captionvend == "") ? 'Documento 2' : tipodocumento.captiondoc2}</Label>
                                            <Input onChange={handleChange} value={Movimiento.documento2} type="text" bsSize="sm" name="documento2" />
                                        </FormGroup>
                                    </Col>}
                                    {(tipodocumento.mvendedor == "si") && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>{(tipodocumento.captionvend == "") ? 'Vendedor' : tipodocumento.captionvend}</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.vendedor} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        buscartodasvendedors('');
                                                        setbusquedavendedors(vendedors)
                                                        setVerModalvendedors(true)

                                                    }
                                                }} name="vendedor" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        buscartodasvendedors('');
                                                        setbusquedavendedors(vendedors)
                                                        setVerModalvendedors(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}
                                    {(tipodocumento.mvendedor == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>-</Label>
                                            <Input onChange={handleChange} value={Movimiento.nombreven} type="text" bsSize="sm" name="nombreven" />
                                        </FormGroup>
                                    </Col>}

                                    {(tipodocumento.formapago == "si") && <Col sm={3}>
                                        <FormGroup>
                                            <Label>Forma de Pago</Label>
                                            <Input onChange={handleChange} value={Movimiento.fpago} type="select" style={{ fontSize: '13px' }} name="fpago"  >
                                                <option value="contado">Contado</option>
                                                <option value="credito">Credito</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>}
                                    {(true) && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Medio de Pago</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.mpago} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        buscartodasmediopagos('');
                                                        setbusquedamediopagos(mediopagos)
                                                        setVerModalmediopagos(true)

                                                    }
                                                }} name="mpago" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        buscartodasmediopagos('');
                                                        setbusquedamediopagos(mediopagos)
                                                        setVerModalmediopagos(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}
                                    {(false) && <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Sucursal</Label>
                                            <InputGroup>
                                                <Input value={Movimiento.sucursal} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {


                                                    }
                                                }} name="sucursal" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {

                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></ i >
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Col>

            <Col sm={4} className={(nuevo || editar) ? "" : "desabilitar"} >
                <Row>
                    <Col sm={12}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                Totales
                            </CardHeader>
                            <CardBody className="mb-2 table-responsive" style={{ height: '210px' }}>
                                <Row >
                                    {/* <Col sm={12 }>
                                        <div style={{ display: 'inline' }}>
                                            <a className="labelmov">Bruto:</a>
                                            <a className="inputmov">0</a>
                                        </div>
                                    </Col>
                                    <Col style={{marginTop:'-1px'}} sm={12}>
                                        <div style={{ display: 'inline' }}>
                                            <a className="labelmov">Bruto:</a>
                                            <a className="inputmov">0</a>
                                        </div>
                                    </Col> */}
                                    <InputGroup size="sm" style={{ marginTop: '-20px' }}>
                                        <InputGroupText className="labelmov" >Bruto:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(bruto)} />
                                    </InputGroup>
                                    {(tipodocumento.descsn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov" >-Descuento:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(descuento)} />
                                    </InputGroup>}
                                    <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">Sub Total:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(subTotal)} />
                                    </InputGroup>
                                    {(tipodocumento.ivasn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">+IVA:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(iva)} />
                                    </InputGroup>}
                                    {(tipodocumento.imptosn == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">+Consumo:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(consumo)} />
                                    </InputGroup>}
                                    {(tipodocumento.reteiva == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">-ReteIva:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(reteiva)} />
                                    </InputGroup>}
                                    {(tipodocumento.rete == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">-ReteFuente:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(retefuente)} />
                                    </InputGroup>}
                                    {(tipodocumento.ica == "si") && <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmov">-ReteIca:</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(reteica)} />
                                    </InputGroup>}
                                    <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                        <InputGroupText className="labelmovtotal">Total:</InputGroupText>
                                        <Input disabled className="inputmovtotal" value={fm3.from(total)} />
                                    </InputGroup>
                                </Row>





                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col sm={12} className={(nuevo || editar) ? "" : "desabilitar"} >
                <Row>
                    <Col sm={12}>
                        <Card style={{ borderColor: '#188BAD' }}>
                            <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                Productos
                            </CardHeader>
                            <CardBody >

                                <div className="table-responsive" style={{ height: '300px' }}>
                                    <Table striped  >
                                        <thead>
                                            <tr>
                                                <th style={{ width: '1%', fontSize: '11px' }}>I</th>
                                                <th style={{ width: '10%' }}>Producto</th>
                                                <th style={{ width: '20%' }}>Descripcion</th>
                                                <th style={{ width: '7%' }}>Bod</th>
                                                <th>Caja</th>
                                                <th style={{ width: '17%' }}>Precio</th>
                                                <th>Blister</th>
                                                <th style={{ width: '17%' }}>Precio</th>
                                                <th>Unidad</th>
                                                <th style={{ width: '17%' }}>Precio</th>
                                                {(tipodocumento.descsn == "si") && <th style={{ width: '7%' }}>Desc</th>}
                                                {(tipodocumento.ivasn == "si") && <th style={{ width: '8%' }}>Iva</th>}

                                                {(tipodocumento.imptosn == "si") && <th style={{ width: '7%' }}>Cons</th>}

                                                {(tipodocumento.rete == "si") && <th style={{ width: '8%' }}>rfte</th>}
                                                {(tipodocumento.ccosto == "si") && <th style={{ width: '8%' }}>C.C</th>}
                                                <th style={{ width: '50%' }}>Subtotal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Movimiento.famovimiento.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="11">Sin productos</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Movimiento.famovimiento.map((item, index) => (
                                                            <tr key={index}>

                                                                <td>
                                                                    <a href="#" onFocus={() => { setfila(index) }} style={{ fontSize: '11px' }} id={"movimiento" + "item" + index} className="fas fa-pen-alt" onClick={() => {
                                                                        setabriropcionespuntoventa(true)
                                                                        // eliminarProducto(index)
                                                                    }}>{index + 1}</ a >
                                                                </td>

                                                                <td>
                                                                    <Input onChange={handleChangegrillafaencmovi} id={"movimiento" + "referencia" + index} onFocus={(e) => {
                                                                        setfila(index)
                                                                        // setacabodeseleccionararticulo(false)
                                                                    }} tabla='famovimiento' className="inputmovgrilla" name='referencia' value={item.referencia} onKeyDown={(e) => {
                                                                        if (e.key == "Enter") {
                                                                            if (tipodocumento.puntoventa == 'si') {
                                                                                e.target.blur()

                                                                            }
                                                                            buscartodasarticulos('');
                                                                            setbusquedaarticulos(articulos)
                                                                            setVerModalarticulos(true)

                                                                        }
                                                                        if (e.key == "Tab") {
                                                                            e.target.blur()

                                                                            handlerchangegrillaarticulo(e)
                                                                        }
                                                                    }} />
                                                                </td>
                                                                <td>
                                                                    <abbr title={item.descrip + " " + item.desadicional}><Input onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" id={"movimiento" + "descrip" + index} name='descrip' value={item.descrip + " " + item.desadicional} /></abbr>
                                                                </td>
                                                                <td>
                                                                    <abbr title={item.bodega + " " + item.nombrebodega}><Input id={"movimiento" + "bodega" + index} disabled={(tipodocumento.tbodegafija == "si" ? true : false)} onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" name='bodega' value={item.bodega} onKeyDown={(item.referencia != "") ? (e) => {
                                                                        if (e.key == "Enter") {
                                                                            buscartodasbodegas('');
                                                                            setbusquedabodegas(bodegas)
                                                                            setVerModalbodegas(true)

                                                                        }
                                                                    } : ingresecantidadmensaje} /></abbr>
                                                                </td>
                                                                {/* <td>  <Input onFocus={(e) => {
                                                                    setfila(index)
                                                                    e.target.value = (item.cantidad + "").replace(/\,/g, '')
                                                                    setpuntodeventacambiarcantidad(false)
                                                                }} onBlur={(e) => {
                                                                    e.target.value = fm2.from(Number(item.cantidad))
                                                                }} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" id={"movimiento" + "cantidad" + index} name='cantidad' value={item.cantidad} /></td>
                                                                <td>
                                                                    <abbr title={fm2.from(Number(item.precio))}><Input onFocus={(e) => {
                                                                        setfila(index)
                                                                        console.log(item.precio)
                                                                        e.target.value = (item.precio + "").replace(/\,/g, '')
                                                                        console.log(e.target.value)
                                                                        e.target.setAttribute('cambio', 'no')
                                                                        setpuntodeventacambiarprecio(false)
                                                                    }} onBlur={(e) => {
                                                                        if (tipodocumento.ivaincluido == "si" && e.target.getAttribute('cambio') == 'si') {
                                                                            ivaincluido(e)

                                                                        }
                                                                        e.target.value = fm2.from(Number(item.precio))
                                                                    }} cambio="no" id={"movimiento" + "precio" + index} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" name='precio' value={fm2.from(item.precio)} /></abbr>
                                                                </td> */}
                                                                {/* caja */}
                                                                <td>  <Input onFocus={(e) => {
                                                                    setfila(index)
                                                                    e.target.value = (item.cantidadcaja + "").replace(/\,/g, '')
                                                                    setpuntodeventacambiarcantidad(false)
                                                                }} onBlur={(e) => {
                                                                    e.target.value = fm2.from(Number(item.cantidadcaja))
                                                                }} onChange={(item.referencia != "") ? handlerchangegrillacantidad : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" id={"movimiento" + "cantidadcaja" + index} name='cantidadcaja' value={item.cantidadcaja} /></td>
                                                                <td>
                                                                    <abbr title={fm2.from(Number(item.preciocaja))}><Input onFocus={(e) => {
                                                                        setfila(index)
                                                                        console.log(item.preciocaja)
                                                                        e.target.value = (item.preciocaja + "").replace(/\,/g, '')
                                                                        console.log(e.target.value)
                                                                        e.target.setAttribute('cambio', 'no')
                                                                        setpuntodeventacambiarprecio(false)
                                                                    }} onBlur={(e) => {
                                                                        if (tipodocumento.ivaincluido == "si" && e.target.getAttribute('cambio') == 'si') {
                                                                            ivaincluido(e)

                                                                        }
                                                                        e.target.value = fm2.from(Number(item.preciocaja))
                                                                    }} cambio="no" id={"movimiento" + "preciocaja" + index} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" name='preciocaja' value={fm2.from(item.preciocaja)} /></abbr>
                                                                </td>
                                                                {/* blister */}
                                                                <td>  <Input onFocus={(e) => {
                                                                    setfila(index)
                                                                    e.target.value = (item.cantidadblister + "").replace(/\,/g, '')
                                                                    setpuntodeventacambiarcantidad(false)
                                                                }} onBlur={(e) => {
                                                                    e.target.value = fm2.from(Number(item.cantidadblister))
                                                                }} onChange={(item.referencia != "") ? handlerchangegrillacantidad : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" id={"movimiento" + "cantidadblister" + index} name='cantidadblister' value={item.cantidadblister} /></td>
                                                                <td>
                                                                    <abbr title={fm2.from(Number(item.precioblister))}><Input onFocus={(e) => {
                                                                        setfila(index)
                                                                        console.log(item.precioblister)
                                                                        e.target.value = (item.precioblister + "").replace(/\,/g, '')
                                                                        console.log(e.target.value)
                                                                        e.target.setAttribute('cambio', 'no')
                                                                        setpuntodeventacambiarprecio(false)
                                                                    }} onBlur={(e) => {
                                                                        if (tipodocumento.ivaincluido == "si" && e.target.getAttribute('cambio') == 'si') {
                                                                            ivaincluido(e)

                                                                        }
                                                                        e.target.value = fm2.from(Number(item.precioblister))
                                                                    }} cambio="no" id={"movimiento" + "precioblister" + index} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" name='precioblister' value={fm2.from(item.precioblister)} /></abbr>
                                                                </td>
                                                                {/* unidad */}
                                                                <td>  <Input onFocus={(e) => {
                                                                    setfila(index)
                                                                    e.target.value = (item.cantidadunidad + "").replace(/\,/g, '')
                                                                    setpuntodeventacambiarcantidad(false)
                                                                }} onBlur={(e) => {
                                                                    e.target.value = fm2.from(Number(item.cantidadunidad))
                                                                }} onChange={(item.referencia != "") ? handlerchangegrillacantidad : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" id={"movimiento" + "cantidadunidad" + index} name='cantidadunidad' value={item.cantidadunidad} /></td>
                                                                <td>
                                                                    <abbr title={fm2.from(Number(item.preciounidad))}><Input onFocus={(e) => {
                                                                        setfila(index)
                                                                        console.log(item.preciounidad)
                                                                        e.target.value = (item.preciounidad + "").replace(/\,/g, '')
                                                                        console.log(e.target.value)
                                                                        e.target.setAttribute('cambio', 'no')
                                                                        setpuntodeventacambiarprecio(false)
                                                                    }} onBlur={(e) => {
                                                                        if (tipodocumento.ivaincluido == "si" && e.target.getAttribute('cambio') == 'si') {
                                                                            ivaincluido(e)

                                                                        }
                                                                        e.target.value = fm2.from(Number(item.preciounidad))
                                                                    }} cambio="no" id={"movimiento" + "preciounidad" + index} onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} tabla='famovimiento' className="inputmovgrilla" name='preciounidad' value={fm2.from(item.preciounidad)} /></abbr>
                                                                </td>
                                                                {(tipodocumento.descsn == "si") && <td>  <Input onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='pordescuento' value={item.pordescuento} /></td>}

                                                                {(tipodocumento.ivasn == "si") && <td>  <Input onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='poriva' value={item.poriva} /></td>}
                                                                {(tipodocumento.imptosn == "si") && <td>  <Input onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='porimptoconsumo' value={item.porimptoconsumo} /></td>}
                                                                {(tipodocumento.rete == "si") && <td>  <Input onFocus={() => { setfila(index) }} tabla='famovimiento' onChange={(item.referencia != "") ? handleChangegrillafaencmovi : ingresecantidadmensaje} className="inputmovgrilla" name='poretefte' value={item.poretefte} /></td>}
                                                                {(tipodocumento.ccosto == "si") &&
                                                                    <td>
                                                                        <abbr title={item.ccosto}><Input disabled={(tipodocumento.tcostofijo == "si") ? true : false} onFocus={() => { setfila(index) }} tabla='famovimiento' className="inputmovgrilla" name='ccosto' value={item.ccosto} onKeyDown={(item.referencia != "") ? (e) => {
                                                                            if (e.key == "Enter") {
                                                                                buscartodasccostos('');
                                                                                setbusquedaccostos(ccostos)
                                                                                setVerModalccostos(true)
                                                                            }
                                                                        } : ingresecantidadmensaje} /></abbr>
                                                                    </td>}
                                                                <td>  <Input onFocus={() => { setfila(index) }} tabla='famovimiento' disabled className="inputmovgrilla" name='referencia' value={fm.from(item.subtotal)} /></td>
                                                                <Modal size="lg" isOpen={abrirdescripcionadicional}>
                                                                    <ModalHeader>
                                                                        Descripcion Adicional
                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        <Input sm="lg" onChange={handleChangegrillafaencmovi} value={item.desadicional} tabla='famovimiento' type="textarea" name="desadicional" />
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <Button size="sm" color="primary" onClick={() => { setabrirdescripcionadicional(false) }}>Listo</Button>
                                                                    </ModalFooter>
                                                                </Modal>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Modal size="lg" isOpen={abrirotrosimpuestos}>
                <ModalHeader>
                    Otros Impuestos
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {(tercero.venporcreteiva > 0) && <Col sm={12}>
                            <Card style={{ borderColor: '#188BAD' }}>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    ReteIva
                                </CardHeader>
                                <CardBody >
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Base Minina</InputGroupText>
                                        <Input disabled className="inputmov" value={fm2.from(tercero.venbasereteiva)} />
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Porcentaje</InputGroupText>
                                        <Input name="poreteiva" onBlur={(e) => { e.target.value = fm1.from(Number(porcentajereteiva + "")) + " %" }} tabla="famovimiento" onChange={handleChangeimpuestosglobales} onFocus={(e) => { e.target.value = (porcentajereteiva + "").replace(/\,/g, '') }} className="inputmov" value={porcentajereteiva} />
                                    </InputGroup>
                                </CardBody >
                            </Card >
                        </Col>}
                        {(tercero.venporica > 0) && <Col sm={12}>
                            <Card style={{ borderColor: '#188BAD' }}>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    Reteica
                                </CardHeader>
                                <CardBody >
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Base Minina</InputGroupText>
                                        <Input className="inputmov" value={fm2.from(tercero.venbaseica)} />
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0px' }}>
                                        <InputGroupText className="labelmov" >Porcentaje</InputGroupText>
                                        <Input name="porica" onBlur={(e) => { e.target.value = fm1.from(Number(porcentajeica + "")) + " %" }} tabla="famovimiento" onChange={handleChangeimpuestosglobales} onFocus={(e) => { e.target.value = (porcentajeica + "").replace(/\,/g, '') }} className="inputmov" value={porcentajeica} />
                                    </InputGroup>
                                </CardBody >
                            </Card >
                        </Col>}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        cambiardatosconimpuesto()
                        setabrirotrosimpuestos(false)
                        terminarVenta()
                    }}>Listo</Button>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={abriropcionespuntoventa}>
                <ModalHeader>
                    Opciones{(tipodocumento.puntoventa == "si") && " Punto Venta"}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {(tipodocumento.puntoventa == "si") && <Col sm={6}>

                            <Button style={{ fontSize: '12px', backgroundColor: '#ffa1eb', color: 'black' }} block onClick={() => { setpuntodeventacambiarcantidad(true); setabriropcionespuntoventa(false); document.getElementById('movimientoreferencia' + (fila)).focus() }} >
                                <i className="fa fa-refresh"></i> Cambiar Cantidad</Button>
                        </Col>}
                        {(tipodocumento.puntoventa == "si") && <Col sm={6}>
                            <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black', marginTop: '5px' }} block onClick={() => { setpuntodeventacambiarprecio(true); setabriropcionespuntoventa(false); document.getElementById('movimientoreferencia' + (fila)).focus() }} >
                                <i className="fa fa-refresh"></i> Cambiar Precio</Button>
                        </Col>

                        }
                        <Col sm={6}>
                            <Button style={{ fontSize: '12px', backgroundColor: 'red', color: 'white' }} block onClick={() => { eliminarProducto(fila); setabriropcionespuntoventa(false); document.getElementById('movimientoreferencia' + (fila)).focus() }} >
                                <i className="fa fa-time"></i> Eliminar Articulo</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>

                    <Button size="sm" color="primary" onClick={() => {
                        setabriropcionespuntoventa(false)
                    }}>cerrar</Button>
                </ModalFooter>
            </Modal>
            <Buscar tituloencabezado={"Buscar Tipo Documento"} filtrar={(coincidencia) => { filtrartipodocumentos(coincidencia) }} busqueda={busquedatipodocumentos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"descripcion"} dato={""} dato2={""} verModal={verModaltipodocumentos} cerrarModal={() => { cerrarModaltipodocumentos() }} seleccionar={(seleccionado) => {
                seleccionartipodocumentos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Tercero"} filtrar={(coincidencia) => { filtrarterceros(coincidencia) }} busqueda={busquedaterceros} encabezado={["Codigo", "Razon Social"]} codigo={"codigo"} nombre={"razsoc"} dato={""} dato2={""} verModal={verModalterceros} cerrarModal={() => { cerrarModalterceros() }} seleccionar={(seleccionado) => {
                seleccionarterceros(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Vendedor"} filtrar={(coincidencia) => { filtrarvendedors(coincidencia) }} busqueda={busquedavendedors} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalvendedors} cerrarModal={() => { cerrarModalvendedors() }} seleccionar={(seleccionado) => {
                seleccionarvendedors(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Medio de Pago"} filtrar={(coincidencia) => { filtrarmediopagos(coincidencia) }} busqueda={busquedamediopagos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalmediopagos} cerrarModal={() => { cerrarModalmediopagos() }} seleccionar={(seleccionado) => {
                seleccionarmediopagos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Productos"} filtrar={(coincidencia) => { filtrararticulos(coincidencia) }} busqueda={busquedaarticulos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalarticulos} cerrarModal={() => { cerrarModalarticulos() }} seleccionar={(seleccionado) => {
                seleccionararticulos(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Bodega"} filtrar={(coincidencia) => { filtrarbodegas(coincidencia) }} busqueda={busquedabodegas} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalbodegas} cerrarModal={() => { cerrarModalbodegas() }} seleccionar={(seleccionado) => {
                seleccionarbodegas(seleccionado)
            }} />
            <Buscar tituloencabezado={"Buscar Centro de Costo"} filtrar={(coincidencia) => { filtrarccostos(coincidencia) }} busqueda={busquedaccostos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalccostos} cerrarModal={() => { cerrarModalccostos() }} seleccionar={(seleccionado) => {
                seleccionarccostos(seleccionado)
            }} />
        </Row>
    )
}

export default Venta;