import { useState } from "react";
import { useContext, useEffect } from "react";
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import React, { UserContext } from "../context/UserProvider";
import Swal from 'sweetalert2'

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const Inicio = (props) => {

    const { user } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [logo, setlogo] = useState("")
    const [certificado, setcertificado] = useState("")

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])
    const base64certificado = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const guardarlogo = async (e) => {
        try {
            let dt = JSON.parse(user);
            const url = props.servidor + '/Configfacturaapi/guardarlogo/' + dt.empresa.config.ruta_logo + '?llave=' + dt.usuario.llaveempresa;

            const requestData = {
                // Coloca aquí los datos que deseas enviar en el cuerpo de la solicitud
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(logo),
            };

            const response = await fetch(url, requestOptions);

            if (response.ok) {
                const dataJson = await response.json();
                if (dataJson == true) {
                    Swal.fire(
                        'Opps!',
                        'Logo Actualizado. Presione Ctrl F5 para refrescar',
                        'error'
                    );
                }
                console.log(dataJson);
            } else {
                const error = await response.text();
                Swal.fire(
                    'Opps!',
                    'No pudieron cargar los movimientos: ' + error,
                    'error'
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    const guardarcertificado = async (e) => {
        try {
            let dt = JSON.parse(user);
            const url = props.servidor + '/Configfacturaapi/guardarcertificado?llave=' + dt.usuario.llaveempresa;

            const requestData = {
                // Coloca aquí los datos que deseas enviar en el cuerpo de la solicitud
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(certificado),
            };

            const response = await fetch(url, requestOptions);

            if (response.ok) {
                const dataJson = await response.json();
                if (dataJson == true) {
                    Swal.fire(
                        'Exito!',
                        'Logo Actualizado. Presione Ctrl F5 para refrescar',
                        'succes'
                    );
                }
                console.log(dataJson);
            } else {
                const error = await response.text();
                Swal.fire(
                    'Opps!',
                    'No pudieron cargar los movimientos: ' + error,
                    'error'
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <Row>
                <Col sm={12} className="text-left">
                    <h2>{dataUser.nombreempresa}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <div className="card">
                        <img className="card-img-top" src={"./imagen/Foto003.jpg"} alt="Card image cap" />
                    </div>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label>Nombre</Label>
                        <Input disabled value={dataUser.usuario.nombre} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Cargo</Label>
                        <Input disabled value={dataUser.usuario.cargo} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Grupo</Label>
                        <Input disabled value={dataUser.usuario.cargo} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Logo</Label>
                        <Input bsSize="sm" placeholder="Seleccionar Logo" type={"file"} onChange={async (e) => {
                            var archivoxml = e.target.files[0]
                            var cerbase64 = await base64certificado(archivoxml)
                            setlogo(cerbase64)
                        }} >
                        </Input>
                    </FormGroup>
                    <Button style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block onClick={guardarlogo} >
                        <i className="fa fa-cloud"></i>Guardar Logo</Button>
                    <FormGroup>
                        <Label>Certificado</Label>
                        <Input bsSize="sm" placeholder="Seleccionar Certificado" type={"file"} onChange={async (e) => {
                            var archivoxml = e.target.files[0]
                            var cerbase64 = await base64certificado(archivoxml)
                            setcertificado(cerbase64)
                        }} >
                        </Input>
                    </FormGroup>
                    <Button style={{ fontSize: '12px', backgroundColor: '#188BAD' }} block onClick={guardarcertificado} >
                        <i className="fa fa-cloud"></i>Guardar Certificado</Button>
                </Col>
                <Col sm={3}>
                </Col>
            </Row>
        </>

    )
}

export default Inicio;