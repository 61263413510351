import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../context/UserProvider';
import { useState } from 'react';
import { Button } from "reactstrap";
import '../../css/Venta.css'
import Icono2 from '../../images/informes/informeventas_auto_x2.jpg'
import IconoCartera from '../../images/informes/cartera.jpg'
import IconoInventario from '../../images/informes/inventario.jpg'
import IconoTesoreria from '../../images/informes/tesoreria.jpg'
import IconoContabilidad from '../../images/informes/contabilidad.jpg'
import IconoSalir from '../../images/informes/salir.jpg'

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}


const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [key, setKey] = React.useState('home');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [historialventa, sethistorialventa] = React.useState(false);
    const [reporteVenta, setreporteVenta] = React.useState(false);
    const [reporteInventario, setreporteInventario] = React.useState(false);
    const [reporteInventarioDrogueria, setreporteInventarioDrogueria] = React.useState(false);
    const [reporteringcall, setreporteringcall] = React.useState(false);

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])
    const handleClick = (reportName) => {
        // Lógica para generar el informe
        console.log(`Generando informe: ${reportName}`);
    };

    return (

        <>

            <div className="button-container">
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setmovimientoporconcepto(true) }}
                    style={{ backgroundColor: '#8B008B', fontSize: '12px' }}
                >
                    Movimientos Por Concepto
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setconceptosafectancaja(true) }}
                    style={{ backgroundColor: '#008000', fontSize: '11px' }}
                >
                    Conceptos que Afectan Caja
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setresumendiariodeventas(true) }}
                    style={{ backgroundColor: '#008080', fontSize: '11px' }}
                >
                    Diario de Ventas
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setlistdoivaydescuentos(true) }}
                    style={{ backgroundColor: '#4682B4', fontSize: '11px' }}
                >
                    Iva y Descuento
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setlistdoivaydescuentoscruzados(true) }}
                    style={{ backgroundColor: '#4682B4', fontSize: '11px' }}
                >
                    Listado de Movimientos Cruzados
                </Button>

                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setcontrolconsecutivos(true) }}
                    style={{ backgroundColor: '#00CED1', fontSize: '11px' }}
                >
                    Control de Consecutivos
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setmovimientodetalleconcartera(true) }}
                    style={{ backgroundColor: '#00CED5', fontSize: '11px' }}
                >
                    Detalle Cartera
                </Button>

                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setreporteVentasalir(false) }}
                    style={{ backgroundColor: '#000000', fontSize: '11px' }}
                >
                    Salir
                </Button>

            </div>
            <div className="button-container2">
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setultimomovimiento(true) }}
                    style={{ backgroundColor: '#00CED5', fontSize: '11px' }}
                >
                    Ultimo Movimiento
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setmovimientosvendedorcomision(true) }}
                    style={{ backgroundColor: '#00CED5', fontSize: '11px' }}
                >
                    Movimiento Comision Vendedor
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setmovimientoselectronicos(true) }}
                    style={{ backgroundColor: '#00CED5', fontSize: '11px' }}
                >
                    Movimientos Electronicos
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setcorreosenviados(true) }}
                    style={{ backgroundColor: '#00CED5', fontSize: '11px' }}
                >
                    Correos Enviados
                </Button>
                <Button
                    className="report-button movie-button"
                    onClick={() => { props.setmovimientomercancia(true) }}
                    style={{ backgroundColor: '#00CED5', fontSize: '11px' }}
                >
                    Movimientos Mercancia
                </Button>
            </div>

            <div className="button-container">


            </div>
        </>
    );
}

export default Reportes