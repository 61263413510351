import axios from "axios";
import React, { Fragment, useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

toast.configure();

const TableCards = ({
  nombre,
  toggleTab,
  mesa,
  setmesa,
  fetchTables,
  notify,
  notify2,
  notify3,
}) => {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const deleteTable = async (id) => {
    // await axios
    //   .delete(`/tables/delete/${id}`)
    //   .then(
    //     (res) =>
    //       res.status &&
    //       res.config.method &&
    //       notify(res.status, res.config.method)
    //   );
    // fetchTables();
    // // setTables(tables.filter((mesa) => mesa.tId !== tId));
  };

  const getTable = (mesa) => {
    setmesa({
      codigo: mesa.codigo,
      nombre: mesa.nombre
    });
  };

  return (
    <Fragment>
      <div key={mesa.codigo} className="cardsinsidetables">
        {/* <Card
          className={`mb-3 shadow rounded ${
            mesa.reserved === "true" ? "reservedColor" : ""
          }`}
        > */}
        <Card
          className="mb-3 shadow rounded"
          outline
        // color={`${mesa.reserved === "true" ? "danger" : null}`}
        >
          <CardHeader>
            {" "}
            <div className="flexbetween">
              <div>
                <span>{nombre}</span>
              </div>
              {true === true ? (
                <div>
                  <Button
                    color="danger"
                    size="sm"
                    className=" shadow rounded"
                    onClick={() => toggle()}
                  >
                    <i className="fas fa-times align-middle"></i>
                  </Button>
                </div>
              ) : null}
            </div>
          </CardHeader>
          <CardBody>
            <CardText>
              {" "}
              <legend>
                Estado :{" "}
                <strong>
                  {/* {mesa.reserved === "true" ? ( */}
                  {mesa.ocupada === "si" ? (
                    <i className="fas fa-times text-danger align-middle"></i>
                  ) : (
                    <i className="fas fa-check text-success align-middle"></i>
                  )}
                </strong>
              </legend>
              {/* <img src={"images/mesa.jpg"}  alt="Mesa" className="img-fluid mt-3" /> */}
            </CardText>
          </CardBody>
          <CardFooter>

            <Button
              className="not-allowed"
              size="sm"
              onClick={() => {
                // toggleTab("2");
                getTable(mesa);
                notify()
                // selectedTable(mesa);
              }}
              color="primary"
              disabled={mesa.ocupada === "si"}
            >
              Ordenar
            </Button>
            <Button
              className="not-allowed"
              size="sm"
              onClick={() => {
                // toggleTab("2");
                getTable(mesa);
                notify2()
                // selectedTable(mesa);
              }}
              color="primary"
              disabled={mesa.ocupada != "si"}
            >
              Editar
            </Button>
            <Button
              className="not-allowed"
              size="sm"
              onClick={() => {
                // toggleTab("2");
                getTable(mesa);
                fetchTables(mesa)
                // selectedTable(mesa);
              }}
              color="danger"
            // disabled={mesa.reserved === "true"}
            >
              Liberar
            </Button>
            <Button
              className="not-allowed"
              size="sm"
              onClick={() => {
                // toggleTab("2");
                notify3(mesa)
                // selectedTable(mesa);
              }}
              color="danger"
            // disabled={mesa.reserved === "true"}
            >
              Factura Electronica
            </Button>
          </CardFooter>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} charCode="x">
              Confirmation
            </ModalHeader>
            <ModalBody>
              <Container>Are you sure?</Container>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  deleteTable(mesa.codigo);
                  toggle();
                }}
              >
                Yes
              </Button>

              <Button
                size="sm"
                color="danger"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Card>
      </div>
    </Fragment>
  );
};

export default TableCards;
