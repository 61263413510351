import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloCorreo = {
    id: "",
    emisor: "",
    asunto: "",
    cuerpo: "",
    adjuntos: []
}
const Correo = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Correo, setCorreo] = useState(modeloCorreo);
    const [Correos, setCorreos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [clave, setclave] = useState([]);
    const [claves, setclaves] = useState([]);
    const [host, sethost] = useState([]);
    const [hosts, sethosts] = useState([]);
    const [puerto, setpuerto] = useState([]);
    const [puertos, setpuertos] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [concorreos, setconcorreos] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalCorreo, setVerModalCorreo] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Correo = { ...Correo }
        _Correo[e.target.name] = value
        setCorreo(_Correo)
        setRoles([])


    }

    const obtenerConCorreos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ConCorreoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setconcorreos(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerCorreos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Correorecibidofacturaapi?llave=' + dt.usuario.llaveempresa + '&correo='+coincidencia);

            if (response.ok) {
                let data = await response.json()
                setCorreos(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        // obtenerCorreos('');
        obtenerConCorreos('');
    }, [])
    const CustomCuerpo = ({ row }) => (
        <div >
            {row.cuerpo}
        </div>
    );

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
            sortable: true,
            width: "80px"
        },
        {
            name: 'Emisor',
            selector: row => row.emisor,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Asunto',
            selector: row => row.asunto,
            sortable: true,
            width: "300px"

        },
        {
            name: 'Cuerpo',
            selector: row => row.cuerpo,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Adjuntos',
            selector: row => row.cantidadadjuntos,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalCorreo(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => haceracuse(row)}
                    >
                        <i className="fa fa-truck"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const haceracuse = async (data) => {
        var enviodata = { ...data }
        let response;
        let dt = JSON.parse(user)
        try {
            response = await fetch("" + props.servidor + "/RadianApi/acuse" + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.empresa.clavecertificado + "?llave=" + dt.usuario.llaveempresa + "&llaveusuario=" + dt.usuario.llaveusuario, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(enviodata.adjuntos)
            })

            if (response.ok) {
                let data = await response.json()
                var dsds = data
                Swal.fire(
                    'ExitoS!',
                    '' + data.mensaje,
                    'succes'
                )
            } else {
                let data = await response.json()
                Swal.fire(
                    'Error al Guardar!',
                    'Error: ' + data.mensaje,
                    'error'
                )
            }
        } catch (error) {
            console.log(error)
        }
    }
    const abrirEditarModalCorreo = (data) => {
        var enviodata = { ...data }
        setCorreo(enviodata);
        setVerModalCorreo(!verModalCorreo);
        seteditar(true)
    }
    const cerrarModalCorreo = () => {
        setCorreo(modeloCorreo)
        setVerModalCorreo(!verModalCorreo);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Correo) => {

        let claves = Object.keys(_Correo);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Correo[clave] = Number(_Correo[clave])
            }
        }
        return _Correo
    }
    const verificar = (_Correo) => {

        let claves = Object.keys(_Correo);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'nombre' || clave == 'clave' || clave == 'host' || clave == 'puerto') {
                if (_Correo[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {

        var adjuntos = { ...Correo }.adjuntos
        adjuntos.forEach(element => {
            descargar(element)
        });
    }
    const descargar = (adjunto) => {
        const linkSource = `data:${adjunto.tipo};base64,${adjunto.body}`;
        const downloadLink = document.createElement("a");
        const fileName = adjunto.nombre;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Correos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >
                                <Input id="buscardataCorreos" type="select"   >
                                    {concorreos.map((data) => {
                                       return <option value={data.correo}>{data.correo}</option>

                                    })}
                                </Input>
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerCorreos(document.getElementById('buscardataCorreos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalCorreo(!verModalCorreo)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Correos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal style={{ maxWidth: '1600px', width: '85%' }} size="lg" isOpen={verModalCorreo}>

                <ModalBody>
                    <Row>
                        <Col sm={1}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }} >Id*</Label>
                                <Input type="text" bsSize="sm" name="nombre" value={Correo.id} />
                            </FormGroup>
                        </Col>
                        <Col sm={10}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }} >Emisor*</Label>
                                <Input type="text" bsSize="sm" name="clave" value={Correo.emisor} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }} >Asunto*</Label>
                                <Input type="text" bsSize="sm" name="host" value={Correo.asunto} />
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup>
                                <Input style={{ height: '300px' }} type="textarea" bsSize="lg" name="puerto" value={Correo.cuerpo} />
                            </FormGroup>
                        </Col>

                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Descargar Adjuntos</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalCorreo}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Correo;
