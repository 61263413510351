import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloDepart = {
    codigodep: "",
    nombredep: "",
    paisdepar: "",
}
const Depart = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Depart, setDepart] = useState(modeloDepart);
    const [Departs, setDeparts] = useState([]);
    const [codigodep, setcodigodep] = useState([]);
    const [codigodeps, setcodigodeps] = useState([]);
    const [nombredep, setnombredep] = useState([]);
    const [nombredeps, setnombredeps] = useState([]);
    const [paisdepar, setpaisdepar] = useState([]);
    const [paisdepars, setpaisdepars] = useState([]);
    const [busquedapaisdepars, setbusquedapaisdepars] = useState([]);
    const [verModalpaisdepar, setVerModalpaisdepar] = useState(false);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalDepart, setVerModalDepart] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Depart = { ...Depart }
        _Depart[e.target.name] = value
        setDepart(_Depart)
        setRoles([])


    }
    const seleccionarpaisdepar = (e) => {
        let value = e
        var _Depart = { ...Depart }
        _Depart['paisdepar'] = value
        setDepart(_Depart)
        setRoles([])
        cerrarModalSeleccionpaisdepars()
    }
    const buscartodaspaisdepars = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Paisapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setpaisdepars(dataJson)
                    setbusquedapaisdepars(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setpaisdepars([])
                    setbusquedapaisdepars([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerDeparts = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Departapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setDeparts(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const borrartodaslaspaisdepars = () => {
        setbusquedapaisdepars([])
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerDeparts('');
        buscartodaspaisdepars('');
    }, [])
    const filtrarpaisdepars = async (coincidencia) => {
        var _paisdepars = paisdepars.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedapaisdepars(_paisdepars)
    }
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigodep,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombredep,
            sortable: true,
        },
        {
            name: 'Pais',
            selector: row => row.paisdepar,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalDepart(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalDepart = (data) => {
        var enviodata = { ...data }
        var _Depart = {
            codigodep: enviodata.codigodep,
            nombredep: enviodata.nombredep,
            paisdepar: enviodata.paisdepar,
        }
        setDepart(_Depart);
        setVerModalDepart(!verModalDepart);
        seteditar(true)
    }
    const cerrarModalDepart = () => {
        setDepart(modeloDepart)
        setVerModalDepart(!verModalDepart);
        seteditar(false)
        setKey('Datos')
    }
    const cerrarModalSeleccionpaisdepars = () => {
        setVerModalpaisdepar(!verModalpaisdepar);
        setbusquedapaisdepars(paisdepars)
    }
    const asignarceros = (_Depart) => {

        let claves = Object.keys(_Depart);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
        }
        return _Depart
    }
    const verificar = (_Depart) => {

        let claves = Object.keys(_Depart);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigodep' || clave == 'nombredep' || clave == 'paisdepar') {
                if (_Depart[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Depart)
        var _Depart = { ...Depart }
        // _Depart = asignarceros(_Depart)
        var _verificado = verificar(_Depart)
        if (_verificado) {
            console.log(_Depart)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Departapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Depart)
                })

            } else {
                response = await fetch("" + props.servidor + "/Departapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Depart)
                })
            }

            if (response.ok) {
                await obtenerDeparts('');
                setDepart(modeloDepart)
                setVerModalDepart(!verModalDepart);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Departamento fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Cierres
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            {/* <div className="input-group-prepend" >

                            <input type="text" id="buscardataDeparts" placeholder='Buscar' />
                        </div> */}
                        <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalDepart(!verModalDepart)
                                seteditar(false)
                                buscartodaspaisdepars('')
                            }}>Cierre de Ingresos/Gastos</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalDepart(!verModalDepart)
                                seteditar(true)
                                buscartodaspaisdepars('')
                            }}>Cierre de Cuentas de Balance</Button>
                            {/* <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcreardepartamento(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col> */}
                        </div>

                    </div>


                    <hr></hr>
                  
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalDepart}>
                <ModalHeader>
                    Cierre
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="codigodep"  />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Comprobante</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep"  />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Documento</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>{editar?"Contra Asiento":"Cuenta PYG"}</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tercero</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Centro de Costo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta Inicial</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep"  />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Cuenta Final</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombredep" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Detalle</Label>
                                        <Input type="textarea" bsSize="sm" onChange={handleChange} name="nombredep" />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                    <Buscar filtrar={(coincidencia) => { filtrarpaisdepars(coincidencia) }} busqueda={busquedapaisdepars} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalpaisdepar} cerrarModal={() => { cerrarModalSeleccionpaisdepars() }} seleccionar={(seleccionado) => {
                        seleccionarpaisdepar(seleccionado.codigo)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalDepart}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Depart;
