import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloRadian = {
    numero: "",
    emisoridentificacion: "",
    cufe: "",
    emisornombre: "",
    aceptacionexpresa: "",
    aceptaciontacita: "",
    rechazada: "",
    recibobien: "",
}
const Radian = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Radian, setRadian] = useState(modeloRadian);
    const [Radians, setRadians] = useState([]);
    const [numero, setnumero] = useState([]);
    const [numeros, setnumeros] = useState([]);
    const [emisoridentificacion, setemisoridentificacion] = useState([]);
    const [emisoridentificacions, setemisoridentificacions] = useState([]);
    const [cufe, setcufe] = useState([]);
    const [cufes, setcufes] = useState([]);
    const [emisornombre, setemisornombre] = useState([]);
    const [emisornombres, setemisornombres] = useState([]);
    const [aceptacionexpresa, setaceptacionexpresa] = useState([]);
    const [aceptacionexpresas, setaceptacionexpresas] = useState([]);
    const [aceptaciontacita, setaceptaciontacita] = useState([]);
    const [aceptaciontacitas, setaceptaciontacitas] = useState([]);
    const [rechazada, setrechazada] = useState([]);
    const [rechazadas, setrechazadas] = useState([]);
    const [recibobien, setrecibobien] = useState([]);
    const [recibobiens, setrecibobiens] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalRadian, setVerModalRadian] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Radian = { ...Radian }
        _Radian[e.target.name] = value
        setRadian(_Radian)
        setRoles([])


    }
    const obtenerRadians = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Radianapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setRadians(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerRadians('');
    }, [])

    const haceraceptacionexpresa = async (data) => {
        var enviodata = { ...data }
        let response;
        let dt = JSON.parse(user)
        try {
            response = await fetch("" + props.servidor + "/RadianApi/aceptacionexpresa" + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.empresa.clavecertificado + "?llave=" + dt.usuario.llaveempresa + "&llaveusuario=" + dt.usuario.llaveusuario, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(enviodata)
            })

            if (response.ok) {
                let data = await response.json()
                var dsds = data
                Swal.fire(
                    'ExitoS!',
                    '' + data.mensaje,
                    'succes'
                )
                obtenerRadians('');
            } else {
                let data = await response.json()
                Swal.fire(
                    'Error al Guardar!',
                    'Error: ' + data.mensaje,
                    'error'
                )
            }
        } catch (error) {
            console.log(error)
        }
    }
    const hacerrechazo = async (data) => {
        var enviodata = { ...data }
        let response;
        let dt = JSON.parse(user)
        try {
            response = await fetch("" + props.servidor + "/RadianApi/rechazada" + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.empresa.clavecertificado + "?llave=" + dt.usuario.llaveempresa + "&llaveusuario=" + dt.usuario.llaveusuario, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(enviodata)
            })

            if (response.ok) {
                let data = await response.json()
                var dsds = data
                Swal.fire(
                    'ExitoS!',
                    '' + data.mensaje,
                    'succes'
                )
                obtenerRadians('');
            } else {
                let data = await response.json()
                Swal.fire(
                    'Error al Guardar!',
                    'Error: ' + data.mensaje,
                    'error'
                )
            }
        } catch (error) {
            console.log(error)
        }
    }

    const hacertacita = async (data) => {
        var enviodata = { ...data }
        let response;
        let dt = JSON.parse(user)
        try {
            response = await fetch("" + props.servidor + "/RadianApi/tacita" + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.empresa.clavecertificado + "?llave=" + dt.usuario.llaveempresa + "&llaveusuario=" + dt.usuario.llaveusuario, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(enviodata)
            })

            if (response.ok) {
                let data = await response.json()
                var dsds = data
                Swal.fire(
                    'ExitoS!',
                    '' + data.mensaje,
                    'succes'
                )
                obtenerRadians('');
            } else {
                let data = await response.json()
                Swal.fire(
                    'Error al Guardar!',
                    'Error: ' + data.mensaje,
                    'error'
                )
            }
        } catch (error) {
            console.log(error)
        }
    }
    const hacerrecibodelbien = async (data) => {
        var enviodata = { ...data }
        let response;
        let dt = JSON.parse(user)
        try {
            response = await fetch("" + props.servidor + "/RadianApi/recibodelbien" + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.empresa.clavecertificado + "?llave=" + dt.usuario.llaveempresa + "&llaveusuario=" + dt.usuario.llaveusuario, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(enviodata)
            })

            if (response.ok) {
                let data = await response.json()
                var dsds = data
                Swal.fire(
                    'ExitoS!',
                    '' + data.mensaje,
                    'succes'
                )
                obtenerRadians('');
            } else {
                let data = await response.json()
                Swal.fire(
                    'Error al Guardar!',
                    'Error: ' + data.mensaje,
                    'error'
                )
            }
        } catch (error) {
            console.log(error)
        }
    }
    const columns = [
        {
            name: 'Numero',
            selector: row => row.numero,
            sortable: true,
        },
        {
            name: 'Proveedor',
            selector: row => row.emisoridentificacion,
            sortable: true,
        },
        {
            name: 'Cufe',
            selector: row => row.cufe,
            sortable: true,
        },
        {
            name: 'Nombre Proveedor',
            selector: row => row.emisornombre,
            sortable: true,
        },
        {
            name: 'Recibo del Bien',
            cell: row => (
                <>
                    <Button style={{ backgroundColor: row.recibobien == "no" ? 'red' : 'green' }} size="sm" className="mr-2"
                        onClick={() => hacerrecibodelbien(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
        {
            name: 'Aceptacion Expresa',
            cell: row => (
                <>
                    <Button color="primary" style={{ backgroundColor: row.aceptacionexpresa == "no" ? 'red' : 'green' }} size="sm" className="mr-2"
                        onClick={() => haceraceptacionexpresa(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
        {
            name: 'Rechazada',
            cell: row => (
                <>
                    <Button style={{ backgroundColor: row.rechazada == "no" ? 'red' : 'green' }} size="sm" className="mr-2"
                        onClick={() => hacerrechazo(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
        {
            name: 'Aceptacion Tacita',
            cell: row => (
                <>
                    <Button style={{ backgroundColor: row.aceptaciontacita == "no" ? 'red' : 'green' }} size="sm" className="mr-2"
                        onClick={() => hacertacita(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },

    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalRadian = (data) => {
        var enviodata = { ...data }
        var _Radian = {
            numero: enviodata.numero,
            emisoridentificacion: enviodata.emisoridentificacion,
            cufe: enviodata.cufe,
            emisornombre: enviodata.emisornombre,
            aceptacionexpresa: enviodata.aceptacionexpresa,
            aceptaciontacita: enviodata.aceptaciontacita,
            rechazada: enviodata.rechazada,
            recibobien: enviodata.recibobien,
        }
        setRadian(_Radian);
        setVerModalRadian(!verModalRadian);
        seteditar(true)
    }
    const cerrarModalRadian = () => {
        setRadian(modeloRadian)
        setVerModalRadian(!verModalRadian);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Radian) => {

        let claves = Object.keys(_Radian);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Radian[clave] = Number(_Radian[clave])
            }
        }
        return _Radian
    }
    const verificar = (_Radian) => {

        let claves = Object.keys(_Radian);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'numero' || clave == 'emisoridentificacion' || clave == 'cufe' || clave == 'emisornombre' || clave == 'aceptacionexpresa' || clave == 'aceptaciontacita' || clave == 'rechazada' || clave == 'recibobien') {
                if (_Radian[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Radian)
        var _Radian = { ...Radian }
        _Radian = asignarceros(_Radian)
        var _verificado = verificar(_Radian)
        if (_verificado) {
            console.log(_Radian)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Radianapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Radian)
                })

            } else {
                response = await fetch("" + props.servidor + "/Radianapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Radian)
                })
            }

            if (response.ok) {
                await obtenerRadians('');
                setRadian(modeloRadian)
                setVerModalRadian(!verModalRadian);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Radian fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Radian',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Radians
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataRadians" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerRadians(document.getElementById('buscardataRadians').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalRadian(!verModalRadian)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Radians}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalRadian}>
                <ModalHeader>
                    Detalle Radians
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Numero*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="numero" value={Radian.numero} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Proveedor*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="emisoridentificacion" value={Radian.emisoridentificacion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Cufe*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="cufe" value={Radian.cufe} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre Proveedor*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="emisornombre" value={Radian.emisornombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >aceptacionexpresa*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="aceptacionexpresa" value={Radian.aceptacionexpresa} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >aceptaciontacita*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="aceptaciontacita" value={Radian.aceptaciontacita} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >rechazada*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="rechazada" value={Radian.rechazada} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >recibobien*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="recibobien" value={Radian.recibobien} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalRadian}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Radian;
