import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import { jsPDF } from "jspdf";

import filtro from "../../../componentes/filtro";
const tabespacio = '\u00A0';

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const municipios = [
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: ""
    },
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: "08001"
    },
    {
        municipio: "Oiba",
        departamento: "Santander",
        codigo: "68500"
    },
    {
        municipio: "Santa Marta",
        departamento: "Magdalena",
        codigo: "47001"
    },
    {
        municipio: "Funza",
        departamento: "Cundinamarca",
        codigo: "25286"
    }
]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [previsualizar, setprevisualizar] = useState(false);
    const [dataUser, setDataUser] = useState(modelo)


    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [referenciafinseleccionado, setreferenciafinseleccionado] = useState("-");
    const [referenciainicioseleccionado, setreferenciainicioseleccionado] = useState("-");
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
    }, [])
    
    const previsualizarpdfelectronica = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    const buscarnombremun = (codigo) => {
        var muni = municipios.filter(p => p.codigo == codigo)
        if (muni.length != 0) {
            return muni[0].municipio + " - " + muni[0].departamento
        } else {

            return "Barranquilla" + " - " + "Atlantico"
        }
    }
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/CtabonoApi/MovimientoDetalleCartera2/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
                // var conceptos = data.reduce((array, venta) => {
                //     if (!array[venta.concepto]) {
                //         array[venta.concepto] = 0;
                //     }
                //     array[venta.concepto] += venta.total;
                //     return array;
                // }, {});

                // const conceptosarray = Object.entries(conceptos).map(([concepto, total]) => ({
                //     concepto,
                //     total,
                // }));
                var poconcepto = []
                var portercero = []
                var porvendedor = []
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    var nohay = true
                    for (let j = 0; j < poconcepto.length; j++) {
                        const element2 = poconcepto[j];
                        if (element2.tipo == element.tipo && element.item == 1) {
                            element2.total = element2.total + element.total
                            element2.abonos = element2.abonos + element.abonos
                            element2.saldo = element2.saldo + element.saldo
                            j = poconcepto.length + 123
                            nohay = false
                        } else {
                            if (element.item != 1) {
                                nohay = false

                            }
                        }
                    }
                    if (nohay) {
                        var concepto = {
                            tipo: element.tipo,
                            total: element.total,
                            abonos: element.abonos,
                            saldo: element.saldo,
                        }
                        poconcepto.push(concepto)
                    }
                    nohay = true
                    for (let j = 0; j < portercero.length; j++) {
                        const element2 = portercero[j];
                        if (element2.nomcliente == element.nomcliente && element.item == 1) {
                            element2.total = element2.total + element.total
                            element2.abonos = element2.abonos + element.abonos
                            element2.saldo = element2.saldo + element.saldo
                            j = portercero.length + 123
                            nohay = false
                        } else {
                            if (element.item != 1) {
                                nohay = false

                            }
                        }
                    }
                    if (nohay) {
                        var concepto = {
                            nomcliente: element.nomcliente,
                            total: element.total,
                            abonos: element.abonos,
                            saldo: element.saldo,
                        }
                        portercero.push(concepto)
                    }
                    nohay = true
                    for (let j = 0; j < porvendedor.length; j++) {
                        const element2 = porvendedor[j];
                        if (element2.vendedor == element.vendedor && element.item == 1) {
                            element2.total = element2.total + element.total
                            element2.comision = element2.total * (element2.porcentaje / 100)
                            j = porvendedor.length + 123
                            nohay = false
                        } else {
                            if (element.item != 1) {
                                nohay = false

                            }
                        }
                    }
                    if (nohay) {
                        var concepto = {
                            vendedor: element.vendedor,
                            total: element.total,
                            porcentaje: element.porcentajevendedor,
                            comision: element.total * (element.porcentajevendedor / 100),
                        }
                        porvendedor.push(concepto)
                    }
                }
                setventasconceptos(poconcepto)
                setventasterceros(portercero)
                setventasvendedor(porvendedor)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const CustomDescripcion = ({ row }) => (
        <div style={{ width: '700px' }}>
            { }
            {row.descripcion}
        </div>
    );
    const CustomTipo = ({ row }) => (
        <div>
            { }
            <div>
                <div

                >
                    { }
                    {row.tipo}
                </div>
            </div>
        </div>
    );
    const CustomCliente = ({ row }) => (
        <div>
            { }
            <div>
                <div

                >
                    { }
                    {row.nomcliente}
                </div>
            </div>
        </div>
    );
    const CustomDireccion = ({ row }) => (
        <div>
            { }
            <div>
                <div

                >
                    { }
                    {row.direccion}
                </div>
            </div>
        </div>
    );
    const columns = [
        {
            name: 'fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 3,

        }, {
            name: 'telefono',
            selector: row => row.telefono,
            sortable: true,
            grow: 3,

        }, {
            name: 'direccion',
            selector: row => row.direccion,
            sortable: true,
            grow: 3,
            cell: row => <CustomDireccion row={row} />,

        },
        {
            name: 'cantidad',
            selector: row => row.cantidad,
            sortable: true,
            grow: 2,
        },
        {
            name: 'descripcion',
            selector: row => row.descripcion,
            sortable: true,
            grow: 10,
            cell: row => <CustomDescripcion row={row} />,
        },
        {
            name: 'nomcliente',
            selector: row => row.nomcliente,
            sortable: true,
            grow: 7,
            cell: row => <CustomCliente row={row} />,

        }, {
            name: 'saldo',
            selector: row => fm.from(Number(row.saldo + "")),
            sortable: true,
            grow: 3,
        },
        (true && {
            name: '',
            cell: row => (
                <>
                    <Button color="success" style={{ marginTop: '40px', fontSize: '25px', width: '130px', color: 'black', textAlign: 'center', fontWeight: 'bold' }} onClick={(e) => {
                        var createA = document.createElement('a');
                        var fechaa = new Date(row.fecha)
                        var dia = fechaa.getDay()
                        var hora = row.fecha.split('T') ? row.fecha.split('T')[1] : "00:00"
                        var tecnico = row.tecnico
                        var mensaje = "Hola," +tabespacio + row.nomcliente + "! Somos."+JSON.parse(user).empresa.config.carpeta_bd+". Deseo que estés bien. %0A" +
                            "Para informarte que tu pedido ya esta listo y va en camino. %0A" +
                            " Gracias"
                        createA.setAttribute('href', "https://api.whatsapp.com/send?phone=+57" + row.telefono + "&text=" + mensaje);
                        createA.setAttribute('target', "_blank");
                        createA.click()
                    }}>WS</Button>

                </>
            )
        })
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            if (element.item == 1) {
                tot = tot + element.total
            }

        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalcomisionvendedor = () => {
        var total = 0
        ventasvendedor.map((dato) => {
            total = total + dato.comision
        })
        return total
    }

    const totaltotalvendedor = () => {
        var total = 0
        ventasvendedor.map((dato) => {
            total = total + dato.total
        })
        return total
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>
                {(true) && <Col sm={1}>
                    <Button style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black' }} block onClick={() => {

                        setprevisualizar(true)

                    }} >
                        <i className="fa fa-paper-plane"></i> PDF</Button>
                </Col>}
            </Row>
            <Row  >
                <Col xs={2} onClick={() => {
                    setconcepto(!concepto)
                    setvendedor(false)
                    settercero(false)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Concepto
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(!tercero)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Cliente
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(!vendedor)
                    settercero(false)
                }}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Vendedor
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={4}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Movimientos Totales
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Abonos</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Saldo</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={2}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.tipo}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.abonos)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.saldo)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {tercero &&
                ventasterceros.map((item, index) => {
                    return <>
                        {index == 0 && <Row>

                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}> Total</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Abonos</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Saldo</p>
                            </Col>
                        </Row>}
                        <Row >

                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold" style={{ color: 'black' }}>{item.nomcliente}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={3}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.abonos)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.saldo)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}
            {vendedor &&
                ventasvendedor.map((item, index) => {
                    return <>
                        {index == 0 && <Row>

                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Vendedor</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Porcentaje</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Comision</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.vendedor == "" ? "Sin Vendedor" : item.vendedor}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={3}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.porcentaje)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.comision)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}
            {vendedor &&
                <Row >
                    <Col xs={4}>
                        <div className="card border-left-info shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{"Totales Vendedor"}</div>
                                            </div>
                                            <div className="col">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>

                    <Col xs={3}>

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(totaltotalvendedor())}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} >

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}></div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} >

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(totalcomisionvendedor())}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            }
            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                {true &&

                    <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar}>

                        <ModalBody id={"pruebapdfventaelectronica"} >
                            <Row >
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                            {<img src={"../../imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                            {JSON.parse(user).empresa.config.carpeta_bd}<br />
                                            {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                            {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />

                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                            {'Mercancia'}<br />
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col sm={12}>
                                            <Table size="sm" className='custom-table'>
                                                <thead>
                                                    <tr style={{ fontSize: '10px', color: 'black' }}>
                                                        <th >Fecha</th>
                                                        <th >Nombre Cliente</th>
                                                        <th >Telefono</th>
                                                        <th >Direccion</th>
                                                        <th >Articulo</th>
                                                        <th >Cantidad</th>
                                                        <th >Saldo</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (ventas.length < 1) ? (
                                                            <tr>
                                                                <td colSpan="15">Sin Datos</td>
                                                            </tr>
                                                        ) :
                                                            (
                                                                ventas.map((item, index) => (
                                                                    <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                        <td style={{ textAlign: 'left',width:'100px' }} >{item.fecha}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.nomcliente}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.telefono}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.direccion}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.descripcion}</td>
                                                                        <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                        <td style={{ textAlign: 'left' }}>{fm.from(item.saldo * 1)}</td>









                                                                    </tr>
                                                                ))
                                                            )


                                                    }
                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>


                                    <Row>

                                        <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                            {"Generado por Solifactin S.A.S"}<br />
                                            {"Tenemos las mejores soluciones a su alcance"}<br />
                                            {"www.factin.com.co"}<br />
                                            {"Cel: 3218561024"}<br />


                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>


                            <Button size="sm" color="primary" onClick={() => {

                                previsualizarpdfelectronica()
                                // setprevisualizarelectronica(false)
                            }}>PDF</Button>
                            <Button size="sm" color="primary" onClick={() => {
                                setprevisualizar(false)

                            }}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>

                }
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    setreferenciainicioseleccionado={(e) => { setreferenciainicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}
                    setreferenciafinseleccionado={(e) => { setreferenciafinseleccionado(e) }}

                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    referenciafinseleccionado={referenciafinseleccionado}
                    referenciainicioseleccionado={referenciainicioseleccionado}

                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;