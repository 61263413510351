import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloPais = {
    codigo: "",
    nombre: "",
}
const Pais = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Pais, setPais] = useState(modeloPais);
    const [Paiss, setPaiss] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalPais, setVerModalPais] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Pais = { ...Pais }
        _Pais[e.target.name] = value
        setPais(_Pais)
        setRoles([])


    }
    const obtenerPaiss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Paisapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPaiss(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerPaiss('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalPais(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalPais = (data) => {
        var enviodata = { ...data }
        var _Pais = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        }
        setPais(_Pais);
        setVerModalPais(!verModalPais);
        seteditar(true)
    }
    const cerrarModalPais = () => {
        setPais(modeloPais)
        setVerModalPais(!verModalPais);
        seteditar(false)
        setKey('Datos')
    }
    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/Paisapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Pais)
            })

        } else {
            response = await fetch("" + props.servidor + "/Paisapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Pais)
            })
        }

        if (response.ok) {
            await obtenerPaiss('');
            setPais(modeloPais)
            setVerModalPais(!verModalPais);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'El Pais fue guardado exitosamente',
                'success'
            )
        } else {
            var textoerrores = await response.text()
            var jsonerrores = JSON.parse(textoerrores)
            var errores = jsonerrores.errors
            let claves = Object.keys(errores);
            var stringerrores=""
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                    if (errores[clave].length!=0) {
                        stringerrores=stringerrores+errores[clave][0]+" \n "
                    }
                
            }
            var mensaje=stringerrores.replace(/The /g,'').replace(/field /g,'').replace(/is /g,'es ').replace(/required/g,'requerido')
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje='Hay campos numericos rellenados con letras'
            }
            console.log(stringerrores)
            Swal.fire(
                'Error al Guardar!',
                 mensaje,
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Paises
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataPaiss" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerPaiss(document.getElementById('buscardataPaiss').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalPais(!verModalPais)
                                seteditar(false)
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearpais(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Paiss}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalPais}>
                <ModalHeader>
                    Pais
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Codigo</Label>
                                        <Input disabled={editar}  type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Pais.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Pais.nombre} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalPais}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Pais;
