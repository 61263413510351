import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloVendedor = {
    codigo: "",
    nombre: "",
    cedula: "",
    direccion: "",
    feching: "",
    telefono1: "",
    telefono2: "",
    telemovil: "",
    comisionservicio: 0,
    ciudad: "",
    departamento: "",
    pais: "",
    email: "",
    subven: "",
    horafecha: "",
    vendedorcomisionesventa: [
    ],
    vendedorcomisionesrecaudo: [
    ],
}
const Vendedor = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Vendedor, setVendedor] = useState(modeloVendedor);
    const [Vendedors, setVendedors] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [cedula, setcedula] = useState([]);
    const [cedulas, setcedulas] = useState([]);
    const [direccion, setdireccion] = useState([]);
    const [direccions, setdireccions] = useState([]);
    const [feching, setfeching] = useState([]);
    const [fechings, setfechings] = useState([]);
    const [telefono1, settelefono1] = useState([]);
    const [telefono1s, settelefono1s] = useState([]);
    const [telefono2, settelefono2] = useState([]);
    const [telefono2s, settelefono2s] = useState([]);
    const [telemovil, settelemovil] = useState([]);
    const [telemovils, settelemovils] = useState([]);
    const [comisionservicio, setcomisionservicio] = useState([]);
    const [comisionservicios, setcomisionservicios] = useState([]);
    const [ciudad, setciudad] = useState([]);
    const [ciudads, setciudads] = useState([]);
    const [busquedaciudads, setbusquedaciudads] = useState([]);
    const [verModalciudad, setVerModalciudad] = useState(false);
    const [departamento, setdepartamento] = useState([]);
    const [departamentos, setdepartamentos] = useState([]);
    const [pais, setpais] = useState([]);
    const [paiss, setpaiss] = useState([]);
    const [email, setemail] = useState([]);
    const [emails, setemails] = useState([]);
    const [subven, setsubven] = useState([]);
    const [subvens, setsubvens] = useState([]);
    const [horafecha, sethorafecha] = useState([]);
    const [horafechas, sethorafechas] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalVendedor, setVerModalVendedor] = useState(false);
    const [vendedorcomisionesventavendedor, setvendedorcomisionesventavendedor] = useState([]);
    const [vendedorcomisionesventavendedors, setvendedorcomisionesventavendedors] = useState([]);
    const [vendedorcomisionesventavalorminimo, setvendedorcomisionesventavalorminimo] = useState([]);
    const [vendedorcomisionesventavalorminimos, setvendedorcomisionesventavalorminimos] = useState([]);
    const [vendedorcomisionesventavalormaximo, setvendedorcomisionesventavalormaximo] = useState([]);
    const [vendedorcomisionesventavalormaximos, setvendedorcomisionesventavalormaximos] = useState([]);
    const [vendedorcomisionesventaporcentaje, setvendedorcomisionesventaporcentaje] = useState([]);
    const [vendedorcomisionesventaporcentajes, setvendedorcomisionesventaporcentajes] = useState([]);
    const [vendedorcomisionesrecaudovendedor, setvendedorcomisionesrecaudovendedor] = useState([]);
    const [vendedorcomisionesrecaudovendedors, setvendedorcomisionesrecaudovendedors] = useState([]);
    const [vendedorcomisionesrecaudovalorminimo, setvendedorcomisionesrecaudovalorminimo] = useState([]);
    const [vendedorcomisionesrecaudovalorminimos, setvendedorcomisionesrecaudovalorminimos] = useState([]);
    const [vendedorcomisionesrecaudovalormaximo, setvendedorcomisionesrecaudovalormaximo] = useState([]);
    const [vendedorcomisionesrecaudovalormaximos, setvendedorcomisionesrecaudovalormaximos] = useState([]);
    const [vendedorcomisionesrecaudoporcentaje, setvendedorcomisionesrecaudoporcentaje] = useState([]);
    const [vendedorcomisionesrecaudoporcentajes, setvendedorcomisionesrecaudoporcentajes] = useState([]);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Vendedor = { ...Vendedor }
        if (e.target.type == "number") {
            _Vendedor[e.target.name] = parseFloat(value)
        }
        else {
            _Vendedor[e.target.name] = value
            if (e.target.name=="codigo") {
                _Vendedor['cedula'] = value
            }
        }
        setVendedor(_Vendedor)
        setRoles([])


    }
    const seleccionarciudad = (e) => {
        let value = e
        var _Vendedor = { ...Vendedor }
        _Vendedor['ciudad'] = value.codigomun
        _Vendedor['departamento'] = value.coddepar
        _Vendedor['pais'] = value.codpais
        setVendedor(_Vendedor)
        setRoles([])
        cerrarModalSeleccionciudads()
    }
    const handleChangegrillavendedorcomisionesventa = async (e) => {
        var _Vendedor = { ...Vendedor }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Vendedor }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                if (e.target.type == "number") {
                    var dat = {
                        ...dato,
                        [e.target.name]: parseFloat(e.target.value)
                    }
                }
                else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                }
                return dat
            } else {
                return dato
            }
        })
        _Vendedor[subtabla] = _enviodatos
        // setVendedor(_Vendedor)

        setVendedor(_Vendedor)
        console.log(Vendedor)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const handleChangegrillavendedorcomisionesrecaudo = async (e) => {
        var _Vendedor = { ...Vendedor }
        var subtabla = e.target.getAttribute('tabla')
        var _datos = { ...Vendedor }[subtabla]
        let _enviodatos = _datos.map((dato, index) => {
            if (index == fila) {
                if (e.target.type == "number") {
                    var dat = {
                        ...dato,
                        [e.target.name]: parseFloat(e.target.value)
                    }
                }
                else {
                    var dat = {
                        ...dato,
                        [e.target.name]: e.target.value
                    }
                }
                return dat
            } else {
                return dato
            }
        })
        _Vendedor[subtabla] = _enviodatos
        // setVendedor(_Vendedor)

        setVendedor(_Vendedor)
        console.log(Vendedor)
        setRoles({ codig: '' })
        console.log(roles)
        var ultimo = e.target.getAttribute('ultimo')
        var ultimo = e.target.getAttribute('ultimo')

    }
    const buscartodasciudads = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Municipioapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setciudads(dataJson)
                    setbusquedaciudads(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setciudads([])
                    setbusquedaciudads([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerVendedors = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Vendedorapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setVendedors(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const borrartodaslasciudads = () => {
        setbusquedaciudads([])
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerVendedors('');
        buscartodasciudads('');
    }, [])
    const filtrarciudads = async (coincidencia) => {
        var _ciudads = ciudads.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaciudads(_ciudads)
    }
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Cedula',
            selector: row => row.cedula,
            sortable: true,
        },
        {
            name: 'Fecha Ingreso',
            selector: row => row.feching,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalVendedor(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalVendedor = (data) => {
        var enviodata = { ...data }
        var _Vendedor = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
            cedula: enviodata.cedula,
            direccion: enviodata.direccion,
            feching: enviodata.feching,
            telefono1: enviodata.telefono1,
            telefono2: enviodata.telefono2,
            telemovil: enviodata.telemovil,
            comisionservicio: enviodata.comisionservicio,
            ciudad: enviodata.ciudad,
            departamento: enviodata.departamento,
            pais: enviodata.pais,
            email: enviodata.email,
            subven: enviodata.subven,
            horafecha: enviodata.horafecha,
            vendedorcomisionesventa: enviodata.vendedorcomisionesventa,
            vendedorcomisionesrecaudo: enviodata.vendedorcomisionesrecaudo,
        }
        setVendedor(_Vendedor);
        setVerModalVendedor(!verModalVendedor);
        seteditar(true)
    }
    const cerrarModalVendedor = () => {
        setVendedor(modeloVendedor)
        setVerModalVendedor(!verModalVendedor);
        seteditar(false)
        setKey('Datos')
    }
    const cerrarModalSeleccionciudads = () => {
        setVerModalciudad(!verModalciudad);
        setbusquedaciudads(ciudads)
    }
    const agregarvendedorcomisionesventa = async (e, index) => {
        var mod = modeloVendedor
        var _Vendedor = { ...Vendedor }
        if (_Vendedor.codigo != '') {
            var _vendedorcomisionesventas = { ...Vendedor }.vendedorcomisionesventa
            var _vendedorcomisionesventasfinales = _vendedorcomisionesventas.concat({
                vendedor: _Vendedor.codigo,
                valorminimo: "",
                valormaximo: "",
                porcentaje: "",
            })
            _Vendedor.vendedorcomisionesventa = _vendedorcomisionesventasfinales
            setVendedor(_Vendedor)
            setRoles([])
        }
    }
    const agregarcondatosvendedorcomisionesventa = async (__Vendedor) => {

        var _Vendedor = __Vendedor
        var _vendedorcomisionesventas = _Vendedor.vendedorcomisionesventa
        var _verificar = _vendedorcomisionesventas.filter((dato) => {
            if (dato.porcentaje == "") {
                return dato
            }
        })
        if (_verificar.length == 0) {
            var _vendedorcomisionesventasfinales = _vendedorcomisionesventas.concat({
                vendedor: _Vendedor.codigo,
                valorminimo: "",
                valormaximo: "",
                porcentaje: "",
            })
            _Vendedor.vendedorcomisionesventa = _vendedorcomisionesventasfinales
            setVendedor(_Vendedor)
            setRoles([])
        }

    }
    const agregarvendedorcomisionesrecaudo = async (e, index) => {
        var mod = modeloVendedor
        var _Vendedor = { ...Vendedor }
        if (_Vendedor.codigo != '') {
            var _vendedorcomisionesrecaudos = { ...Vendedor }.vendedorcomisionesrecaudo
            var _vendedorcomisionesrecaudosfinales = _vendedorcomisionesrecaudos.concat({
                vendedor: _Vendedor.codigo,
                valorminimo: "",
                valormaximo: "",
                porcentaje: "",
            })
            _Vendedor.vendedorcomisionesrecaudo = _vendedorcomisionesrecaudosfinales
            setVendedor(_Vendedor)
            setRoles([])
        }
    }
    const agregarcondatosvendedorcomisionesrecaudo = async (__Vendedor) => {

        var _Vendedor = __Vendedor
        var _vendedorcomisionesrecaudos = _Vendedor.vendedorcomisionesrecaudo
        var _verificar = _vendedorcomisionesrecaudos.filter((dato) => {
            if (dato.porcentaje == "") {
                return dato
            }
        })
        if (_verificar.length == 0) {
            var _vendedorcomisionesrecaudosfinales = _vendedorcomisionesrecaudos.concat({
                vendedor: _Vendedor.codigo,
                valorminimo: "",
                valormaximo: "",
                porcentaje: "",
            })
            _Vendedor.vendedorcomisionesrecaudo = _vendedorcomisionesrecaudosfinales
            setVendedor(_Vendedor)
            setRoles([])
        }

    }
    const guardarCambios = async () => {
        let response;
        console.log(Vendedor)
        if (!editar) {
            response = await fetch("" + props.servidor + "/Vendedorapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Vendedor)
            })

        } else {
            response = await fetch("" + props.servidor + "/Vendedorapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Vendedor)
            })
        }

        if (response.ok) {
            await obtenerVendedors('');
            setVendedor(modeloVendedor)
            setVerModalVendedor(!verModalVendedor);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'El Vendedor fue guardado exitosamente',
                'success'
            )
        } else {
            var textoerrores = await response.text()
            var jsonerrores = JSON.parse(textoerrores)
            var errores = jsonerrores.errors
            let claves = Object.keys(errores);
            var stringerrores=""
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                    if (errores[clave].length!=0) {
                        stringerrores=stringerrores+errores[clave][0]+" \n "
                    }
                
            }
            var mensaje=stringerrores.replace(/The /g,'').replace(/field /g,'').replace(/is /g,'es ').replace(/required/g,'requerido')
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje='Hay campos numericos rellenados con letras'
            }
            console.log(stringerrores)
            Swal.fire(
                'Error al Guardar!',
                 mensaje,
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Vendedores
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataVendedors" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerVendedors(document.getElementById('buscardataVendedors').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalVendedor(!verModalVendedor)
                                seteditar(false)
                                buscartodasciudads('')
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearvendedor(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Vendedors}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalVendedor}>
                <ModalHeader>
                    Vendedor
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Codigo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Vendedor.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Vendedor.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Cedula</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="cedula" value={Vendedor.cedula} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Direccion</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="direccion" value={Vendedor.direccion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Fecha Ingreso</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="feching" value={Vendedor.feching} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="DatosAdicionales" title="DatosAdicionales">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Telefono1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono1" value={Vendedor.telefono1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Telefono2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono2" value={Vendedor.telefono2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Telemovil</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telemovil" value={Vendedor.telemovil} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>ComisionServicio</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="comisionservicio" value={Vendedor.comisionservicio} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup inline={true}>
                                        <Label>Ciudad</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedaciudads(ciudads)
                                                    setVerModalciudad(true)

                                                }
                                            }} name="ciudad" value={Vendedor.ciudad} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedaciudads(ciudads)
                                                    setVerModalciudad(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Departamento</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="departamento" value={Vendedor.departamento} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Pais</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="pais" value={Vendedor.pais} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="email" value={Vendedor.email} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Subven</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="subven" value={Vendedor.subven} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Horafecha</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="horafecha" value={Vendedor.horafecha} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="ComisionesVenta" title="ComisionesVenta">
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>ValorMinimo</th>
                                                <th>ValorMaximo</th>
                                                <th>Porcentaje</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Vendedor.vendedorcomisionesventa.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">{<a href="#" onClick={(e) => { agregarvendedorcomisionesventa(e) }} className={"badge badge-info p-2"}>{"Agregar ComisionesVenta"}</a>}</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Vendedor.vendedorcomisionesventa.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='number' ultimo={false} onClick={() => { setfila(index) }} onChange={handleChangegrillavendedorcomisionesventa} tabla={'vendedorcomisionesventa'} name="valorminimo" value={Vendedor.vendedorcomisionesventa[index].valorminimo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='number' ultimo={false} onClick={() => { setfila(index) }} onChange={handleChangegrillavendedorcomisionesventa} tabla={'vendedorcomisionesventa'} name="valormaximo" value={Vendedor.vendedorcomisionesventa[index].valormaximo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='number' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosvendedorcomisionesventa(Vendedor) } }} onClick={() => { setfila(index) }} onChange={handleChangegrillavendedorcomisionesventa} tabla={'vendedorcomisionesventa'} name="porcentaje" value={Vendedor.vendedorcomisionesventa[index].porcentaje} />
                                                                    </FormGroup>
                                                                </td>
                                                                <tr>
                                                                    <td colSpan="1">{<a href="#" onClick={(e) => { agregarcondatosvendedorcomisionesventa(Vendedor) }} className={"badge badge-info p-2"}>{"Agregar ComisionesVenta"}</a>}</td>
                                                                </tr>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>
                        <Tab eventKey="ComisionesRecaudo" title="ComisionesRecaudo">
                            <Row>
                                <Col sm={12}>

                                    <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th>ValorMinimo</th>
                                                <th>ValorMaximo</th>
                                                <th>Porcentaje</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Vendedor.vendedorcomisionesrecaudo.length < 1) ? (
                                                    <tr>
                                                        <td colSpan="5">{<a href="#" onClick={(e) => { agregarvendedorcomisionesrecaudo(e) }} className={"badge badge-info p-2"}>{"Agregar ComisionesRecaudo"}</a>}</td>
                                                    </tr>
                                                ) :
                                                    (
                                                        Vendedor.vendedorcomisionesrecaudo.map((item, index) => (
                                                            <tr key={(index + 1)}>

                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='number' ultimo={false} onClick={() => { setfila(index) }} onChange={handleChangegrillavendedorcomisionesrecaudo} tabla={'vendedorcomisionesrecaudo'} name="valorminimo" value={Vendedor.vendedorcomisionesrecaudo[index].valorminimo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='number' ultimo={false} onClick={() => { setfila(index) }} onChange={handleChangegrillavendedorcomisionesrecaudo} tabla={'vendedorcomisionesrecaudo'} name="valormaximo" value={Vendedor.vendedorcomisionesrecaudo[index].valormaximo} />
                                                                    </FormGroup>
                                                                </td>
                                                                <td >
                                                                    <FormGroup>
                                                                        <Input bsSize="sm" type='number' ultimo={true} onKeyDown={(e) => { if (e.key == "Enter" && e.target.value != "") { agregarcondatosvendedorcomisionesrecaudo(Vendedor) } }} onClick={() => { setfila(index) }} onChange={handleChangegrillavendedorcomisionesrecaudo} tabla={'vendedorcomisionesrecaudo'} name="porcentaje" value={Vendedor.vendedorcomisionesrecaudo[index].porcentaje} />
                                                                    </FormGroup>
                                                                </td>
                                                                <tr>
                                                                    <td colSpan="1">{<a href="#" onClick={(e) => { agregarcondatosvendedorcomisionesrecaudo(Vendedor) }} className={"badge badge-info p-2"}>{"Agregar ComisionesVenta"}</a>}</td>
                                                                </tr>
                                                            </tr>
                                                        ))
                                                    )


                                            }
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </Tab>
                    </ Tabs >
                    <Buscar filtrar={(coincidencia) => { filtrarciudads(coincidencia) }} busqueda={busquedaciudads} encabezado={["Codigo", "Nombre"]} codigo={"codigomun"} nombre={"nombremun"} dato={""} dato2={""} verModal={verModalciudad} cerrarModal={() => { cerrarModalSeleccionciudads() }} seleccionar={(seleccionado) => {
                        seleccionarciudad(seleccionado)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalVendedor}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Vendedor;
