import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloPais = {
    codigo: "",
    nombre: "",
}
const ImportarExportar = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [usuario, setDataUser] = useState([]);
    const [fileD, setfileD] = useState([]);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const obtenerPaiss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Paisapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        // obtenerPaiss('');
    }, [])
    const convertBlobToBase64 = async (blob) => { // blob data
        return await blobToBase64(blob);
    }
    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const recibirelemento = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const fileData = e.target.result;
            const blob = new Blob([fileData], { type: file.type });
            var base64data = ""
            base64data = await convertBlobToBase64(blob);
            setfileD(base64data)
        };

        reader.readAsArrayBuffer(file);
        // var blob = doc.output('blob')
        // var reader = new FileReader();
        // var base64data = ""
        // base64data = await convertBlobToBase64(blob);
        // console.log(base64data)
        // var pdf = {
        //     pdfbase64: base64data

        // }

    }


    const guardarCambiosinquilinos = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch(props.servidor + '/EnvioApi/enviodianbloque/' + fechahoy + '/' + usuario.empresa.empresa.nit + '/' + usuario.empresa.config.clave_certificado+ '/' + usuario.empresa.config.clave_certificado + '?llave=' + usuario.usuario.llaveempresa + '');



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Facturas Generadas Exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.json()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores.mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }
    return (
        <>
            <Card>

                <CardBody>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    Envio Dian Bloque
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Fecha</Label>
                                                <Input name="fecha" value={fechahoy} onChange={(e) => { setfechahoy(e.target.value) }} type="date" bsSize="sm" />
                                            </FormGroup>
                                        </Col>
                                        <Button style={{ backgroundColor: '#188BAD', color: "white", marginTop: '10px' }} onClick={() => { guardarCambiosinquilinos() }}>Enviar</Button>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </CardBody>
            </Card>


        </>
    )
}
export default ImportarExportar;
