import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const fechaejemplo = new Date('1995-12-4 03:24')
const modeloUsuarionuevo = {
    llamada: {
        id: 4,
        nit: "",
        nombre: "",
        asesor: "",
        plan: '',
        fecha: '',
        hora: "",
        modulo: "",
        pedido: "",
        contacto: "",
        agendado: false,
        fechaagenda: "",
        horaagenda: "",
        estado: 'Por Atender',
    },
    historial: [
        {
            ticket: 4,
            mensaje: "",
        }
    ]
}
const modeloUsuario = {
    llamada: {
        id: 1,
        nit: "1140900262",
        nombre: "Miguel Julio",
        asesor: "Cristian",
        plan: 'Plan Anual',
        fecha: '2022-10-04',
        hora: "11:28",
        modulo: "Contabilidad",
        pedido: "",
        contacto: "",
        agendado: false,
        fechaagenda: "",
        horaagenda: "",
        estado: 'Por Atender',
    },
    historial: [
        {
            ticket: 1,
            mensaje: "",
        }
    ]
}
const modeloUsuario2 = {
    llamada: {
        id: 2,
        nit: "800225585",
        nombre: "CONSTRUCTORA RUMIE S.A.S.",
        asesor: "Jesus Rua",
        plan: 'Plan Anual',
        fecha: '2022-10-04',
        hora: "12:28",
        modulo: "Nomina",
        pedido: "",
        contacto: "",
        agendado: false,
        fechaagenda: "",
        horaagenda: "",
        estado: 'Por Atender',
    },
    historial: [
        {
            ticket: 2,
            mensaje: "",
        }
    ]
}
const modeloUsuario3 = {
    llamada: {
        id: 3,
        nit: "800043769",
        nombre: "SERVICIOS DE DRAGADOS Y CONSTRUCCIONES S.A.S.",
        asesor: "Jesus Rua",
        plan: 'Plan Anual',
        fecha: '2022-10-04',
        hora: "13:28",
        modulo: "Contabilidad",
        pedido: "",
        contacto: "",
        agendado: "",
        fechaagenda: "",
        horaagenda: "",
        estado: 'Por Atender',
    },
    historial: [
        {
            ticket: 3,
            mensaje: "",
        }
    ]
}
const listamodelos = [modeloUsuario3, modeloUsuario2, modeloUsuario]

const Usuario = () => {

    const [llamada, setLlamada] = useState(modeloUsuarionuevo);
    const [pendiente, setPendiente] = useState(false);
    const [llamadas, setLlamadas] = useState(listamodelos);
    const [roles, setRoles] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [verModal2, setVerModal2] = useState(false);
    const [verModalGrupo, setverModalGrupo] = useState(false);
    const [key, setKey] = useState('Datos');
    const [empresas, setempresas] = useState([]);
    const [editarempresa, seteditarempresa] = useState(false);
    const [grupos, setgrupos] = useState([]);
    const [busquedaempresas, setbusquedaempresas] = useState([]);
    const [busquedagrupos, setbusquedagrupos] = useState([]);
    const [fila, setfila] = useState(0);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);

    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _llamada = { ...llamada.llamada }
        if (e.target.name == 'fechaagenda') {
            _llamada[e.target.name] = value.split('T')[0]
            _llamada['horaagenda'] = value.split('T')[1]

        } else {
            if (e.target.name == 'agendado') {
                _llamada[e.target.name] = e.target.checked
            } else {

                _llamada[e.target.name] = value
            }

        }
        setLlamada({
            ...llamada,
            llamada: _llamada
        })
        setRoles([])


    }


    const buscartodasempresas = (value) => {

        const api = fetch('https://localhost:44351/Empresaapi/nombres/' + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setempresas(dataJson)
                setbusquedaempresas(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    const buscartodoslosgrupos = (value) => {

        const api = fetch('https://localhost:44351/Grupoapi/' + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setgrupos(dataJson)
                setbusquedagrupos(dataJson)
                console.log(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    const borrartodaslasempresas = () => {
        setempresas([])
    }
    const valoressugeridos = (sugerencia) => {

        return sugerencia.nit + " - " + sugerencia.nombre
    }
    const rendervaloressugeridos = (sugerencia) => (
        <span onClick={(e) => {

        }}>
            {sugerencia.nit + " - " + sugerencia.nombre}
        </span>
    )

    const rendervaloressugeridos2 = ({ containerProps, children, query }) => {
        <span onClick={(e) => {
            console.log(this)
        }}>
            {children.nit + " - " + children.nombre}
        </span>
    }

    const obtenerEmpresas = async (coincidencia) => {
        let response = await fetch('https://localhost:44351/Empresaapi/nombres/' + coincidencia);

        if (response.ok) {
            let data = await response.json()
            setempresas(data)
        }
    }
    const filtrarempresas = async (coincidencia) => {
        var _empresas = empresas.filter((emp) => {
            if (emp.nit.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedaempresas(_empresas)
    }
    const filtrargrupos = async (coincidencia) => {
        var _empresas = grupos.filter((emp) => {
            if (emp.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedaempresas(_empresas)
    }
    const obtenerUsuarios = async (coincidencia) => {
        let response = await fetch('https://localhost:44351/Usuarioapi/completo/' + coincidencia);

        if (response.ok) {
            let data = await response.json()
            setLlamadas(data)
            setPendiente(false)
        }
    }

    useEffect(() => {
        // obtenerRoles();
        // obtenerUsuarios('');
        obtenerEmpresas('');
        buscartodoslosgrupos('');
    }, [])

    useEffect(() => {
        console.log('')
        //    agregarcondatos()

    }, [editarempresa])

    const columns = [
        {
            name: 'Ticket',
            selector: row => row.llamada.id,
            sortable: true,
        },
        {
            name: 'Nit',
            selector: row => row.llamada.nit,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.llamada.nombre,
            sortable: true,
        },
        {
            name: 'Asesor',
            selector: row => row.llamada.asesor,
            sortable: true,
        },
        {
            name: 'Plan',
            selector: row => row.llamada.plan,
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: row => row.llamada.fecha,
            sortable: true,
        },
        {
            name: 'Hora',
            selector: row => row.llamada.hora,
            sortable: true,
        },
        {
            name: 'Estado',
            // selector: row => row.esActivo,
            selector: row => true,
            sortable: true,
            cell: row => {
                let clase;
                // clase = row.esActivo ? "badge badge-info p-2" : "badge badge-danger p-2"
                clase = (row.llamada.estado == 'Por Atender') ? "badge badge-danger p-2" : "badge badge-info p-2"
                return (
                    // <span className={clase}>{row.esActivo ? "Activo" : "No Activo"}</span>
                    <span className={clase}>{row.llamada.estado}</span>
                )
            }
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModal = (data) => {
        var enviodata = { ...data }
        var _usuario = {
            id: enviodata.llamada.id,
            cedula: enviodata.llamada.cedula,
            nit: "",
            nombre: enviodata.llamada.nombre,
            nombreempresa: "",
            contraseña: enviodata.llamada.contraseña,
            cargo: enviodata.llamada.cargo,
            departamento: enviodata.llamada.departamento,
            empresas: enviodata.historial
        }
        setLlamada(_usuario);
        setVerModal(!verModal);
        seteditar(true)
    }

    const cerrarModal = () => {
        setLlamada(modeloUsuario)
        setVerModal(!verModal);
        seteditar(false)
        setKey('Datos')
    }

    const seleccionarempresa = (empresa) => {
        console.log()
        setVerModal2(false);
        console.log(empresa)
        var _empresaseleccionada = empresas.filter((emp) => {
            if (emp.nit == empresa) { return emp }

        })
        var _usuario = { ...llamada }
        var _empresas = _usuario.historial
        var _yaexiste = _empresas.filter((emp) => {
            if (emp.empresa == empresa) { return emp }

        })
        if (_empresaseleccionada.length != 0 && _yaexiste.length == 0) {
            _empresas[fila]['empresa'] = _empresaseleccionada[0].nit
            _empresas[fila]['nombreempresa'] = _empresaseleccionada[0].nombre
            _empresas[fila]['grupo'] = ''
        }
        _usuario.historial = _empresas

        setLlamada(_usuario)
        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const seleccionargrupo2 = (grupo) => {
        console.log('')
        setfila2('')
        console.log(fila2)

    }
    const seleccionargrupo = async (grupo) => {
        console.log(grupo)
        setverModalGrupo(false);
        var usuuu = llamadas
        console.log(grupo)
        var _gruposelecionado = grupos.filter((gru) => {
            if (gru.codigogrupo == grupo) { return gru }

        })
        var _usuario = { ...llamada }
        var _empresas = { ...llamada }.historial
        if (_gruposelecionado.length != 0) {
            let _envioempresas = _empresas.map((emp, index) => {
                if (index == fila) {
                    return {
                        llamada: emp.llamada,
                        empresa: emp.empresa,
                        nombreusuario: emp.nombreusuario,
                        nombreempresa: emp.nombreempresa,
                        grupo: _gruposelecionado[0].codigogrupo

                    }
                } else {
                    return emp
                }
            })
            _usuario.historial = _envioempresas
            // setLlamada(_usuario)
        }
        setLlamada(_usuario)
        console.log(llamada)
        setRoles({ codig: '' })
        console.log(roles)
        seteditarempresa(!editarempresa)
        agregarcondatos(_usuario)

        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const cerrarModalSeleccionEmpresas = () => {
        setVerModal2(!verModal2);
        setbusquedaempresas(empresas)
    }
    const cerrarModalSeleccionGrupos = () => {
        setverModalGrupo(!verModalGrupo);
        setbusquedagrupos(grupos)
    }
    const agregar = async (e, index) => {
        var mod = modeloUsuario
        var _usuario = { ...llamada }
        var _empresas = { ...llamada }.historial
        var _empresasfinales = _empresas.concat({
            llamada: _usuario.cedula,
            empresa: "",
            nombreusuario: _usuario.nombre,
            nombreempresa: "",
            grupo: ""
        })
        _usuario.historial = _empresasfinales
        setLlamada(_usuario)
        setRoles([])
    }
    const agregarcondatos = async (__usuario) => {

        var _usuario = __usuario
        var _empresas = _usuario.historial
        var _verificar = _empresas.filter((emp) => {
            if (emp.empresa == "") {
                return emp
            }
        })
        if (_verificar.length == 0) {
            var _empresasfinales = _empresas.concat({
                llamada: _usuario.cedula,
                empresa: "",
                nombreusuario: _usuario.nombre,
                nombreempresa: "",
                grupo: ""
            })
            _usuario.historial = _empresasfinales
            setLlamada(_usuario)
            setRoles([])
        }

    }

    const guardarCambios = async () => {

        // delete llamada.idRolNavigation;
        var _usuario = { ...llamada }
        var _empresas = _usuario.historial
        var _enviousuario = {
            llamada: {
                id: _usuario.id,
                cedula: _usuario.cedula,
                nit: _usuario.nit,
                nombre: _usuario.nombre,
                nombreempresa: _usuario.nombreempresa,
                contraseña: _usuario.contraseña,
                cargo: _usuario.cargo,
                departamento: _usuario.departamento,
            },
            empresas: []
        }
        for (let i = 0; i < _empresas.length; i++) {
            const element = _empresas[i]
            if (element.empresa != "") {
                _enviousuario.historial.push({
                    llamada: _usuario.cedula,
                    empresa: element.empresa,
                    nombreusuario: _usuario.nombre,
                    nombreempresa: element.nombreempresa,
                    grupo: element.grupo
                })
            }

        }
        let response;
        if (!editar) {
            response = await fetch("https://localhost:44351/Usuarioapi/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_enviousuario)
            })

        } else {
            response = await fetch("https://localhost:44351/Usuarioapi/", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_enviousuario)
            })
        }

        if (response.ok) {
            await obtenerUsuarios('');
            setLlamada(modeloUsuario)
            setVerModal(!verModal);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'El Usuario fue guardado exitosamente',
                'success'
            )
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Sucedio un error al guardar el Usuario',
                'error'
            )
        }

    }

    const eliminarUsuario = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el llamada",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/llamada/Eliminar/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            // obtenerUsuarios();

                            Swal.fire(
                                'Eliminado!',
                                'El llamada fue eliminado.',
                                'success'
                            )
                        }
                    })
            }
        })
    }


    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Llamadas
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataringcallrecepcion" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerUsuarios(document.getElementById('buscardataringcallrecepcion').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModal(!verModal)
                                seteditar(false)
                                buscartodasempresas('')
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={llamadas}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={verModal}>
                <ModalHeader>
                    Soporte
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <Label>Nit</Label>
                                    <FormGroup inline={true}>
                                        <InputGroup>
                                            <Input disabled={editar} onChange={handleChange} bsSize="sm" name="nit" value={llamada.llamada.nit} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    // setbusquedaempresas(empresas)
                                                    // setfila(index)
                                                    // setVerModal2(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={8}>
                                    <Label>Nombre</Label>
                                    <FormGroup>
                                        <Input disabled bsSize="sm" onChange={handleChange} name="nombre" value={llamada.llamada.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <Label>Contacto</Label>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input bsSize="sm" onChange={handleChange} name="contacto" value={llamada.llamada.contacto} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    // setbusquedaempresas(empresas)
                                                    // setfila(index)
                                                    // setVerModal2(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <Label>Modulo</Label>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input bsSize="sm" onChange={handleChange} name="modulo" value={llamada.llamada.modulo} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    // setbusquedaempresas(empresas)
                                                    // setfila(index)
                                                    // setVerModal2(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <Label>Asesor</Label>
                                    <FormGroup>
                                        <InputGroup>
                                            <Input bsSize="sm" onChange={handleChange} name="asesor" value={llamada.llamada.asesor} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    // setbusquedaempresas(empresas)
                                                    // setfila(index)
                                                    // setVerModal2(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                {(llamada.llamada.agendado) &&
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Fecha de Agenda</Label>
                                            <Input type="datetime-local" bsSize="sm" onChange={handleChange} name="fechaagenda" value={llamada.llamada.asesor} />

                                        </FormGroup>
                                    </Col>
                                }

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label check className="mt-4 ml-4">
                                            <Input type="checkbox" bsSize="sm" onChange={handleChange} name="agendado" checked={llamada.llamada.agendado} />Agendar

                                        </Label>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col sm={12}>
                                    <FormGroup>
                                        <Label>Observación</Label>
                                        <Input type="textarea" onChange={handleChange} style={{ fontSize: '14px', height: '120px' }} name="mensaje" value={llamada.llamada.mensaje} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Buscar filtrar={(coincidencia) => { filtrarempresas(coincidencia) }} busqueda={busquedaempresas} encabezado={["Nit", "Nombre"]} codigo={"nit"} nombre={"nombre"} dato={""} dato2={""} verModal={verModal2} cerrarModal={() => { cerrarModalSeleccionEmpresas() }} seleccionar={(seleccionado) => {
                                seleccionarempresa(seleccionado)
                            }} />
                            <Buscar filtrar={(coincidencia) => { filtrargrupos(coincidencia) }} busqueda={busquedagrupos} encabezado={["Codigo", "Nombre"]} codigo={"codigogrupo"} nombre={"nombregrupo"} dato={""} dato2={""} verModal={verModalGrupo} cerrarModal={() => { cerrarModalSeleccionGrupos() }} seleccionar={(seleccionado) => {
                                seleccionargrupo(seleccionado)
                            }} />

                        </Tab>
                    </Tabs>

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default Usuario;