import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloTercero = {
    tercero: "",
    codigo: "",
    direccion: "",
    cedula: "",
    digito: "",
    contacto: "",
    nom1: "",
    nom2: "",
    ape1: "",
    ape2: "",
    razsoc: "",
    repleg: "",
    beneficiario: "",
    tel1: "",
    tel2: "",
    tel3: "",
    coddepar: "",
    codigomun: "",
    codpais: "",
    email: "",
    ciudad: "",
    pais: "",
    desccom: "",
    plazo: "",
    cupocre: "",
    estado: "",
    clas1: "?",
    clas2: "?",
    clas3: "?",
    clas4: "?",
    clas5: "?",
    reteica: "",
    regtrib: "",
    resfis:"",
    // personat: 00,
    // auto: "",
    feching: "",
    fechulne: "",
    salant1: "",
    saldeb1: "",
    salcre1: "",
    salact1: "",
    salant2: "",
    saldeb2: "",
    salcre2: "",
    salact2: "",
    brutop1: "",
    brutop2: "",
    ivap: "",
    desctop1: "",
    desctop2: "",
    reteftep: "",
    reteivap: "",
    reteica2p: "",
    imptoconsumop: "",
    brutoa1: "",
    brutoa2: "",
    ivaa: "",
    desctoa1: "",
    desctoa2: "",
    retftea: "",
    reteivaa: "",
    reteicaa: "",
    imptoconsa: "",
    creadopor: "",
    borradopor: "",
    modificpor: "",
    vendedor: "",
    precsug:"",
    municipio: "",
    desccom2: "",
    desccom3: "",
    desccom4: "",
    clase: "2",
    interes: "",
    cambioimp: "",
    flete: "",
    fechnac:"",
    // cumplemes: "",
    // cumpledia: "",
    // cumpleano: "",
    horafecha: "",
    sexo: "",
    zona: "",
    ccosto: "",
    autoreteft: "",
    actecono: "",
    clas6: "",
    clas7: "",
    clas8: "",
    clas9: "",
    clas10: "",
    autorexpro: "",
    barrio: "",
    cod_postal: "",
    reg_merc: "",
    nompais: "",
    campo: "",
    venretefuente: "",
    venbasefte: "",
    venporcfte: "",
    vencuenfte: "",
    venreteiva: "",
    venbasereteiva: "",
    venporcreteiva: "",
    vencuenreteiva: "",
    veniva: "",
    venbaseiva: "",
    venporciva: "",
    vencueniva: "",
    venreteica: "",
    venbaseica: "",
    venporica: "",
    vencuenica: "",
    comretefte: "",
    combasefte: "",
    comporcfte: "",
    comcuenfte: "",
    comretereteiva: "",
    combasereteiva: "",
    comporcreteiva: "",
    comcuenreteiva: "",
    veniva: "",
    combaseiva: "",
    comporciva: "",
    comcueniva: "",
    comreteica: "",
    combaseica: "",
    comporica: "",
    comcuenica: "",
    vencree: "",
    venbacree: "",
    venporcree: "",
    comcree: "",
    combacree: "",
    comporcree: "",
    otrocampo1: "",
    otrocampo2: "",
    otrocampo3: "",
    otrocampo4: "",

}

const Tercero = () => {

    const [tercero, setTercero] = useState(modeloTercero);
    const [pendiente, setPendiente] = useState(true);
    const [terceros, setTerceros] = useState([]);
    const [roles, setRoles] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [verModalMunicipio, setVerModalMunicipio] = useState(false);
    const [verModal2, setVerModal2] = useState(false);
    const [verModalGrupo, setverModalGrupo] = useState(false);
    const [key, setKey] = useState('Datos');
    const [empresas, setempresas] = useState([]);
    const [editarempresa, seteditarempresa] = useState(false);
    const [grupos, setgrupos] = useState([]);
    const [busquedaempresas, setbusquedaempresas] = useState([]);
    const [busquedagrupos, setbusquedagrupos] = useState([]);
    const [fila, setfila] = useState(0);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [municipios, setmunicipio] = useState(0);
    const [busquedamunicipios, setbusquedamunicipios] = useState(0);
    const [departamento, setdepartamento] = useState(0);
    
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _tercero = { ...tercero }
        _tercero[e.target.name] = value
        setTercero(_tercero)
        setRoles([])


    }


    const buscartodasempresas = (value) => {

        const api = fetch('https://localhost:44351/Empresaapi/nombres/' + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setempresas(dataJson)
                setbusquedaempresas(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    const buscartodoslosgrupos = (value) => {

        const api = fetch('https://localhost:44351/Grupoapi/' + value)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                setgrupos(dataJson)
                setbusquedagrupos(dataJson)
                console.log(dataJson)
            }).catch((error) => {
                console.log("No se pudo obtener datos, mayor detalle: ", error)
            })

    }
    const borrartodaslasempresas = () => {
        setempresas([])
    }
    const valoressugeridos = (sugerencia) => {

        return sugerencia.nit + " - " + sugerencia.nombre
    }
    const rendervaloressugeridos = (sugerencia) => (
        <span onClick={(e) => {

        }}>
            {sugerencia.nit + " - " + sugerencia.nombre}
        </span>
    )

    const rendervaloressugeridos2 = ({ containerProps, children, query }) => {
        <span onClick={(e) => {
            console.log(this)
        }}>
            {children.nit + " - " + children.nombre}
        </span>
    }

    const obtenerEmpresas = async (coincidencia) => {
        let response = await fetch('https://localhost:44351/Empresaapi/nombres/' + coincidencia);

        if (response.ok) {
            let data = await response.json()
            setempresas(data)
        }
    }

    const obtenerMunicipio = async (coincidencia) => {
        const api = fetch("https://localhost:44351/Empresaapi/municipio/" + coincidencia)
        .then((response) => {
            return response.ok ? response.json() : Promise.reject(response);
        })
        .then((dataJson) => {
            setmunicipio(dataJson.municipio)
            setdepartamento(dataJson.departamento)
            setbusquedamunicipios(dataJson.municipio)
        }).catch((error) => {
            console.log("No se pudo obtener datos, mayor detalle: ", error)
        })

    }
    const filtrarempresas = async (coincidencia) => {
        var _empresas = empresas.filter((emp) => {
            if (emp.nit.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedaempresas(_empresas)
    }

    const filtrarmunicipios = async (coincidencia) => {
        var _empresas = municipios.filter((emp) => {
            if (emp.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedamunicipios(_empresas)
    }
    const filtrargrupos = async (coincidencia) => {
        var _empresas = grupos.filter((emp) => {
            if (emp.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || emp.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return emp }

        })
        setbusquedaempresas(_empresas)
    }
    const obtenerTerceros = async (coincidencia) => {
        let response = await fetch('https://localhost:44351/Terceroapi/completo/' + coincidencia);

        if (response.ok) {
            let data = await response.json()
            setTerceros(data)
            setPendiente(false)
        }
    }

    useEffect(() => {
        // obtenerRoles();
        // obtenerTerceros('');
        // obtenerEmpresas('');
        // buscartodoslosgrupos('');
        obtenerMunicipio('');
    }, [])

    useEffect(() => {
        console.log('')
        //    agregarcondatos()

    }, [editarempresa])

    const columns = [

        {
            name: 'Nit',
            selector: row => row.tercero.cedula,
            sortable: true,
        },
        {
            name: 'Razon Social',
            selector: row => row.tercero.nombre,
            sortable: true,
        },
        {
            name: 'Direccion',
            selector: row => row.tercero.contraseña,
            sortable: true,
        },

        {
            name: 'Telefono',
            selector: row => row.tercero.contraseña,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModal = (data) => {
        var enviodata = { ...data }
        var _tercero = {
            id: enviodata.tercero.id,
            cedula: enviodata.tercero.cedula,
            nit: "",
            nombre: enviodata.tercero.nombre,
            nombreempresa: "",
            contraseña: enviodata.tercero.contraseña,
            cargo: enviodata.tercero.cargo,
            departamento: enviodata.tercero.departamento,
            empresas: enviodata.empresas
        }
        setTercero(_tercero);
        setVerModal(!verModal);
        seteditar(true)
    }

    const cerrarModal = () => {
        setTercero(modeloTercero)
        setVerModal(!verModal);
        seteditar(false)
        setKey('Datos')
    }

    const seleccionarempresa = (empresa) => {
        console.log()
        setVerModal2(false);
        console.log(empresa)
        var _empresaseleccionada = empresas.filter((emp) => {
            if (emp.nit == empresa) { return emp }

        })
        var _tercero = { ...tercero }
        var _empresas = _tercero.empresas
        var _yaexiste = _empresas.filter((emp) => {
            if (emp.empresa == empresa) { return emp }

        })
        if (_empresaseleccionada.length != 0 && _yaexiste.length == 0) {
            _empresas[fila]['empresa'] = _empresaseleccionada[0].nit
            _empresas[fila]['nombreempresa'] = _empresaseleccionada[0].nombre
            _empresas[fila]['grupo'] = ''
        }
        _tercero.empresas = _empresas

        setTercero(_tercero)
        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const seleccionarmunicipio = (value) => {
        
        setVerModalMunicipio(false);
        var nombremunicipio=municipios.filter(p => p.codigo ==value)[0].nombre
        var codigodepartamento=value.substring("",2)
        var nombredepartamente=departamento.filter(p => p.codigo ==codigodepartamento)[0].nombre
        setTercero({
            ...tercero,
            ['codigomun']: value,
            ['municipio']:nombremunicipio,
            ['coddepar']:value.substring("",2),
            ['departamento']:nombredepartamente,
            ['pais']:'COLOMBIA',
            ['codpais']:'CO'
        })
        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const seleccionargrupo2 = (grupo) => {
        console.log('')
        setfila2('')
        console.log(fila2)

    }
    const seleccionargrupo = async (grupo) => {
        console.log(grupo)
        setverModalGrupo(false);
        var usuuu = terceros
        console.log(grupo)
        var _gruposelecionado = grupos.filter((gru) => {
            if (gru.codigogrupo == grupo) { return gru }

        })
        var _tercero = { ...tercero }
        var _empresas = { ...tercero }.empresas
        if (_gruposelecionado.length != 0) {
            let _envioempresas = _empresas.map((emp, index) => {
                if (index == fila) {
                    return {
                        tercero: emp.tercero,
                        empresa: emp.empresa,
                        nombretercero: emp.nombretercero,
                        nombreempresa: emp.nombreempresa,
                        grupo: _gruposelecionado[0].codigogrupo

                    }
                } else {
                    return emp
                }
            })
            _tercero.empresas = _envioempresas
            // setTercero(_tercero)
        }
        setTercero(_tercero)
        console.log(tercero)
        setRoles({ codig: '' })
        console.log(roles)
        seteditarempresa(!editarempresa)
        agregarcondatos(_tercero)

        // setempresas(_empresas)
        // setbusquedaempresas(_empresas)
    }
    const cerrarModalSeleccionEmpresas = () => {
        setVerModal2(!verModal2);
        setbusquedaempresas(empresas)
    }
    const cerrarModalSeleccionMunicipios = () => {
        setVerModalMunicipio(!verModalMunicipio);
        setbusquedamunicipios(municipios)
    }
    const cerrarModalSeleccionGrupos = () => {
        setverModalGrupo(!verModalGrupo);
        setbusquedagrupos(grupos)
    }
    const agregar = async (e, index) => {
        var mod = modeloTercero
        var _tercero = { ...tercero }
        var _empresas = { ...tercero }.empresas
        var _empresasfinales = _empresas.concat({
            tercero: _tercero.cedula,
            empresa: "",
            nombretercero: _tercero.nombre,
            nombreempresa: "",
            grupo: ""
        })
        _tercero.empresas = _empresasfinales
        setTercero(_tercero)
        setRoles([])
    }
    const agregarcondatos = async (__tercero) => {

        var _tercero = __tercero
        var _empresas = _tercero.empresas
        var _verificar = _empresas.filter((emp) => {
            if (emp.empresa == "") {
                return emp
            }
        })
        if (_verificar.length == 0) {
            var _empresasfinales = _empresas.concat({
                tercero: _tercero.cedula,
                empresa: "",
                nombretercero: _tercero.nombre,
                nombreempresa: "",
                grupo: ""
            })
            _tercero.empresas = _empresasfinales
            setTercero(_tercero)
            setRoles([])
        }

    }

    const guardarCambios = async () => {

        // delete tercero.idRolNavigation;
        var _tercero = { ...tercero }
        var _empresas = _tercero.empresas
        var _enviotercero = {
            tercero: {
                id: _tercero.id,
                cedula: _tercero.cedula,
                nit: _tercero.nit,
                nombre: _tercero.nombre,
                nombreempresa: _tercero.nombreempresa,
                contraseña: _tercero.contraseña,
                cargo: _tercero.cargo,
                departamento: _tercero.departamento,
            },
            empresas: []
        }
        for (let i = 0; i < _empresas.length; i++) {
            const element = _empresas[i]
            if (element.empresa != "") {
                _enviotercero.empresas.push({
                    tercero: _tercero.cedula,
                    empresa: element.empresa,
                    nombretercero: _tercero.nombre,
                    nombreempresa: element.nombreempresa,
                    grupo: element.grupo
                })
            }

        }
        let response;
        if (!editar) {
            response = await fetch("https://localhost:44351/Terceroapi/", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_enviotercero)
            })

        } else {
            response = await fetch("https://localhost:44351/Terceroapi/", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(_enviotercero)
            })
        }

        if (response.ok) {
            await obtenerTerceros('');
            setTercero(modeloTercero)
            setVerModal(!verModal);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'El Tercero fue guardado exitosamente',
                'success'
            )
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Sucedio un error al guardar el Tercero',
                'error'
            )
        }

    }

    const eliminarTercero = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el tercero",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/tercero/Eliminar/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            // obtenerTerceros();

                            Swal.fire(
                                'Eliminado!',
                                'El tercero fue eliminado.',
                                'success'
                            )
                        }
                    })
            }
        })
    }


    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Terceros
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataTerceros" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerTerceros(document.getElementById('buscardataTerceros').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModal(!verModal)
                                seteditar(false)
                                buscartodasempresas('')
                                buscartodoslosgrupos('')
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={terceros}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={verModal}>
                <ModalHeader  style={{ backgroundColor: '#188BAD', color: 'white' }} >
                    Crear Tercero
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        {(true) &&
                            <Tab eventKey="Datos" title="Datos">
                                <Row>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>Tipo Tercero</Label>
                                            <Input type="select" style={{ fontSize: '13px' }} name="tercero" >
                                                <option value="1">Cliente</option>
                                                <option value="2">Proveedor</option>
                                                <option value="3">Otros</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Clase</Label>
                                            <Input type="select" style={{ fontSize: '13px' }} name="clase" >
                                                <option value="31">Nit</option>
                                                <option value="13">Cedula</option>
                                                <option value="22">Cedula Extranjeria</option>
                                                <option value="41">Pasaporte</option>
                                                <option value="11">Registro Civil</option>
                                                <option value="12">Tarjeta de Identidad</option>
                                                <option value="42">Documento de Identifiacion Extranjero</option>
                                                <option value="10">Tarjeta de Extranjeria</option>
                                                <option value="91">NUIP</option>
                                                <option value="47">Permiso Especial de Permanencia (PEP)</option>
                                                <option value="13">Sin Identificación del Exterior o para uso Definido por la DIAN</option>
                                                <option value="50">Nit de otro País</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Codigo</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="codigo" value={tercero.cedula} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Documento</Label>

                                            <InputGroup size="sm" >
                                                <Input bsSize="lg" onChange={handleChange} name="cedula" value={tercero.cedula} />
                                                <Col sm={3}>
                                                    <Input disabled bsSize="sm" name="digito" />
                                                </Col>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>Razon Social</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="razsoc" value={tercero.nombre} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Nombre 1</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="nom1" value={tercero.cargo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Nombre 2</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="nom2" value={tercero.cargo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Apellido 1</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="ape1" value={tercero.cargo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Apellido 2</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="ape2" value={tercero.cargo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Municipio</Label>
                                            <InputGroup>
                                                <Input value={tercero.municipio} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        setbusquedamunicipios(municipios)
                                                        setVerModalMunicipio(true)

                                                    }
                                                }} name="codigomun" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        setbusquedamunicipios(municipios)
                                                        setVerModalMunicipio(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></i>
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Departamento</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="coddepar" value={tercero.departamento} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Pais</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="codpais" value={tercero.pais} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="email" value={tercero.email} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Tab>}
                        {(true) &&
                            <Tab eventKey="Datos Adicionales" title="Datos Adicionales">
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Contacto</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="contacto" value={tercero.contacto} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Beneficiario</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="beneficiario" value={tercero.beneficiario} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Representante Legal</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="repleg" value={tercero.repleg} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Registro Mercantil</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="reg_merc" value={tercero.reg_merc} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Direccion</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="direccion" value={tercero.direccion} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Telefono 1</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="tel1" value={tercero.tel1} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Telefono 2</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="tel2" value={tercero.tel2} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Barrio</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="barrio" value={tercero.barrio} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Fecha de Nacimiento</Label>
                                            <Input type="date" bsSize="sm" onChange={handleChange} name="fechnac" value={tercero.fechnac} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>% Interes</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="interes" value={tercero.interes} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Cupo Credito</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="cupocre" value={tercero.cupocre} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Plazo</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="plazo" value={tercero.plazo} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Vendedor</Label>
                                            <InputGroup>
                                                <Input  value={tercero.vendedor}  bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        // setbusquedaempresas(empresas)
                                                        // setfila(index)
                                                        // setVerModal2(true)

                                                    }
                                                }} name="vendedor" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        // setbusquedaempresas(empresas)
                                                        // setfila(index)
                                                        // setVerModal2(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></i>
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Centro de Costo</Label>
                                            <InputGroup>
                                                <Input  value={tercero.ccosto} bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        // setbusquedaempresas(empresas)
                                                        // setfila(index)
                                                        // setVerModal2(true)

                                                    }
                                                }} name="ccosto" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        // setbusquedaempresas(empresas)
                                                        // setfila(index)
                                                        // setVerModal2(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></i>
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup inline={true}>
                                            <Label>Precio Sugerido</Label>
                                            <InputGroup>
                                                <Input value={tercero.precsug}  bsSize="sm" onKeyDown={(e) => {
                                                    if (e.key == "Enter" && e.target.value == "") {
                                                        // setbusquedaempresas(empresas)
                                                        // setfila(index)
                                                        // setVerModal2(true)

                                                    }
                                                }} name="precsug" />
                                                <Button color="primary" size="sm" className="mr-2"
                                                    onClick={() => {
                                                        // setbusquedaempresas(empresas)
                                                        // setfila(index)
                                                        // setVerModal2(true)
                                                    }}
                                                >
                                                    <i className="fas fa-pen-alt"></i>
                                                </Button>
                                            </InputGroup>

                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Actividad Economica</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="actecono" value={tercero.actecono} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Tab>}
                        {(true) &&
                            <Tab eventKey="Datos Especificos" title="Datos Especificos">
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Fecha de Ingreso</Label>
                                            <Input disabled bsSize="sm" onChange={handleChange} name="feching" value={tercero.feching} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Fecha Ultimo Negocio</Label>
                                            <Input disabled bsSize="sm" onChange={handleChange} name="fechulne" value={tercero.fechulne} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Regimen Tributario</Label>
                                            <Input type="select" style={{ fontSize: '13px' }} name="regtrib" value={tercero.regtrib}  >
                                                <option value="O-13">Gran contribuyente</option>
                                                <option value="O-15">Autorretenedor</option>
                                                <option value="O-23">Agente de retención IVA</option>
                                                <option value="O-47">Régimen simple de tributación</option>
                                                <option value="R-99-PN">Otro</option>
                                                <option value="No aplica">No Aplica</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Responsabilidad Fiscal</Label>
                                            <Input type="select" style={{ fontSize: '13px' }} name="resfis"  value={tercero.resfis} >
                                                <option value="O-13">Gran contribuyente</option>
                                                <option value="O-15">Autorretenedor</option>
                                                <option value="O-23">Agente de retención IVA</option>
                                                <option value="O-47">Régimen simple de tributación</option>
                                                <option value="O-48">Impuesto sobre las ventas</option>
                                                <option value="O-49">No responsable de IVA</option>
                                                <option value="R-99-PN">No responsable</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Descuento Comercial 1</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="desccom" value={tercero.desccom} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Descuento Comercial 2</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="desccom" value={tercero.desccom} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Descuento Comercial 3</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="desccom3" value={tercero.desccom3} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Descuento Comercial 4</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="desccom4" value={tercero.desccom4} />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Auto ReteFuente</Label>
                                            <Input type="select" style={{ fontSize: '13px' }} name="autoreteft" value={tercero.autoreteft} >
                                                <option value="0">No</option>
                                                <option value="1">Si</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Estado</Label>
                                            <Input bsSize="sm" onChange={handleChange} name="estado" value={tercero.estado} />
                                        </FormGroup>
                                    </Col>
                                   
                                    <Col sm={12} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                        <p>Saldos</p>

                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Anterior</Label>
                                            <Input disabled bsSize="sm" onChange={handleChange} name="salant1" value={tercero.salant1} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Debito</Label>
                                            <Input disabled bsSize="sm" onChange={handleChange} name="saldeb1" value={tercero.saldeb1} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Credito</Label>
                                            <Input disabled bsSize="sm" onChange={handleChange} name="salcre1" value={tercero.salcre1} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>Actual</Label>
                                            <Input disabled bsSize="sm" onChange={handleChange} name="salact1" value={tercero.salact1} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Tab>}
                        {(true) &&
                            <Tab eventKey="Impuestos Ventas" title="Impuestos Ventas">
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11}  style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>Iva</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="venbaseiva" value={tercero.venbaseiva} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input style={{ width: '60px' }} bsSize="sm" name="venporciva"  value={tercero.venporciva} />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.vencueniva}  bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="vencueniva" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>ReteFuente</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="venbasefte" value={tercero.venbasefte} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input style={{ width: '60px' }} bsSize="sm" name="venporcfte"  value={tercero.venporcfte} />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input value={tercero.vencuenfte} bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="vencuenfte" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>Rete Iva</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="venbasereteiva" value={tercero.venbasereteiva} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input style={{ width: '60px' }} bsSize="sm" name="venporcreteiva"  value={tercero.venporcreteiva} />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.vencuenreteiva} bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="vencuenreteiva" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>Rete Ica</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="venbaseica" value={tercero.venbaseica} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.venporica} style={{ width: '60px' }} bsSize="sm" name="venporica" />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input value={tercero.vencuenica}  bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="vencuenica" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Tab>}

                            {(true) &&
                            <Tab eventKey="Impuestos Compras" title="Impuestos Compras">
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11}  style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>Iva</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="combaseiva" value={tercero.combaseiva} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input value={tercero.comporciva}  style={{ width: '60px' }} bsSize="sm" name="comporciva" />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.comcueniva} bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="comcueniva" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>ReteFuente</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="combasefte" value={tercero.combasefte} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.comporcfte} style={{ width: '60px' }} bsSize="sm" name="comporcfte" />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input value={tercero.comcuenfte}  bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="comcuenfte" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>Rete Iva</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="combasereteiva" value={tercero.combasereteiva} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.comporcreteiva} style={{ width: '60px' }} bsSize="sm" name="comporcreteiva" />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input value={tercero.comcuenreteiva}  bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="comcuenreteiva" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={11} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                                <p>Rete Ica</p>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label>Base Minima</Label>
                                                    <Input bsSize="sm" onChange={handleChange} name="combaseica" value={tercero.combaseica} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup inline={true}>
                                                    <Label>Porcentaje</Label>
                                                    <InputGroup>
                                                        <Input value={tercero.comporica}  style={{ width: '60px' }} bsSize="sm" name="comporica" />
                                                        <Input disabled bsSize="sm" name="empresa" value="%" />
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <FormGroup inline={true}>
                                                    <Label>Cuenta</Label>
                                                    <InputGroup>
                                                        <Input  value={tercero.comcuenica} bsSize="sm" onKeyDown={(e) => {
                                                            if (e.key == "Enter" && e.target.value == "") {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)

                                                            }
                                                        }} name="comcuenica" />
                                                        <Button color="primary" size="sm" className="mr-2"
                                                            onClick={() => {
                                                                // setbusquedaempresas(empresas)
                                                                // setfila(index)
                                                                // setVerModal2(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-pen-alt"></i>
                                                        </Button>
                                                    </InputGroup>

                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Tab>}
                    </Tabs>

                    {/* <Buscar filtrar={(coincidencia) => { filtrarmunicipios(coincidencia) }} busqueda={busquedamunicipios} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalMunicipio} cerrarModal={() => { cerrarModalSeleccionMunicipios() }} seleccionar={(seleccionado) => {
                        seleccionarmunicipio(seleccionado)
                    }} />
                    <Buscar filtrar={(coincidencia) => { filtrargrupos(coincidencia) }} busqueda={busquedagrupos} encabezado={["Codigo", "Nombre"]} codigo={"codigogrupo"} nombre={"nombregrupo"} dato={""} dato2={""} verModal={verModalGrupo} cerrarModal={() => { cerrarModalSeleccionGrupos() }} seleccionar={(seleccionado) => {
                        seleccionargrupo(seleccionado)
                    }} /> */}


                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default Tercero;