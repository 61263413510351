import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import * as XLSX from "xlsx"

const modeloListadeprecio = {
    codigo: "",
    nombre: "",
}
const Listadeprecio = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Listadeprecio, setListadeprecio] = useState(modeloListadeprecio);
    const [Listadeprecios, setListadeprecios] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalListadeprecio, setVerModalListadeprecio] = useState(false);


    const exportarExcel = () => {

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(Listadeprecios);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Exportacion Lista de Precios.xlsx")
    }
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Listadeprecio = { ...Listadeprecio }
        if (e.target.type == "number") {
            _Listadeprecio[e.target.name] = parseFloat(value)
        }
        else {
            _Listadeprecio[e.target.name] = value
        }
        if (e.target.name.includes('descuento')) {
            _Listadeprecio[e.target.name] = Number(value + "")
        }
        setListadeprecio(_Listadeprecio)
        setRoles([])


    }
    const obtenerListadeprecios = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Listadeprecioapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setListadeprecios(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerListadeprecios('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalListadeprecio(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalListadeprecio = (data) => {
        var enviodata = { ...data }
        var _Listadeprecio = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
            descuento1: enviodata.descuento1,
            descuento2: enviodata.descuento2,
            descuento3: enviodata.descuento3,
            resolucion: enviodata.resolucion,
        }
        setListadeprecio(_Listadeprecio);
        setVerModalListadeprecio(!verModalListadeprecio);
        seteditar(true)
    }
    const cerrarModalListadeprecio = () => {
        setListadeprecio(modeloListadeprecio)
        setVerModalListadeprecio(!verModalListadeprecio);
        seteditar(false)
        setKey('Datos')
    }
    const guardarCambios = async () => {
        let response;
        console.log(Listadeprecio)
        if (!editar) {
            response = await fetch("" + props.servidor + "/Listadeprecioapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Listadeprecio)
            })

        } else {
            response = await fetch("" + props.servidor + "/Listadeprecioapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Listadeprecio)
            })
        }

        if (response.ok) {
            await obtenerListadeprecios('');
            setListadeprecio(modeloListadeprecio)
            setVerModalListadeprecio(!verModalListadeprecio);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'La lista de Precio fue guardado exitosamente',
                'success'
            )
        } else {
            var textoerrores = await response.text()
            var jsonerrores = JSON.parse(textoerrores)
            var errores = jsonerrores.errors
            let claves = Object.keys(errores);
            var stringerrores = ""
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                if (errores[clave].length != 0) {
                    stringerrores = stringerrores + errores[clave][0] + " \n "
                }

            }
            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje = 'Hay campos numericos rellenados con letras'
            }
            console.log(stringerrores)
            Swal.fire(
                'Error al Guardar!',
                mensaje,
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Precios
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataListadeprecios" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerListadeprecios(document.getElementById('buscardataListadeprecios').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalListadeprecio(!verModalListadeprecio)
                                seteditar(false)
                            }}>Nuevo</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                exportarExcel()
                            }}>Exportar</Button>

                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearlistadeprecio(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Listadeprecios}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalListadeprecio}>
                <ModalHeader>
                    Lista de Precio
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Codigo</Label>
                                        <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Listadeprecio.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Listadeprecio.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Desc 1</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descuento1" value={Listadeprecio.descuento1} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Desc 2</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descuento2" value={Listadeprecio.descuento2} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Desc 3</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descuento3" value={Listadeprecio.descuento3} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Resolucion</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="resolucion" value={Listadeprecio.resolucion} />
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalListadeprecio}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Listadeprecio;
