import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../../componentes/Buscar'
const modeloConplantillainforme = {
    codigo: "",
    descripcionfila: "",
    descripciontotales: "",
}
const Conplantillainforme = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Conplantillainforme, setConplantillainforme] = useState(modeloConplantillainforme);
    const [Conplantillainformes, setConplantillainformes] = useState([]);
    const [codigo, setCodigo] = useState([]);
    const [Codigos, setCodigos] = useState([]);
    const [descripcionfila, setdescripcionfila] = useState([]);
    const [descripcionfilas, setdescripcionfilas] = useState([]);
    const [descripciontotales, setdescripciontotales] = useState([]);
    const [descripciontotaless, setdescripciontotaless] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalConplantillainforme, setVerModalConplantillainforme] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Conplantillainforme = { ...Conplantillainforme }
        _Conplantillainforme[e.target.name] = value
        setConplantillainforme(_Conplantillainforme)
        setRoles([])


    }
    const obtenerConplantillainformes = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Conplantillainformeapi?llave=' + dt.usuario.llaveempresa  + '');

            if (response.ok) {
                let data = await response.json()
                setConplantillainformes(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerConplantillainformes('');
    }, [])
    const columns = [
        {
            name: 'codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Descripcion Fila',
            selector: row => row.descripcionfila,
            sortable: true,
        },
        {
            name: 'Descripcion Totales',
            selector: row => row.descripciontotales,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalConplantillainforme(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalConplantillainforme = (data) => {
        var enviodata = { ...data }
        var _Conplantillainforme = {
            codigo: enviodata.codigo,
            descripcionfila: enviodata.descripcionfila,
            descripciontotales: enviodata.descripciontotales,
        }
        setConplantillainforme(_Conplantillainforme);
        setVerModalConplantillainforme(!verModalConplantillainforme);
        seteditar(true)
    }
    const cerrarModalConplantillainforme = () => {
        setConplantillainforme(modeloConplantillainforme)
        setVerModalConplantillainforme(!verModalConplantillainforme);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Conplantillainforme) => {

        let claves = Object.keys(_Conplantillainforme);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Conplantillainforme[clave] = Number(_Conplantillainforme[clave])
            }
        }
        return _Conplantillainforme
    }
    const verificar = (_Conplantillainforme) => {

        let claves = Object.keys(_Conplantillainforme);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' ) {
                if (_Conplantillainforme[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Conplantillainforme)
        var _Conplantillainforme = { ...Conplantillainforme }
        _Conplantillainforme = asignarceros(_Conplantillainforme)
        var _verificado = verificar(_Conplantillainforme)
        if (_verificado) {
            console.log(_Conplantillainforme)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Conplantillainformeapi?llave=" +  usuario.usuario.llaveempresa  + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Conplantillainforme)
                })

            } else {
                response = await fetch("" + props.servidor + "/Conplantillainformeapi?llave=" + usuario.usuario.llaveempresa   + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Conplantillainforme)
                })
            }

            if (response.ok) {
                await obtenerConplantillainformes('');
                setConplantillainforme(modeloConplantillainforme)
                setVerModalConplantillainforme(!verModalConplantillainforme);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Conplantillainforme fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Conplantillainforme',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Configuracion Plantilla
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataConplantillainformes" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerConplantillainformes(document.getElementById('buscardataConplantillainformes').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalConplantillainforme(!verModalConplantillainforme)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Conplantillainformes}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={verModalConplantillainforme}>
                <ModalHeader>
                    Configuracion Plantilla
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Conplantillainforme.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Descripcion Fila*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descripcionfila" value={Conplantillainforme.descripcionfila} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Descripcion Totales*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descripciontotales" value={Conplantillainforme.descripciontotales} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalConplantillainforme}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Conplantillainforme;
