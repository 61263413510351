import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../../context/UserProvider';
import Filtro from '../../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import filtro from "../../../../componentes/filtro";
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [centrodecosto, setcentrodecosto] = useState(false);
    const [cuenta, setcuenta] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventascentrodecostos, setventascentrodecostos] = useState(modeloInicio)
    const [ventascuenta, setventascuenta] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [nivel, setnivel] = useState("-");
    const [cuentainicioseleccionado, setcuentainicioseleccionado] = useState("1");
    const [centrodecostoinicioseleccionado, setcentrodecostoinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [cuentafinseleccionado, setcuentafinseleccionado] = useState("39999999999");
    const [centrodecostofinseleccionado, setcentrodecostofinseleccionado] = useState("-");
    const [formatopyg, setformatopyg] = useState("formato 1");
    const [tipoinformepyg, settipoinformepyg] = useState("01");
    const [activo, setactivo] = useState(0);
    const [pasivo, setpasivog] = useState(0);
    const [patrimonio, setpatrimonio] = useState(0);
    const [resultadofinal, setresultadofinal] = useState(0);
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var nivv = '-'
        if (nivel != '') {
            nivv = nivel
        }

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/balancegeneral/" + fechaInicio + "/" + fechaFin + "/" + nivv + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + "/" + tipoinformepyg + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson.balance;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
                setactivo(dataJson.activo)
                setpasivog(dataJson.pasivo)
                setpatrimonio(dataJson.patrimonio)
                setresultadofinal(dataJson.resultadofinal)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    
    const buscarexcel2 = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var nivv = '-'

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        var coincidencia = ''
        const api = fetch(props.servidor + "/ContabilidadApi/balancegeneralexcel2/" + fechaInicio + "/" + fechaFin + "/" + nivv + "/-/-/1/399999999999/" + rule + "/" + true + "/" + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }
                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Balance_General.xlsx');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch(async (error) => {
                console.log(error)
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }


    const buscarpdf = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var nivv = '-'
        if (nivel != '') {
            nivv = nivel
        }

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        var coincidencia = ''
        const api = fetch(props.servidor + "/ContabilidadApi/balancegeneralpdf/" + fechaInicio + "/" + fechaFin + "/" + nivv + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + "/" + tipoinformepyg +"/" + dt.empresa.empresa.nit + '?llave=' + dt.usuario.llaveempresa + '')
           .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'balance_de_prueba.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch(async (error) => {
                console.log(error)
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const columns = [
        {
            name: 'Cuenta',
            selector: row => row.cuenta,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: 'Saldo Anterior',
            selector: row => fm.from(Number(row.saldoanterior)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Debito',
            selector: row => fm.from(Number(row.debito)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Credito',
            selector: row => fm.from(Number(row.credito)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Saldo Actual',
            selector: row => fm.from(Number(row.saldoactual)),
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Centro Costo',
            selector: row => row.codcentrocostos,
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Acreedor',
            selector: row => row.acreedor,
            sortable: true,
            grow: 2,
            width: '200px',
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Balance de Prueba.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Button color="success" size="sm" block onClick={() => { buscarpdf() }}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF 2
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Button color="success" size="sm" block onClick={() => { buscarexcel2() }}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Excel Basico
                        </Button>
                    </FormGroup>
                </Col>
                
            </Row>
            <Row  >
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Activo
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(activo)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Pasivo
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(pasivo)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Patrimonio
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(patrimonio+resultadofinal)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Pasivo + Patrimonio
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(pasivo+patrimonio+resultadofinal)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                {/* <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        {resultadofinal >= 0 ? "Utilidad" : "Perdidas"}
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from((resultadofinal*(-1)))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col> */}

            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={2}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.concepto}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={4} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {centrodecosto &&
                ventascentrodecostos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Nit</p>
                            </Col>
                            <Col xs={5}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={3}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.cliente}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={5}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold" style={{ color: 'black' }}>{item.nombrecli}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}
            {cuenta &&
                ventascuenta.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Nit</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.cuenta == "" ? "Sin cuenta" : item.cuenta}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}

            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    setcentrodecostoinicioseleccionado={(e) => { setcentrodecostoinicioseleccionado(e) }}
                    setcentrodecostofinseleccionado={(e) => { setcentrodecostofinseleccionado(e) }}
                    setcuentainicioseleccionado={(e) => { setcuentainicioseleccionado(e) }}
                    setcuentafinseleccionado={(e) => { setcuentafinseleccionado(e) }}
                    setnivel={(e) => {
                        setnivel(e)
                    }
                    }
                    tipoinformepyg={tipoinformepyg}
                    settipoinformepyg={(e) => { settipoinformepyg(e) }}
                    centrodecostoinicioseleccionado={centrodecostoinicioseleccionado}
                    centrodecostofinseleccionado={centrodecostofinseleccionado}
                    cuentainicioseleccionado={cuentainicioseleccionado}
                    cuentafinseleccionado={cuentafinseleccionado}
                    nivel={nivel}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;