import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { jsPDF } from "jspdf";

const tabespacio = '\u00A0';


const Bcbanco = (props) => {

    const previsualizarpdf = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        const doc = new jsPDF('p', 'pt', 'letter');
        doc.page = 1; // use this as a counter.

        console.log(doc.getFontList())
        doc.html(document.querySelector("#pdfcomandarestaurante"), {
            callback: function (pdf) {
                props.setmostrarimagenes(true)
                doc.autoPrint();
                doc.output('dataurlnewwindow', { filename: 'comanda.pdf' });
            }
        })
    }
    const print = () => {
        previsualizarpdf()
    }
    const totalunidades = () => {
        var tot = 0
        for (let i = 0; i < props.Movimiento.famovimiento.length; i++) {
            const element = props.Movimiento.famovimiento[i];
            tot = tot + element.cantidad
        }
        return tot
    }
    return (


        <Modal size="lg" isOpen={props.previsualizar} onOpened={() => { previsualizarpdf() }}>
            <ModalHeader>
                Opciones
            </ModalHeader>
            <ModalBody id={"pdfcomandarestaurante"}>
                {props.Movimiento && <Row >
                    <Col sm={3}>
                        <Row>
                            <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                {/* {JSON.parse(props.user).empresa.empresa.nombre}<br />
                                {"Nit: " + JSON.parse(props.user).empresa.empresa.nit}<br />
                                {"Comanda No: " + props.Movimiento.ndocumento}<br />
                                {"Fecha: " + props.Movimiento.fecha}<br />
                                {"Cliente: " + props.Movimiento.nombrecli}<br />
                                {"Direccion: " + props.Movimiento.direccion}<br />
                                {"Nit: " + props.Movimiento.cliente}<br /> */}
                                {"Cajero: " + props.dataUser.usuario.nombre}<br />
                                {"Domiciliario: " + props.Movimiento.nombreven}<br />
                                {"Mesa: " + props.Movimiento.estacion}<br />
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Table size="sm">
                                    <thead>
                                        <tr style={{ fontSize: '10px', color: 'black' }}>
                                            <th >Cant</th>
                                            <th >Descr</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (props.Movimiento.famovimiento.length < 1) ? (
                                                <tr>
                                                    <td colSpan="15">Sin Datos</td>
                                                </tr>
                                            ) :
                                                (
                                                    props.Movimiento.famovimiento.map((item, index) => (
                                                        <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                            <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                            <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip + " - " + item.desadicional}</td>



                                                        </tr>
                                                    ))
                                                )


                                        }
                                    </tbody>
                                </Table>

                            </Col>

                        </Row>
                        <Row>
                            <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                {"Items:" + props.Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                {"Unidades:" + totalunidades()}

                            </Col>

                        </Row>

                        <Row>
                            <Col sm={12} style={{ textAlign: 'center', fontSize: '7px', color: 'black' }}>
                                {"Gracias Por Su Compra"}<br />
                                {"Generado por Solifactin S.A.S"}
                              
                                {"Este no es numero para pedir servicio al restaurante"}<br />


                            </Col>

                        </Row>
                    </Col>

                </Row>}
            </ModalBody>
            <ModalFooter>



                <Button size="sm" color="primary" onClick={() => {

                    props.setprevisualizar(false)
                    print()
                }}>PDF</Button>
                <Button size="sm" color="primary" onClick={() => {
                    props.setprevisualizar(false)
                    props.setmostrarimagenes(true)
                }}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}
export default Bcbanco;

