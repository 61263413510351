import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import filtro from "../../../componentes/filtro";
import Movimientos from '../../Venta'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const data_inicio_donut = {
    labels: ['Sin resultados'],
    datasets: [
        {
            data: [0],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)'
            ],
            borderWidth: 1,
        },
    ],
};


const data_inicio_bar = {
    labels: ['Sin resultados'],
    datasets: [
        {
            label: 'Cantidad',
            data: [0],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [dataDonut, setDataDonut] = useState(data_inicio_donut)
    const [dataBar, setDataBar] = useState(data_inicio_bar)

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [movimientoenvio, setmovimientoenvio] = useState(false);
    const [cajaenvio, setcajaenvio] = useState(false);
    const [editarorden, seteditarorden] = useState(false);
    const [config, setConfig] = useState([])
    const [tipodoctipodoc, settipodoctipodoc] = useState("factura");
    const [acumulados, setacumulados] = useState("-");
    useEffect(() => {
        buscar()
    }, [])
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/MovimientoApi/MovimientosPorSucursal/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado+"/"+tipodoctipodoc+"/"+acumulados + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => { 
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setVentas(data);

                let d = dataJson;
                var datosamostrar = []

                d.forEach(dato => {
                    var mess = dato.sucursal
                    if (datosamostrar.length == 0) {
                        var datt = {
                            mes: mess,
                            total: dato.total
                        }
                        datosamostrar.push(datt)
                    } else {
                        const existingItemIndex = datosamostrar.findIndex(item2 => item2.mes === mess);

                        if (existingItemIndex !== -1) {
                            // El mes ya existe en el array2, sumar el valor.
                            datosamostrar[existingItemIndex].total += dato.total;
                        } else {
                            // El mes no existe en el array2, agregarlo.
                            datosamostrar.push({ mes: mess, total: dato.total });
                        }
                    }
                });

                let lblsBar = datosamostrar.map((item) => { return item.mes })
                let dtaBartotal = datosamostrar.map((item) => { return item.total })

                let lblsDonut = datosamostrar.map((item) => { return item.mes })
                let dtaDonuttotal = datosamostrar.map((item) => { return item.total })

                let modeloBar = {
                    labels: lblsBar,
                    datasets: [{
                        label: 'Total',
                        data: dtaBartotal,
                        backgroundColor: '#298BFA',
                    }
                    ]
                };

                let modeloDonut = {
                    labels: lblsDonut,
                    datasets: [{
                        data: dtaBartotal,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)'
                        ],
                        borderWidth: 1
                    }
                    ],
                }

                if (d.length < 1)
                    setDataBar(data_inicio_bar)
                else
                    setDataBar(modeloBar)

                if (d.length < 1)
                    setDataDonut(data_inicio_donut)
                else
                    setDataDonut(modeloDonut)


            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const optionsBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };

    const CustomOrden = ({ row }) => (
        <div>
            { }
            <div>
                <div style={{ color: 'blue' }} onClick={() => {
                    try {

                        let dt = JSON.parse(user)
                        var url = props.servidor + '/Movimientoapi/Buscarbuscarunico/' + row.numero + '?llave=' + dt.usuario.llaveempresa + ''
                        const api = fetch(props.servidor + '/Movimientoapi/buscarunico/' + row.concepto + '/' + row.ndocumento + '?llave=' + dt.usuario.llaveempresa + '')
                            .then((response) => {
                                return response.ok ? response.json() : Promise.reject(response);
                            })
                            .then((dataJson) => {
                                setmovimientoenvio(dataJson)
                                seteditarorden(true)

                            }).catch((error) => {
                                Swal.fire(
                                    'Opps!',
                                    'No se encontro el movimiento',
                                    'error'
                                )
                            })
                    } catch (error) {
                        console.log(error)
                    }
                }}
                >
                    {row.ndocumento}
                </div>
            </div>
        </div>
    );
    const columns = [
        {
            name: 'Concepto',
            selector: row => row.concepto,
            sortable: true,
        },
        {
            name: 'Documento',
            selector: row => row.ndocumento,
            sortable: true,
            cell: row => <CustomOrden row={row} />,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Vendedor',
            selector: row => row.vendedor,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Cliente',
            selector: row => row.cliente,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Nombre Cliente',
            selector: row => row.nombrecli,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Total',
            selector: row => fm3.from(Number(row.total + "")),
            sortable: true,
            grow: 4,
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label >Ti. Documento</Label>
                        <Input type="select" onChange={(e) => {
                            settipodoctipodoc(e.target.value)
                        }} value={tipodoctipodoc}  >
                            <option value="-"></option>
                            <option value="factura">Factura</option>
                            <option value="compra">Compra</option>
                            <option value="recibo de caja">Recibo de Caja</option>
                            <option value="otros">Otros</option>
                            <option value="remision">Remision</option>
                            <option value="translado">Translado</option>
                            <option value="cotizacion">Cotizacion</option>
                            <option value="bancos">Bancos</option>
                            <option value="causaciones">Causaciones</option>
                            <option value="devoluciones">Devoluciones</option>
                            <option value="contabilidad">Contabilidad</option>
                            <option value="transladodebancos">Translado de Bancos</option>
                            <option value="produccion">Produccion</option>
                            <option value="pedidos">Pedidos</option>
                            <option value="ordendecompra">Orden de Compra</option>
                            <option value="bonos">Bonos</option>
                            <option value="activosfijos">Activos Fijos</option>
                            <option value="anticipoaproveedores">Anticipo a Proveedores</option>
                            <option value="radian">Radian</option>
                            <option value="plan separe">Plan Separe</option>
                            <option value="nota debito">Nota Debito</option>
                        </Input>
                    </FormGroup>
                </Col>


                <Col sm={2}>
                    <FormGroup>
                        <Label >Act. Acumulados</Label>
                        <Input type="select" onChange={(e) => {
                            setacumulados(e.target.value)
                        }}  name="actuacu" value={acumulados}  >
                            <option value="-"></option>
                            <option value="ventas">Ventas</option>
                            <option value="compras">Compras</option>
                            <option value="entradasespeciales">Entradas Especiales</option>
                            <option value="salidasespeciales">Salidas Especiales</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={1}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={1}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={1}>
                    <FormGroup>
                        <Label style={{ fontSize: '9px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exp
                        </Button>
                    </FormGroup>
                </Col>

            </Row>
           
            <Row>
                <Col sm={12}>
                <div className=" mt-5">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between " style={{ background: '#188BAD' }} >
                                <h6 className="m-0 font-weight-bold text-white">Ventas Por Mes</h6>

                            </div>
                            <div className="card-body">
                                <div style={{ height: 350 }}>
                                    <Bar options={optionsBar} data={dataBar} />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-5">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between " style={{ background: '#188BAD' }}>
                                <h6 className="m-0 font-weight-bold text-white">Productos más vendidos</h6>

                            </div>
                            <div className="card-body">
                                <div style={{ height: 350 }}>
                                    <Doughnut options={optionsBar} data={dataDonut} />
                                </div>

                            </div>
                        </div>
                    </div> */}


                </div>
                </Col>
               
                <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={editarorden}>

                    <ModalBody >

                        <Movimientos caja={props.caja} permisosusuario={props.permisosusuario} movimiento={movimientoenvio} cerrar={() => {
                            seteditarorden(false)
                            buscar()
                        }} editarorden={editarorden} servidor={props.servidor} />

                    </ModalBody>
                    <ModalFooter>

                        <Button size="lg" color="primary" onClick={() => {
                            seteditarorden(false)
                            buscar()
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}

                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;