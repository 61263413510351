import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloMunicipio = {
    codigomun: "",
    nombremun: "",
    coddepar: "",
    codpais: "",
}
const Municipio = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Municipio, setMunicipio] = useState(modeloMunicipio);
    const [Municipios, setMunicipios] = useState([]);
    const [codigomun, setcodigomun] = useState([]);
    const [codigomuns, setcodigomuns] = useState([]);
    const [nombremun, setnombremun] = useState([]);
    const [nombremuns, setnombremuns] = useState([]);
    const [coddepar, setcoddepar] = useState([]);
    const [coddepars, setcoddepars] = useState([]);
    const [busquedacoddepars, setbusquedacoddepars] = useState([]);
    const [verModalcoddepar, setVerModalcoddepar] = useState(false);
    const [codpais, setcodpais] = useState([]);
    const [codpaiss, setcodpaiss] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalMunicipio, setVerModalMunicipio] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Municipio = { ...Municipio }
        _Municipio[e.target.name] = value
        setMunicipio(_Municipio)
        setRoles([])


    }
    const seleccionarcoddepar = (e) => {
        let value = e
        var _pais = coddepars.filter((dato) => {
            if (dato.codigodep.toUpperCase()==value.toUpperCase()) { return dato }

        })
        var _Municipio = { ...Municipio }
        _Municipio['coddepar'] = value
        _Municipio['codpais'] = _pais[0].paisdepar
        setMunicipio(_Municipio)
        setRoles([])
        cerrarModalSeleccioncoddepars()
    }
    const buscartodascoddepars = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Departapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setcoddepars(dataJson)
                    setbusquedacoddepars(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setcoddepars([])
                    setbusquedacoddepars([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerMunicipios = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Municipioapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setMunicipios(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const borrartodaslascoddepars = () => {
        setbusquedacoddepars([])
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerMunicipios('');
        buscartodascoddepars('');
    }, [])
    const filtrarcoddepars = async (coincidencia) => {
        var _coddepars = coddepars.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedacoddepars(_coddepars)
    }
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigomun,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombremun,
            sortable: true,
        },
        {
            name: 'Departamento',
            selector: row => row.coddepar,
            sortable: true,
        },
        {
            name: 'Pais',
            selector: row => row.codpais,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalMunicipio(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalMunicipio = (data) => {
        var enviodata = { ...data }
        var _Municipio = {
            codigomun: enviodata.codigomun,
            nombremun: enviodata.nombremun,
            coddepar: enviodata.coddepar,
            codpais: enviodata.codpais,
        }
        setMunicipio(_Municipio);
        setVerModalMunicipio(!verModalMunicipio);
        seteditar(true)
    }
    const cerrarModalMunicipio = () => {
        setMunicipio(modeloMunicipio)
        setVerModalMunicipio(!verModalMunicipio);
        seteditar(false)
        setKey('Datos')
    }
    const cerrarModalSeleccioncoddepars = () => {
        setVerModalcoddepar(!verModalcoddepar);
        setbusquedacoddepars(coddepars)
    }
    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/Municipioapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Municipio)
            })

        } else {
            response = await fetch("" + props.servidor + "/Municipioapi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Municipio)
            })
        }

        if (response.ok) {
            await obtenerMunicipios('');
            setMunicipio(modeloMunicipio)
            setVerModalMunicipio(!verModalMunicipio);
            setKey('Datos')
            Swal.fire(
                'Guardado Exitosamente!',
                'El Municipio fue guardado exitosamente',
                'success'
            )
        } else {
            var textoerrores = await response.text()
            var jsonerrores = JSON.parse(textoerrores)
            var errores = jsonerrores.errors
            let claves = Object.keys(errores);
            var stringerrores=""
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                    if (errores[clave].length!=0) {
                        stringerrores=stringerrores+errores[clave][0]+" \n "
                    }
                
            }
            var mensaje=stringerrores.replace(/The /g,'').replace(/field /g,'').replace(/is /g,'es ').replace(/required/g,'requerido')
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje='Hay campos numericos rellenados con letras'
            }
            console.log(stringerrores)
            Swal.fire(
                'Error al Guardar!',
                 mensaje,
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Municipio
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataMunicipios" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerMunicipios(document.getElementById('buscardataMunicipios').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalMunicipio(!verModalMunicipio)
                                seteditar(false)
                                buscartodascoddepars('')
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearmunicipio(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Municipios}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalMunicipio}>
                <ModalHeader>
                    Detalle Municipios
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Codigo</Label>
                                        <Input  disabled={editar}  type="text" bsSize="sm" onChange={handleChange} name="codigomun" value={Municipio.codigomun} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombremun" value={Municipio.nombremun} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup inline={true}>
                                        <Label>Departamento</Label>
                                        <InputGroup>
                                            <Input  disabled={editar}  bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedacoddepars(coddepars)
                                                    setVerModalcoddepar(true)

                                                }
                                            }} placeholder="coddepar" name="coddepar" value={Municipio.coddepar} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedacoddepars(coddepars)
                                                    setVerModalcoddepar(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Pais</Label>
                                        <Input  disabled={editar}  type="text" bsSize="sm" onChange={handleChange} name="codpais" value={Municipio.codpais} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                    <Buscar filtrar={(coincidencia) => { filtrarcoddepars(coincidencia) }} busqueda={busquedacoddepars} encabezado={["Codigo", "Nombre","Pais"]} codigo={"codigodep"} nombre={"nombredep"} dato={"paisdepar"} dato2={""} verModal={verModalcoddepar} cerrarModal={() => { cerrarModalSeleccioncoddepars() }} seleccionar={(seleccionado) => {
                        seleccionarcoddepar(seleccionado.codigodep)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalMunicipio}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Municipio;
