import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import * as XLSX from "xlsx"

const modeloCtcomprobantes = {
    codigo: "",
    nombre: "",
}
const Ctcomprobantes = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Ctcomprobantes, setCtcomprobantes] = useState(modeloCtcomprobantes);
    const [Ctcomprobantess, setCtcomprobantess] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalCtcomprobantes, setVerModalCtcomprobantes] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Ctcomprobantes = { ...Ctcomprobantes }
        _Ctcomprobantes[e.target.name] = value
        setCtcomprobantes(_Ctcomprobantes)
        setRoles([])


    }
    const obtenerCtcomprobantess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcomprobantesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcomprobantess(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerCtcomprobantess('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalCtcomprobantes(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalCtcomprobantes = (data) => {
        var enviodata = { ...data }
        var _Ctcomprobantes = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        }
        setCtcomprobantes(_Ctcomprobantes);
        setVerModalCtcomprobantes(!verModalCtcomprobantes);
        seteditar(true)
    }
    const cerrarModalCtcomprobantes = () => {
        setCtcomprobantes(modeloCtcomprobantes)
        setVerModalCtcomprobantes(!verModalCtcomprobantes);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Ctcomprobantes) => {

        let claves = Object.keys(_Ctcomprobantes);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Ctcomprobantes[clave] = Number(_Ctcomprobantes[clave])
            }
        }
        return _Ctcomprobantes
    }
    const verificar = (_Ctcomprobantes) => {

        let claves = Object.keys(_Ctcomprobantes);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' || clave == 'nombre') {
                if (_Ctcomprobantes[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(Ctcomprobantess);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte.xlsx")
    }
    const guardarCambios = async () => {
        let response;
        console.log(Ctcomprobantes)
        var _Ctcomprobantes = { ...Ctcomprobantes }
        _Ctcomprobantes = asignarceros(_Ctcomprobantes)
        var _verificado = verificar(_Ctcomprobantes)
        if (_verificado) {
            console.log(_Ctcomprobantes)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Ctcomprobantesapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Ctcomprobantes)
                })

            } else {
                response = await fetch("" + props.servidor + "/Ctcomprobantesapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Ctcomprobantes)
                })
            }

            if (response.ok) {
                await obtenerCtcomprobantess('');
                setCtcomprobantes(modeloCtcomprobantes)
                setVerModalCtcomprobantes(!verModalCtcomprobantes);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Ctcomprobantes fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Ctcomprobantes',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Ctcomprobantess
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataCtcomprobantess" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerCtcomprobantess(document.getElementById('buscardataCtcomprobantess').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalCtcomprobantes(!verModalCtcomprobantes)
                                seteditar(false)
                            }}>Nuevo</Button>
                            <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcel}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Ctcomprobantess}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalCtcomprobantes}>
                <ModalHeader>
                    Detalle Ctcomprobantess
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Ctcomprobantes.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Ctcomprobantes.nombre} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalCtcomprobantes}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Ctcomprobantes;
