import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Contables from './contables/inicio'
import Administrativos from './administrativos/inicio'
import Tributarios from './Tributarios/inicio'
import Control from './Control/inicio'
import Financieros from './Financieros/inicio'
const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [key, setKey] = React.useState('home');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [reporteExistenciaArticulos, setreporteExistenciaArticulos] = React.useState(false);
    const [reporteCatalogoArticulos, setreporteCatalogoArticulos] = React.useState(false);
    const [reporteListadePrecio, setreporteListadePrecio] = React.useState(false);
    const [reporteCostoMercanciaVendida, setreporteCostoMercanciaVendida] = React.useState(false);
    const [reporteLibroAuxiliar, setreporteLibroAuxiliar] = React.useState(false);

    // const [reporteVenta, setreporteVenta] = React.useState(false);

    if (user == null) {
        return <Navigate to="/Login" />
    }



    return (
        <>
            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >
                            <Tab eventKey="Basicos" title="Basicos">
                                {/* <ReporteExistenciaArticulos servidor={props.servidor} setreporteExistenciaArticulos={(reporteExistenciaArticulos) => {
                                    setreporteExistenciaArticulos(reporteExistenciaArticulos)
                                }} /> */}
                            </Tab>
                            <Tab eventKey="Control" title="Control">
                                <Control servidor={props.servidor} />
                            </Tab>
                            <Tab eventKey="Contables" title="Contables">
                                <Contables servidor={props.servidor} />
                            </Tab>
                            <Tab eventKey="Administrativos" title="Administrativos">
                                <Administrativos servidor={props.servidor} />
                            </Tab>
                            <Tab eventKey="Tributarios" title="Tributarios">
                                <Tributarios servidor={props.servidor} />

                            </Tab>
                            <Tab eventKey="Financieros" title="Financieros">
                                <Financieros servidor={props.servidor} />
                            </Tab>
                        </Tabs>

                        {/* <Outlet /> */}

                    </div>
                </div>

            </div>


        </>
    )
}

export default Reportes