import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../../context/UserProvider';
import Filtro from '../../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import filtro from "../../../../componentes/filtro";
import Venta from "../../../Venta";
const tabespacio = '\u00A0';

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [centrodecosto, setcentrodecosto] = useState(false);
    const [cuenta, setcuenta] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventascentrodecostos, setventascentrodecostos] = useState(modeloInicio)
    const [ventascuenta, setventascuenta] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [nivel, setnivel] = useState("-");
    const [cuentainicioseleccionado, setcuentainicioseleccionado] = useState("-");
    const [centrodecostoinicioseleccionado, setcentrodecostoinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [cuentafinseleccionado, setcuentafinseleccionado] = useState("-");
    const [centrodecostofinseleccionado, setcentrodecostofinseleccionado] = useState("-");
    const [tipoinformepyg, settipoinformepyg] = useState("-");
    const [tipoinf, settipoinf] = useState("resumido");
    const [comparativo, setcomparativo] = useState("no");
    const [periodicoacumulado, setperiodicoacumulado] = useState("periodico");
    const [incluyecuentas, setincluyecuentas] = useState("no");
    const [formatopyg, setformatopyg] = useState("formato 1");
    const [dataUser, setDataUser] = useState(modelo)
    const [previsualizar, setprevisualizar] = useState(false)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var nivv = '-'
        if (nivel != '') {
            nivv = nivel
        }

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/estadoderesultados/" + tipoinformepyg + "/" + fechaInicio + "/" + fechaFin + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + tipoinf + "/" + comparativo + "/" + periodicoacumulado + "/" + incluyecuentas + "?llave=" + dt.usuario.llaveempresa + "")
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
                console.log(data)
                setprevisualizar(true)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const columns = [
        {
            name: 'Cuenta',
            selector: row => row.cuenta,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: 'Saldo Anterior',
            selector: row => fm.from(Number(row.saldoanterior)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Debito',
            selector: row => fm.from(Number(row.debito)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Credito',
            selector: row => fm.from(Number(row.credito)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Saldo Actual',
            selector: row => fm.from(Number(row.saldoactual)),
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Centro Costo',
            selector: row => row.codcentrocostos,
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Acreedor',
            selector: row => row.acreedor,
            sortable: true,
            grow: 2,
            width: '200px',
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.total
        }
        return tot
    }
    const totaltotalfila = (dett, nombredetalledetalle) => {
        var tot = 0
        for (let i = 0; i < dett.length; i++) {
            const element = dett[i];
            if (nombredetalledetalle == element.nombredetalledetalle) {
                tot = tot + element.valor

            }
        }
        return tot
    }
    
    const totaltotaldelejercicio = () => {
        var tot = 0
       try {
        for (let i = 0; i < ventas.detalle.length; i++) {
            const element = ventas.detalle[i];
            element.detalledetalle.forEach(element2 => {
                if (element2.columnastotales) {
                    tot = tot + element2.valor
    
                }
            });
           
        }
       } catch (error) {
        
       }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label>Tipo</Label>
                        <Input type="select" bsSize="sm" onChange={(e) => { settipoinf(e.target.value) }} value={tipoinf}>
                            <option value="resumido">Resumido</option>
                            <option value="detallado">Detallado</option>
                        </Input>
                    </FormGroup>
                </Col>
                {
                    tipoinf == "detallado" ?
                        <Col sm={2}>
                            <FormGroup>
                                <Label>Incluye Cuentas</Label>
                                <Input type="select" bsSize="sm" onChange={(e) => { setincluyecuentas(e.target.value) }} value={incluyecuentas}>
                                    <option value="no">No</option>
                                    <option value="si">Si</option>
                                </Input>
                            </FormGroup>
                        </Col> :
                        false
                }
                <Col sm={2}>
                    <FormGroup>
                        <Label>Comparativo</Label>
                        <Input type="select" bsSize="sm" onChange={(e) => { setcomparativo(e.target.value) }} value={comparativo}>
                            <option value="no">No</option>
                            <option value="si">Si</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label>Periodico/Acumulado</Label>
                        <Input type="select" bsSize="sm" onChange={(e) => { setperiodicoacumulado(e.target.value) }} value={periodicoacumulado}>
                            <option value="periodico">Periodico</option>
                            <option value="acumulado">Acumulado</option>
                        </Input>
                    </FormGroup>
                </Col>


                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>

            </Row>
            {formatopyg == "formato 1" &&

                <Modal size="lg" style={{ maxWidth: '100%', width: '95%' }} isOpen={previsualizar}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '15px', fontWeight: 'normal', color: 'black' }}>
                                        {ventas.encabezado && ventas.encabezado.titulo}

                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12} style={{ marginTop: '100px' }}>
                                        {ventas.encabezado &&

                                            ventas.detalle.map((dat, index) => {
                                                return <>
                                                    <div>{dat.detalle.descripcionfila}</div>
                                                    {
                                                        dat.detalledetalle.map((dat2, index) => {
                                                            return <>
                                                                {
                                                                    dat2.cuenta &&
                                                                    <Row>
                                                                        <Col sm={1}>
                                                                            <div></div>

                                                                        </Col>
                                                                        <Col sm={2}>
                                                                            <div>{dat2.cuenta}</div>

                                                                        </Col>
                                                                        <Col sm={4}>
                                                                            <div>{dat2.cuentanombre}</div>

                                                                        </Col>
                                                                        <Col sm={4}>
                                                                            <div style={{ color: dat2.valor < 0 ? '#E30D0D' : '#188BAD' }}>{fm2.from(dat2.valor)}</div>

                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                {
                                                                    dat.detalledetalle[index + 1] ?
                                                                        (dat.detalledetalle[index + 1].nombredetalledetalle != dat2.nombredetalledetalle) &&
                                                                            dat2.cuenta ?
                                                                            <Row>
                                                                                <Col sm={1}>
                                                                                    <div></div>

                                                                                </Col>
                                                                                <Col sm={2}>
                                                                                    <div>{dat2.nombredetalledetalle}</div>

                                                                                </Col>
                                                                                <Col sm={4}>
                                                                                    <div></div>

                                                                                </Col>
                                                                                <Col sm={4}>
                                                                                    <div style={{ color: totaltotalfila(dat.detalledetalle, dat2.nombredetalledetalle) < 0 ? '#E30D0D' : '#188BAD' }}>{fm2.from(totaltotalfila(dat.detalledetalle, dat2.nombredetalledetalle))}</div>

                                                                                </Col>
                                                                            </Row> :
                                                                            <Row>
                                                                                <Col sm={2}>
                                                                                    <div>{dat2.nombredetalletotales}</div>


                                                                                </Col>
                                                                                <Col sm={2}>

                                                                                </Col>
                                                                                <Col sm={4}>
                                                                                    <div></div>

                                                                                </Col>
                                                                                <Col sm={4}>
                                                                                    <div style={{ color: dat2.valor < 0 ? '#E30D0D' : '#188BAD' }}>{fm2.from(dat2.valor)}</div>

                                                                                </Col>
                                                                            </Row>
                                                                        :
                                                                        dat2.cuenta ?
                                                                            dat2.nombredetalledetalle != '' ?
                                                                                <Row>
                                                                                    <Col sm={1}>
                                                                                        <div></div>

                                                                                    </Col>
                                                                                    <Col sm={2}>
                                                                                        <div>{dat2.nombredetalledetalle}</div>

                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <div></div>

                                                                                    </Col>
                                                                                    <Col sm={4}>
                                                                                        <div style={{ color: totaltotalfila(dat.detalledetalle, dat2.nombredetalledetalle) < 0 ? '#E30D0D' : '#188BAD' }}>{fm2.from(totaltotalfila(dat.detalledetalle, dat2.nombredetalledetalle))}</div>

                                                                                    </Col>


                                                                                </Row> : false :
                                                                            <Row>
                                                                                <Col sm={2}>
                                                                                    <div>{dat2.nombredetalletotales}</div>


                                                                                </Col>
                                                                                <Col sm={1}>

                                                                                </Col>
                                                                                <Col sm={5}>
                                                                                    <div></div>

                                                                                </Col>
                                                                                <Col sm={3}>
                                                                                    <div style={{ color: dat2.valor < 0 ? '#E30D0D' : '#188BAD' }}>{fm2.from(dat2.valor)}</div>

                                                                                </Col>
                                                                            </Row>

                                                                }
                                                            </>
                                                        })
                                                    }

                                                </>
                                            })


                                        }
                                        {/* {
                                            <Row>
                                                <Col sm={2}>
                                                    <div>RESULTADO DEL EJERCICIO</div>


                                                </Col>
                                                <Col sm={1}>

                                                </Col>
                                                <Col sm={5}>
                                                    <div></div>

                                                </Col>
                                                <Col sm={3}>
                                                    <div style={{ color: totaltotaldelejercicio() < 0 ? '#E30D0D' : '#188BAD' }}>{fm2.from(totaltotaldelejercicio())}</div>

                                                </Col>
                                            </Row>
                                        } */}
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>

                                        </Table>

                                    </Col>

                                </Row>


                                <Row>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            // setprevisualizarenviocorreo(true)
                            // setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            // previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            <Filtro
                setcentrodecostoinicioseleccionado={(e) => { setcentrodecostoinicioseleccionado(e) }}
                setcentrodecostofinseleccionado={(e) => { setcentrodecostofinseleccionado(e) }}
                setcuentainicioseleccionado={(e) => { setcuentainicioseleccionado(e) }}
                setcuentafinseleccionado={(e) => { setcuentafinseleccionado(e) }}
                setnivel={(e) => {
                    setnivel(e)
                }
                }
                tipoinformepyg={tipoinformepyg}
                settipoinformepyg={(e) => { settipoinformepyg(e) }}
                centrodecostoinicioseleccionado={centrodecostoinicioseleccionado}
                centrodecostofinseleccionado={centrodecostofinseleccionado}
                cuentainicioseleccionado={cuentainicioseleccionado}
                cuentafinseleccionado={cuentafinseleccionado}
                nivel={nivel}
                servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
        </>
    )
}

export default ReporteVenta;