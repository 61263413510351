import React, { useState } from "react"
import { createContext } from "react"


export const PermisoContext = createContext()

const PermisosProvider = ({ children }) => {
    const [permisos, setpermisos] = useState(window.localStorage.getItem("permisosusuario"))


    const obtenerPermisos = (data) => {
        window.localStorage.setItem("permisosusuario", JSON.stringify(data))
        setpermisos(JSON.stringify(data))
    }

    const borrarPermisos = () => {
        window.localStorage.removeItem("permisosusuario")
        setpermisos(null)
    }

    return (
        <PermisoContext.Provider value={{ permisos, obtenerPermisos, borrarPermisos }}>
            {children}
        </PermisoContext.Provider>
    )
}

export default PermisosProvider