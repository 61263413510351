import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'


const Buscar = (props) => {


    return (
        <>

            <Modal size={(props.tamaño == "sm") ? "sm" : ""} isOpen={props.verModal}>
                <ModalHeader style={{ backgroundColor: '#188BAD', color: 'white' }} >
                </ModalHeader>
                <ModalBody>
                    ESPERANDO...
                </ModalBody>
            </Modal>
        </>



    )
}

export default Buscar;