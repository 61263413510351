import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useState, useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { UserContext } from '../context/UserProvider';
import { FormatMoney } from 'format-money-js'
import DraggableComponent from './dragable';
import MovimientosPorSucursal from './ReportesGrafico/Movimiento/MovimientosPorSucursal';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const data_inicio_donut = {
    labels: ['Sin resultados'],
    datasets: [
        {
            data: [0],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)'
            ],
            borderWidth: 1,
        },
    ],
};


const data_inicio_bar = {
    labels: ['Sin resultados'],
    datasets: [
        {
            label: 'Cantidad',
            data: [0],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

const DashBoard = (props) => {

    const [config, setConfig] = useState({})
    const [dataDonut, setDataDonut] = useState(data_inicio_donut)
    const [dataBar, setDataBar] = useState(data_inicio_bar)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));

    const optionsBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    const obtenerConfiguracion = () => {
        let dt = JSON.parse(user)
        const api = fetch(props.servidor + '/Inarticulosapi/reportecostomercanciavendida/?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                let d = dataJson;

                let lblsBar = d.map((item) => { return item.codigo })
                let dtaBar = d.map((item) => { return item.ulticosto })
                let dtaBar2 = d.map((item) => { return item.ultiventa })
                let dtaBar3 = d.map((item) => { return item.utilidad })
                let dtaBar4 = d.map((item) => { return item.valorventa })
                let dtaBar5 = d.map((item) => { return item.cantidad })
                let dtaBar6 = d.map((item) => { return item.costo })
                // let lblsBar = d.ventasporDias.map((item) => { return item.fecha })
                // let dtaBar = d.ventasporDias.map((item) => { return item.total } )
                let lblsDonut = d.map((item) => { return item.referencia })
                let dtaDonut = d.map((item) => { return item.existenciablister })
                // let lblsDonut = d.productosVendidos.map((item) => { return item.producto })
                // let dtaDonut = d.productosVendidos.map((item) => { return item.total })
                let modeloBar = {
                    labels: lblsBar,
                    datasets: [
                        {
                            label: 'Cantidades',
                            data: dtaBar5,
                            backgroundColor: '#FF5738',
                        },
                        {
                            label: 'Costo de Mercancia',
                            data: dtaBar6,
                            backgroundColor: '#FF5733',
                        }, {
                            label: 'Valor Venta',
                            data: dtaBar4,
                            backgroundColor: '#C069F8',
                        }, {
                            label: 'Utilidad',
                            data: dtaBar3,
                            backgroundColor: '#65F010',
                        },
                        {
                            label: 'Ultima Venta',
                            data: dtaBar2,
                            backgroundColor: '#69E7F8',
                        },
                    ]
                };

                let modeloDonut = {
                    labels: lblsDonut,
                    datasets: [
                        {
                            data: dtaDonut,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)'
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)'
                            ],
                            borderWidth: 1
                        },
                    ],
                }

                if (d.length < 1)
                    setDataBar(data_inicio_bar)
                else
                    setDataBar(modeloBar)

                if (d.length < 1)
                    setDataDonut(data_inicio_donut)
                else
                    setDataDonut(modeloDonut)

                setConfig(d)
            }).catch((error) => {
                console.log("error")
            })

    }
    const totalcajas = () => {
        var tot = 0
        for (let i = 0; i < config.length; i++) {
            const element = config[i];
            tot = tot + element.cantidad
        }
        return tot
    }
    const totalblister = () => {
        var tot = 0
        for (let i = 0; i < config.length; i++) {
            const element = config[i];
            tot = tot + element.costo
        }
        return tot
    }
    const totalunidades = () => {
        var tot = 0
        for (let i = 0; i < config.length; i++) {
            const element = config[i];
            tot = tot + element.valorventa
        }
        return tot
    }
    const totalutilidad = () => {
        var tot = 0
        for (let i = 0; i < config.length; i++) {
            const element = config[i];
            tot = tot + element.utilidad
        }
        return tot
    }
    useEffect(() => {
        // obtenerConfiguracion()
    }, [])

    return (
        <>
            <Row>
                <Col sm={12}>
                    <MovimientosPorSucursal caja={props.caja} permisosusuario={props.permisosusuario} servidor={props.servidor} setmovimientoporsucursal={(reporteInventario) => {
                        // setmovimientoporsucursal(reporteInventario)
                    }} />
                </Col>
            </Row>
        </>
    )
}

export default DashBoard;