import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import filtro from "../../../componentes/filtro";
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");


    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [referenciafinseleccionado, setreferenciafinseleccionado] = useState("-");
    const [referenciainicioseleccionado, setreferenciainicioseleccionado] = useState("-");
    const [clasificacionarticulo1, setclasificacionarticulo1] = useState("-");

    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = '' 
        const api = fetch(props.servidor + "/CtabonoApi/MovimientoDetalleCarteracomision/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + "/" + clasificacionarticulo1 + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
                // var conceptos = data.reduce((array, venta) => {
                //     if (!array[venta.concepto]) {
                //         array[venta.concepto] = 0;
                //     }
                //     array[venta.concepto] += venta.total;
                //     return array;
                // }, {});

                // const conceptosarray = Object.entries(conceptos).map(([concepto, total]) => ({
                //     concepto,
                //     total,
                // }));
                // var poconcepto = []
                // var portercero = []
                // var porvendedor = []
                // for (let i = 0; i < data.length; i++) {
                //     const element = data[i];
                //     var nohay = true
                //     for (let j = 0; j < poconcepto.length; j++) {
                //         const element2 = poconcepto[j];
                //         if (element2.tipo == element.tipo && element.item == 1) {
                //             element2.total = element2.total + element.total
                //             element2.abonos = element2.abonos + element.abonos
                //             element2.saldo = element2.saldo + element.saldo
                //             j = poconcepto.length + 123
                //             nohay = false
                //         } else {
                //             if (element.item != 1) {
                //                 nohay = false

                //             }
                //         }
                //     }
                //     if (nohay) {
                //         var concepto = {
                //             tipo: element.tipo,
                //             total: element.total,
                //             abonos: element.abonos,
                //             saldo: element.saldo,
                //         }
                //         poconcepto.push(concepto)
                //     }
                //     nohay = true
                //     for (let j = 0; j < portercero.length; j++) {
                //         const element2 = portercero[j];
                //         if (element2.nomcliente == element.nomcliente && element.item == 1) {
                //             element2.total = element2.total + element.total
                //             element2.abonos = element2.abonos + element.abonos
                //             element2.saldo = element2.saldo + element.saldo
                //             j = portercero.length + 123
                //             nohay = false
                //         } else {
                //             if (element.item != 1) {
                //                 nohay = false

                //             }
                //         }
                //     }
                //     if (nohay) {
                //         var concepto = {
                //             nomcliente: element.nomcliente,
                //             total: element.total,
                //             abonos: element.abonos,
                //             saldo: element.saldo,
                //         }
                //         portercero.push(concepto)
                //     }
                //     nohay = true
                //     for (let j = 0; j < porvendedor.length; j++) {
                //         const element2 = porvendedor[j];
                //         if (element2.vendedor == element.vendedor && element.item == 1) {
                //             element2.total = element2.total + element.total
                //             element2.comision = element2.total * (element2.porcentaje / 100)
                //             j = porvendedor.length + 123
                //             nohay = false
                //         } else {
                //             if (element.item != 1) {
                //                 nohay = false

                //             }
                //         }
                //     }
                //     if (nohay) {
                //         var concepto = {
                //             vendedor: element.vendedor,
                //             total: element.total,
                //             porcentaje: element.porcentajevendedor,
                //             comision: element.total * (element.porcentajevendedor / 100),
                //         }
                //         porvendedor.push(concepto)
                //     }
                // }
                // setventasconceptos(poconcepto)
                // setventasterceros(portercero)
                // setventasvendedor(porvendedor)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const CustomDescripcion = ({ row }) => (
        <div style={{ width: '700px' }}>
            { }
            {row.descripcion}
        </div>
    );
    const CustomTipo = ({ row }) => (
        <div>
            { }
            <div>
                <div

                >
                    { }
                    {row.tipo}
                </div>
            </div>
        </div>
    );
    const CustomCliente = ({ row }) => (
        <div>
            { }
            <div>
                <div

                >
                    { }
                    {row.nomcliente}
                </div>
            </div>
        </div>
    );
    const columns = [
        {
            name: 'Item',
            selector: row => row.item,
            sortable: true,
            grow: 7,

        },
        {
            name: 'Vendedor',
            selector: row => row.vendedor,
            sortable: true,
            grow: 1,
        },
        {
            name: 'Nombre',
            selector: row => row.nombreven,
            sortable: true,
            grow: 4,
        },
        {
            name: 'Valor',
            selector: row => row.valor,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Comision',
            selector: row => row.comision,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Total',
            selector: row => row.total,
            sortable: true,
            grow: 2,
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
    }
    const buscarpdf = () => {

        setPendiente(true) 
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/CtabonoApi/MovimientoDetalleCarteracomisionpdf/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + "/" + clasificacionarticulo1 + '?llave=' + dt.usuario.llaveempresa + '')
             .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Comision_Vendedor.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const totaltotal = () => {
        var tot = 0
        var facturasechas = []
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            if (!facturasechas.includes(element.tipo + element.nrodocumento)) {
                tot = tot + element.total
                facturasechas.push(element.tipo + element.nrodocumento)

            }


        }
        return tot
    }
    const totalseleccion = () => {
        var tot = 0
        var facturasechas = []
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            if (!facturasechas.includes(element.tipo + element.nrodocumento)) {
                tot = tot + (element.precio * element.cantidad)
                facturasechas.push(element.tipo + element.nrodocumento)

            }


        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalcomisionvendedor = () => {
        var total = 0
        ventasvendedor.map((dato) => {
            total = total + dato.comision
        })
        return total
    }

    const totaltotalvendedor = () => {
        var total = 0
        ventasvendedor.map((dato) => {
            total = total + dato.total
        })
        return total
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={buscarpdf}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> PDF
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            <Row  >
                <Col xs={2} onClick={() => {
                    setconcepto(!concepto)
                    setvendedor(false)
                    settercero(false)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Concepto
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(!tercero)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Cliente
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(!vendedor)
                    settercero(false)
                }}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Vendedor
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Movimientos Totales
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Totales Seleccion
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalseleccion())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Abonos</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Saldo</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={2}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.tipo}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.abonos)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.saldo)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {tercero &&
                ventasterceros.map((item, index) => {
                    return <>
                        {index == 0 && <Row>

                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}> Total</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Abonos</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Saldo</p>
                            </Col>
                        </Row>}
                        <Row >

                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold" style={{ color: 'black' }}>{item.nomcliente}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={3}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.abonos)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.saldo)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}
            {vendedor &&
                ventasvendedor.map((item, index) => {
                    return <>
                        {index == 0 && <Row>

                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Vendedor</p>
                            </Col>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Porcentaje</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Comision</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.vendedor == "" ? "Sin Vendedor" : item.vendedor}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={3}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.porcentaje)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={2} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.comision)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}
            {vendedor &&
                <Row >
                    <Col xs={4}>
                        <div className="card border-left-info shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{"Totales Vendedor"}</div>
                                            </div>
                                            <div className="col">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>

                    <Col xs={3}>

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(totaltotalvendedor())}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} >

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}></div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={2} >

                        <div className="card border-left-primary shadow ">
                            <div className="card-body p-2">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(totalcomisionvendedor())}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            }
            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    setreferenciainicioseleccionado={(e) => { setreferenciainicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}
                    setreferenciafinseleccionado={(e) => { setreferenciafinseleccionado(e) }}

                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    referenciafinseleccionado={referenciafinseleccionado}
                    referenciainicioseleccionado={referenciainicioseleccionado}
                    clasificacionarticulo1={clasificacionarticulo1}
                    setclasificacionarticulo1={setclasificacionarticulo1}

                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>


        </>
    )
}

export default ReporteVenta;