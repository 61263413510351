// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./Tree.css";
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
const tabespacio = '\u00A0';
const Tree = (props) => {
  return (
    <div className="d-tree">
      <ul className="d-flex d-tree-container flex-column">
        {props.data.map((tree) => (
          <TreeNode cambio={props.cambio} node={tree} />
        ))}
      </ul>
    </div>
  );
};

const TreeNode = (props) => {
  const [childVisible, setChildVisiblity] = useState(false);

  const hasChild = props.node.children ? true : false;

  return (
    <li className="d-tree-node border-0">
      <div className="d-flex">
        {hasChild && (
          <div
            className={`d-inline d-tree-toggler ${
              childVisible ? "active" : ""
            }`}
          >
            {/* <FontAwesomeIcon icon="caret-right" /> */}
          </div>
        )}

        <div className="col d-tree-head">
          {/* <i className={`mr-1 ${node.icon}`}> </i> */}
          <InputGroup  className={(hasChild)?"primary":""}>
          <a href="#"  onClick={(e) => setChildVisiblity((v) => !v)} className={`${props.node.icon}`}></a>
          <a href="#" onClick={()=>{props.cambio(props.node)}}  >{tabespacio+props.node.key+" - "+props.node.label}</a> 
          </InputGroup>
          
        </div>
      </div>

      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <Tree cambio={props.cambio} data={props.node.children} />
          </ul>
        </div>
      )}
    </li>
  );
};

export default Tree;