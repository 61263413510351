import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import BuscarMunicipio from '../componentes/Buscar2/Municipio'
const modeloPais = {
    codigo: "",
    nombre: "",
}
const ImportarExportar = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [usuario, setDataUser] = useState([]);
    const [fileD, setfileD] = useState([]);
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [abrirmunicipio, setabrirmunicipio] = useState(false);
    const [municipio, setmunicipio] = useState("");
    const [municipiojson, setmunicipiojson] = useState({});
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [codigotercero, setcodigotercero] = useState("");

    const obtenerPaiss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Paisapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        // obtenerPaiss('');
    }, [])
    const convertBlobToBase64 = async (blob) => { // blob data
        return await blobToBase64(blob);
    }
    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const recibirelemento = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const fileData = e.target.result;
            const blob = new Blob([fileData], { type: file.type });
            var base64data = ""
            base64data = await convertBlobToBase64(blob);
            setfileD(base64data)
        };

        reader.readAsArrayBuffer(file);
        // var blob = doc.output('blob')
        // var reader = new FileReader();
        // var base64data = ""
        // base64data = await convertBlobToBase64(blob);
        // console.log(base64data)
        // var pdf = {
        //     pdfbase64: base64data

        // }

    }


    const guardarCambiosinquilinos = async () => {
        try {

            let response;
            response = await fetch("" + props.servidor + "/MovimientoApi/actualizarfacturagas/" + usuario.empresa.empresa.nit + "/" + fechaInicio +"/"+codigotercero+ "/?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(municipiojson)
            })


            if (response.ok) {
                var textoerrores = await response.json()
                var data = textoerrores;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'facturas.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                Swal.fire(
                    'Guardado Exitosamente!',
                    'Facturas Generadas Exitosamente',
                    'success'
                )

                guardarlogdeerrores(textoerrores.mensaje)
            } else {
                var textoerrores = await response.json()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores.mensaje,
                    'error'
                )
                guardarlogdeerrores(textoerrores.mensaje)
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }
    const guardarlogdeerrores = (contenido) => {
        const a = document.createElement("a");
        const archivo = new Blob([contenido], { type: 'text/plain' });
        const url = URL.createObjectURL(archivo);
        a.href = url;
        a.download = 'log';
        a.click();
        URL.revokeObjectURL(url);
    }
    const guardarCambiospropietarios = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/ContratosInmobiliariaModelapi/bloque/propietario/" + fechahoy + "?llave=" + usuario.usuario.llaveempresa + "")



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Facturas Generadas Exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }

    return (
        <>
            <Card>

                <CardBody>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                                    Facturacion
                                </CardHeader>
                                <CardBody>
                                    <Row>

                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label style={{ fontSize: '13px' }}>Tercero:</Label>
                                                <Input bsSize="sm" type="text" className="form-control form-control-sm"
                                                    value={codigotercero}
                                                    onChange={(e) => setcodigotercero(e.target.value)}></Input>

                                            </FormGroup>
                                        </Col>
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                                                <Input bsSize="sm" type="date" className="form-control form-control-sm"
                                                    selected={fechaInicio}
                                                    value={fechaInicio}
                                                    onChange={(e) => setFechaInicio(e.target.value)}></Input>

                                            </FormGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Input value={municipio}
                                                    onKeyDown={() => {
                                                        setabrirmunicipio(true)
                                                    }}
                                                    type="text" bsSize="sm" />
                                            </FormGroup>
                                        </Col>
                                        <Button style={{ backgroundColor: '#188BAD', color: "white", marginTop: '10px' }} onClick={() => { guardarCambiosinquilinos() }}>FACTURAR</Button>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </CardBody>
            </Card>

            <BuscarMunicipio tituloencabezado={"Municipio"} abrir={abrirmunicipio} servidor={props.servidor} setabrirbanco={(ab) => { setabrirmunicipio(ab) }} seleccionar={(value) => {
                setmunicipio(value.nombremun)
                setmunicipiojson(value)
                setabrirmunicipio(false)
            }} />

        </>
    )
}
export default ImportarExportar;
