import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import { FormatMoney } from 'format-money-js'

const modeloCategoria = {
    idCategoria: 0,
    descripcion: "",
    esActivo: true
}

const Buscar = (props) => {

    const [categoria, setCategoria] = useState(modeloCategoria);
    const [pendiente, setPendiente] = useState(true);
    const [categorias, setCategorias] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [seleccionado, setseleccionado] = useState([]);
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const abrirEditarModal = (data) => {
        setCategoria(data);
        setVerModal(!verModal);
    }
    useEffect(() => {
        setseleccionado([])
    }, [])
    const cerrarModal = () => {
        setCategoria(modeloCategoria)
        setVerModal(!verModal);
    }
    const guardarCambios = () => {
        setVerModal(!verModal);
    }



    return (
        <>

            <Modal size={(props.tamaño == "sm") ? "sm" : "lg"} isOpen={props.verModal}>
                <ModalHeader style={{ backgroundColor: '#188BAD', color: 'white' }} >
                    {props.tituloencabezado}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <InputGroup>
                                    <Input bsSize="sm" name="departamento" id={'busquedaempresasmaestrousuario'} onKeyDown={(e) => {
                                        if (e.key == "Enter") {
                                            props.filtrar(document.getElementById('busquedaempresasmaestrousuario').value)

                                        }
                                    }} />
                                    <Button color="primary" size="sm" className="mr-2"
                                        onClick={() => {
                                            props.filtrar(document.getElementById('busquedaempresasmaestrousuario').value)


                                        }}
                                    >
                                        <i className="fas fa-pen-alt"></i>
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <div className="table-responsive" style={{ height: '300px' }}>


                                <Table striped size={(props.tamaño == "sm") ? "sm" : "lg"} >
                                    <thead>
                                        <tr>
                                            <th>sel</th>
                                            {
                                                props.encabezado.map((item, index) => (
                                                    <th>{item}</th>
                                                ))
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (props.busqueda.length < 1) ? (
                                                <tr>
                                                    <th >
                                                        No hay datos

                                                    </th>
                                                </tr>
                                            ) :
                                                (
                                                    props.busqueda.map((item, index) => (
                                                        <tr onClick={() => {
                                                            console.log(props.busqueda[index][props.codigo])
                                                            // setseleccionado(props.busqueda[index])
                                                        }} key={(index + 1)}>
                                                            <td><input type="checkbox" checked={seleccionado.includes(props.busqueda[index]) ? true : false} onChange={async (e) => {


                                                                if (true) {
                                                                    var ff = seleccionado.map((dato) => {
                                                                        return dato
                                                                    })
                                                                    if (!ff.includes(props.busqueda[index])) {
                                                                        ff.push(props.busqueda[index])

                                                                    } else {
                                                                        ff = ff.filter(p => p != props.busqueda[index])
                                                                    }
                                                                    setseleccionado(ff)
                                                                }
                                                            }}></input></td>

                                                            {
                                                                (props.codigo != "") && <th >
                                                                    <a style={{ color: 'black' }} href="#">{props.busqueda[index][props.codigo]}</a>

                                                                </th>
                                                            }
                                                            {
                                                                (props.nombre != "") && <th >
                                                                    <a style={{ color: 'black' }} href="#">{props.busqueda[index][props.nombre]}</a>


                                                                </th>
                                                            }
                                                            {(props.dato != "") && <th >
                                                                <a style={{ color: 'black' }} href="#">{props.busqueda[index][props.dato]}</a>


                                                            </th>}
                                                            {(props.dato2 != "") && <th >
                                                                <a style={{ color: 'black' }} href="#">{props.busqueda[index][props.dato2]}</a>

                                                            </th>}
                                                            {(props.dato3 != "") && <th >
                                                                <a style={{ color: 'black' }} href="#">{Number(props.busqueda[index][props.dato3]) ? fm2.from(props.busqueda[index][props.dato3]) : props.busqueda[index][props.dato3]}</a>

                                                            </th>}
                                                        </tr>
                                                    ))
                                                )


                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>

                    </Row>





                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        props.seleccionar(seleccionado)
                        setseleccionado([])
                    }}>Seleccionar</Button>
                    <Button size="sm" color="danger" onClick={() => { props.cerrarModal() }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>



    )
}

export default Buscar;