import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloConclasiarticulos = {
    codigo: "",
    nombre: "",
}
const Conclasiarticulos = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Conclasiarticulos, setConclasiarticulos] = useState(modeloConclasiarticulos);
    const [Conclasiarticuloss, setConclasiarticuloss] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalConclasiarticulos, setVerModalConclasiarticulos] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Conclasiarticulos = { ...Conclasiarticulos }
        _Conclasiarticulos[e.target.name] = value
        setConclasiarticulos(_Conclasiarticulos)
        setRoles([])


    }
    const obtenerConclasiarticuloss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Conclasiarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setConclasiarticuloss(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerConclasiarticuloss('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalConclasiarticulos(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalConclasiarticulos = (data) => {
        var enviodata = { ...data }
        var _Conclasiarticulos = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        }
        setConclasiarticulos(_Conclasiarticulos);
        setVerModalConclasiarticulos(!verModalConclasiarticulos);
        seteditar(true)
    }
    const cerrarModalConclasiarticulos = () => {
        setConclasiarticulos(modeloConclasiarticulos)
        setVerModalConclasiarticulos(!verModalConclasiarticulos);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Conclasiarticulos) => {

        let claves = Object.keys(_Conclasiarticulos);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Conclasiarticulos[clave] = Number(_Conclasiarticulos[clave])
            }
        }
        return _Conclasiarticulos
    }
    const verificar = (_Conclasiarticulos) => {

        let claves = Object.keys(_Conclasiarticulos);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' || clave == 'nombre') {
                if (_Conclasiarticulos[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Conclasiarticulos)
        var _Conclasiarticulos = { ...Conclasiarticulos }
        _Conclasiarticulos = asignarceros(_Conclasiarticulos)
        var _verificado = verificar(_Conclasiarticulos)
        if (_verificado) {
            console.log(_Conclasiarticulos)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Conclasiarticulosapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Conclasiarticulos)
                })

            } else {
                response = await fetch("" + props.servidor + "/Conclasiarticulosapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Conclasiarticulos)
                })
            }

            if (response.ok) {
                await obtenerConclasiarticuloss('');
                setConclasiarticulos(modeloConclasiarticulos)
                setVerModalConclasiarticulos(!verModalConclasiarticulos);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Conclasiarticulos fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Conclasiarticulos',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Conclasiarticuloss
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataConclasiarticuloss" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerConclasiarticuloss(document.getElementById('buscardataConclasiarticuloss').value)}>Buscar</Button>
                            {/* <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalConclasiarticulos(!verModalConclasiarticulos)
                                seteditar(false)
                            }}>Nuevo</Button> */}
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Conclasiarticuloss}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalConclasiarticulos}>
                <ModalHeader>
                    Detalle Conclasiarticuloss
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Conclasiarticulos.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Conclasiarticulos.nombre} />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalConclasiarticulos}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Conclasiarticulos;
