import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import Tree from "../componentes/Tree";
import * as XLSX from "xlsx"

const modeloClasiarticulos = {
    codigo: "",
    nombre: "",
    conclasificacion: "",
    conclasificacionnombre: "",
}
const Clasiarticulos = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Clasiarticulos, setClasiarticulos] = useState(modeloClasiarticulos);
    const [Clasiarticuloss, setClasiarticuloss] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [conclasificacion, setconclasificacion] = useState([]);
    const [conclasificacions, setconclasificacions] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalClasiarticulos, setVerModalClasiarticulos] = useState(false);
    const [busquedasubcodigos, setbusquedasubcodigos] = useState([]);
    const [verModalsubcodigo, setVerModalsubcodigo] = useState(false);
    const [subcodigos, setsubcodigos] = useState([]);
    const [treee, settreee] = useState([]);

    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Clasiarticulos = { ...Clasiarticulos }
        _Clasiarticulos[e.target.name] = value
        setClasiarticulos(_Clasiarticulos)
        setRoles([])


    }
    const filtrarsubcodigos = async (coincidencia) => {
        var _subcodigos = subcodigos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedasubcodigos(_subcodigos)
    }
    const cerrarModalSeleccionsubcodigos = () => {
        setVerModalsubcodigo(!verModalsubcodigo);
        setbusquedasubcodigos(subcodigos)
    }
    const seleccionarsubcodigo = (e) => {
        let value = e
        var _Ctcentrocosto = { ...Clasiarticulos }
        if (_Ctcentrocosto.codigo != value.codigo) {
            _Ctcentrocosto['conclasificacion'] = value.codigo
            _Ctcentrocosto['conclasificacionnombre'] = value.nombre
        }

        setClasiarticulos(_Ctcentrocosto)
        setRoles([])
        cerrarModalSeleccionsubcodigos()
    }
    const obtenerClasiarticuloss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Clasiarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setClasiarticuloss(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerClasiarticuloss('');
        obtenerConclasiarticuloss('')
    }, [])
    const seleccionardatoarbol = (e) => {
        seteditar(true)
        let value = e
        console.log(e)
        var _Ctcentrocosto = { ...Clasiarticulos }
        _Ctcentrocosto['codigo'] = value.key
        _Ctcentrocosto['nombre'] = value.label

        _Ctcentrocosto['conclasificacion'] = value.padre
        _Ctcentrocosto['conclasificacionnombre'] = value.nompadre

        setClasiarticulos(_Ctcentrocosto)
        setRoles([])


    }

    const crerarbol = (tabla, subcodigo) => {
        var devuelto = []
        var fff= tabla[0]
        var padres = tabla.filter((dato) => {
            if (dato.conclasificacion == subcodigo) {
                return dato
             }

        })
        if (padres.length == 0) {

            return []
        } else {
            padres.forEach(dato => {
                devuelto.push({
                    key: dato.codigo,
                    padre: dato.conclasificacion,
                    nompadre: dato.conclasificacionnombre,
                    label: dato.nombre,
                    icon: "fa fa-plus",
                    title: dato.nombre,
                    children: crerarbol(tabla, dato.codigo)
                })
            });
        }
        return devuelto
    }
    const obtenerConclasiarticuloss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Clasiarticulosapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');
            if (response.ok) {
                let data = await response.json()
                var dd=data.filter(p=>p.conclasificacion=="" && p.nombre!="")
                setbusquedasubcodigos(dd)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Clasificacion',
            selector: row => row.conclasificacion,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => {
                            var treecreado = crerarbol(Clasiarticuloss, "")
                            settreee(treecreado)
                            obtenerClasiarticuloss('')
                            abrirEditarModalClasiarticulos(row)
                        }}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalClasiarticulos = (data) => {
        var enviodata = { ...data }
        var _Clasiarticulos = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
            conclasificacion: enviodata.conclasificacion,
        }
        setClasiarticulos(_Clasiarticulos);
        setVerModalClasiarticulos(!verModalClasiarticulos);
        seteditar(true)
    }
    const cerrarModalClasiarticulos = () => {
        setClasiarticulos(modeloClasiarticulos)
        setVerModalClasiarticulos(!verModalClasiarticulos);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Clasiarticulos) => {

        let claves = Object.keys(_Clasiarticulos);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Clasiarticulos[clave] = Number(_Clasiarticulos[clave])
            }
        }
        return _Clasiarticulos
    }
    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(Clasiarticuloss);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Clasificaciones.xlsx")
    }
    const verificar = (_Clasiarticulos) => {

        let claves = Object.keys(_Clasiarticulos);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' || clave == 'nombre') {
                if (_Clasiarticulos[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Clasiarticulos)
        var _Clasiarticulos = { ...Clasiarticulos }
        _Clasiarticulos = asignarceros(_Clasiarticulos)
        var _verificado = verificar(_Clasiarticulos)
        if (_verificado) {
            console.log(_Clasiarticulos)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Clasiarticulosapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Clasiarticulos)
                })

            } else {
                response = await fetch("" + props.servidor + "/Clasiarticulosapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Clasiarticulos)
                })
            }

            if (response.ok) {
                await obtenerClasiarticuloss('');
                setClasiarticulos(modeloClasiarticulos)
                setVerModalClasiarticulos(!verModalClasiarticulos);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Clasiarticulos fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Clasiarticulos',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Clasiarticuloss
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataClasiarticuloss" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerClasiarticuloss(document.getElementById('buscardataClasiarticuloss').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                var treecreado = crerarbol(Clasiarticuloss, "")
                                settreee(treecreado)
                                setVerModalClasiarticulos(!verModalClasiarticulos)
                                seteditar(false)
                            }}>Nuevo</Button>
                            <Col sm={2}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setclasiarticulos(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Button color="success" size="sm" block onClick={exportarExcel}>
                                        <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Clasiarticuloss}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalClasiarticulos}>
                <ModalHeader>
                    Detalle Clasiarticuloss
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Clasiarticulos.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Clasiarticulos.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup inline={true}>
                                        <Label>Clasificacion*</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedasubcodigos(subcodigos)
                                                    setVerModalsubcodigo(true)
                                                    obtenerConclasiarticuloss('')

                                                }
                                            }} name="conclasificacion" value={Clasiarticulos.conclasificacion} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedasubcodigos(subcodigos)
                                                    setVerModalsubcodigo(true)
                                                    obtenerConclasiarticuloss('')

                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Clasificacion*</Label>
                                        <Input disabled type="text" bsSize="sm" onChange={handleChange} name="conclasificacionnombre" value={Clasiarticulos.conclasificacionnombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} style={{ backgroundColor: '#188BAD', color: 'white' }} >
                                    <p>Clasificaciones</p>

                                </Col>
                                <Row className="table-responsive" style={{ height: '200px' }} >
                                    <Tree cambio={(dato) => { seleccionardatoarbol(dato) }} data={treee} />
                                </Row>
                            </Row>
                            <Buscar filtrar={(coincidencia) => { filtrarsubcodigos(coincidencia) }} busqueda={busquedasubcodigos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalsubcodigo} cerrarModal={() => { cerrarModalSeleccionsubcodigos() }} seleccionar={(seleccionado) => {
                                seleccionarsubcodigo(seleccionado)
                            }} />
                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button color="success" className="ml-2" size="sm" onClick={() => {
                        cerrarModalClasiarticulos()
                        setVerModalClasiarticulos(true)
                        seteditar(false)
                        obtenerClasiarticuloss('')
                        var treecreado = crerarbol(Clasiarticuloss, "")
                        settreee(treecreado)
                    }}>Nuevo</Button>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalClasiarticulos}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Clasiarticulos;
