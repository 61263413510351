import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import App from './App';
import Categoria from './views/Categoria';
import DashBoard from './views/DashBoard';
import HistorialVenta from './views/HistorialVenta';
import Inicio from './views/Inicio';
import NotFound from './views/NotFound';
import Producto from './views/Producto';
import ReporteVenta from './views/ReporteVenta';
import Usuario from './views/Usuario';
import Venta from './views/Venta';
import Login from './views/Login';
import Pestañas from './views/Pestañas';

import UserProvider from "./context/UserProvider"
import MensajesProvider from "./context/MensajesProvider"
import PermisosProvider from "./context/PermisosProvider"
import VerificarUsuario from './componentes/VerificarUsuario';
import {servidor,servidorempresa} from './config'
// export const servidor="https://localhost:44352"
// export const servidorempresa="https://localhost:44351"
// export const servidor="http://factin.ddns.net:5021"
// export const servidorempresa="http://factin.ddns.net:5020"
    // const [servidor, setservidor] = React.useState("https://192.168.1.87:44352");
    // const [servidorempresa, setservidorempresa] = React.useState("https://192.168.1.87:44351");

    // const [servidor, setservidor] = React.useState("http://192.168.1.100:5021");
    // const [servidorempresa, setservidorempresa] = React.useState("http://192.168.1.100:5020");

    // const [servidor, setservidor] = React.useState("http://factin.ddns.net:5021");
    // const [servidorempresa, setservidorempresa] = React.useState("http://factin.ddns.net:5020");

const root = ReactDOM.createRoot(document.getElementById('wrapper'));

root.render(
    <BrowserRouter>
        <UserProvider>
        <MensajesProvider>
        <PermisosProvider>
            <Routes>

                {/*ACONTINUACION ESTABLECEMOS LAS RUTAS DE NUESTRO SISTEMA*/}

                {/*ruta individual sin usar una como base*/}
                <Route index path='/Login' element={<Login servidor={servidor} servidorempresa={servidorempresa}/>} />

                {/*Permite anidar rutas en base a una*/}
                <Route path='/' element={<App servidor={servidor} servidorempresa={servidorempresa}/>}>

                    <Route index element={<Inicio />} />
                    {/* <Route path='dashboard' element={<VerificarUsuario> <DashBoard /> </VerificarUsuario>} /> */}
                    <Route path='dashboard' element={<VerificarUsuario> <Pestañas /> </VerificarUsuario>} />
                    <Route path='usuario' element={<VerificarUsuario> <Usuario /> </VerificarUsuario>} />
                    <Route path='producto' element={<VerificarUsuario> <Producto /> </VerificarUsuario>} />
                    <Route path='categoria' element={<VerificarUsuario> <Categoria /> </VerificarUsuario>} />
                    <Route path='venta' element={<VerificarUsuario> <Venta /> </VerificarUsuario>} />
                    <Route path='historialventa' element={<VerificarUsuario> <HistorialVenta /> </VerificarUsuario>} />
                    <Route path='reporteventa' element={<VerificarUsuario > <ReporteVenta /> </VerificarUsuario>} />

                </Route>
                <Route path='*' element={<NotFound />} />
                
            </Routes>

        </PermisosProvider>
        </MensajesProvider>
        </UserProvider>
        

    </BrowserRouter>
);

