import React, { useState } from "react"
import { createContext } from "react"


export const MensajeContext = createContext()

const MensajesProvider = ({ children }) => {
    const [mensajelocal, setMensajelocal] = useState(window.localStorage.getItem("mensajeslocal"))


    const obtenerMensajeslocal = (data) => {
        window.localStorage.setItem("mensajeslocal", JSON.stringify(data))
        setMensajelocal(JSON.stringify(data))
    }

    const borrarMensajeslocal = () => {
        window.localStorage.removeItem("mensajeslocal")
        setMensajelocal(null)
    }

    return (
        <MensajeContext.Provider value={{ mensajelocal, obtenerMensajeslocal, borrarMensajeslocal }}>
            {children}
        </MensajeContext.Provider>
    )
}

export default MensajesProvider