import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { Table, Row, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Col } from "reactstrap"
import Swal from 'sweetalert2'
import { UserContext } from "../context/UserProvider";

const modeloGrupo = {
    nitempresa: "",
    codigogrupo: "",
    nombreempresa: "",
    nombregrupo: "",
    modulos: [{
        id: 0,
        codigomodulo: "",
        nombremodulo: "",
        editar: true,
        buscar: true,
        imprimir: true,
        eliminar: true,
        adicionar: true,
        electronico: true
    }]
}

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}
const Grupo = (props) => {

    const [grupo, setGrupo] = useState(modeloGrupo);
    const [pendiente, setPendiente] = useState(true);
    const [grupos, setGrupos] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [modulos, setmodulos] = useState([]);
    const [render, setrender] = useState([]);
    const [empresas, setempresas] = useState([]);
    const [todaslasempresas, settodaslasempresas] = useState(false);
    const [editar, seteditar] = useState(false);
    const [dataUser, setDataUser] = useState(modelo)
    const { user } = useContext(UserContext)

    const handleChange = (e) => {
        let value = e.target.value
        console.log(grupo)


        if (e.target.name == 'nitempresa') {
            setGrupo({
                ...grupo,
                [e.target.name]: value,
                ['nombreempresa']: e.target.options[e.target.selectedIndex].text
            })
        } else {
            setGrupo({
                ...grupo,
                [e.target.name]: value

            })
        }

        console.log(grupo)
    }

    const obtenerEmpresas = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Empresaapi/nombres/' + coincidencia);
        let dt = JSON.parse(user)

        if (response.ok) {
            let data = await response.json()
            if (props.usuariounico) {
                var data2 = data.map((dat) => {
                    if (dat.nit == dt.empresa.empresa.nit) {
                        return dat
                    }

                })
                data2 = data2.filter(p => p != undefined)
                setempresas(data2)
            } else {
                setempresas(data)
            }
        }
    }
    const obtenerModulos = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Moduloapi/' + coincidencia);
        var modulosconpermisos = [];
        var modulosconpermisosdefecto = [];
        if (response.ok) {
            let data = await response.json()
            console.log(data)
            var grupo = {
                nitempresa: "",
                codigogrupo: "",
                nombreempresa: "",
                nombregrupo: "",
                modulos: []
            };
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                modulosconpermisos.push({
                    codigomodulo: element.codigo,
                    nombremodulo: element.nombre,
                    editar: true,
                    buscar: true,
                    imprimir: true,
                    eliminar: true,
                    adicionar: true,
                    electronico: true,

                })
                modulosconpermisosdefecto.push({
                    codigomodulo: element.codigo,
                    nombremodulo: element.nombre,
                    editar: true,
                    buscar: true,
                    imprimir: true,
                    eliminar: true,
                    adicionar: true,
                    electronico: true,

                })
            }
            grupo.modulos = modulosconpermisos
            setGrupo(grupo)
            setmodulos(modulosconpermisosdefecto)
        }
    }
    const obtenerGrupos = async (coincidencia) => {
        let response = await fetch(props.servidor + '/Grupoapi/' + coincidencia);
        let dt = JSON.parse(user)

        if (response.ok) {
            let data = await response.json()
            if (props.usuariounico) {
                var data2 = data.map((dat) => {
                    if (dat.nitempresa == dt.empresa.empresa.nit) {
                        return dat
                    }

                })
                data2 = data2.filter(p => p != undefined)
                setGrupos(data2)
                setPendiente(false)
            } else {
                setGrupos(data)
                setPendiente(false)
            }

        }
    }

    const handleChangemodulos = (e, index) => {

        console.log(e.target.getAttribute('value'))

        var value;
        value = e.target.getAttribute('value');
        var moduloscambio = { ...grupo }.modulos.map((modulo, index2) => {
            if (index2 == index) {
                if (value != 'false') {
                    var mod = {
                        ...modulo,
                        [e.target.getAttribute('name')]: false
                    }
                    return mod
                } else {
                    var mod = {
                        ...modulo,
                        [e.target.getAttribute('name')]: true
                    }
                    return mod

                }

            } else {
                return modulo
            }
        });


        var empresa = []
        setrender(empresa)
        setGrupo({
            ...grupo,
            modulos: moduloscambio
        })

        console.log(moduloscambio)
    }

    useEffect(() => {
        obtenerGrupos('');
        obtenerModulos('');
        obtenerEmpresas('');
        seteditar(false);
    }, [])


    const columns = [
        {
            name: 'Grupo',
            selector: row => row.codigogrupo,
            sortable: true,
        },
        {
            name: 'Nombre Grupo',
            selector: row => row.nombregrupo,
            sortable: true,
        },
        {
            name: 'Empresa',
            selector: row => row.nitempresa,
            sortable: true,
        },
        {
            name: 'Nombre Empresa',
            selector: row => row.nombreempresa,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="danger" size="sm"
                        onClick={() => eliminarGrupo(row.idGrupo)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const abrirEditarModal = (data) => {
        setGrupo(data);
        setVerModal(!verModal);
        seteditar(true)
    }

    const cerrarModal = () => {
        modeloGrupo.modulos = modulos
        setGrupo(modeloGrupo)
        setVerModal(!verModal);
        seteditar(false)
        // obtenerModulos('');
        settodaslasempresas(false)
    }

    const guardarCambios = async () => {

        if ((grupo.nitempresa != "" || todaslasempresas) && grupo.codigogrupo != "" && grupo.nombregrupo != "") {

            let response;
            var grupoenvio = []
            if (!todaslasempresas) {
                for (let i = 0; i < grupo.modulos.length; i++) {
                    grupoenvio.push({
                        id: grupo.modulos[i].id,
                        nitempresa: grupo.nitempresa,
                        codigogrupo: grupo.codigogrupo,
                        nombreempresa: grupo.nombreempresa,
                        nombregrupo: grupo.nombregrupo,
                        codigomodulo: grupo.modulos[i].codigomodulo,
                        nombremodulo: grupo.modulos[i].nombremodulo,
                        editar: grupo.modulos[i].editar,
                        buscar: grupo.modulos[i].buscar,
                        imprimir: grupo.modulos[i].imprimir,
                        eliminar: grupo.modulos[i].eliminar,
                        adicionar: grupo.modulos[i].adicionar,
                        electronico: grupo.modulos[i].electronico

                    })
                }
            } else {
                for (let i = 0; i < empresas.length; i++) {
                    const emp = empresas[i];
                    for (let j = 0; j < grupo.modulos.length; j++) {
                        grupoenvio.push({
                            id: grupo.modulos[i].id,
                            nitempresa: emp.nit,
                            codigogrupo: grupo.codigogrupo,
                            nombreempresa: emp.nombre,
                            nombregrupo: grupo.nombregrupo,
                            codigomodulo: grupo.modulos[j].codigomodulo,
                            nombremodulo: grupo.modulos[j].nombremodulo,
                            editar: grupo.modulos[j].editar,
                            buscar: grupo.modulos[j].buscar,
                            imprimir: grupo.modulos[j].imprimir,
                            eliminar: grupo.modulos[j].eliminar,
                            adicionar: grupo.modulos[j].adicionar,
                            electronico: grupo.modulos[j].electronico

                        })
                    }

                }
            }
            if (!editar) {


                console.log(grupoenvio)
                response = await fetch(props.servidor + "/Grupoapi/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(grupoenvio)
                })

            } else {
                response = await fetch(props.servidor + "/Grupoapi/", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(grupoenvio)
                })
            }

            if (response.ok) {
                await obtenerGrupos('');
                modeloGrupo.modulos = modulos
                setGrupo(modeloGrupo)
                setVerModal(!verModal);
                settodaslasempresas(false)

                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Grupo fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Grupo',
                    'error'
                )
            }

        } else {
            Swal.fire(
                'Datos Incompletos!',
                'Tiene que completar todos los Datos',
                'error'
            )


        }
    }


    const eliminarGrupo = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desesa eliminar esta grupo",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/grupo/Eliminar/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            obtenerGrupos();

                            Swal.fire(
                                'Eliminado!',
                                'La grupo fue eliminada.',
                                'success'
                            )
                        }
                    })

            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Grupos
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6" >
                        <div className="input-group input-group-sm mb-3" >
                            <div className="input-group-prepend" >

                                <input type="text" id="buscardataGrupos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerGrupos(document.getElementById('buscardataGrupos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo</Button>

                        </div>
                    </div>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={grupos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal size="lg" isOpen={verModal}>
                <ModalHeader>
                    Grupo
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Codigo</Label>
                                <Input disabled={editar} bsSize="sm" name="codigogrupo" onChange={handleChange} value={grupo.codigogrupo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre</Label>
                                <Input bsSize="sm" name="nombregrupo" onChange={handleChange} value={grupo.nombregrupo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Empresa</Label>
                                <Input disabled={todaslasempresas} bsSize="sm" type={"select"} name="nitempresa" config={"true"} onChange={handleChange} value={grupo.nitempresa} >
                                    <option value={0}>Seleccionar</option>
                                    {
                                        empresas.map((item) => (<option value={item.nit}>{item.nombre}</option>))
                                    }
                                </Input>

                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Seleccionar todas las Empresas</Label>
                                <Input disabled={editar} bsSize="sm" type={"select"} name="nitempresa" config={"true"} onChange={(e) => { settodaslasempresas(e.target.value == 0 ? false : true) }} >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>

                                </Input>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>

                            <Table striped size="sm" className="table-responsive" style={{ height: '300px' }}>
                                <thead>
                                    <tr>
                                        <th>Codigo</th>
                                        <th>Nombre</th>
                                        <th>Editar</th>
                                        <th>Buscar</th>
                                        <th>Imprimir</th>
                                        <th>Eliminar</th>
                                        <th>Adicionar</th>
                                        <th>Electronico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (grupo.modulos.length < 1) ? (
                                            <tr>
                                                <td colSpan="5">Sin modulos</td>
                                            </tr>
                                        ) :
                                            (
                                                grupo.modulos.map((item, index) => (
                                                    <tr key={(index + 1)}>

                                                        <td >{item.codigomodulo}</td>
                                                        <td>{item.nombremodulo}</td>
                                                        <td>{<a href="#" value={item.editar} onClick={(e) => { handleChangemodulos(e, index) }} name='editar' className={item.editar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.editar ? "Activo" : "No Activo"}</a>}</td>
                                                        <td>{<a href="#" value={item.buscar} onClick={(e) => { handleChangemodulos(e, index) }} name='buscar' className={item.buscar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.buscar ? "Activo" : "No Activo"}</a>}</td>
                                                        <td>{<a href="#" value={item.imprimir} onClick={(e) => { handleChangemodulos(e, index) }} name='imprimir' className={item.imprimir ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.imprimir ? "Activo" : "No Activo"}</a>}</td>
                                                        <td>{<a href="#" value={item.eliminar} onClick={(e) => { handleChangemodulos(e, index) }} name='eliminar' className={item.eliminar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.eliminar ? "Activo" : "No Activo"}</a>}</td>
                                                        <td>{<a href="#" value={item.adicionar} onClick={(e) => { handleChangemodulos(e, index) }} name='adicionar' className={item.adicionar ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.adicionar ? "Activo" : "No Activo"}</a>}</td>
                                                        <td>{<a href="#" value={item.electronico} onClick={(e) => { handleChangemodulos(e, index) }} name='electronico' className={item.electronico ? "badge badge-info p-2" : "badge badge-danger p-2"}>{item.electronico ? "Activo" : "No Activo"}</a>}</td>

                                                    </tr>
                                                ))
                                            )


                                    }
                                </tbody>
                            </Table>
                        </Col>

                    </Row>
                    {/* grilla */}
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>



    )
}

export default Grupo;