import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import { Autocomplete, TextField } from '@mui/material';

const modeloMediodepago = {
    codigo: "",
    nombre: "",
    codigodian: "",
    semanejaencaja: "Si",
    cuenta: "",
}
const Mediodepago = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Mediodepago, setMediodepago] = useState(modeloMediodepago);
    const [Mediodepagos, setMediodepagos] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [nombre, setnombre] = useState([]);
    const [nombres, setnombres] = useState([]);
    const [codigodian, setcodigodian] = useState([]);
    const [codigodians, setcodigodians] = useState([]);
    const [busquedacodigodians, setbusquedacodigodians] = useState([]);
    const [verModalcodigodian, setVerModalcodigodian] = useState(false);
    const [semanejaencaja, setsemanejaencaja] = useState([]);
    const [semanejaencajas, setsemanejaencajas] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [Plandecuentass, setPlandecuentass] = useState([]);

    const [pendiente, setPendiente] = useState(true);
    const [verModalMediodepago, setVerModalMediodepago] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Mediodepago = { ...Mediodepago }
        _Mediodepago[e.target.name] = value
        setMediodepago(_Mediodepago)
        setRoles([])
    }

    const handleChangegrillafaencmovi = async (e, value, name, codi, fila) => {

       
      
        var vll = ''
        if (value) {
            vll = value[codi]
        }
        console.log(e.target)
        var _Mediodepago = { ...Mediodepago }
        _Mediodepago['cuenta'] = vll
        setMediodepago(_Mediodepago)
        setRoles([])

    }
    
    const obtenerPlandecuentass = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Plandecuentasapi/ultimonivel?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setPlandecuentass(data)
                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const seleccionarcodigodian = (e) => {
        let value = e
        var _Mediodepago = { ...Mediodepago }
        _Mediodepago['codigodian'] = value
        setMediodepago(_Mediodepago)
        setRoles([])
        cerrarModalSeleccioncodigodians()
    }
    const buscartodascodigodians = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Mediodepagodianapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setcodigodians(dataJson)
                    setbusquedacodigodians(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setcodigodians([])
                    setbusquedacodigodians([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerMediodepagos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Mediodepagoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setMediodepagos(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const borrartodaslascodigodians = () => {
        setbusquedacodigodians([])
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerMediodepagos('');
        buscartodascodigodians('');
        obtenerPlandecuentass('');
    }, [])
    const filtrarcodigodians = async (coincidencia) => {
        var _codigodians = codigodians.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedacodigodians(_codigodians)
    }
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'Codigo Dian',
            selector: row => row.codigodian,
            sortable: true,
        },
        {
            name: 'Mostrar en Caja',
            selector: row => row.semanejaencaja,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalMediodepago(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalMediodepago = (data) => {
        var enviodata = { ...data }
        var _Mediodepago = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
            codigodian: enviodata.codigodian,
            semanejaencaja: enviodata.semanejaencaja,
            cuenta: enviodata.cuenta,
        }
        setMediodepago(_Mediodepago);
        setVerModalMediodepago(!verModalMediodepago);
        seteditar(true)
    }
    const cerrarModalMediodepago = () => {
        setMediodepago(modeloMediodepago)
        setVerModalMediodepago(!verModalMediodepago);
        seteditar(false)
        setKey('Datos')
    }
    const cerrarModalSeleccioncodigodians = () => {
        setVerModalcodigodian(!verModalcodigodian);
        setbusquedacodigodians(codigodians)
    }
    const asignarceros = (_Mediodepago) => {

        let claves = Object.keys(_Mediodepago);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Mediodepago[clave] = Number(_Mediodepago[clave])
            }
        }
        return _Mediodepago
    }
    const verificar = (_Mediodepago) => {

        let claves = Object.keys(_Mediodepago);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'codigo' || clave == 'nombre' || clave == 'codigodian' || clave == 'semanejaencaja') {
                if (_Mediodepago[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Mediodepago)
        var _Mediodepago = { ...Mediodepago }
        _Mediodepago = asignarceros(_Mediodepago)
        var _verificado = verificar(_Mediodepago)
        if (_verificado) {
            console.log(_Mediodepago)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Mediodepagoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Mediodepago)
                })

            } else {
                response = await fetch("" + props.servidor + "/Mediodepagoapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Mediodepago)
                })
            }

            if (response.ok) {
                await obtenerMediodepagos('');
                setMediodepago(modeloMediodepago)
                setVerModalMediodepago(!verModalMediodepago);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Medio de Pago fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Medio de Pagos
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataMediodepagos" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerMediodepagos(document.getElementById('buscardataMediodepagos').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalMediodepago(!verModalMediodepago)
                                seteditar(false)
                                buscartodascodigodians('')
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcrearmediodepago(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Mediodepagos}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalMediodepago}>
                <ModalHeader>
                    Medios de Pago
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Codigo*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Mediodepago.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Mediodepago.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup inline={true}>
                                        <Label style={{ fontSize: '13px' }}>Codigo Dian*</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedacodigodians(codigodians)
                                                    setVerModalcodigodian(true)

                                                }
                                            }} name="codigodian" value={Mediodepago.codigodian} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedacodigodians(codigodians)
                                                    setVerModalcodigodian(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Mostrar en Caja*</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="semanejaencaja" value={Mediodepago.semanejaencaja}  >
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Cuenta</Label>
                                        <Autocomplete
                                            onChange={(e, value) => { handleChangegrillafaencmovi(e, value, 'cuenta', 'codcuenta') }}

                                            disablePortal
                                            options={Plandecuentass}
                                            getOptionLabel={(clasi) => clasi.codcuenta + ' - ' + clasi.descripcion}
                                            value={Plandecuentass.filter(p => p.codcuenta == Mediodepago.cuenta)[0]}
                                            renderInput={(params) => <TextField {...params} size="small" style={{ width: '200px', borderRadius: '4px' }}  />}
                                            maxHeight={150}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>

                        </Tab>
                    </ Tabs >
                    <Buscar filtrar={(coincidencia) => { filtrarcodigodians(coincidencia) }} busqueda={busquedacodigodians} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalcodigodian} cerrarModal={() => { cerrarModalSeleccioncodigodians() }} seleccionar={(seleccionado) => {
                        seleccionarcodigodian(seleccionado.codigo)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalMediodepago}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Mediodepago;
